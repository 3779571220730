import styled from 'styled-components';

const S = {
  OpenAppInterstitial: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 ${props => props.theme.margin.mobile.page};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.actionColor.brand};
    z-index: 5000;
  `,

  LogoWrapper: styled.div`
    padding: ${props => props.theme.spacing.XS};
  `,

  Logo: styled.img`
    height: 72px;
  `,

  Close: styled.div`
    position: absolute;
    top: ${props => props.theme.spacing.MD};
    right: ${props => props.theme.spacing.MD};
  `,

  AppButton: styled.div`
    margin: ${props => props.theme.spacing['5XL']} 0
      ${props => props.theme.spacing.MD};
  `,
};
export default S;
