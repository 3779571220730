import styled, {DefaultTheme} from 'styled-components';
import {
  media,
  mediaContained,
  MediaSize,
} from '@brightlive/shared/styles/breakpoints-v3';
import {getHeadingXS} from '@brightlive/shared/styles/typography-v3';

const S: Record<string, React.ElementType> = {};

export type Align = 'start' | 'center' | 'end';

const maxCols = {
  XS: 4,
  SM: 8,
  MD: 12,
  LG: 12,
  XL: 12,
};

const alignment = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
};

const getCollapse = (collapse: MediaSize[]) => {
  const queries = collapse.map((size: MediaSize) => {
    return `
      ${mediaContained[size]} {
        display: none;
      }
    `;
  });
  return queries.join(' ');
};

const getColReorder = (pos: 'first' | 'last', reorder: MediaSize[]) => {
  const posCSS = pos === 'first' ? -1 : 1;
  const queries = reorder.map((size: MediaSize) => {
    return `
      ${mediaContained[size]} {
        order: ${posCSS};
      }
    `;
  });
  return queries.join(' ');
};

const getOffset = ({size, offset}: {size: MediaSize; offset?: number}) => {
  if (!offset) return '0';
  const offsetPerc = offset / maxCols[size];
  return `${Math.round(offsetPerc * 100000000) / 1000000}%`;
};

const getFlex = ({size, colCount}: {size: MediaSize; colCount?: number}) => {
  if (!colCount) return 'flex: 1;';
  const flexPerc = colCount / maxCols[size];
  const flexPercRounded = `${Math.round(flexPerc * 100000000) / 1000000}%`;

  return `
    -ms-flex-preferred-size: ${flexPercRounded};
    flex-basis: ${flexPercRounded};
    max-width: ${flexPercRounded};
  `;
};

S.Grid = styled.div`
  width: 100%;
  padding: 0 ${props => props.theme.spacing.MD};
  box-sizing: border-box;

  ${media.SM} {
    padding: 0 ${props => props.theme.spacing.LG};
  }

  ${media.MD} {
    padding: 0 ${props => props.theme.spacing['5XL']};
  }

  ${media.LG} {
    padding: 0;
    width: 1320px;
    margin: 0 auto;
  }

  // if adding new XL layout - add width: 1704px to an XL media query here
`;

S.Row = styled.div(
  (props: {
    $align?: Align;
    $smAlign?: Align;
    $mdAlign?: Align;
    $lgAlign?: Align;
    $xlAlign?: Align;
    theme: DefaultTheme;
  }) => `
  display: flex;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-wrap: wrap;
  justify-content: ${props.$align ? alignment[props.$align] : 'flex-start'};
  margin-right: -${props.theme.spacing.MD};
  margin-left: -${props.theme.spacing.MD};

  ${media.SM} {
    margin-right: -${props.theme.spacing.LG};
    margin-left: -${props.theme.spacing.LG};
    ${props.$smAlign ? `justify-content: ${alignment[props.$smAlign]};` : ''};
  }

  ${media.MD} {
    ${props.$mdAlign ? `justify-content: ${alignment[props.$mdAlign]};` : ''};
  }

  ${media.LG} {
    ${props.$lgAlign ? `justify-content: ${alignment[props.$lgAlign]};` : ''};
  }

  ${media.XL} {
    ${props.$xlAlign ? `justify-content: ${alignment[props.$xlAlign]};` : ''};
  }
`
);

S.Col = styled.div(
  (props: {
    $xs?: number;
    $sm?: number;
    $md?: number;
    $lg?: number;
    $xl?: number;
    $xsOffset?: number;
    $smOffset?: number;
    $mdOffset?: number;
    $lgOffset?: number;
    $xlOffset?: number;
    $collapse?: MediaSize[];
    $first?: MediaSize[];
    $last?: MediaSize[];
    theme: DefaultTheme;
  }) => `
  box-sizing: border-box;
  flex: 0 0 auto;
  ${getFlex({size: 'XS', colCount: props.$xs})}
  padding-right: ${props.theme.spacing.MD};
  padding-left: ${props.theme.spacing.MD};
  margin-left: ${
    props.$xsOffset ? getOffset({size: 'XS', offset: props.$xsOffset}) : ''
  };

  ${media.SM} {
    ${getFlex({size: 'SM', colCount: props.$sm ?? props.$xs})}
    padding-right: ${props.theme.spacing.LG};
    padding-left: ${props.theme.spacing.LG};
    ${
      typeof props.$xsOffset === 'number' || typeof props.$smOffset === 'number'
        ? `margin-left: ${getOffset({
            size: 'SM',
            offset: props.$smOffset ?? props.$xsOffset,
          })};`
        : ''
    }
  }

  ${media.MD} {
    ${getFlex({size: 'MD', colCount: props.$md ?? props.$sm ?? props.$xs})}
    ${
      typeof props.$xsOffset === 'number' ||
      typeof props.$smOffset === 'number' ||
      typeof props.$mdOffset === 'number'
        ? `margin-left: ${getOffset({
            size: 'MD',
            offset: props.$mdOffset ?? props.$smOffset ?? props.$xsOffset,
          })};`
        : ''
    }
  }

  ${media.LG} {
    ${getFlex({
      size: 'LG',
      colCount: props.$lg ?? props.$md ?? props.$sm ?? props.$xs,
    })}
    ${
      props.$lgOffset
        ? `margin-left: ${getOffset({
            size: 'LG',
            offset: props.$lgOffset,
          })};`
        : ''
    }
  }

  ${media.XL} {
    ${getFlex({
      size: 'XL',
      colCount: props.$xl ?? props.$lg ?? props.$md ?? props.$sm ?? props.$xs,
    })}

    ${
      props.$xlOffset
        ? `margin-left: ${getOffset({
            size: 'XL',
            offset: props.$xlOffset,
          })};`
        : ''
    }
  }

  ${props.$collapse ? getCollapse(props.$collapse) : ''}
  ${props.$first ? getColReorder('first', props.$first) : ''}
  ${props.$last ? getColReorder('last', props.$last) : ''}
`
);

export default S;

// For storybook examples
export const SampleCell = styled.div`
  ${props => getHeadingXS(props.theme)}
  padding: ${props => props.theme.spacing.XS};
  margin: ${props => props.theme.spacing.XS} 0;
  border: ${props =>
    `${props.theme.borderWidth[1]} solid ${props.theme.borderColor.default}`};
  text-align: center;
  background-color: ${props => props.theme.backgroundColor.secondary};
`;
