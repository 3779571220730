import React, {useEffect, useState} from 'react';
import {useInterval} from '@brightlive/shared/hooks/useInterval';
// Style
import S from './style';
import {toggleAlertBanner} from 'redux/ui/actions';
import {useDispatch} from 'react-redux';

export const AlertBanner = ({
  type,
  text,
  persist,
  navExpanded = false,
}: {
  type: 'alert' | 'warning';
  text: string;
  persist: boolean;
  navExpanded?: boolean;
}) => {
  const dispatch = useDispatch();
  const [alertTime, setAlertTime] = useState(0);

  useInterval(() => {
    const alertTimeout = (alertTime + 10000 - Date.now()) / 1000;
    if (alertTimeout <= 0 && !persist) {
      // reset state
      dispatch(toggleAlertBanner('', false, 'warning'));
    }
  }, 1000);

  useEffect(() => {
    setAlertTime(Date.now());
  }, [text]);
  return (
    <>
      {text && (
        <S.AlertBanner $alertType={type} $navExpanded={navExpanded}>
          <S.AlertBannerText $alertType={type}>{text}</S.AlertBannerText>
        </S.AlertBanner>
      )}
    </>
  );
};
