import {useDispatch, useSelector} from 'react-redux';
import React, {Dispatch, useEffect, useMemo, useRef, useState} from 'react';
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
// Components
import {ParticipantProps, Role, useParticipant, ViewMode} from 'bright-livekit';
import Avatar from '@brightlive/shared/components/Avatar';
import IconButton from '@brightlive/shared/components/IconButton';
import Menu from '@brightlive/shared/components/Menu';
import Accordion from '@brightlive/shared/components/Accordion';
import Divider from '@brightlive/shared/components/Divider';
import ContextualAlert from '@brightlive/shared/components/ContextualAlert';
import TextInput from '@brightlive/shared/components/TextInput';
import Button from '@brightlive/shared/components/Button';
import TooltipWrapper from '@brightlive/shared/components/TooltipWrapper';
import Modal from '@brightlive/shared/components/Modal';
// Icons
import SignalCellular3 from '@brightlive/shared/icons/SignalCellular3';
import SignalCellular2 from '@brightlive/shared/icons/SignalCellular2';
import SignalCellular1 from '@brightlive/shared/icons/SignalCellular1';
import MicOn from '@brightlive/shared/icons/MicOn';
import VideocamOn from '@brightlive/shared/icons/VideocamOn';
import VolumeUp from '@brightlive/shared/icons/VolumeUp';
// Actions
import {
  toggleDeleteCommentModal,
  toggleFlagUserModal,
} from 'redux/livekit/actions';
import {AuthReducer} from 'redux/auth/reducer';
import {useInterval} from '@brightlive/shared/hooks/useInterval';
// Helpers
import {MenuItem} from '@brightlive/shared/helpers/interfaces';
// Style
import S from './style';

export const SessionListParticipantView = ({
  participant,
  aspectWidth,
  aspectHeight,
  orientation,
  sessionID,
  roomState,
  replaceQuestion,
}: ParticipantProps) => {
  const dispatch = useDispatch();
  const authToken =
    useSelector((state: AuthReducer) => state.auth.auth.authToken) || '';

  const accordionContainerRef = useRef<HTMLDivElement>(null);

  const {isVideoMuted, isAudioMuted} = useParticipant(
    participant?.videoParticipant
  );
  const {isMobile, width, height} = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [editName, setEditName] = useState(false);
  const [displayName, setDisplayName] = useState(
    roomState.currentUser.displayName
  );
  const [showActionBar, setShowActionBar] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [recordingStatus, setRecordingStatus] = useState(false);
  const [videoDevice, setVideoDevice] = useState<string | null>(null);
  const [speakerDevice, setSpeakerDevice] = useState<string | null>(null);
  const [audioDevice, setAudioDevice] = useState<string | null>(null);
  const [overlayPosition, setOverlayPosition] = useState({top: 0, left: 0});
  const [actionBarPosition, setActionBarPosition] = useState({top: 0});
  const [audioHz, setAudioHz] = useState(0);
  const [resolution, setResolution] = useState(720);
  const [fps, setFps] = useState(0);
  const [connQuality, setConnQuality] = useState<string>('unknown');
  const [errorMessage, setErrorMessage] = useState('');

  useInterval(() => {
    const part = participant?.videoParticipant;
    setErrorMessage(participant?.errorMessage ?? '');
    if (!part || recordingStatus) {
      return;
    }
    if (part.videoTracks.length > 0) {
      const vidTrack = part.videoTracks[0];
      setResolution(vidTrack.dimensions.height);
      const newFPS = Math.round(vidTrack.framerate);
      setFps(newFPS === Infinity ? 0 : newFPS);
    }
    if (part.audioTracks.length > 0) {
      const audTrack = part.audioTracks[0];
      setAudioHz(audTrack.audioHz);
    }
    setConnQuality(part.connectionQuality);
  }, 1000);

  useEffect(() => {
    // If we are currently recording and this user is on stage use the remote values for recording status set by the recorder bot
    if (roomState.isRecording && participant?.recordingStatus?.onStage) {
      setRecordingStatus(true);
      setResolution(participant.recordingStatus.videoResolution ?? 720);
      setAudioHz(participant.recordingStatus.audiokHz ?? 0);
      setFps(participant.recordingStatus.videoFramerate ?? 30);
      setConnQuality(
        participant.recordingStatus.connectionQuality ?? 'unknown'
      );
    } else {
      setRecordingStatus(false);
    }
  }, [
    participant?.recordingStatus?.videoFramerate,
    participant?.recordingStatus?.videoResolution,
    participant?.recordingStatus?.audiokHz,
    participant?.recordingStatus?.videoFramerate,
    participant?.recordingStatus?.connectionQuality,
  ]);

  // Find a default or saved value for each device
  function findDevice(
    kind: string,
    savedDevice: string | null,
    set: Dispatch<React.SetStateAction<string | null>>,
    devices: MediaDeviceInfo[]
  ) {
    if (savedDevice) {
      const activeDevice = devices.find(
        device => device.deviceId === savedDevice
      );
      set(activeDevice?.label ?? 'N/A');
    } else {
      const defaultDevice = devices.find(
        device => device.deviceId === 'default' && device.kind === kind
      );
      set(defaultDevice?.label ?? 'N/A');
    }
  }

  const getMediaDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    if (devices?.length) {
      // Pull local devices saved during selection
      const savedSpeakerDevice = window?.localStorage.getItem(
        'livekit.audioOutput'
      );
      const savedVideoDevice =
        window?.localStorage.getItem('livekit.videoInput');
      const savedAudioDevice =
        window?.localStorage.getItem('livekit.audioInput');

      findDevice('videoinput', savedVideoDevice, setVideoDevice, devices);
      findDevice('audiooutput', savedSpeakerDevice, setSpeakerDevice, devices);
      findDevice('audioinput', savedAudioDevice, setAudioDevice, devices);
    }
    return;
  };

  useEffect(() => {
    getMediaDevices();
  }, []);

  const reclacHoverPositions = () => {
    if (accordionContainerRef?.current) {
      const rect = accordionContainerRef.current?.getBoundingClientRect();
      setActionBarPosition({top: rect.y - 20});
    }
  };

  // Recalc the hover container positions when particiants leave and join are made
  useEffect(() => reclacHoverPositions(), [roomState.participantsPresent]);

  useEffect(() => {
    reclacHoverPositions();
  }, [accordionContainerRef, width, height]);

  const internetConnectionBars = useMemo(() => {
    if (connQuality === 'excellent') {
      return <SignalCellular3 size={24} contentColor="positive" />;
    } else if (connQuality === 'good') {
      return (
        <S.InternetConnBars>
          <SignalCellular3 size={24} contentColor="disabled" />
          <S.InternetConnBar>
            <SignalCellular2 size={24} contentColor="warning" />
          </S.InternetConnBar>
        </S.InternetConnBars>
      );
    } else if (connQuality === 'poor') {
      return (
        <S.InternetConnBars>
          <SignalCellular3 size={24} contentColor="disabled" />
          <S.InternetConnBar>
            <SignalCellular1 size={24} contentColor="negative" />
          </S.InternetConnBar>
        </S.InternetConnBars>
      );
    } else {
      return <SignalCellular3 size={24} contentColor="disabled" />;
    }
  }, [connQuality]);

  if (!participant || !participant.videoParticipant) {
    return <></>;
  }

  const currentUserIs = {
    creator: roomState.isCreator,
    mod: roomState.modControls,
    guest: !roomState.modControls,
  };

  const participantIs = {
    currentUser: participant.id === roomState.currentUser.id,
    guest: participant.role !== Role.Creator && !participant.isShowRunner,
    mod: participant.role !== Role.Creator && participant.isShowRunner,
    creator: participant.role === Role.Creator,
    onStage: [Role.Creator, Role.OnStage].includes(participant.role),
    offStage: ![Role.Creator, Role.OnStage].includes(participant.role),
  };

  // when aspect matches, cover instead
  if (!orientation && aspectWidth && aspectHeight) {
    orientation = aspectWidth > aspectHeight ? 'landscape' : 'portrait';
  }

  if (!showActionBar && showOptions) {
    setShowOptions(false);
  }

  const addVideoToStage = () => {
    if (!roomState.stageFull && participantIs.offStage) {
      if (participantIs.currentUser) {
        roomState.videoUtils?.moveOnStage(
          sessionID,
          roomState.currentUser.id,
          ViewMode.Video
        );
      } else {
        roomState.videoUtils?.inviteOnStage(
          sessionID,
          roomState.currentUser.id,
          participant.id,
          ViewMode.Video
        );
      }
    } else {
      roomState.videoUtils?.removeFromStage(
        sessionID,
        roomState.currentUser.id,
        participant.id,
        !!roomState.modControls
      );
    }
    setShowOptions(false);
  };
  const handleAudioToggle = () => {
    if (participantIs.currentUser) {
      roomState.videoService?.localParticipant?.setMicrophoneEnabled(
        isAudioMuted
      );
    } else {
      if (isAudioMuted) {
        roomState.videoUtils?.askToUnmute(
          sessionID,
          roomState.currentUser.id,
          participant.id
        );
      } else {
        roomState.videoUtils?.muteUser(
          sessionID,
          participant.id,
          'audio',
          authToken
        );
      }
    }
  };
  const handleVideoToggle = () => {
    if (participantIs.currentUser) {
      roomState.videoService?.localParticipant?.setCameraEnabled(isVideoMuted);
    } else {
      if (isVideoMuted) {
        roomState.videoUtils?.askToTurnOnCamera(
          sessionID,
          roomState.currentUser.id,
          participant.id
        );
      } else {
        roomState.videoUtils?.muteUser(
          sessionID,
          participant.id,
          'video',
          authToken
        );
      }
    }
  };

  const moreMenuItems = (): MenuItem[] => {
    const hasComment = participant.question && !participant.questionAnswered;

    const items: MenuItem[] = [];
    // All Options for selfControls
    if (participantIs.currentUser) {
      const selfControls: MenuItem[] = [];
      // If not a mod/creator, you have the ability to bring yourself off stage
      if (!currentUserIs.creator && participantIs.onStage) {
        selfControls.push({
          label: 'Leave stage',
          onClick: addVideoToStage,
        });
      }
      // If you have a comment and are not on stage
      // You can delete your question
      // MODs can show or hide there question
      if (hasComment) {
        if (participantIs.offStage) {
          selfControls.push(
            {
              label: 'Replace question',
              onClick: () => {
                // toggle question input in question tab
                if (replaceQuestion) {
                  replaceQuestion();
                  setShowOptions(false);
                }
              },
            },
            {
              label: 'Delete question',
              onClick: () => {
                dispatch(
                  toggleDeleteCommentModal({
                    toggled: true,
                    participant: participant,
                  })
                );
                setShowOptions(false);
              },
            }
          );
          selfControls.push({isLine: true});
        }
        if (
          participantIs.onStage &&
          currentUserIs.mod &&
          !participantIs.creator
        ) {
          selfControls.push(
            {
              label:
                participant.viewMode === ViewMode.Question
                  ? 'Hide question'
                  : 'Show question',
              onClick: () => {
                roomState.videoUtils?.changeViewMode(
                  sessionID,
                  participant.id,
                  participant.viewMode === ViewMode.Question
                    ? ViewMode.Video
                    : ViewMode.Question
                );
                setShowOptions(false);
              },
            },
            {
              label: 'Delete question',
              onClick: () => {
                dispatch(
                  toggleDeleteCommentModal({
                    toggled: true,
                    participant: participant,
                  })
                );
                setShowOptions(false);
              },
            }
          );
          selfControls.push({isLine: true});
        }
      }
      const editNameControls: MenuItem[] = [
        {
          label: 'Edit name',
          onClick: () => {
            setEditName(true);
            setShowOptions(false);
          },
        },
      ];
      selfControls.push(...editNameControls);
      items.push(...selfControls);
    } else {
      if (currentUserIs.mod && !participantIs.creator) {
        // Is the user we are acting on not on stage (Full moderator controls)
        if (participantIs.offStage) {
          if (hasComment) {
            items.push({
              label: 'Delete question',
              onClick: () => {
                dispatch(
                  toggleDeleteCommentModal({
                    toggled: true,
                    participant: participant,
                  })
                );
                setShowOptions(false);
              },
            });
            items.push({isLine: true});
          }
        } else if (participantIs.onStage) {
          items.push({
            label: 'Take offstage',
            onClick: addVideoToStage,
          });
          if (hasComment) {
            items.push({
              label: `${
                participant.viewMode === ViewMode.Question ? 'Hide' : 'Show'
              } question`,
              onClick: () => {
                roomState.videoUtils?.changeViewMode(
                  sessionID,
                  participant.id,
                  participant.viewMode === ViewMode.Question
                    ? ViewMode.Video
                    : ViewMode.Question
                );
                setShowOptions(false);
              },
            });
            items.push({
              label: 'Delete question',
              onClick: () => {
                dispatch(
                  toggleDeleteCommentModal({
                    toggled: true,
                    participant: participant,
                  })
                );
                setShowOptions(false);
              },
            });
          }
          items.push({isLine: true});
        }
      }
      // Add ban or unban button
      if (!participantIs.currentUser) {
        // Add ability to remove user from session
        items.push({
          label:
            participant.role === Role.Banned
              ? 'Add to session'
              : 'Remove from session',
          negative: participant.role === Role.Banned ? false : true,
          onClick: () => {
            if (participant.role === Role.Banned) {
              roomState.videoUtils?.unban(sessionID, participant.id);
            } else {
              dispatch(
                toggleFlagUserModal({
                  toggled: true,
                  participant: participant,
                })
              );
            }
            setShowOptions(false);
          },
        });
      }
    }
    return items;
  };

  const handleSaveDisplayName = async () => {
    setLoading(true);
    roomState.videoUtils?.updateDisplayName(
      sessionID,
      roomState.currentUser.id,
      displayName
    );
    setEditName(false);
    setLoading(false);
  };

  const AttendeeInfo = () => {
    return (
      <S.AttendeeTopBar>
        <S.AttendeeAvatarContainer>
          <Avatar
            size="small"
            avatar={participant.avatar}
            displayName={participant.displayName}
          />
        </S.AttendeeAvatarContainer>
        <S.AttendeeInfo>
          <S.AttendeeNameWrapper>
            <S.AttendeeName>{participant.displayName}</S.AttendeeName>
            <S.AttendeeYou>
              {participantIs.currentUser ? '(You)' : null}
            </S.AttendeeYou>
          </S.AttendeeNameWrapper>
          <S.AttendeeRole>
            {participant.role === Role.Creator ? 'host' : 'guest'}
          </S.AttendeeRole>
        </S.AttendeeInfo>
        <S.AttendeeTopBarActions>
          {currentUserIs.mod && participantIs.offStage && (
            <TooltipWrapper
              tooltipContent={
                participantIs.currentUser ? 'Join stage' : 'Bring to stage'
              }
              referenceContent={
                <IconButton
                  contentColor="subdued"
                  size="small"
                  type="tertiary"
                  icon="PersonAdd"
                  onClick={addVideoToStage}
                />
              }
            />
          )}
          {currentUserIs.mod &&
            (!participantIs.creator || currentUserIs.creator) &&
            roomState.screenShareUser === participant.id &&
            roomState.screenShareTrack?.isSubscribed && (
              <TooltipWrapper
                tooltipContent="Stop screenshare"
                referenceContent={
                  <IconButton
                    contentColor="subdued"
                    icon="ScreenShare"
                    size="small"
                    type="tertiary"
                    onClick={() =>
                      roomState.videoService?.toggleScreenshare(false, true)
                    }
                  />
                }
              />
            )}
          {participantIs.onStage && (
            <TooltipWrapper
              tooltipContent={isAudioMuted ? 'Unmute' : 'Mute'}
              referenceContent={
                <IconButton
                  icon={isAudioMuted ? 'MicOff' : 'MicOn'}
                  contentColor={isAudioMuted ? 'negative' : 'subdued'}
                  size="small"
                  type="tertiary"
                  disabled={!participantIs.currentUser && participantIs.creator}
                  onClick={handleAudioToggle}
                />
              }
            />
          )}
          <TooltipWrapper
            tooltipContent={
              isVideoMuted
                ? participantIs.currentUser
                  ? 'Turn on camera'
                  : 'Ask to turn on camera'
                : 'Turn off camera'
            }
            referenceContent={
              <IconButton
                icon={isVideoMuted ? 'VideocamOff' : 'VideocamOn'}
                contentColor={isVideoMuted ? 'negative' : 'subdued'}
                size="small"
                type="tertiary"
                disabled={!participantIs.currentUser && participantIs.creator}
                onClick={handleVideoToggle}
              />
            }
          />
          {isMobile &&
          (participantIs.currentUser ||
            (currentUserIs.mod && !participantIs.creator)) ? (
            <IconButton
              icon="AndroidMore"
              contentColor="default"
              size="small"
              type="tertiary"
              onClick={e => {
                if (isMobile) {
                  setShowOptions(!showOptions);
                } else {
                  const rect = e.target.getBoundingClientRect();
                  setOverlayPosition({
                    top: rect.y - 45,
                    left: rect.x - 200,
                  });
                  setShowOptions(!showOptions);
                }
              }}
            />
          ) : null}
        </S.AttendeeTopBarActions>
      </S.AttendeeTopBar>
    );
  };

  const Attendee = () => {
    if (currentUserIs.mod || participantIs.currentUser) {
      return (
        <S.AccordionContainer ref={accordionContainerRef}>
          <Accordion
            text={AttendeeInfo()}
            size="xs"
            smPadding={true}
            content={
              <S.ConnectionDetails>
                <S.InternetConnection>
                  {internetConnectionBars}
                  <S.ConnectionEyebrow>INTERNET CONNECTION</S.ConnectionEyebrow>
                  {errorMessage && (
                    <S.ConnectionAlert>
                      <ContextualAlert type="warning" text={errorMessage} />
                    </S.ConnectionAlert>
                  )}
                </S.InternetConnection>
                {'poor' === connQuality && (
                  <S.ConnectionAlert>
                    <ContextualAlert
                      type="warning"
                      text="Your internet connection is unstable. Check your internet
                          connection."
                    />
                  </S.ConnectionAlert>
                )}
                <S.QualityContainer>
                  <S.QualityStat>
                    <S.QualityHeader>
                      {resolution}p · {fps}fps
                    </S.QualityHeader>
                    <S.ConnectionEyebrow>VIDEO QUALITY</S.ConnectionEyebrow>
                  </S.QualityStat>
                  <S.QualityStat>
                    <S.QualityHeader>
                      {participant.id !== roomState.currentUser.id &&
                      isAudioMuted
                        ? 'N/A'
                        : `${audioHz / 1000}kHz`}
                    </S.QualityHeader>
                    <S.ConnectionEyebrow>AUDIO QUALITY</S.ConnectionEyebrow>
                  </S.QualityStat>
                </S.QualityContainer>
                {participant.id === roomState.currentUser.id && (
                  <S.Devices>
                    <S.DeviceWrapper>
                      <MicOn contentColor="moreSubdued" />
                      <S.DeviceInfo>
                        <S.DeviceHeader>Microphone</S.DeviceHeader>
                        <S.DeviceText>{audioDevice ?? 'N/A'}</S.DeviceText>
                      </S.DeviceInfo>
                    </S.DeviceWrapper>
                    <Divider />
                    <S.DeviceWrapper>
                      <VideocamOn contentColor="moreSubdued" />
                      <S.DeviceInfo>
                        <S.DeviceHeader>Camera</S.DeviceHeader>
                        <S.DeviceText>{videoDevice ?? 'N/A'}</S.DeviceText>
                      </S.DeviceInfo>
                    </S.DeviceWrapper>
                    <Divider />
                    <S.DeviceWrapper>
                      <VolumeUp contentColor="moreSubdued" />
                      <S.DeviceInfo>
                        <S.DeviceHeader>Speaker</S.DeviceHeader>
                        <S.DeviceText>{speakerDevice ?? 'N/A'}</S.DeviceText>
                      </S.DeviceInfo>
                    </S.DeviceWrapper>
                  </S.Devices>
                )}
              </S.ConnectionDetails>
            }
          />
          {showActionBar && !isMobile && (
            <>
              <S.ActionBar
                style={{
                  top: actionBarPosition.top,
                }}
              >
                <TooltipWrapper
                  tooltipContent="More actions"
                  referenceContent={
                    <IconButton
                      type="tertiary"
                      size="small"
                      icon="AndroidMore"
                      onClick={e => {
                        if (isMobile) {
                          setShowOptions(!showOptions);
                        } else {
                          const rect = e.target.getBoundingClientRect();
                          setOverlayPosition({
                            top: rect.y + 45,
                            left: rect.x - 200,
                          });
                          setShowOptions(!showOptions);
                        }
                      }}
                    />
                  }
                />
              </S.ActionBar>
            </>
          )}
        </S.AccordionContainer>
      );
    } else {
      return <S.GuestContainer>{AttendeeInfo()}</S.GuestContainer>;
    }
  };

  return (
    <S.Attendee
      onMouseEnter={() => {
        setShowActionBar(true);
        reclacHoverPositions();
      }}
      onMouseLeave={() => {
        setShowActionBar(false);
        setShowOptions(false);
        setEditName(false);
        setDisplayName(roomState.currentUser.displayName);
      }}
    >
      {editName && !isMobile ? (
        <S.EditContainer>
          <TextInput
            value={displayName}
            label="Display name"
            autoFocus
            onChange={e => {
              e.preventDefault();
              setDisplayName(e.target.value);
            }}
            keyPressHandler={handleSaveDisplayName}
          />
          <S.EditButtons>
            <Button
              text="Cancel"
              size="small"
              type="tertiary"
              onClick={async () => {
                setEditName(false);
                setDisplayName(roomState.currentUser.displayName);
              }}
            />
            <Button
              text="Save"
              size="small"
              type="primary"
              disabled={displayName.length <= 0}
              loading={loading}
              onClick={handleSaveDisplayName}
            />
          </S.EditButtons>
        </S.EditContainer>
      ) : (
        Attendee()
      )}
      {editName && isMobile ? (
        <Modal
          closeModal={async () => {
            setEditName(false);
            setDisplayName(roomState.currentUser.displayName);
          }}
          buttons={{
            button1Text: 'Cancel',
            button2Text: 'Save',
            button2OnClick: handleSaveDisplayName,
          }}
        >
          <S.ModalContent>
            <S.ModalTitle>Edit display name</S.ModalTitle>
            <TextInput
              value={displayName}
              label="Display name"
              autoFocus
              onChange={e => {
                e.preventDefault();
                setDisplayName(e.target.value);
              }}
              keyPressHandler={handleSaveDisplayName}
            />
          </S.ModalContent>
        </Modal>
      ) : null}

      {showOptions && !isMobile && (
        <S.MenuHoverContainer
          style={{
            top: overlayPosition.top,
          }}
        >
          <Menu
            isMobile={false}
            type="default"
            onClose={() => {
              setShowOptions(false);
            }}
            menuItems={moreMenuItems()}
          />
        </S.MenuHoverContainer>
      )}
      {showOptions && isMobile && (
        <Menu
          isMobile={true}
          type="default"
          onClose={() => setShowOptions(false)}
          menuItems={moreMenuItems()}
        />
      )}
    </S.Attendee>
  );
};
