import React, {useState} from 'react';
import {RoomState, TrackType} from 'bright-livekit';
// Hooks
import {useInterval} from '@brightlive/shared/hooks/useInterval';
// Styles
import S from './style';

export const StatsDashboard = ({roomState}: {roomState: RoomState}) => {
  const [time, setTime] = useState(0);
  useInterval(() => {
    setTime(Date.now());
  }, 1000);
  let i = 0;

  return (
    <S.StatsContainer>
      <S.StatsTable>
        <thead>
          <S.StatsRow>
            <S.StatsHead>Name</S.StatsHead>
            <S.StatsHead>Role</S.StatsHead>
            <S.StatsHead>Conn. Quality</S.StatsHead>
            <S.StatsHead>Simulcast</S.StatsHead>
            <S.StatsHead>Subscriptions</S.StatsHead>
            <S.StatsHead>Bitrate</S.StatsHead>
            <S.StatsHead>Video Dimensions</S.StatsHead>
          </S.StatsRow>
        </thead>
        <tbody>
          {roomState.participantsPresent.map(part => {
            const vidTrack = part.videoParticipant?.getTrack(TrackType.Video);
            const audioTrack = part.videoParticipant?.getTrack(TrackType.Audio);
            const local = vidTrack?.isLocal;
            return (
              <S.StatsRow key={`stat-${part.id}`} odd={!!(i++ % 2)}>
                <S.StatsCell>{part.displayName}</S.StatsCell>
                <S.StatsCell>{part.role}</S.StatsCell>
                <S.StatsCell>
                  {part.videoParticipant?.connectionQuality}
                </S.StatsCell>
                <S.StatsCell>
                  {local || vidTrack?.simulcasted ? 'Yes' : 'No'}
                </S.StatsCell>
                <S.StatsCell>
                  Video: {vidTrack?.isSubscribed ? 'Yes' : 'No'}
                  <br />
                  Audio: {audioTrack?.isSubscribed ? 'Yes' : 'No'}
                </S.StatsCell>
                <S.StatsCell>
                  Video:
                  {` ${Math.floor(
                    (vidTrack?.currentBitrate || 0) / 1000
                  )} kbps`}
                  <br />
                  Audio:
                  {` ${Math.floor(
                    (audioTrack?.currentBitrate || 0) / 1000
                  )} kbps`}
                </S.StatsCell>
                <S.StatsCell>
                  {vidTrack?.dimensions?.width}x{vidTrack?.dimensions?.height}
                </S.StatsCell>
              </S.StatsRow>
            );
          })}
        </tbody>
      </S.StatsTable>
      <div>Stats Time: {new Date(time).toISOString()}</div>
    </S.StatsContainer>
  );
};
