import types from './types';

export const setToken = (token: string) => ({
  type: types.SET_TOKEN,
  token,
});
/**
 * Set current tab for session
 *
 * @return {void}
 */
export const setCurrentTab = tab => ({
  type: types.SET_CURRENT_TAB,
  tab,
});

/**
 * Toggle Flag User Modal
 *
 * @return {void}
 */
export const toggleFlagUserModal = data => ({
  type: types.TOGGLE_FLAG_USER_MODAL,
  data,
});

/**
 * Toggle Flag User Modal
 *
 * @return {void}
 */
export const toggleDeleteCommentModal = data => ({
  type: types.TOGGLE_DELETE_COMMENT_MODAL,
  data,
});
