import styled, {DefaultTheme} from 'styled-components';
import {Property} from 'csstype';

const S = {
  Video: styled.video(
    (props: {
      $doRotate: boolean;
      width: string;
      height: string;
      $maxHeight: string;
      $borderTopLeftRadius?: Property.BorderRadius;
      $borderTopRightRadius?: Property.BorderRadius;
      $borderBottomLeftRadius?: Property.BorderRadius;
      $borderBottomRightRadius?: Property.BorderRadius;
      $objectFit?: Property.ObjectFit;
      theme: DefaultTheme;
    }) => `
    transform: ${props.$doRotate ? 'rotateY(180deg)' : ''};
    width: ${props.width};
    height: ${props.height};
    object-position: center;
    min-height: 0;
    min-width: 0;
    max-height: ${props.$maxHeight ? props.$maxHeight : 'inherit'};
    border-top-right-radius: ${
      props.$borderTopRightRadius ? props.$borderTopRightRadius : '0'
    };
    border-bottom-right-radius: ${
      props.$borderBottomRightRadius ? props.$borderBottomRightRadius : '0'
    };
    border-top-left-radius: ${
      props.$borderTopLeftRadius ? props.$borderTopLeftRadius : '0'
    };
    border-bottom-left-radius: ${
      props.$borderBottomLeftRadius ? props.$borderBottomLeftRadius : '0'
    };
    object-fit: ${props.$objectFit ? props.$objectFit : 'inherit'};
`
  ),
};
export default S;
