import React from 'react';
//Components
import IconButton from '@brightlive/shared/components/IconButton';
import Button from '@brightlive/shared/components/Button';
// Images
import BrightLogo from 'public/images/logo.svg';
// Helpers
import {downloadLinks} from '@brightlive/shared/helpers/downloadLinks';
// Styles
import S from './style';

export const OpenAppInterstitial = ({onClose}: {onClose: Function}) => {
  const openApp = () => {
    window.location.replace('com.brightlive.sessions.ios://');

    setTimeout(() => {
      window.location.replace(downloadLinks.iOS);
    }, 2000);
  };

  return (
    <S.OpenAppInterstitial>
      <S.Close>
        <IconButton
          icon="Close"
          size="medium"
          type="tertiary"
          contentColor="inverse"
          onClick={onClose}
        />
      </S.Close>
      <div>
        <S.Logo src={BrightLogo} />
      </div>
      <S.AppButton>
        <Button
          type="primary inverse"
          width="100%"
          size="medium"
          text="Open in the Bright app"
          onClick={openApp}
        />
      </S.AppButton>
      <Button
        type="tertiary inverse"
        width="100%"
        size="medium"
        text="Continue in browser"
        onClick={onClose}
      />
    </S.OpenAppInterstitial>
  );
};
