import React from 'react';
//Components
import Modal from '@brightlive/shared/components/Modal';
import Button from '@brightlive/shared/components/Button';
// Icons
import Flag from '@brightlive/shared/icons/Flag';
// Styles
import S from './style';

export const BannedModal = ({onClose}: {onClose: () => void}) => {
  return (
    <Modal closeModal={onClose}>
      <S.ModalContent>
        <S.FlagBackground>
          <Flag size={27} contentColor="white" />
        </S.FlagBackground>
        <S.ModalTitle>You have been flagged by the host</S.ModalTitle>
        <S.ModalBody>
          The host or moderator have identified that you have violated our{' '}
          <S.Link href="/terms-of-service" target="_blank" rel="noreferrer">
            Terms and Conditions
          </S.Link>{' '}
          for safe and respectable behavior. You may still watch the session,
          but can no longer comment or be seen by others. For more information,
          visit our{' '}
          <S.Link
            href="https://support.brightlive.com/hc/en-us"
            target="_blank"
            rel="noreferrer"
          >
            Help Center
          </S.Link>
          .
        </S.ModalBody>
        <Button type="primary" size="medium" text="Got it" onClick={onClose} />
      </S.ModalContent>
    </Modal>
  );
};
