import {Track} from 'livekit-client';
import {ITrack} from './ITrack';

export class LivekitTrack implements ITrack {
  track: Track;
  constructor(track: Track) {
    this.track = track;
  }
  public get isMuted(): boolean {
    return this.track.isMuted;
  }
  public get sid(): string | undefined {
    return this.track.sid;
  }
  public attach(element: HTMLMediaElement): HTMLMediaElement | undefined {
    return this.track.attach(element);
  }
  public detach(element?: HTMLMediaElement): HTMLMediaElement[] | undefined {
    if (!element) {
      return this.track.detach();
    } else {
      const res = this.track.detach(element);
      return res ? [res] : [];
    }
  }
  public isEqual(track: unknown): boolean {
    return this.track === track;
  }
}
