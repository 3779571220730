import React, {useState} from 'react';
import Link from 'next/link';
// Images
import ChevronLeft from '../../icons/ChevronLeft';
// Style
import S from './style';

export interface BreadcrumbProps {
  text: string;
  onClick?: Function;
  href?: string;
}

const Breadcrumb = ({text, onClick, href}: BreadcrumbProps) => {
  const [underlined, setUnderlined] = useState(false);

  const getContent = () => {
    return (
      <S.Breadcrumb
        onTouchStart={() => {
          setUnderlined(true);
        }}
        onMouseDown={() => {
          setUnderlined(true);
        }}
        onMouseEnter={() => {
          setUnderlined(true);
        }}
        onMouseLeave={() => {
          setUnderlined(false);
        }}
        onClick={onClick}
      >
        <ChevronLeft size={24} contentColor="accent" />
        <S.Text $underlined={underlined}>{text}</S.Text>
      </S.Breadcrumb>
    );
  };

  if (href) {
    return <Link href={href}>{getContent()}</Link>;
  }

  return getContent();
};

export default React.memo(Breadcrumb);
