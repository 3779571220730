import styled, {DefaultTheme} from 'styled-components';
import {motion} from 'framer-motion';

const S: Record<string, React.ElementType> = {};

S.Switch = styled.div(
  (props: {disabled: boolean; $toggled: boolean; theme: DefaultTheme}) => `
  background: ${
    props.$toggled
      ? props.theme.backgroundColor.positive
      : props.theme.backgroundColor.tertiary
  };
  opacity: ${props.disabled ? 0.5 : 1};
  width: 52px;
  height: 28px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 0 2px;
  box-sizing: border-box;
  cursor: pointer;
`
);

S.Knob = styled(motion.div)`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${props => props.theme.backgroundColor.white};
`;

export default S;
