import styled, {DefaultTheme} from 'styled-components';
import {motion} from 'framer-motion';
import {toDropShadow} from '@brightlive/shared/helpers/elevationConverter';
import {
  getParagraphSM,
  getParagraphXS,
} from '@brightlive/shared/styles/typography-v3';

const getBackgroundColor = (
  callout: boolean,
  theme: DefaultTheme,
  inverse?: boolean
) => {
  if (callout) {
    return theme.actionColor.brand;
  }
  if (inverse) {
    return theme.backgroundColor.inversePrimary;
  } else {
    return theme.backgroundColor.primary;
  }
};

const getColor = (callout: boolean, theme: DefaultTheme, inverse?: boolean) => {
  if (callout || inverse) {
    return theme.contentColor.inverse;
  } else {
    return theme.contentColor.default;
  }
};

const S = {
  TooltipWrapper: styled(motion.div)`
    position: relative;
    z-index: 3003;
  `,
  Tooltip: styled.div(
    (props: {
      $callout: boolean;
      $size: 'small' | 'large';
      $inverse: boolean;
      theme: DefaultTheme;
    }) => `
    padding: ${
      props.$size === 'large' ? props.theme.spacing.SM : props.theme.spacing.XS
    };
    background: ${getBackgroundColor(
      props.$callout,
      props.theme,
      props.$inverse
    )};
    box-sizing: border-box;
    border-radius: ${props.theme.borderRadius.SM};
    width: 100%;
    filter: ${toDropShadow(props.theme.elevation.level3)};
    will-change: filter;
    position: relative;
  `
  ),

  SmallText: styled.div(
    (props: {$callout: boolean; $inverse: boolean; theme: DefaultTheme}) => `
    ${getParagraphXS(props.theme)}
    color: ${getColor(props.$callout, props.theme, props.$inverse)};
  `
  ),

  LargeText: styled.div(
    (props: {$callout: boolean; $inverse: boolean; theme: DefaultTheme}) => `
    ${getParagraphSM(props.theme)}
    color: ${getColor(props.$callout, props.theme, props.$inverse)};
  `
  ),

  ButtonWrapper: styled.div`
    margin-top: ${props => props.theme.spacing.XS};
    display: flex;
    justify-content: flex-end;
  `,

  Caret: styled.div(
    (props: {$inverse: boolean; $callout: boolean; theme: DefaultTheme}) => `
      position: absolute;
      width: 10px;
      height: 10px;
      background: ${getBackgroundColor(
        props.$callout,
        props.theme,
        props.$inverse
      )};
      z-index: -1;
      pointer-events: none;
  `
  ),
  MainContainer: styled.div(
    (props: {disabled: boolean}) => `
      position: relative;
      cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
    `
  ),
  ButtonRef: styled.div``,
  Arrow: styled.div``,
};

export default S;
