import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
//Components
import Modal from '@brightlive/shared/components/Modal';
import Button from '@brightlive/shared/components/Button';
import IconButton from '@brightlive/shared/components/IconButton';
import Select from '@brightlive/shared/components/Select';
// Hooks
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
// Actions
import {toggleToast} from 'redux/ui/actions';
import {SessionReducer} from 'redux/session/reducer';
// Styles
import S from './style';

interface InviteModalProps {
  onClose: Function;
}

export const InviteModal = ({onClose}: InviteModalProps) => {
  const dispatch = useDispatch();
  const {isMobile} = useWindowSize();

  const session = useSelector(
    (state: SessionReducer) => state.session.activeSession
  );

  const [inviteMode, setInviteMode] = useState<'mod' | 'guest'>('guest');
  const sessionLink = `${process.env.NEXT_PUBLIC_BOOKING_URL}/join/${session.id}`;

  const getInviteLink = () => {
    return `${sessionLink}?t=${
      inviteMode === 'mod' ? session.inviteTokenMod : session.inviteTokenGuest
    }`;
  };

  const dropdownOptions = [
    {
      value: 'guest',
      label: 'Guest',
      description:
        'Guests can actively participate in the live conversation off stage, and are recorded if brought on stage',
    },
    {
      value: 'mod',
      label: 'Moderator',
      description:
        'Moderators have full access to controls to support you during the Session',
    },
  ];

  return (
    <Modal closeModal={onClose} mobileHeight="100%" desktopWidth="648px">
      <S.InviteModalContent>
        <S.InviteModalTitle>Invite people</S.InviteModalTitle>
        <S.InviteModalBody>
          <S.InviteModalHeader>Share a link</S.InviteModalHeader>
          <S.InviteModalDescription>
            Assign roles and copy the link to share and invite people to your
            Session.
          </S.InviteModalDescription>
          <S.FlexWrapper>
            <Select
              desktopWidth={isMobile ? undefined : '500px'}
              menuDirection="right"
              hasDesktopMenuStyling={true}
              hasMobileMenuStyling={true}
              placeholder="Select..."
              value={inviteMode}
              onChange={setInviteMode}
              options={dropdownOptions}
            />
            <S.InviteModalLinkWrapper>
              <S.InviteModalLink>{getInviteLink()}</S.InviteModalLink>
              {isMobile && (
                <IconButton
                  icon="ContentCopy"
                  size="small"
                  type="tertiary"
                  onClick={() => {
                    navigator.clipboard.writeText(getInviteLink());
                    dispatch(
                      toggleToast(
                        true,
                        'Link copied!',
                        'default',
                        undefined,
                        'top'
                      )
                    );
                  }}
                />
              )}
            </S.InviteModalLinkWrapper>
          </S.FlexWrapper>
        </S.InviteModalBody>
        {!isMobile ? (
          <S.CopyLink>
            <Button
              type="secondary"
              size="medium"
              text="Copy link"
              onClick={() => {
                navigator.clipboard.writeText(getInviteLink());
                dispatch(
                  toggleToast(true, 'Link copied!', 'default', undefined, 'top')
                );
              }}
            />
          </S.CopyLink>
        ) : (
          <Button
            type="secondary"
            size="medium"
            text="Share via"
            onClick={async () => {
              if (navigator?.share) {
                const shareData = {
                  title: 'Join my session',
                  url: getInviteLink(),
                };
                await navigator.share(shareData);
                dispatch(
                  toggleToast(
                    true,
                    'Link shared successfully!',
                    'default',
                    undefined,
                    'top'
                  )
                );
              }
            }}
          />
        )}
      </S.InviteModalContent>
    </Modal>
  );
};
