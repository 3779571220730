import styled from 'styled-components';
import {motion} from 'framer-motion';
import Ag from '@brightlive/shared/styles/typography-v3';

const S = {
  Countdown: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.theme.backgroundColor.scrim};
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  Modal: styled.div`
    background: ${props => props.theme.backgroundColor.black};
    padding: ${props => props.theme.spacing['3XL']}
      ${props => props.theme.spacing['2XL']};
    max-width: 392px;
    box-shadow: ${props => props.theme.elevation.level5};
    border-radius: ${props => props.theme.borderRadius.XL};
  `,

  Title: styled(Ag.HeadingMD)`
    color: ${props => props.theme.contentColor.white};
    margin-bottom: ${props => props.theme.spacing.LG};
  `,

  NumberWrapper: styled.div`
    height: 72px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  Number: styled(motion.p)`
    font-size: 96px;
    line-height: 72px;
    font-family: ${props => props.theme.typeface.ownersText};
    color: ${props => props.theme.contentColor.brand};
  `,
};
export default S;
