import React, {useState} from 'react';
import {browserName} from 'react-device-detect';
// Components
import Emoji from '@brightlive/shared/components/Emoji';
import Button from '@brightlive/shared/components/Button';
import Breadcrumb from '@brightlive/shared/components/Breadcrumb';
import {Grid, Row, Col} from '@brightlive/shared/components/Grid';
// Helpers
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
import {useInterval} from '@brightlive/shared/hooks/useInterval';
import {RoomState} from 'bright-livekit/types/RoomState';
import {hasCameraPermission} from '@brightlive/shared/helpers/cameraPermission';
// Styles
import S from './style';

export interface PermissionRequestProps {
  roomState: RoomState;
}

export const PermissionRequest = ({roomState}: PermissionRequestProps) => {
  const {isMobile, height} = useWindowSize();

  const [permissionError, setPermissionError] = useState(false);

  useInterval(async () => {
    if (await hasCameraPermission()) {
      roomState.setCameraPermission(true);
    }
  }, 1000);

  const getBrowserName = () => {
    if (browserName.toLocaleLowerCase().includes('safari')) {
      return 'Safari';
    } else {
      return 'Chrome';
    }
  };

  const requestPermission = async () => {
    try {
      await roomState.videoUtils?.createLocalTracks({audio: true, video: true});
      const hasPermission = await hasCameraPermission();
      roomState.setCameraPermission(hasPermission);
      setPermissionError(!hasPermission);
    } catch (err) {
      console.error(err);
      setPermissionError(true);
    }
  };

  return (
    <S.PermissionRequest $pageHeight={height || 0}>
      <Grid>
        <Row>
          <Col>
            <S.Breadcrumb>
              {roomState.currentUser.id === roomState.session.creator?.id && (
                <Breadcrumb
                  text="Back to Sessions"
                  href={'/dashboard/sessions'}
                />
              )}
            </S.Breadcrumb>
          </Col>
        </Row>
        <Row align="center">
          <Col md={4}>
            {permissionError ? (
              <>
                <S.PermissionRequestEmoji>
                  <Emoji symbol="⛔️" label="no entry" size={64} />
                </S.PermissionRequestEmoji>
                <S.PermissionRequestTitle>
                  {browserName.replace('Mobile', '')} has blocked access to your
                  camera
                </S.PermissionRequestTitle>
                <S.PermissionRequestMessage>
                  Bright needs access your mic and camera so that other people
                  can see and hear you.
                  <br />
                  <br />
                  Click on the camera icon in the browser URL bar, adjust the
                  permissions, and refresh the page. Check out our{' '}
                  <S.HelpCenterLink
                    href="https://support.brightlive.com/hc/en-us"
                    target="_blank"
                    rel="noreferrer"
                  >
                    help center
                  </S.HelpCenterLink>{' '}
                  if you’re having trouble.
                </S.PermissionRequestMessage>
                <S.PermissionRequestButton>
                  <Button
                    type="tertiary"
                    size="medium"
                    width={isMobile ? '100%' : undefined}
                    text="Contact Support"
                    onClick={() => {
                      window.open(
                        'https://support.brightlive.com/hc/en-us/requests/new',
                        '_blank'
                      );
                    }}
                  />
                </S.PermissionRequestButton>
              </>
            ) : (
              <>
                <S.PermissionRequestPreview
                  src={`/images/${getBrowserName()}.gif`}
                ></S.PermissionRequestPreview>
                <S.PermissionRequestTitle>
                  Let's get your equipment ready before joining!
                </S.PermissionRequestTitle>
                <S.PermissionRequestMessage>
                  Bright needs access your mic and camera so that other people
                  can see and hear you.
                </S.PermissionRequestMessage>
                <Button
                  type="primary"
                  text="Request permissions"
                  onClick={requestPermission}
                  width="100%"
                  size="medium"
                />
              </>
            )}
          </Col>
        </Row>
      </Grid>
    </S.PermissionRequest>
  );
};
