import React from 'react';
// Components
import Button from '@brightlive/shared/components/Button';
import TextInput from '@brightlive/shared/components/TextInput';
// Helpers
import {
  LoadingAuthButton,
  AuthPages,
} from '@brightlive/shared/helpers/interfaces';
// Styles
import S from 'components/auth/style';

interface ExistingGuestContentProps {
  emailValue: string;
  emailError: string;
  passwordValue: string;
  setPasswordValue: (val: string) => void;
  setEmailError: (val: string) => void;
  handleEmailLogin: () => Promise<void>;
  setPage: (val: AuthPages) => void;
  loading: LoadingAuthButton;
}

export const ExistingGuestContent = ({
  emailValue,
  emailError,
  passwordValue,
  setPasswordValue,
  setEmailError,
  handleEmailLogin,
  setPage,
  loading,
}: ExistingGuestContentProps) => {
  const checkForErrors = () => {
    setEmailError('');
    if (!passwordValue) {
      setEmailError('Please enter a password');
      return;
    }
    handleEmailLogin();
  };

  const onPasswordChange = e => {
    e.preventDefault();
    setPasswordValue(e.target.value);
  };

  return (
    <>
      <S.Title>Join this Bright Session!</S.Title>
      <S.Description>
        Looks like the email, <S.Bold>{emailValue}</S.Bold>, is associated with
        an account already. Enter your password to finish logging in.
      </S.Description>
      <S.Input>
        <TextInput
          label="Password"
          placeholder=""
          onChange={onPasswordChange}
          value={passwordValue}
          keyPressHandler={checkForErrors}
          error={emailError}
          type="password"
        />
      </S.Input>
      <S.ForgotPassword>
        <S.Link
          onClick={() => {
            setPage('forgot-password');
          }}
        >
          Forgot Password?
        </S.Link>
      </S.ForgotPassword>

      <S.Input>
        <Button
          type="primary"
          text="Log in"
          disabled={!passwordValue || loading === 'join'}
          loading={loading === 'email'}
          onClick={checkForErrors}
          width="100%"
          size="medium"
        />
      </S.Input>
    </>
  );
};
