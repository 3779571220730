import React, {ReactElement, useState, useRef, useEffect} from 'react';
import {useAnimation} from 'framer-motion';
import {assign, max, values} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import {useSwipeable} from 'react-swipeable';
import moment from 'moment';
import {withTheme, DefaultTheme} from 'styled-components';
// Components
import {
  Role,
  AttendeesView,
  MobileQuestionInput,
  QuestionsView,
  StageProps,
  SessionListParticipantView,
  SessionStageMissingHostView,
  PreSession,
  ControlsViewMobile,
  NotificationsView,
  MobileVideoPreview,
  VideoRenderer,
  EndSessionModal,
  BannedModal,
  Countdown,
  BrightScreenShareView,
  FlagUserModal,
  DeleteCommentModal,
  LiveSessionIndicator,
  LayoutTypes,
  PermissionRequest,
  ChatView,
  ChatInput,
  SessionInfoView,
  RecordingsView,
  SettingsModal,
  OnboardingSteps,
  useParticipant,
  TrackType,
  AlertBanner,
  StatusMessage,
  RecordingStartingModal,
} from 'bright-livekit';
import {InviteModal} from 'components/shared/InviteModal';
import Modal from '@brightlive/shared/components/Modal';
import Button from '@brightlive/shared/components/Button';
import TooltipWrapper from '@brightlive/shared/components/TooltipWrapper';
import Menu from '@brightlive/shared/components/Menu';
import NavigationBar from 'components/shared/Navigation/NavigationBar';
import Divider from '@brightlive/shared/components/Divider';
import Switch from '@brightlive/shared/components/Switch';
import IconButton from '@brightlive/shared/components/IconButton';
// Icons
import ChevronLeft from '@brightlive/shared/icons/ChevronLeft';
// Redux
import {
  toggleFlagUserModal,
  toggleDeleteCommentModal,
} from 'redux/livekit/actions';
import {AuthReducer} from 'redux/auth/reducer';
import {VideoReducer} from 'redux/livekit/reducer';
import {
  toggleMessageCallout,
  toggleSettingsModal,
  toggleToast,
} from 'redux/ui/actions';
import {UIReducer} from 'redux/ui/reducer';
// Hooks
import {useLocalStorage} from '@brightlive/shared/hooks/useLocalStorage';
import {useInterval} from '@brightlive/shared/hooks/useInterval';
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
// Helpers
import {MenuItem} from '@brightlive/shared/helpers/interfaces';
import {TrackingService} from 'bright-livekit/services/TrackingService';
// Styles
import S from './style';

interface MenuHeights {
  controls: number;
  comments: number;
  sessionInfo: number;
  recordings: number;
  participants: number;
  more: number;
}
interface StageSize {
  width: number;
  height: number;
}
const MobileStageComponent = ({
  roomState,
  participantRenderer,
  onLeave,
  sessionID,
  setIsLive,
  countdownRunning,
  needsSessionTour,
  theme,
}: StageProps & {theme: DefaultTheme}) => {
  const {isConnecting, error, videoService} = roomState;
  const dispatch = useDispatch();
  const isLandscape = useMediaQuery({orientation: 'landscape'});
  const isSmallScreen = useMediaQuery({maxWidth: 450});
  const {height: windowHeight = 0, width: windowWidth = 0} = useWindowSize();

  const authToken =
    useSelector((state: AuthReducer) => state.auth.auth.authToken) || '';
  const settingsModalVisible = useSelector(
    (state: UIReducer) => state.ui.settingsModalVisible
  );
  const callout = useSelector((state: UIReducer) => state.ui.messageCallout);
  const alertBanner = useSelector((state: UIReducer) => state.ui.alertBanner);

  const videoPreviewRef = useRef<HTMLDivElement>(null);
  const screenShareRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const chatTextareaRef = useRef<HTMLTextAreaElement>(null);

  const [flagModalClosed, setFlagModalClosed] = useLocalStorage(
    'flag-modal-closed',
    false
  );

  const [alwaysShowSessionControls] = useLocalStorage(
    'always-show-session-controls',
    false
  );
  const [displayQuestionPreview] = useLocalStorage(
    'display-question-preview',
    true
  );

  const mainControlsControl = useAnimation();
  const flagUserModal = useSelector(
    (state: VideoReducer) => state.video.flagUserModal
  );
  const deleteCommentModal = useSelector(
    (state: VideoReducer) => state.video.deleteCommentModal
  );

  const [buttonLoading, setButtonLoading] = useState('');
  const [navigationTime, setNavigationTime] = useState(0);
  const [questionText, setQuestionText] = useState('');
  const [chatText, setChatText] = useState('');
  const [alwaysShowControls, setAlwaysShowControls] = useState(
    alwaysShowSessionControls
  );
  const [showQuestionPreview, setShowQuestionPreview] = useState(
    displayQuestionPreview
  );
  const [toggleControls, setToggleControls] = useState(false);
  const [toggleNavigation, setToggleNavigation] = useState(false);
  const [toggleLiveIndicator, setToggleLiveIndicator] = useState(false);
  const [initialControlsToggle, setInitialControlsToggle] = useState(false);
  const [toggleComments, setToggleComments] = useState(false);
  const [toggleChat, setToggleChat] = useState(false);
  // const [showCC, setShowCC] = useState(false);
  const [toggleParticipants, setToggleParticipants] = useState(false);
  const [toggleMoreOptions, setToggleMoreOptions] = useState(false);
  const [toggleLeaveModal, setToggleLeaveModal] = useState(false);
  const [toggleSessionInfo, setToggleSessionInfo] = useState(false);
  const [toggleRecordings, setToggleRecordings] = useState(false);
  const [toggleFlagModal, setToggleFlagModal] = useState(
    roomState.selfParticipant?.role === Role.Banned
  );
  const [toggleEndSessionModal, setToggleEndSessionModal] = useState(false);
  const [toggleInviteModal, setToggleInviteModal] = useState(false);
  const [replaceQuestion, setReplaceQuestion] = useState(false);
  const [facingModeAvail, setFacingModeAvail] = useState(false);
  const [onboardingStep, setOnboardingStep] = useState<OnboardingSteps>(
    needsSessionTour ? 'share' : null
  );
  const [adjustedStageSize, setAdjustedStageSize] = useState<StageSize>();
  const [menuHeights, setMenuHeights] = useState<MenuHeights>({
    controls: 0,
    comments: 0,
    sessionInfo: 0,
    recordings: 0,
    participants: 0,
    more: 0,
  });
  const {isVideoMuted} = useParticipant(videoService?.localParticipant);

  const menuIsOpen =
    toggleChat || toggleComments || toggleParticipants || toggleMoreOptions;

  useEffect(() => {
    if (!isSmallScreen) {
      closeAllMenus();
    }
  }, [isLandscape, isSmallScreen]);
  // Show question preview in toast if showQuestionPreview is true
  useEffect(() => {
    if (menuIsOpen && callout.toggled) {
      dispatch(toggleMessageCallout(false, '', '', ''));
    } else if (!showQuestionPreview && callout.toggled) {
      dispatch(toggleMessageCallout(false, '', '', ''));
    } else if (callout.toggled) {
      dispatch(toggleMessageCallout(false, '', '', ''));
      const icon = callout.messageType === 'chat' ? 'Chat' : 'Questions';
      const handleOnClick = () => {
        if (callout.messageType === 'chat') {
          setToggleChat(true);
        } else {
          setToggleComments(true);
        }
      };
      dispatch(
        toggleToast(
          true,
          <S.CalloutWrapper onClick={handleOnClick}>
            <S.CalloutName>{callout.name}:</S.CalloutName>
            <S.CalloutText>{callout.text}</S.CalloutText>
          </S.CalloutWrapper>,
          'default',
          icon,
          'bottom'
        )
      );
    }
  }, [callout]);

  const handleSwipeDown = () => {
    setToggleNavigation(true);
    setToggleLiveIndicator(false);
    setNavigationTime(Date.now());
  };

  const handleSwipeUp = () => {
    if (!isLandscape) {
      setToggleControls(true);
    }
  };

  const handlers = useSwipeable({
    onSwipedDown: handleSwipeDown,
    onSwipedUp: handleSwipeUp,
    preventDefaultTouchmoveEvent: false,
    trackMouse: true,
  });

  const updateAdjustedStageSize = (heights: MenuHeights | null = null) => {
    const tallestMenu = max(values(heights ?? menuHeights));
    const stageSize = {
      height: windowHeight,
      width: windowWidth,
    };
    if (tallestMenu) {
      const newStageSize = assign({}, stageSize, {
        height: windowHeight - tallestMenu,
      });
      setAdjustedStageSize(newStageSize);
    } else {
      setAdjustedStageSize(stageSize);
    }
  };

  const closeAllMenus = async () => {
    setToggleControls(false);
    setToggleMoreOptions(false);
    setToggleParticipants(false);
    setToggleComments(false);
    // Delay so that new stageSizes can update before setting adjusted sizes
    setTimeout(() => {
      updateAdjustedStageSize({
        controls: 0,
        comments: 0,
        recordings: 0,
        sessionInfo: 0,
        participants: 0,
        more: 0,
      });
    }, 200);
  };

  const onMenuMount = (menuName, menuHeight) => {
    if (menuName === 'controls' && videoPreviewRef.current) {
      menuHeight = menuHeight - videoPreviewRef.current.clientHeight;
    }
    const newMenuHeights = assign({}, menuHeights, {
      [`${menuName}`]: menuHeight,
    });
    setMenuHeights(newMenuHeights);
    updateAdjustedStageSize(newMenuHeights);
  };

  const onMenuUnmount = menuName => {
    // Do not recalc stage size if close the more menu to open participants
    if (menuName === 'more' && toggleParticipants) {
      return;
    }
    const menuUpdates = {
      [`${menuName}`]: 0,
    };
    // to handle race condition
    if (
      ['participants', 'recordings', 'sessionInfo', 'comments'].includes(
        menuName
      )
    ) {
      menuUpdates.more = 0;
    }
    const newMenuHeights = assign({}, menuHeights, menuUpdates);
    setMenuHeights(newMenuHeights);
    updateAdjustedStageSize(newMenuHeights);
  };

  const handleShowPreview = () => {
    if (!videoPreviewRef.current) return;
    mainControlsControl.start({
      marginBottom: 0,
      transition: {duration: 0.3, ease: 'easeIn'},
    });
    const newControlsMenuHeight =
      menuHeights.controls + videoPreviewRef.current.clientHeight;
    const newMenuHeights = assign({}, menuHeights, {
      controls: newControlsMenuHeight,
    });
    setMenuHeights(newMenuHeights);
    updateAdjustedStageSize(newMenuHeights);
  };

  // Animate controls tray on session start
  useEffect(() => {
    if (
      roomState.sessionState !== 'loading' &&
      videoService &&
      !isConnecting &&
      !initialControlsToggle
    )
      setTimeout(() => {
        setToggleControls(true);
        setToggleNavigation(true);
        setInitialControlsToggle(true);
      }, 500); // delay in order to prevent preview window from showing on load

    // Check if user is banned
    if (roomState.selfParticipant?.role === Role.Banned && !flagModalClosed) {
      setToggleFlagModal(true);
    }
    // remove flag from local storage
    if (roomState.selfParticipant?.role !== Role.Banned && flagModalClosed) {
      setFlagModalClosed(false);
    }
  }, [roomState]);

  useEffect(() => {
    if (toggleControls && videoPreviewRef.current) {
      mainControlsControl.set({
        marginBottom: -videoPreviewRef.current.clientHeight,
      });
    }
  }, [toggleControls, roomState.sessionState]);
  // Live indicator animation
  const opacityVariants = {
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: 'easeIn',
      },
    },
    hidden: {
      opacity: 0,
    },
  };
  // Toggle nav animation
  const navVariants = {
    visible: {
      y: 0,
      transition: {
        duration: 0.3,
        ease: 'easeIn',
      },
    },
    hidden: {
      y: -parseInt(theme.sizing.mobile.navHeight, 10),
      transition: {
        duration: 0.3,
        ease: 'easeIn',
      },
    },
  };
  // Edit/add question
  const editVariants = {
    visible: {
      y: 0,
      display: 'flex',
      transition: {
        duration: 0.6,
      },
    },
    hidden: {
      y: '100vh',
      transitionEnd: {
        display: 'none',
      },
      transition: {
        duration: 0.6,
      },
    },
  };

  // Hide nav after 5 secs of inactivity
  useInterval(() => {
    const navTimeout = (navigationTime + 5000 - Date.now()) / 1000;
    if (navTimeout <= 0) {
      setToggleNavigation(false);
      setToggleLiveIndicator(true);
    }
  }, 1000);

  const getDeviceIDs = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    if (!devices?.length) return;
    // Get last audio device
    const lastAudio = devices.find(device => device.kind === 'audioinput');
    if (videoService && lastAudio?.deviceId) {
      window?.localStorage?.setItem('livekit.audioInput', lastAudio.deviceId);
      videoService.switchActiveDevice('audioinput', lastAudio.deviceId);
    }
    // Get last video device
    const lastVideo = devices.find(device => device.kind === 'videoinput');
    if (videoService && lastVideo?.deviceId) {
      window?.localStorage?.setItem('livekit.videoInput', lastVideo.deviceId);
      videoService.switchActiveDevice('videoinput', lastVideo.deviceId);
    }
  };
  // Check if facing mode is available
  const getFacingMode = async () => {
    const constraints = await navigator.mediaDevices.getSupportedConstraints();
    setFacingModeAvail(constraints?.facingMode ?? false);
  };

  useEffect(() => {
    if (navigator.mediaDevices) {
      // Check if media device info is avail
      getDeviceIDs(); // save cameras and microphones ids
      getFacingMode(); // Check if mobile device and save facing mode
    }
    return;
  }, []);

  if (roomState.sessionState === 'permission-request') {
    return <PermissionRequest roomState={roomState} />;
  }

  if (roomState.sessionState === 'pre-session') {
    return (
      <PreSession roomState={roomState} needsSessionTour={needsSessionTour} />
    );
  }

  if (error) {
    return (
      <StatusMessage
        text="Error: Please Refresh"
        brandColor={roomState.brandColor}
      />
    );
  }
  if (roomState.sessionState === 'loading' || !videoService || isConnecting) {
    return (
      <StatusMessage
        text="Loading"
        loading={true}
        brandColor={roomState.brandColor}
      />
    );
  }

  const moreMenuItems = () => {
    const items: MenuItem[] = [];
    // Add recording options if a mod
    if (roomState.modControls) {
      items.push({
        label: `${roomState.isRecording ? 'Stop' : 'Start'} recording`,
        negative: roomState.isRecording ? true : false,
        disabled: buttonLoading === 'recording' || roomState.isStarting,
        onClick: () => {
          setButtonLoading('recording');
          try {
            handleRecordingClick();
          } catch (err) {
            console.error(err);
            dispatch(
              toggleToast(
                true,
                `There was a problem ${
                  roomState.isRecording ? 'stopping' : 'starting'
                } the recording. Please try again.`,
                'default',
                undefined,
                'top'
              )
            );
          }
          setButtonLoading('');
        },
      });
    }
    // Add ability to hide/show CC if avail
    // if (captions && captions.length) {
    //   items.push({
    //     label: `${showCC ? 'Hide' : 'Show'} closed captions`,
    //     onClick: () => {
    //       setShowCC(!showCC);
    //     },
    //   });
    // }
    // Add line to separate next options if current length is longer than 0
    if (items.length > 0) items.push({isLine: true});
    // Add ability to edit session info
    if (roomState.modControls) {
      items.push({
        label: 'Session info',
        onClick: () => {
          setToggleSessionInfo(!toggleSessionInfo);
          setToggleMoreOptions(false);
        },
      });
    }
    // Add generic options for all users
    items.push(
      ...[
        {
          label: `Attendees (${roomState?.participantsPresent?.length})`,
          onClick: () => {
            setToggleParticipants(true);
            setToggleMoreOptions(false);
          },
        },
        {
          label: `Questions ${
            roomState.unreadQuestions > 0
              ? `(${roomState.unreadQuestions})`
              : ''
          }`,
          onClick: () => {
            setToggleComments(true);
            setToggleMoreOptions(false);
          },
        },
        {
          label: `Chat ${
            roomState.unreadChatMessages > 0
              ? `(${roomState.unreadChatMessages})`
              : ''
          }`,
          onClick: () => {
            setToggleChat(true);
            setToggleMoreOptions(false);
          },
        },
      ]
    );
    // Add recording page for mods
    if (roomState.modControls) {
      items.push({
        label: 'Recordings',
        onClick: () => {
          setToggleRecordings(!toggleRecordings);
          setToggleMoreOptions(false);
        },
      });
    }
    // Add divider and settings/help
    items.push(
      ...[
        {isLine: true},
        {
          label: 'Settings',
          onClick: () => {
            dispatch(toggleSettingsModal(true));
            setToggleMoreOptions(false);
          },
        },
        {
          label: 'Help center',
          onClick: () => {
            window.open('https://brightlive.zendesk.com', '_blank');
          },
        },
      ]
    );
    return items;
  };

  const handleRecordingClick = () => {
    if (roomState.isRecording) {
      roomState.videoUtils?.stopRecording(sessionID, authToken);
      TrackingService.fire({
        event: 'stop_recording',
        currentUser: roomState.currentUser,
        data: {
          session_id: roomState.session.id,
          minutes: moment().diff(moment(roomState?.startedAt), 'minutes'),
        },
      });
      continueOnboarding('recordings');
    } else {
      roomState.videoUtils?.startRecording(sessionID, authToken);
      TrackingService.fire({
        event: 'spinup_recording',
        currentUser: roomState.currentUser,
        data: {
          session_id: roomState.session.id,
        },
      });
      TrackingService.fire({
        event: 'spinup_livestream',
        currentUser: roomState.currentUser,
        data: {
          session_id: roomState.session.id,
        },
      });
      continueOnboarding('record-end');
    }
  };

  const sendChat = () => {
    if (chatText) {
      roomState.videoUtils?.submitChat(
        sessionID,
        roomState.currentUser.id,
        roomState.selfParticipant?.displayName || '',
        chatText
      );
      setChatText('');
    }
  };

  const sendQuestion = () => {
    if (questionText) {
      TrackingService.fire({
        event: 'submit_qc',
        currentUser: roomState.currentUser,
        data: {
          session_id: sessionID,
          string: questionText,
          time: new Date(new Date().getTime()),
        },
      });
      roomState.videoUtils?.sendQuestion(
        sessionID,
        roomState.currentUser.id,
        questionText
      );
      setReplaceQuestion(false);
    }
  };

  const ParticipantRenderer = participantRenderer ?? SessionListParticipantView;

  let mainView: ReactElement;

  const creatorParticipant = roomState.participantsOnStage.find(
    p => p.role === Role.Creator
  );
  const isHorizontal =
    (adjustedStageSize?.width || 0) > (adjustedStageSize?.height || 0);

  const backup = creatorParticipant ?? roomState.participantsOnStage[0];
  const speaker =
    roomState.layout === LayoutTypes.CreatorOnly || !roomState.mainSpeaker
      ? backup
      : roomState.mainSpeaker;
  if (roomState.participantsOnStage.length === 0) {
    mainView = (
      <StatusMessage
        text="Your host will be here shortly"
        brandColor={roomState.brandColor}
      />
    );
  } else if (roomState.screenShareTrack?.isSubscribed) {
    let i = 0;
    const creatorVideo = creatorParticipant?.videoParticipant?.getTrack(
      TrackType.Video
    );
    const creatorVideoSize =
      adjustedStageSize?.height &&
      adjustedStageSize.height < 450 &&
      adjustedStageSize.height < adjustedStageSize?.width
        ? 56
        : 120;
    const participantVideoSize =
      adjustedStageSize?.height &&
      adjustedStageSize.height < 450 &&
      adjustedStageSize.height < adjustedStageSize?.width
        ? 32
        : 96;
    mainView = (
      <>
        <S.MobileScreenShareWrapper ref={screenShareRef}>
          <BrightScreenShareView
            trackPublication={roomState.screenShareTrack}
          ></BrightScreenShareView>
        </S.MobileScreenShareWrapper>
        <S.ScreenShareOnStageParticipants
          $menuIsOpen={menuIsOpen}
          $screenShareHeight={screenShareRef.current?.clientHeight ?? 0}
        >
          {roomState.participantsOnStage?.map(participant => {
            // If creator is not present skip their slot
            if (participant.role === Role.Creator) {
              return;
            }
            return (
              <ParticipantRenderer
                roomState={roomState}
                modControls={roomState.modControls}
                onStage={roomState.onStage}
                key={participant.id}
                participant={participant}
                sessionID={sessionID}
                stageSize={adjustedStageSize}
                stagePosition={i++}
                totalStageParticipants={length}
                shareHeight={`${participantVideoSize}px`}
                orientation="landscape"
                mode="share"
                condensedView={isHorizontal || menuIsOpen}
              />
            );
          })}
        </S.ScreenShareOnStageParticipants>
        {creatorParticipant && creatorVideo && (
          <S.ScreenShareCamera
            height={creatorVideoSize}
            $screenShareHeight={screenShareRef.current?.clientHeight ?? 0}
            $menuIsOpen={menuIsOpen}
          >
            <VideoRenderer
              track={creatorVideo.track}
              isLocal={creatorParticipant.videoParticipant?.isLocal || false}
              objectFit="cover"
              name={creatorParticipant.id}
              width={`${creatorVideoSize}px`}
              height={`${creatorVideoSize}px`}
            />
          </S.ScreenShareCamera>
        )}
      </>
    );
  } else if (
    [LayoutTypes.CreatorOnly, LayoutTypes.Speaker].includes(roomState.layout) &&
    speaker
  ) {
    mainView = (
      <ParticipantRenderer
        roomState={roomState}
        modControls={roomState.modControls}
        onStage={roomState.onStage}
        key={speaker.id}
        participant={speaker}
        sessionID={sessionID}
        stageSize={adjustedStageSize}
        stagePosition={0}
        totalStageParticipants={1}
        shareHeight="100%"
        orientation="landscape"
        mode="mobile"
        condensedView={isHorizontal || menuIsOpen}
      />
    );
  } else {
    const participants = roomState.participantsOnStage;
    let i = 0;
    let length = participants.length;

    const allStageMembers: JSX.Element[] = [];
    let creatorOnly: JSX.Element | null = null;

    participants.map(participant => {
      // If creator is not present skip their slot
      if (i === 0 && participant.role !== Role.Creator) {
        creatorOnly = (
          <SessionStageMissingHostView
            layout={roomState.layout}
            stageSize={adjustedStageSize}
            stagePosition={0}
            totalStageParticipants={length + 1}
            mode="mobile"
            brandColor={roomState.brandColor}
          />
        );

        i++;
        length++;
      }
      const participantRender = (
        <ParticipantRenderer
          roomState={roomState}
          modControls={roomState.modControls}
          onStage={roomState.onStage}
          key={participant.id}
          participant={participant}
          sessionID={sessionID}
          stageSize={adjustedStageSize}
          stagePosition={i++}
          totalStageParticipants={length}
          shareHeight="100%"
          orientation="landscape"
          mode="mobile"
          condensedView={isHorizontal || menuIsOpen}
        />
      );
      if (i === 1 && participant.role === Role.Creator) {
        creatorOnly = participantRender;
      } else {
        allStageMembers.push(participantRender);
      }
    });

    mainView = (
      <>
        <S.Creator $reducedCreator={isHorizontal && length >= 6}>
          {creatorOnly}
        </S.Creator>
        <S.AttendeesStage $reducedCreator={isHorizontal && length >= 6}>
          {allStageMembers}
        </S.AttendeesStage>
      </>
    );
  }

  const flipCamera = async () => {
    videoService?.localParticipant?.flipCamera();
  };

  const closeBannedModal = () => {
    setFlagModalClosed(true);
    setToggleFlagModal(false);
  };

  const closeDeleteCommentModal = () => {
    dispatch(toggleDeleteCommentModal({toggled: false, participant: null}));
  };

  const endSession = () => {
    setButtonLoading('end');
    roomState.videoUtils?.endSession(roomState.session.id);
  };

  const continueOnboarding = (step: OnboardingSteps) => {
    if (!needsSessionTour) return;
    const stepOrder: OnboardingSteps[] = [
      'share',
      'record-start',
      'record-end',
      'recordings',
      null,
    ];
    const currentStepIdx = stepOrder.indexOf(onboardingStep);
    const newStepIdx = stepOrder.indexOf(step);
    // make sure order is enforced and don't let people skip steps
    // unless their recording ends and they haven't seen 'recordings' then skip the rest and show them 'recordings'
    if (step === 'recordings' && onboardingStep !== null) {
      setOnboardingStep('recordings');
    }
    if (newStepIdx === currentStepIdx + 1) {
      setOnboardingStep(step);
    }
  };

  const handleMoreOptionsClick = () => {
    setToggleMoreOptions(true);
    if (onboardingStep === 'recordings') {
      continueOnboarding(null);
    }
  };

  const LeaveModal = () => {
    if (toggleLeaveModal) {
      return (
        <Modal
          closeModal={() => setToggleLeaveModal(false)}
          buttons={{
            button1Text: 'Cancel',
            button2Text: 'Leave',
            button2OnClick: () => {
              TrackingService.fire({
                event: 'session_leave',
                currentUser: roomState.currentUser,
                data: {
                  time: new Date(new Date().getTime()),
                },
              });
              return onLeave ? onLeave(videoService) : null;
            },
          }}
        >
          <S.ModalContent>
            <S.ModalTitle>Leave Session?</S.ModalTitle>
            <S.ModalDescription>
              Are you sure you want to leave the session?
            </S.ModalDescription>
          </S.ModalContent>
        </Modal>
      );
    }
    return;
  };

  const currentUserHasQuestion =
    roomState.selfParticipant?.question &&
    !roomState.selfParticipant.questionAnswered;

  const toggleVideo = async () => {
    await videoService.localParticipant?.setCameraEnabled(isVideoMuted);
  };

  const handleInviteClick = () => {
    setToggleInviteModal(true);
    continueOnboarding('record-start');
  };

  return (
    // global container
    <div
      onClick={() => {
        if (!toggleNavigation) handleSwipeUp();
      }}
      {...handlers}
    >
      <S.NavWrapper
        animate={toggleNavigation ? 'visible' : 'hidden'}
        initial="hidden"
        variants={navVariants}
        onClick={() => setNavigationTime(Date.now())}
      >
        <NavigationBar />
      </S.NavWrapper>
      {roomState.startedAt && (
        <S.LiveIndicatorWrapper
          animate={toggleLiveIndicator ? 'visible' : 'hidden'}
          initial="hidden"
          variants={opacityVariants}
        >
          <LiveSessionIndicator startedAt={roomState.startedAt} />
        </S.LiveIndicatorWrapper>
      )}
      <S.TopIcons>
        <S.TopIcon>
          <IconButton
            icon="GroupAdd"
            size="medium"
            type="tertiary inverse"
            onClick={handleInviteClick}
          />
          {onboardingStep === 'share' && (
            <TooltipWrapper
              tooltipContent="Invite your friends and record video podcasts together!"
              position="top-start"
              callout={true}
              width="286px"
              dismiss={() => continueOnboarding('record-start')}
              disableTooltip={onboardingStep !== 'share'}
              size="large"
              delayMount={true}
              referenceContent={<S.TooltipWrapper />}
            />
          )}
        </S.TopIcon>
        {!isVideoMuted && (
          <S.TopIcon>
            <IconButton
              icon="IosFlipCamera"
              size="medium"
              type="tertiary inverse"
              disabled={!facingModeAvail}
              onClick={flipCamera}
            />
          </S.TopIcon>
        )}
      </S.TopIcons>
      <S.LivekitWrapper height={windowHeight}>
        <AlertBanner
          type={alertBanner.alertType}
          text={alertBanner.text}
          persist={alertBanner.persist}
        />
        <NotificationsView
          sessionID={sessionID}
          roomState={roomState}
        ></NotificationsView>
        <S.LivekitTop>
          <S.LivekitStage
            $isVertical={
              (adjustedStageSize?.width || 0) < (adjustedStageSize?.height || 0)
            }
            $stageHeight={adjustedStageSize?.height || 0}
          >
            {mainView}
            {/* {showCC && captions && captions.length > 0 && (
              <S.CaptionsContainer>
                {captions.slice(-3).map(val => {
                  return (
                    <S.CaptionLine key={val.userID}>
                      {val.name}: {val.transcript}
                    </S.CaptionLine>
                  );
                })}
              </S.CaptionsContainer>
            )} */}
          </S.LivekitStage>
        </S.LivekitTop>
        {toggleControls && (
          <Menu
            type="default"
            isMobile={true}
            disableClose={alwaysShowControls}
            onClose={() => {
              if (!alwaysShowControls) {
                setToggleControls(false);
              }
            }}
            onMount={menuHeight => {
              onMenuMount('controls', menuHeight);
            }}
            onUnmount={() => {
              onMenuUnmount('controls');
            }}
            onSwipeUp={handleShowPreview}
            hasClearOverlay={true}
            customTopContent={
              <S.MainControlWrapper animate={mainControlsControl}>
                <S.SendButtonContainer>
                  <ControlsViewMobile
                    videoService={videoService}
                    roomState={roomState}
                    onStage={roomState.onStage}
                    // toggleComments={() => setToggleComments(true)}
                    toggleMoreOptions={handleMoreOptionsClick}
                    handleLeaveSession={() =>
                      onLeave ? onLeave(videoService) : null
                    }
                    handleRecordingClick={handleRecordingClick}
                    // flipCamera={flipCamera}
                    // facingModeAvail={facingModeAvail}
                    cameraEnabled={!isVideoMuted}
                    toggleVideo={toggleVideo}
                    onboardingStep={onboardingStep}
                    continueOnboarding={continueOnboarding}
                  />
                </S.SendButtonContainer>
                <S.VideoPreview ref={videoPreviewRef}>
                  <MobileVideoPreview roomState={roomState} />
                </S.VideoPreview>
              </S.MainControlWrapper>
            }
          />
        )}
        {toggleSessionInfo && (
          <Menu
            isMobile={true}
            type="default"
            hasClearOverlay={true}
            onClose={() => setToggleSessionInfo(false)}
            onMount={menuHeight => {
              onMenuMount('sessionInfo', menuHeight);
            }}
            onUnmount={() => {
              onMenuUnmount('sessionInfo');
            }}
            customTopContent={
              <S.CommentsWrapper>
                <SessionInfoView roomState={roomState}></SessionInfoView>
              </S.CommentsWrapper>
            }
          />
        )}
        {toggleRecordings && (
          <Menu
            isMobile={true}
            type="default"
            hasClearOverlay={true}
            onClose={() => setToggleRecordings(false)}
            onMount={menuHeight => {
              onMenuMount('recordings', menuHeight);
            }}
            onUnmount={() => {
              onMenuUnmount('recordings');
            }}
            customTopContent={
              <S.CommentsWrapper>
                <RecordingsView roomState={roomState} />
              </S.CommentsWrapper>
            }
          />
        )}
        {toggleChat && (
          <Menu
            isMobile={true}
            type="default"
            hasClearOverlay={true}
            onClose={() => setToggleChat(false)}
            onMount={menuHeight => {
              onMenuMount('comments', menuHeight);
            }}
            onUnmount={() => {
              onMenuUnmount('comments');
            }}
            customTopContent={
              <S.CommentsWrapper>
                <ChatView roomState={roomState}></ChatView>
                <S.CommentInputWrapper>
                  <ChatInput
                    messageText={chatText}
                    setMessageText={setChatText}
                    sendMessage={sendChat}
                    textareaRef={chatTextareaRef}
                  />
                </S.CommentInputWrapper>
              </S.CommentsWrapper>
            }
          />
        )}
        {toggleComments && (
          <Menu
            isMobile={true}
            type="default"
            hasClearOverlay={true}
            onClose={() => setToggleComments(false)}
            onMount={menuHeight => {
              onMenuMount('comments', menuHeight);
            }}
            onUnmount={() => {
              onMenuUnmount('comments');
            }}
            customTopContent={
              <S.CommentsWrapper>
                {replaceQuestion}
                <QuestionsView roomState={roomState} sessionID={sessionID} />
                <S.BottomDivider>
                  <Divider />
                </S.BottomDivider>
                <S.ReplaceButton>
                  <Button
                    width="100%"
                    type={currentUserHasQuestion ? 'secondary' : 'primary'}
                    size="medium"
                    disabled={roomState.selfParticipant?.role === Role.Banned}
                    text={
                      currentUserHasQuestion
                        ? 'Replace comment'
                        : 'Ask a question'
                    }
                    onClick={() => {
                      setReplaceQuestion(true);
                      setQuestionText('');
                    }}
                  />
                </S.ReplaceButton>
              </S.CommentsWrapper>
            }
          />
        )}
        <S.EditCommentWrapper
          variants={editVariants}
          animate={replaceQuestion ? 'visible' : 'hidden'}
          initial="hidden"
        >
          <S.EditCommentTopBar>
            <ChevronLeft size={32} onClick={() => setReplaceQuestion(false)} />
            <Button
              type="primary"
              size="small"
              text="Submit"
              onClick={sendQuestion}
              disabled={!questionText.trim() || questionText.length > 140}
            />
          </S.EditCommentTopBar>
          <S.EditCommentContent>
            <MobileQuestionInput
              questionText={questionText}
              setQuestionText={setQuestionText}
              sendQuestion={sendQuestion}
              roomState={roomState}
              textareaRef={textareaRef}
            />
          </S.EditCommentContent>
          <S.BottomDivider>
            <Divider />
          </S.BottomDivider>
          <S.CameraToggle>
            <S.Flex>
              <S.CameraToggleHeader>Camera</S.CameraToggleHeader>
              <S.CameraToggleDescription>
                Your video will be seen by everyone after submitting a question.
              </S.CameraToggleDescription>
            </S.Flex>
            <Switch
              toggled={
                roomState?.videoService?.localParticipant?.isCameraEnabled ??
                false
              }
              onToggle={toggleVideo}
            />
          </S.CameraToggle>
        </S.EditCommentWrapper>
        {toggleParticipants && (
          <Menu
            customTopContent={
              <S.AttendeeWrapper>
                <AttendeesView
                  roomState={roomState}
                  replaceQuestion={() => {
                    setReplaceQuestion(true);
                    setQuestionText('');
                  }}
                />
                <S.BottomDivider>
                  <Divider />
                </S.BottomDivider>
                <S.ReplaceButton>
                  <Button
                    type="secondary"
                    size="medium"
                    text="Invite"
                    icon="GroupAdd"
                    width="100%"
                    onClick={() => setToggleInviteModal(true)}
                  />
                </S.ReplaceButton>
              </S.AttendeeWrapper>
            }
            isMobile={true}
            type="default"
            onClose={() => setToggleParticipants(false)}
            onMount={menuHeight => {
              onMenuMount('participants', menuHeight);
            }}
            onUnmount={() => {
              onMenuUnmount('participants');
            }}
          />
        )}
        {toggleMoreOptions && (
          <Menu
            isMobile={true}
            type="default"
            onClose={() => setToggleMoreOptions(false)}
            menuItems={moreMenuItems()}
            onMount={menuHeight => {
              onMenuMount('more', menuHeight);
            }}
            onUnmount={() => {
              onMenuUnmount('more');
            }}
          />
        )}
        {LeaveModal()}
        {toggleFlagModal && <BannedModal onClose={closeBannedModal} />}
        {flagUserModal.toggled && flagUserModal.participant && (
          <FlagUserModal
            onClose={() =>
              dispatch(toggleFlagUserModal({toggled: false, participant: null}))
            }
            participant={flagUserModal.participant}
            roomState={roomState}
            sessionID={sessionID}
          />
        )}
        {deleteCommentModal.toggled && deleteCommentModal.participant && (
          <DeleteCommentModal
            roomState={roomState}
            onClose={closeDeleteCommentModal}
            participant={deleteCommentModal.participant}
            sessionID={sessionID}
          />
        )}
        {toggleInviteModal && (
          <InviteModal onClose={() => setToggleInviteModal(false)} />
        )}
        {toggleEndSessionModal && (
          <EndSessionModal
            onClose={() => setToggleEndSessionModal(false)}
            buttonLoading={buttonLoading === 'end'}
            endSession={endSession}
          />
        )}
        {settingsModalVisible && (
          <SettingsModal
            sessionID={sessionID}
            roomState={roomState}
            setAlwaysShowControls={setAlwaysShowControls}
            setShowQuestionPreview={setShowQuestionPreview}
          />
        )}
        {roomState.isStarting && (
          <RecordingStartingModal
            creatorName={roomState.session.creator?.displayName}
            isCreator={roomState.isCreator}
          />
        )}
        {!!countdownRunning && setIsLive && (
          <Countdown onComplete={() => setIsLive(true)} />
        )}
      </S.LivekitWrapper>
    </div>
  );
};

export const MobileStage = withTheme(MobileStageComponent);
