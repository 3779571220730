import Ag from '@brightlive/shared/styles/typography-v3';
import styled from 'styled-components';

const S = {
  ModalHeader: styled(Ag.HeadingMD)`
    text-align: center;
    margin-bottom: ${props => props.theme.spacing.MD};
  `,

  ModalContent: styled(Ag.ParagraphMD)`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${props => props.theme.spacing.MD};
  `,
};
export default S;
