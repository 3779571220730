import React from 'react';
import {useDropzone} from 'react-dropzone';
import {get} from 'lodash';
// Components
import Button from '../Button';
import ContextualAlert from '../ContextualAlert';
// Icons
import Upload from '../../icons/Upload';
// Style
import S from './style';

export interface FileUploaderProps {
  onFileChange: (file: File) => void;
  onError: (error: string) => void;
  instructions?: string | React.ReactNode;
  error?: string;
  accept?: string | string[];
  maxSize?: number;
}

const FileUploader = ({
  onFileChange,
  onError,
  instructions,
  error,
  accept = 'image/jpeg, image/jpg, image/png',
  maxSize = 10485760, // bytes (10MB)
}: FileUploaderProps) => {
  const handleFileChange = file => {
    if (!file) return;

    onFileChange(file);
  };

  const {getRootProps, getInputProps} = useDropzone({
    accept,
    maxSize,
    multiple: false,
    onDropRejected: e => {
      if (get(e, '[0]errors[0].code') === 'file-too-large') {
        onError('File size is too large. Files cannot be greater than 10MB.');
        return;
      }
      onError(get(e, '[0]errors[0].message', 'Error uploading image'));
    },
    onDropAccepted: async files => {
      handleFileChange(files[0]);
    },
  });

  return (
    <>
      <S.Uploader {...getRootProps()}>
        <S.Icon>
          <Upload size={48} contentColor="subdued" />
        </S.Icon>
        <S.Label>Drag and drop to upload</S.Label>
        <S.LabelMobile>Upload from your device</S.LabelMobile>
        <S.Button>
          <Button size="small" type="secondary" text="Browse file" />
        </S.Button>
      </S.Uploader>
      {instructions && !error && <S.HelperText>{instructions}</S.HelperText>}
      {!!error && <ContextualAlert text={error} type="error" />}
      <S.FileInput {...getInputProps()} />
    </>
  );
};

export default React.memo(FileUploader);
