import React from 'react';
// Icons
import MicOff from '@brightlive/shared/icons/MicOff';
// Types
import {NameDisplayTypes} from 'bright-livekit';
import {Hex} from '@brightlive/shared/helpers/interfaces';
// Style
import S from './style';

export const NameDisplay = ({
  displayName,
  type,
  brandColor,
  nameDisplayVisible,
  isAudioMuted,
}: {
  displayName: string;
  type: NameDisplayTypes;
  brandColor: Hex;
  nameDisplayVisible: boolean;
  isAudioMuted: boolean;
}) => {
  const AudioIcon = () => {
    if (isAudioMuted) {
      return (
        <S.AudioIconBackground>
          <MicOff contentColor="white" size={16} />
        </S.AudioIconBackground>
      );
    }
    return null;
  };

  return (
    <S.ParticipantBar
      $nameDisplay={type}
      $nameDisplayVisible={nameDisplayVisible}
    >
      {nameDisplayVisible ? (
        <S.NameDisplay $isAudioMuted={isAudioMuted}>
          <S.Background $type={type} $brandColor={brandColor} />
          <S.Text $type={type} $brandColor={brandColor}>
            {displayName}
          </S.Text>
        </S.NameDisplay>
      ) : (
        <span />
      )}
      {AudioIcon()}
    </S.ParticipantBar>
  );
};
