import Ag from '@brightlive/shared/styles/typography-v3';
import styled, {DefaultTheme} from 'styled-components';

const S = {
  ChatMessage: styled.div(
    (props: {$isMe: boolean; theme: DefaultTheme}) => `
  display: flex;
  flex-direction: column;
  align-self: ${props.$isMe ? 'flex-end' : 'flex-start'};
`
  ),
  AvatarContainer: styled.div`
    align-self: flex-end;
    margin-bottom: ${props => props.theme.spacing.MD};
    padding-left: ${props => props.theme.spacing.SM};
    padding-right: ${props => props.theme.spacing.SM};
  `,
  MessageTop: styled.div`
    display: flex;
    margin-left: ${props => props.theme.spacing['5XL']};
    padding-bottom: ${props => props.theme.spacing['2XS']};
  `,
  MessageBottom: styled.div`
    display: flex;
  `,
  MessageName: styled(Ag.ParagraphXS)`
    padding-right: ${props => props.theme.spacing.XS};
  `,
  MessageText: styled(Ag.ParagraphMD)(
    (props: {$isMe: boolean; theme: DefaultTheme}) => `
  color: ${
    props.$isMe
      ? props.theme.contentColor.inverse
      : props.theme.contentColor.default
  };
  margin-bottom: ${props.theme.spacing.MD};
  border-radius: ${props.theme.borderRadius['2XL']};
  padding: ${props.theme.spacing.SM} ${props.theme.spacing.MD} ${
      props.theme.spacing.SM
    } ${props.theme.spacing.MD};
  background-color: ${
    props.$isMe
      ? props.theme.actionColor.brand
      : props.theme.backgroundColor.secondary
  };
  max-width: 276px;
  word-break: break-word;
  a {
    color: ${
      props.$isMe
        ? props.theme.contentColor.inverse
        : props.theme.contentColor.default
    };
    text-decoration: underline;
  }
`
  ),
  Timestamp: styled(Ag.ParagraphXS)`
    color: ${props => props.theme.contentColor.moreSubdued};
  `,
};
export default S;
