import styled from 'styled-components';
import {motion} from 'framer-motion';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag, {getBold} from '@brightlive/shared/styles/typography-v3';

const S = {
  CalloutWrapper: styled(motion.div)`
    position: absolute;
    top: 50%;
    z-index: 20000;
    display: none;
    justify-content: flex-start;
    white-space: nowrap;

    ${media.MD} {
      display: flex;
    }
  `,
  TextWrapper: styled(motion.div)`
    justify-content: flex-start;
    white-space: nowrap;
    position: relative;

    ${media.MD} {
      display: flex;
    }
  `,
  Text: styled(Ag.ParagraphSM)`
    color: ${props => props.theme.contentColor.default};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  Name: styled(Ag.ParagraphSM)`
    color: ${props => props.theme.contentColor.default};
    ${getBold()};
    margin-right: ${props => props.theme.spacing['2XS']};
  `,
};

export default S;
