import React from 'react';
// Components
import Modal from '@brightlive/shared/components/Modal';
// Styles
import S from './style';

export const ErrorModal = ({
  message,
  closeModal,
}: {
  message: string;
  closeModal: Function;
}) => {
  return (
    <Modal closeModal={closeModal} buttons={false}>
      <S.Header>Oops...</S.Header>
      <S.Text>{message}</S.Text>
      <S.Text>
        Please visit{' '}
        <a target="_blank" href="https://support.brightlive.com">
          support.brightlive.com
        </a>{' '}
        for help
      </S.Text>
    </Modal>
  );
};
