import React, {useEffect, useRef, useState} from 'react';
// Components
import {Role, RoomState, SessionChatParticipantView} from 'bright-livekit';
// Styles
import S from './style';
import {clone} from 'lodash';

export const QuestionsView = ({
  replaceQuestion,
  roomState,
  sessionID,
}: {
  replaceQuestion?: () => void;
  sessionID: string;
  roomState: RoomState;
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const {participantsQandC} = roomState;
  const [isAtBottom, setIsAtBottom] = useState(true);

  useEffect(() => {
    const seenQuestions = roomState.lastSeenQuestions
      ? clone(roomState.lastSeenQuestions)
      : [];
    // Set unread questions to 0
    roomState.setUnreadQuestions(0);
    // Add unread question timestamp to lastSeenQuestions array
    for (const question of participantsQandC) {
      const timestamp = question?.questionTimestamp;
      if (timestamp && !seenQuestions.includes(timestamp)) {
        seenQuestions.push(timestamp);
      }
    }
    roomState.setLastSeenQuestions(seenQuestions);
    if (isAtBottom) {
      scrollRef?.current?.scrollTo(0, scrollRef.current.scrollHeight);
    }
  }, [participantsQandC]);

  function onScroll() {
    if (scrollRef.current) {
      setIsAtBottom(
        scrollRef.current.scrollTop + scrollRef.current.offsetHeight >
          scrollRef.current.scrollHeight
      );
    }
  }

  return (
    <S.QuestionsContainer>
      {participantsQandC?.length > 0 ? (
        <S.ChatMessages ref={scrollRef} onScroll={onScroll}>
          {participantsQandC?.map(val => {
            if (![Role.Banned, Role.OnStage].includes(val.role)) {
              return (
                <SessionChatParticipantView
                  roomState={roomState}
                  key={val.id}
                  text={val.question}
                  timestamp={val.questionTimestamp}
                  displayName={val.displayName}
                  participant={val}
                  sessionID={sessionID}
                  shareHeight="40%"
                  orientation="landscape"
                  modControls={roomState.modControls}
                  onStage={roomState.onStage}
                  replaceQuestion={replaceQuestion}
                />
              );
            }
            return null;
          })}
        </S.ChatMessages>
      ) : (
        <S.EmptyContainer>
          <S.EmptyIconContainer>💬</S.EmptyIconContainer>
          <S.EmptyText>No questions yet</S.EmptyText>
        </S.EmptyContainer>
      )}
    </S.QuestionsContainer>
  );
};
