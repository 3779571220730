import {CaptionEntry} from 'bright-livekit';
import {
  IVideoService,
  IVideoServiceConnectParams,
} from 'bright-livekit/services/video/IVideoService';
import {IParticipant} from 'bright-livekit/types/participant/IParticipant';
import {LivekitParticipant} from 'bright-livekit/types/participant/LivekitParticipant';
import {ITrack} from 'bright-livekit/types/track/ITrack';
import {ITrackPublication} from 'bright-livekit/types/track/ITrackPublication';
import {LivekitTrack} from 'bright-livekit/types/track/LivekitTrack';
import {LivekitTrackPublication} from 'bright-livekit/types/track/LivekitTrackPublication';
import {
  DataPacket_Kind,
  LocalParticipant,
  LocalTrack,
  Participant,
  ParticipantEvent,
  RemoteParticipant,
  RemoteTrackPublication,
  Room,
  RoomEvent,
  Track,
  TrackInvalidError,
  VideoPresets,
} from 'livekit-client';
import {EventRegister} from '../EventRegister';
const presets = {
  adaptiveStream: true,
  dynacast: true,
  audioCaptureDefaults: {
    noiseSuppression: true,
    echoCancellation: true,
  },
  videoCaptureDefaults: {
    resolution: VideoPresets.h720.resolution,
  },
  publishDefaults: {
    simulcast: true,
  },
};

const roomPresets = {
  autoSubscribe: true,
};

export class LivekitVideoService implements IVideoService {
  private _participants: WeakMap<Participant, LivekitParticipant>;
  private pendingPublishing = new Set<Track.Source>();
  private _livekitParticipant: LivekitParticipant;
  localParticipant?: IParticipant;
  room: Room;
  captions: CaptionEntry[];
  decoder = new TextDecoder();
  onActiveSpeakerChangedHandler?: (participants: IParticipant[]) => void;
  onParticipantsChangedHandler?: () => void;
  onParticipantTrackPublishedHandler?: (
    publication: ITrackPublication,
    participant: IParticipant
  ) => void;
  onParticipantLeaveHandler?: (participant: IParticipant) => void;
  onClosedCaptionsUpdated?: (captions: CaptionEntry[]) => void;
  onDisconnectHandler?: () => void;
  constructor() {
    this.room = new Room(presets);
    this._livekitParticipant = new LivekitParticipant(
      this.room.localParticipant
    );
    this.localParticipant = this._livekitParticipant;
    this.captions = [];
    this._participants = new WeakMap();
  }

  private _getParticipant(participant: Participant) {
    let part = this._participants.get(participant);
    if (!part) {
      part = new LivekitParticipant(participant);
      this._participants.set(participant, part);
    }
    return part;
  }

  public get participants(): IParticipant[] {
    const remoteParticipants = Array.from(this.room.participants.values()).map(
      p => this._getParticipant(p)
    );
    if (this.localParticipant) {
      return [this.localParticipant, ...remoteParticipants];
    } else {
      return remoteParticipants;
    }
  }

  async connect(params: IVideoServiceConnectParams) {
    if (!params.url) {
      throw new Error('Missing param "url"');
    }
    await this.room.connect(params.url, params.token, roomPresets);
    this._livekitParticipant = this._getParticipant(this.room.localParticipant);
    this.localParticipant = this._livekitParticipant;
    this.room.once(RoomEvent.Disconnected, () => this.disconnect());
  }
  async disconnect() {
    this.room.disconnect();
    this.removeHandlers();
  }

  switchActiveDevice(
    kind: 'audioinput' | 'videoinput' | 'audiooutput',
    deviceId: string
  ) {
    this.room.switchActiveDevice(kind, deviceId);
  }

  async toggleScreenshare(
    enabled: boolean,
    shareAudio = true
  ): Promise<ITrack[] | undefined> {
    let tracks: LocalTrack[] = [];
    const participant = this._livekitParticipant;
    const livekitParticipant = participant.participant as LocalParticipant;
    const shareTrack = livekitParticipant.getTrack(Track.Source.ScreenShare);
    const audioTrack = livekitParticipant.getTrack(
      Track.Source.ScreenShareAudio
    );
    if (enabled) {
      if (shareTrack) {
        if (shareTrack.track) {
          tracks.push(shareTrack.track);
        }
        if (audioTrack && audioTrack.track) {
          tracks.push(audioTrack.track);
        }
        await shareTrack.unmute();
        await audioTrack?.unmute();
      } else {
        if (this.pendingPublishing.has(Track.Source.ScreenShare)) {
          console.info('skipping duplicate published screenshare source');
          // no-op it's already been requested
          return;
        }
        this.pendingPublishing.add(Track.Source.ScreenShare);
        try {
          tracks = await livekitParticipant.createScreenTracks({
            audio: shareAudio,
          });
          for (const track of tracks) {
            await livekitParticipant.publishTrack(track);
          }
        } catch (e) {
          if (e instanceof Error && !(e instanceof TrackInvalidError)) {
            livekitParticipant.emit(ParticipantEvent.MediaDevicesError, e);
          }
          throw e;
        } finally {
          this.pendingPublishing.delete(Track.Source.ScreenShare);
        }
      }
    } else {
      // screenshare cannot be muted, unpublish instead
      if (shareTrack && shareTrack.track) {
        livekitParticipant.unpublishTrack(shareTrack.track);
      }
      if (audioTrack && audioTrack.track) {
        livekitParticipant.unpublishTrack(audioTrack.track);
      }
    }
    return tracks.map(t => new LivekitTrack(t));
  }

  removeHandlers() {
    this.room.off(RoomEvent.DataReceived, this.closedCaptionHandler);
    if (this.onParticipantsChangedHandler) {
      this.room
        .off(RoomEvent.ParticipantConnected, this.onParticipantsChangedHandler)
        .off(
          RoomEvent.ParticipantDisconnected,
          this.onParticipantsChangedHandler
        )
        .off(RoomEvent.TrackPublished, this.onParticipantsChangedHandler)
        .off(RoomEvent.TrackUnpublished, this.onParticipantsChangedHandler)
        .off(RoomEvent.TrackSubscribed, this.onParticipantsChangedHandler)
        .off(RoomEvent.TrackUnsubscribed, this.onParticipantsChangedHandler)
        .off(RoomEvent.LocalTrackPublished, this.onParticipantsChangedHandler)
        .off(
          RoomEvent.AudioPlaybackStatusChanged,
          this.onParticipantsChangedHandler
        )
        .off(RoomEvent.DataReceived, this.onParticipantsChangedHandler);
    }
    if (this.onParticipantLeaveHandler) {
      this.room.off(
        RoomEvent.ParticipantDisconnected,
        EventRegister.wrap(
          this._participantConverter,
          this.onParticipantLeaveHandler
        )
      );
    }
    if (this.onActiveSpeakerChangedHandler) {
      this.room.off(
        RoomEvent.ParticipantDisconnected,
        EventRegister.wrap(
          this._participantsConverter,
          this.onActiveSpeakerChangedHandler
        )
      );
    }
  }
  onDisconnect(handler: () => void) {
    this.onDisconnectHandler = handler;
  }
  onParticipantChanged(handler: () => void) {
    this.onParticipantsChangedHandler = handler;
    this.room
      .on(RoomEvent.ParticipantConnected, this.onParticipantsChangedHandler)
      .on(RoomEvent.ParticipantDisconnected, this.onParticipantsChangedHandler)
      .on(RoomEvent.TrackPublished, this.onParticipantsChangedHandler)
      .on(RoomEvent.TrackUnpublished, this.onParticipantsChangedHandler)
      .on(RoomEvent.TrackSubscribed, this.onParticipantsChangedHandler)
      .on(RoomEvent.TrackUnsubscribed, this.onParticipantsChangedHandler)
      .on(RoomEvent.LocalTrackPublished, this.onParticipantsChangedHandler)
      .on(
        RoomEvent.AudioPlaybackStatusChanged,
        this.onParticipantsChangedHandler
      )
      .on(RoomEvent.DataReceived, this.onParticipantsChangedHandler);
  }
  private _participantConverter = (
    handler: (participant: LivekitParticipant) => void
  ) => {
    return (participant: RemoteParticipant) => {
      handler(this._getParticipant(participant));
    };
  };
  private _participantsConverter = (
    handler: (participants: LivekitParticipant[]) => void
  ) => {
    return (participants: Participant[]) => {
      handler(participants.map(s => this._getParticipant(s)));
    };
  };

  private _participantTrackPublishedConverter = (
    handler: (
      publication: LivekitTrackPublication,
      participant: LivekitParticipant
    ) => void
  ) => {
    return (
      publication: RemoteTrackPublication,
      participant: RemoteParticipant
    ) => {
      const part = this._getParticipant(participant);
      const pub = part.getTrackPublication(publication);
      handler(pub, part);
    };
  };
  onParticipantTrackPublished(
    handler: (publication: ITrackPublication, participant: IParticipant) => void
  ) {
    this.onParticipantTrackPublishedHandler = handler;
    this.room.on(
      RoomEvent.TrackPublished,
      EventRegister.wrap(
        this._participantTrackPublishedConverter,
        this.onParticipantTrackPublishedHandler
      )
    );
  }

  onActiveSpeakerChanged(handler: (speakers: IParticipant[]) => void) {
    this.onActiveSpeakerChangedHandler = handler;
    this.room.on(
      RoomEvent.ActiveSpeakersChanged,
      EventRegister.wrap(
        this._participantsConverter,
        this.onActiveSpeakerChangedHandler
      )
    );
  }

  onParticipantLeave(handler: (participant: IParticipant) => void) {
    this.onParticipantLeaveHandler = handler;
    this.room.on(
      RoomEvent.ParticipantDisconnected,
      EventRegister.wrap(
        this._participantConverter,
        this.onParticipantLeaveHandler
      )
    );
  }
  closedCaptionHandler = (
    payload: Uint8Array,
    participant?: RemoteParticipant | undefined,
    kind?: DataPacket_Kind | undefined
  ) => {
    if (kind !== DataPacket_Kind.LOSSY || !this.onClosedCaptionsUpdated) {
      return;
    }
    // closed captions
    const msg = this.decoder.decode(payload);
    const decodedMessage = JSON.parse(msg);
    if (!decodedMessage.transcript) {
      return;
    }
    let caption = this.captions.find(c => c.userID === decodedMessage.id);
    if (!caption) {
      caption = new CaptionEntry(decodedMessage.id, decodedMessage.name);
      this.captions.push(caption);
    }
    caption.transcript = decodedMessage.transcript;
    caption.name = decodedMessage.name;
    this.captions = this.captions
      .filter(c => !c.isExpired)
      .sort((a, b) => a.lastUpdateTime - b.lastUpdateTime);
    this.onClosedCaptionsUpdated(this.captions);
  };
  onClosedCaptionReceived(handler: (captions: CaptionEntry[]) => void) {
    this.onClosedCaptionsUpdated = handler;
    this.room.on(RoomEvent.DataReceived, this.closedCaptionHandler);
  }
}
