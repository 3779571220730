import React, {useState} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
// Icons
import icons, {IconNames} from '../../icons';
// Helpers
import {IconProps} from '../../helpers/interfaces';
// Style
import S, {getColor, TabTypes} from './style';
export interface TabProps {
  text: string | React.ReactNode;
  type: TabTypes;
  onClick: Function;
  icon?: IconNames;
  selected: boolean;
  theme: DefaultTheme;
}

const Tab = ({text, type, onClick, icon, selected}: TabProps) => {
  const [hovered, setHovered] = useState(false);
  const [pressed, setPressed] = useState(false);
  const handleClick = e => {
    e.stopPropagation();
    if (!onClick) {
      return null;
    }
    return onClick(e);
  };

  const Icon: React.FC<IconProps> | null = icon ? icons[icon] : null;

  return (
    <S.Tab
      $type={type}
      onClick={handleClick}
      $selected={selected}
      $pressed={pressed}
      $hovered={hovered}
      tabIndex={0}
      onTouchStart={() => {
        setPressed(true);
      }}
      onTouchEnd={() => {
        setPressed(false);
      }}
      onMouseDown={() => {
        setPressed(true);
      }}
      onMouseUp={() => {
        setPressed(false);
      }}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      {Icon && (
        <S.Icon>
          <Icon
            contentColor={getColor({
              type: type,
              selected: selected,
              hovered: hovered,
              pressed: pressed,
            })}
            size={24}
          />
        </S.Icon>
      )}
      <S.Text
        $type={type}
        $selected={selected}
        $pressed={pressed}
        $hovered={hovered}
      >
        {text}
      </S.Text>
    </S.Tab>
  );
};

export default withTheme(Tab);
