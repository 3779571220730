import React, {useState, useEffect, useRef} from 'react';
import {findIndex, times} from 'lodash';
// Components
import Menu from '../Menu';
import ContextualAlert from '../ContextualAlert';
// Icons
import ArrowDropDown from '../../icons/ArrowDropDown';
// Helpers
import {MenuItem} from '../../helpers/interfaces';
import {useWindowSize} from '../../hooks/useWindowSize';
// Style
import S from './style';

export interface SelectProps {
  onFocus?: Function;
  onBlur?: Function;
  onChange?: Function;
  defaultStyling?: boolean;
  desktopWidth?: string;
  menuDirection?: 'left' | 'right';
  hasDesktopMenuStyling: boolean;
  hasMobileMenuStyling: boolean;
  value?: string | number;
  label?: string;
  placeholder?: string;
  error?: string;
  instructions?: string;
  options?: MenuItem[];
  disabled?: boolean;
  maxHeight?: string;
}
const arrow = {left: 37, up: 38, right: 39, down: 40};

const Select = ({
  onFocus,
  onBlur,
  onChange,
  desktopWidth,
  menuDirection,
  hasDesktopMenuStyling,
  hasMobileMenuStyling,
  value,
  label,
  placeholder = 'Select...',
  error,
  instructions,
  options,
  disabled,
}: SelectProps) => {
  const {isMobile, width, height} = useWindowSize();
  const selectRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const [menuPosition, setMenuPosition] = useState({
    top: 0,
    left: 0,
    right: 0,
    width: desktopWidth,
  });

  useEffect(() => {
    window.addEventListener('scroll', reclacHoverPositions);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('scroll', reclacHoverPositions);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    reclacHoverPositions();
  }, [selectRef, width, height]);

  const reclacHoverPositions = () => {
    if (selectRef?.current) {
      const rect = selectRef.current?.getBoundingClientRect();
      const parsedDesktopWidth = parseInt(desktopWidth ?? '');
      const width =
        rect.width > parsedDesktopWidth ? rect.width : parsedDesktopWidth;
      const rightOffset =
        rect.width > parsedDesktopWidth ? 0 : parsedDesktopWidth - rect.width;
      setMenuPosition({
        top: rect.bottom + 4,
        left: rect.x,
        right: rect.x - rightOffset,
        width: `${width}px`,
      });
    }
  };

  useEffect(() => {
    if (menuOpen) {
      setIsFocused(true);
    }
  }, [menuOpen]);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Element;
    if (selectRef.current && !selectRef.current.contains(target) && menuOpen) {
      setMenuOpen(false);
      setIsFocused(false);
    }
  };

  const onInputFocus = () => {
    setIsFocused(true);
    onFocus && onFocus();
  };

  const onInputBlur = () => {
    setIsFocused(false);
    onBlur && onBlur();
  };

  const handleOnChange = e => {
    onChange && onChange(e.target.value);
    setSelectedValue(e.target.value);
  };

  const handleKeypress = e => {
    if (e.which === 13) {
      setMenuOpen(isMenuOpen => !isMenuOpen);
      reclacHoverPositions();
    }
  };

  const handleKeyDown = e => {
    if (!options) return;
    const selectIndex =
      findIndex(options, option => option.value === selectedValue) ?? 0;

    if (e.which === arrow.up) {
      if (selectIndex > 0) {
        return handleOnChange({
          target: {value: options?.[selectIndex - 1].value},
        });
      }
    } else if (e.which === arrow.down) {
      if (selectIndex < options?.length - 1) {
        return handleOnChange({
          target: {value: options?.[selectIndex + 1].value},
        });
      }
    }
  };

  const handleClick = () => {
    if (
      (isMobile && hasMobileMenuStyling) ||
      (!isMobile && hasDesktopMenuStyling)
    ) {
      setMenuOpen(!menuOpen);
      reclacHoverPositions();
    }
  };

  const getMaxHeightMobile = () => {
    if (!options) return undefined;
    const menuHasDescriptions = !!options?.[0]?.description;
    const optionHeight = menuHasDescriptions ? 92 : 56;

    // the amount of space that is needed in order to display all the options
    const spaceNeeded = options.length * optionHeight;

    const spaceAvailable = window.innerHeight * 0.75;
    if (spaceAvailable > spaceNeeded) return undefined;
    const howManyCanFit = spaceAvailable / optionHeight;
    // if you can fit at least half of the last one - show the last one as a half
    if (howManyCanFit % 1 >= 0.5) {
      return `${(Math.floor(howManyCanFit) + 0.5) * optionHeight}px`;
      // else just show the last half of the previous one
    } else {
      return `${(Math.floor(howManyCanFit) - 0.5) * optionHeight}px`;
    }
  };

  const getMaxHeightDesktop = () => {
    if (!options || !menuRef.current) return undefined;
    const menuHasDescriptions = !!options?.[0]?.description;
    const optionHeight = menuHasDescriptions ? 88 : 48;
    const maxOptions = menuHasDescriptions ? 7 : 15;
    const spaceFromBottom = 24;
    // make sure that options get cut off in the middle of the option. one less point than the max options because we want to always display at least the whole first one
    const cutoffPoints = times(maxOptions - 1, (i: number) => {
      return (i + 1) * optionHeight + optionHeight / 2;
    });

    // the amount of space that is actually available under the select
    const spaceAvailable =
      window.innerHeight -
      menuRef.current.getBoundingClientRect().top -
      spaceFromBottom;

    // the amount of space that is needed in order to display all the options
    const spaceNeeded = options.length * optionHeight + spaceFromBottom;

    // if there's less space available than needed map through the cut off points and find the one that fits in the space
    if (spaceAvailable < spaceNeeded) {
      let cutoffPoint;
      cutoffPoints.map((point, i) => {
        // if the next point is bigger than whats available - you've gone too far so use the previous one
        if (!cutoffPoint && point > spaceAvailable) {
          cutoffPoint = cutoffPoints[i - 1];
        }
      });
      return `${cutoffPoint}px`;
    } else if (spaceAvailable >= spaceNeeded && options.length <= maxOptions) {
      // if theres enough space and theres less than the max options - show them all
      return undefined;
    } else {
      // else there's enough space but more than the max options - so show only half of the last one
      return `${optionHeight * (maxOptions - 0.5)}px`;
    }
  };

  return (
    <>
      <S.SelectWrapper ref={selectRef}>
        <S.DropdownIcon open={menuOpen} onClick={handleClick}>
          <ArrowDropDown
            size={24}
            contentColor={disabled ? 'disabled' : 'default'}
          />
        </S.DropdownIcon>
        <S.Label disabled={disabled}>{label}</S.Label>
        {(isMobile && hasMobileMenuStyling) ||
        (!isMobile && hasDesktopMenuStyling) ? (
          <>
            <S.Select
              as="div"
              $isFocused={isFocused}
              $hasValue={!!value}
              error={!!error}
              disabled={disabled}
              aria-disabled={disabled}
              aria-label={label}
              role="list"
              aria-placeholder={placeholder}
              $hasLabel={!!label}
              onClick={handleClick}
              onFocus={() => {
                setIsFocused(true);
              }}
              onBlur={() => {
                setIsFocused(false);
              }}
              tabIndex={0}
              onKeyPress={handleKeypress}
              onKeyDown={handleKeyDown}
            >
              {options?.find(option => option.value === value)?.label ||
                placeholder}
            </S.Select>
            <div ref={menuRef} />
          </>
        ) : (
          <S.Select
            as="select"
            value={value}
            aria-placeholder={placeholder}
            aria-label={label}
            aria-disabled={disabled}
            role="list"
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            onChange={e => handleOnChange(e)}
            $isFocused={isFocused}
            $hasValue={!!value}
            error={!!error}
            disabled={disabled}
            $hasLabel={!!label}
          >
            <option key={`${label}option`} value="" role="listitem">
              {placeholder}
            </option>
            {options?.map(option => (
              <option
                role="listitem"
                key={`${label}option${option.value}`}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </S.Select>
        )}
        {menuOpen && (
          <Menu
            menuItems={(options || []).map(option => ({
              label: option.label,
              value: option.value,
              description: option?.description,
              onClick: () => handleOnChange({target: {value: option.value}}),
              isLine: false,
            }))}
            value={selectedValue}
            isMobile={isMobile}
            onClose={() => {
              setMenuOpen(false);
            }}
            type="select"
            desktopWidth={menuPosition.width}
            menuPosition={menuPosition}
            menuDirection={menuDirection}
            maxHeightDesktop={getMaxHeightDesktop()}
            maxHeightMobile={getMaxHeightMobile()}
            triggerRef={selectRef}
          />
        )}
      </S.SelectWrapper>

      {instructions && !error && <S.HelperText>{instructions}</S.HelperText>}
      {!!error && <ContextualAlert text={error} type="error" />}
    </>
  );
};

export default Select;
