import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag, {getHeadingMD} from '@brightlive/shared/styles/typography-v3';
import styled, {DefaultTheme} from 'styled-components';

export enum AlertBackgroundColors {
  warning = 'warning',
  alert = 'negative',
}

const S = {
  AlertBanner: styled.div(
    (props: {
      $navExpanded: boolean;
      $alertType: 'warning' | 'alert';
      theme: DefaultTheme;
    }) => `
      display: flex;
      background-color: ${
        props.theme.backgroundColor[AlertBackgroundColors[props.$alertType]]
      };
      padding: ${props.theme.spacing.MD};
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      z-index: 2000;
      ${media.MD} {
        padding: ${props.theme.spacing.MD} 0;
        justify-content: center;
        align-items: center;
        width: ${
          props.$navExpanded
            ? 'calc((100vw / 3) * 2 + 15px)'
            : `calc(100% - ${props.theme.spacing['4XL']})`
        };
      }
    `
  ),

  AlertBannerText: styled(Ag.HeadingSM)(
    (props: {$alertType: 'warning' | 'alert'; theme: DefaultTheme}) => `
    text-align: center;
    color: ${
      props.$alertType === 'warning'
        ? props.theme.contentColor.black
        : props.theme.contentColor.white
    };
    ${media.MD} {
      ${getHeadingMD(props.theme)}
      color: ${
        props.$alertType === 'warning'
          ? props.theme.contentColor.black
          : props.theme.contentColor.white
      };
    }
  `
  ),
};

export default S;
