import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
//Components
import {RoomState} from 'bright-livekit';
import Radio from '@brightlive/shared/components/Radio';
import TextInput from '@brightlive/shared/components/TextInput';
import Switch from '@brightlive/shared/components/Switch';
// Actions
import {toggleSessionTheme} from 'redux/ui/actions';
import {useLocalStorage} from '@brightlive/shared/hooks/useLocalStorage';
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
import useDebounce from '@brightlive/shared/hooks/useDebounce';
// Styles
import S from './style';
import Divider from '@brightlive/shared/components/Divider';

interface SettingsModalProps {
  sessionID: string;
  roomState: RoomState;
  setAlwaysShowControls?: Function;
  setShowQuestionPreview?: Function;
}

export const GeneralSettings = ({
  sessionID,
  roomState,
  setAlwaysShowControls,
  setShowQuestionPreview,
}: SettingsModalProps) => {
  const dispatch = useDispatch();
  const [displayName, setDisplayName] = useState(
    roomState.currentUser.displayName
  );
  const debouncedName = useDebounce<string>(displayName, 500);
  const [preferredColorTheme, setPreferredColorTheme] = useLocalStorage(
    'prefer-color-scheme',
    'light'
  );
  const [alwaysShowSessionControls, setAlwaysShowSessionControls] =
    useLocalStorage('always-show-session-controls', false);
  const [displayQuestionPreview, setDisplayQuestionPreview] = useLocalStorage(
    'display-question-preview',
    true
  );

  const {isMobile} = useWindowSize();

  const updateDisplayName = (name: string) => {
    setDisplayName(name);
  };

  useEffect(() => {
    // Triggers when debouncedName changes
    roomState.videoUtils?.updateDisplayName(
      sessionID,
      roomState.currentUser.id,
      displayName
    );
  }, [debouncedName]);

  return (
    <S.GeneralSettings>
      <S.GeneralProfile>
        <S.GeneralHeader>Profile</S.GeneralHeader>
        <S.GeneralProfileText>
          This could be your first name, nickname, or however you would like
          people to refer to you in this Session.
        </S.GeneralProfileText>
        <TextInput
          value={displayName}
          label="Display name"
          onChange={e => updateDisplayName(e.target.value)}
        />
      </S.GeneralProfile>
      <S.GeneralAppearance>
        <S.GeneralHeader>Appearance</S.GeneralHeader>
        <S.GeneralRadio>
          <Radio
            label="Light"
            onCheck={() => {
              setPreferredColorTheme('light');
              dispatch(toggleSessionTheme('light'));
            }}
            checked={preferredColorTheme === 'light'}
          />
        </S.GeneralRadio>
        <S.GeneralRadio>
          <Radio
            label="Dark"
            onCheck={() => {
              setPreferredColorTheme('dark');
              dispatch(toggleSessionTheme('dark'));
            }}
            checked={preferredColorTheme === 'dark'}
          />
        </S.GeneralRadio>
        <Radio
          label="Sync with OS setting"
          onCheck={() => {
            setPreferredColorTheme('sync');
            dispatch(toggleSessionTheme('sync'));
          }}
          checked={preferredColorTheme === 'sync'}
        />
      </S.GeneralAppearance>
      <S.GeneralStage>
        <S.GeneralHeader>Stage</S.GeneralHeader>
        {isMobile && (
          <S.GeneralSessionControls>
            <S.GeneralParagraph>
              Always show Session controls
            </S.GeneralParagraph>
            <Switch
              toggled={alwaysShowSessionControls}
              onToggle={() => {
                if (setAlwaysShowControls) {
                  setAlwaysShowControls(!alwaysShowSessionControls);
                }
                setAlwaysShowSessionControls(!alwaysShowSessionControls);
              }}
            />
          </S.GeneralSessionControls>
        )}
        {isMobile && <Divider />}
        <S.GeneralQuestionsPreview>
          <S.GeneralQuestionText>
            <S.GeneralQuestionsPreviewText>
              Questions preview
            </S.GeneralQuestionsPreviewText>
            <S.GeneralSubText>
              Display a quick preview of an attendee’s question when you are
              viewing the full stage.
            </S.GeneralSubText>
          </S.GeneralQuestionText>
          <Switch
            toggled={displayQuestionPreview}
            onToggle={() => {
              if (setShowQuestionPreview) {
                setShowQuestionPreview(!displayQuestionPreview);
              }
              setDisplayQuestionPreview(!displayQuestionPreview);
            }}
          />
        </S.GeneralQuestionsPreview>
      </S.GeneralStage>
    </S.GeneralSettings>
  );
};
