import React, {useEffect, useRef, useState} from 'react';
import {
  useFloating,
  offset,
  flip,
  shift,
  useInteractions,
  useHover,
  FloatingPortal,
  arrow,
} from '@floating-ui/react';
import type {Placement} from '@floating-ui/react';
// Components
import Button from '@brightlive/shared/components/Button';
// Styles
import S from './style';

const TooltipWrapper = ({
  referenceContent,
  tooltipContent,
  size = 'small',
  width,
  position = 'top',
  tooltipOffset = 12,
  inverse = true,
  callout = false,
  isMenu = false,
  disabled = false,
  disableTooltip = false,
  delayMount = false,
  dismiss,
}: {
  referenceContent: React.ReactNode;
  tooltipContent: string | React.ReactNode;
  tooltipOffset?: number;
  inverse?: boolean;
  callout?: boolean;
  dismiss?: Function;
  width?: string;
  size?: 'small' | 'large';
  position?: Placement;
  isMenu?: boolean;
  disabled?: boolean;
  delayMount?: boolean;
  disableTooltip?: boolean;
}) => {
  const variants = {
    visible: {
      opacity: 1,
      display: 'flex',
    },
    hidden: {
      opacity: 0,
      transitionEnd: {
        display: 'none',
      },
      transition: {
        duration: 0.3,
      },
    },
  };
  const [showTooltip, setShowTooltip] = useState(false);
  const arrowEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (delayMount) {
      setTimeout(() => setShowTooltip(true), 500);
    }
  }, [delayMount]);
  const {x, y, strategy, refs, context, middlewareData, placement, update} =
    useFloating({
      open: showTooltip,
      onOpenChange: !callout ? setShowTooltip : () => {},
      placement: position,
      middleware: [
        offset(tooltipOffset),
        flip(),
        shift(),
        arrow({element: arrowEl}),
      ],
    });
  // Update arrow position if element is flipped
  useEffect(() => {
    if (middlewareData.arrow && placement && arrowEl.current) {
      const side = placement.split('-')[0];
      const staticSideconverter = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
      };
      const staticSide = staticSideconverter[side];
      const {x, y} = middlewareData.arrow;
      Object.assign(arrowEl.current.style, {
        left: x !== null ? `${x}px` : '',
        top: y !== null ? `${y}px` : '',
        [staticSide]: '-4px',
        transform: 'rotate(45deg)',
      });
    }
  }, [middlewareData.arrow]);

  const hover = useHover(context);

  const {getReferenceProps, getFloatingProps} = useInteractions([hover]);

  const Text = size === 'small' ? S.SmallText : S.LargeText;
  return (
    <S.MainContainer
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => !callout && setShowTooltip(false)}
      disabled={disabled}
    >
      {!disableTooltip && showTooltip && (
        <FloatingPortal>
          <S.TooltipWrapper
            initial="hidden"
            animate={tooltipContent && showTooltip ? 'visible' : 'hidden'}
            variants={variants}
            ref={refs.setFloating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              width: width ?? 'max-content',
            }}
            {...getFloatingProps()}
          >
            <S.Tooltip $callout={callout} $size={size} $inverse={inverse}>
              <Text $inverse={inverse} $callout={callout}>
                {tooltipContent}
              </Text>
              {dismiss && (
                <S.ButtonWrapper>
                  <Button
                    type="tertiary inverse"
                    size="small"
                    text="Got it"
                    onClick={() => {
                      setShowTooltip(false);
                      dismiss();
                      update();
                    }}
                  />
                </S.ButtonWrapper>
              )}
              {!isMenu && (
                <S.Caret ref={arrowEl} $inverse={inverse} $callout={callout} />
              )}
            </S.Tooltip>
          </S.TooltipWrapper>
        </FloatingPortal>
      )}
      <S.ButtonRef ref={refs.setReference} {...getReferenceProps}>
        {referenceContent}
      </S.ButtonRef>
    </S.MainContainer>
  );
};

export default React.memo(TooltipWrapper);
