import React from 'react';
// Components
import Button from '@brightlive/shared/components/Button';
import TextInput from '@brightlive/shared/components/TextInput';
import Divider from '@brightlive/shared/components/Divider';
// Images
import Google from 'public/images/google.svg';
// Helpers
import {
  LoadingAuthButton,
  AuthPages,
} from '@brightlive/shared/helpers/interfaces';
// Styles
import S from 'components/auth/style';
import {validateEmail} from '@brightlive/shared/helpers/validators/isEmail';
import {useSelector} from 'react-redux';
import {SessionReducer} from 'redux/session/reducer';

interface GuestLoginContentProps {
  loading: LoadingAuthButton;
  setPage: (val: AuthPages) => void;
  nameValue: string;
  setNameValue: (val: string) => void;
  nameError: string;
  setNameError: (val: string) => void;
  emailValue: string;
  setEmailValue: (val: string) => void;
  emailError: string;
  setEmailError: (val: string) => void;
  handleAnonLogin: () => Promise<void>;
  handleSocialLogin: (provider: 'google' | 'apple') => Promise<void>;
}

export const GuestLoginContent = ({
  loading,
  setPage,
  nameValue,
  setNameValue,
  nameError,
  setNameError,
  emailValue,
  setEmailValue,
  emailError,
  setEmailError,
  handleAnonLogin,
  handleSocialLogin,
}: GuestLoginContentProps) => {
  const session = useSelector(
    (state: SessionReducer) => state.session.activeSession
  );
  const onEmailChange = e => {
    e.preventDefault();
    setEmailValue(e.target.value);
    setEmailError('');
  };

  const onNameChange = e => {
    e.preventDefault();
    setNameValue(e.target.value);
    setNameError('');
  };

  const checkForErrors = () => {
    if (nameValue.length < 2) {
      setNameError('Please enter your name');
    }
    if (!emailValue || !validateEmail(emailValue)) {
      setEmailError('Please enter a valid email');
    } else {
      handleAnonLogin();
    }
  };

  const disabled = nameValue.length < 2 || !validateEmail(emailValue);

  let name = 'this Bright';

  if (session.creator?.displayName) {
    name = `${session.creator.displayName}${
      session.creator.displayName[session.creator.displayName.length - 1] ===
      's'
        ? "'"
        : "'s"
    }`;
  }

  return (
    <>
      <S.Title>{`Join ${name} Session!`}</S.Title>
      <S.Input>
        <TextInput
          label="Your Name"
          onChange={onNameChange}
          value={nameValue}
          error={nameError}
          keyPressHandler={checkForErrors}
        />
      </S.Input>
      <S.Input>
        <TextInput
          label="Email"
          placeholder="example@email.com"
          onChange={onEmailChange}
          value={emailValue}
          error={emailError}
          keyPressHandler={checkForErrors}
        />
      </S.Input>
      <S.ContinueButton>
        <Button
          type="primary"
          disabled={disabled}
          text="Continue"
          loading={loading === 'join'}
          onClick={checkForErrors}
          width="100%"
          size="medium"
        />
      </S.ContinueButton>
      <S.Divider>
        <Divider />
        <S.Or>or</S.Or>
      </S.Divider>
      <S.GoogleButton>
        <Button
          text="Continue with Google"
          type="secondary"
          size="medium"
          width="100%"
          imgIcon={Google}
          loading={loading === 'google'}
          onClick={() => handleSocialLogin('google')}
        />
      </S.GoogleButton>
      <S.AppleButton>
        <Button
          type="secondary"
          icon="Apple"
          loading={loading === 'apple'}
          text={'Continue with Apple'}
          onClick={() => handleSocialLogin('apple')}
          width="100%"
          size="medium"
        />
      </S.AppleButton>
      <S.Terms>
        By selecting <S.Bold>Continue</S.Bold>, I agree to Bright’s{' '}
        <S.Link
          target="_blank"
          href="/terms-of-service`"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </S.Link>{' '}
        and acknowledge the{' '}
        <S.Link
          target="_blank"
          href="/privacy-policy"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </S.Link>
        .
      </S.Terms>
      <S.AltText>
        Already have an account?{' '}
        <S.Link onClick={() => setPage('login')}>Log in</S.Link>
      </S.AltText>
    </>
  );
};
