import styled, {DefaultTheme} from 'styled-components';
import {media} from '@brightlive/shared/styles/breakpoints-v3';

export const getDisplayXL = (theme: DefaultTheme) => `
  font-family: owners, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 500;
  letter-spacing: 0;
  font-size: 40px;
  line-height: 44px;
`;

export const getDisplayXLDesktop = (theme: DefaultTheme) => `
  font-family: owners, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 500;
  letter-spacing: 0;
  font-size: 80px;
  line-height: 1;
`;

export const getDisplayLG = (theme: DefaultTheme) => `
  font-family: owners, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 500;
  letter-spacing: 0;
  font-size: 40px;
  line-height: 44px;
`;

export const getDisplayLGDesktop = (theme: DefaultTheme) => `
  font-family: owners, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 500;
  letter-spacing: 0;
  font-size: 64px;
  line-height: 1;
`;

export const getDisplayMD = (theme: DefaultTheme) => `
  font-family: owners, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 500;
  letter-spacing: 0;
  font-size: 32px;
  line-height: 36px;
`;

export const getDisplayMDDesktop = (theme: DefaultTheme) => `
  font-family: owners, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 500;
  letter-spacing: 0;
  font-size: 48px;
  line-height: 52px;
`;

export const getHeadingLG = (theme: DefaultTheme) => `
  font-family: owners, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 500;
  letter-spacing: 0;
  font-size: 28px;
  line-height: 32px;
`;

export const getHeadingLGDesktop = (theme: DefaultTheme) => `
  font-family: owners, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 500;
  letter-spacing: 0;
  font-size: 32px;
  line-height: 36px;
`;

export const getHeadingMD = (theme: DefaultTheme) => `
  font-family: owners, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 500;
  letter-spacing: 0;
  font-size: 24px;
  line-height: 28px;
`;

export const getHeadingSM = (theme: DefaultTheme) => `
  font-family: owners-text, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 700;
  letter-spacing: 0;
  font-size: 20px;
  line-height: 24px;
`;

export const getHeadingXS = (theme: DefaultTheme) => `
  font-family: owners-text, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 700;
  letter-spacing: 0;
  font-size: 16px;
  line-height: 20px;
`;

export const getHeading2XS = (theme: DefaultTheme) => `
  font-family: owners-text, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 700;
  letter-spacing: 0;
  font-size: 14px;
  line-height: 18px;
`;

export const getParagraphLG = (theme: DefaultTheme) => `
  font-family: owners-text, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 400;
  letter-spacing: 0;
  font-size: 18px;
  line-height: 24px;
`;

export const getParagraphLGDesktop = (theme: DefaultTheme) => `
  font-family: owners-text, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 400;
  letter-spacing: 0;
  font-size: 20px;
  line-height: 28px;
`;

export const getParagraphMD = (theme: DefaultTheme) => `
  font-family: owners-text, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 400;
  letter-spacing: 0;
  font-size: 16px;
  line-height: 24px;
`;

export const getParagraphSM = (theme: DefaultTheme) => `
  font-family: owners-text, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 400;
  letter-spacing: 0;
  font-size: 14px;
  line-height: 20px;
`;

export const getParagraphXS = (theme: DefaultTheme) => `
  font-family: owners-text, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 400;
  letter-spacing: 0;
  font-size: 12px;
  line-height: 16px;
`;

export const getButtonLabelMD = () => `
  font-family: owners-text, Helvetica, Arial;
  font-weight: 500;
  letter-spacing: 0;
  font-size: 16px;
  line-height: 24px;
`;

export const getButtonLabelSM = () => `
  font-family: owners-text, Helvetica, Arial;
  font-weight: 500;
  letter-spacing: 0;
  font-size: 14px;
  line-height: 20px;
`;

export const getEyebrowMD = (theme: DefaultTheme) => `
  font-family: owners-narrow, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 700;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1;
`;

export const getEyebrowSM = (theme: DefaultTheme) => `
  font-family: owners-text, Helvetica, Arial;
  color: ${theme.contentColor.default};
  font-weight: 500;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
`;

export const getLink = (theme: DefaultTheme) => `
  color: ${theme.contentColor.accent};
  font-weight: 700;
  cursor: pointer;

  &:hover,
  &:active {
    text-decoration: underline;
  }
`;

export const getBold = () => `
  font-weight: 500;
`;

const Ag = {
  DisplayXL: styled.h1`
    ${props => getDisplayXL(props.theme)}

    ${media.LG} {
      ${props => getDisplayXLDesktop(props.theme)}
    }
  `,
  DisplayLG: styled.h2`
    ${props => getDisplayLG(props.theme)}

    ${media.LG} {
      ${props => getDisplayLGDesktop(props.theme)}
    }
  `,
  DisplayMD: styled.h3`
    ${props => getDisplayMD(props.theme)}

    ${media.LG} {
      ${props => getDisplayMDDesktop(props.theme)}
    }
  `,
  HeadingLG: styled.h1`
    ${props => getHeadingLG(props.theme)}

    ${media.LG} {
      ${props => getHeadingLGDesktop(props.theme)}
    }
  `,
  HeadingMD: styled.h2`
    ${props => getHeadingMD(props.theme)}
  `,
  HeadingSM: styled.h3`
    ${props => getHeadingSM(props.theme)}
  `,
  HeadingXS: styled.h4`
    ${props => getHeadingXS(props.theme)}
  `,
  Heading2XS: styled.h5`
    ${props => getHeading2XS(props.theme)}
  `,
  ParagraphLG: styled.p`
    ${props => getParagraphLG(props.theme)}

    ${media.LG} {
      ${props => getParagraphLGDesktop(props.theme)}
    }
  `,
  ParagraphMD: styled.p`
    ${props => getParagraphMD(props.theme)}
  `,
  ParagraphSM: styled.p`
    ${props => getParagraphSM(props.theme)}
  `,
  ParagraphXS: styled.p`
    ${props => getParagraphXS(props.theme)}
  `,
  ButtonLabelMD: styled.div`
    ${getButtonLabelMD()}
  `,
  ButtonLabelSM: styled.div`
    ${getButtonLabelSM()}
  `,
  EyebrowMD: styled.h2`
    ${props => getEyebrowMD(props.theme)}
  `,
  EyebrowSM: styled.h3`
    ${props => getEyebrowSM(props.theme)}
  `,
  Link: styled.a`
    ${props => getLink(props.theme)}
  `,
  Bold: styled.span`
    ${getBold()}
  `,
};

export default Ag;
