import styled, {DefaultTheme} from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';

const S: Record<string, React.ElementType> = {};

S.ChatInput = styled.div`
  margin-bottom: ${props => props.theme.spacing.MD};
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

S.TextAreaWrapperOuter = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

S.TextAreaWrapperInner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

S.TextArea = styled(Ag.ParagraphMD)`
  color: ${props => props.theme.contentColor.default};
  background: transparent;
  border: none;
  flex: 1;
  box-sizing: border-box;
  resize: none;
  overflow: auto;
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
`;

S.ChatInputVideoPreview = styled.div`
  border-radius: ${props => props.theme.borderRadius.MD};
  padding-left: ${props => props.theme.spacing.SM};
  display: flex;
  align-items: center;
`;

S.ChatInputAvatar = styled.div`
  padding: 0 0 0 ${props => props.theme.spacing.SM};
`;

S.CharCount = styled(Ag.ParagraphXS)(
  (props: {$hasError: boolean; theme: DefaultTheme}) => `
    color: ${
      props.$hasError
        ? props.theme.contentColor.negative
        : props.theme.contentColor.default
    };
`
);

export default S;
