import {media} from '@brightlive/shared/styles/breakpoints-v3';
import {getEyebrowMD} from '@brightlive/shared/styles/typography-v3';
import {motion} from 'framer-motion';
import styled from 'styled-components';

const S = {
  LivekitLiveIndicator: styled(motion.div)`
    display: flex;
    align-items: center;
    opacity: 0;
    background-color: ${props => props.theme.actionColor.brandAlt};
    margin-left: ${props => props.theme.spacing.LG};
    padding: ${props => props.theme.spacing.XS}
      ${props => props.theme.spacing.SM};
    border-radius: ${props => props.theme.borderRadius.XS};

    ${media.MD} {
      margin-left: -${props => props.theme.spacing.LG};
    }
  `,

  LivekitLiveIcon: styled.div`
    height: 14px;
    width: 14px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${props => props.theme.spacing.XS};
  `,

  LivekitLiveIconOuter: styled(motion.div)`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    opacity: 0.5;
    background: ${props => props.theme.contentColor.black};
  `,

  LivekitLiveIconInner: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${props => props.theme.contentColor.black};
  `,

  LivekitLiveText: styled(motion.p)`
    ${props => getEyebrowMD(props.theme)}

    color: ${props => props.theme.contentColor.black};
  `,
};
export default S;
