import React from 'react';
// Style
import S from './style';

export type DividerType = 'default' | 'inverse';
export type Direction = 'horizontal' | 'vertical';

export interface DividerProps {
  type?: DividerType;
  direction?: Direction;
}

const Divider = ({
  type = 'default',
  direction = 'horizontal',
}: DividerProps) => {
  return <S.Divider $type={type} $direction={direction} />;
};

export default React.memo(Divider);
