import React, {useState} from 'react';
// Components
import Menu from '../Menu';
// Images
import DropdownIconDefault from '../../images/arrow-drop-down-icon-content-default.svg';
import DropdownIconInverse from '../../images/arrow-drop-down-icon-content-inverse.svg';
import DropdownIconDisabled from '../../images/arrow-drop-down-icon-content-disabled.svg';
import DropdownIconInverseDisabled from '../../images/arrow-drop-down-icon-content-inverse-disabled.svg';
// Helpers
import {MenuItem} from '../../helpers/interfaces';
// Style
import S from './style';

export type ButtonTypes =
  | 'primary'
  | 'secondary'
  | 'primary inverse'
  | 'secondary inverse';

export interface DropdownButtonProps {
  text: string;
  type: ButtonTypes;
  width?: string;
  disabled?: boolean;
  options?: MenuItem[];
  isMobile: boolean;
}

const DropdownButton = ({
  text,
  type,
  width,
  disabled = false,
  options = [],
  isMobile,
}: DropdownButtonProps) => {
  const [focused, setFocused] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClick = e => {
    e.stopPropagation();
    if (disabled) return;
    setOpen(!open);
  };

  const iconDisabled = {
    primary: DropdownIconDisabled,
    secondary: DropdownIconDisabled,
    'primary inverse': DropdownIconInverseDisabled,
    'secondary inverse': DropdownIconInverseDisabled,
  };

  const icon = {
    primary: DropdownIconInverse,
    secondary: DropdownIconDefault,
    'primary inverse': DropdownIconDefault,
    'secondary inverse': DropdownIconInverse,
  };

  return (
    <S.ButtonWrapperOuter
      $width={width}
      tabIndex={disabled ? -1 : 0}
      onClick={handleClick}
      disabled={disabled}
      onFocus={() => {
        if (!disabled) setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
    >
      <S.ButtonWrapper $type={type} disabled={disabled} $width={width}>
        {focused && <S.FocusBorder $type={type} disabled={disabled} />}
        <S.Button disabled={disabled} $type={type} $width={width}>
          <S.TextWrapper>
            <S.Text $type={type} disabled={disabled}>
              {text}
            </S.Text>
            <S.Icon
              src={disabled ? iconDisabled[type] : icon[type]}
              $open={open}
            />
          </S.TextWrapper>
        </S.Button>
        {open && (
          <Menu
            menuItems={options}
            isMobile={isMobile}
            onClose={() => {
              setOpen(false);
            }}
            type="dropdown button"
          />
        )}
      </S.ButtonWrapper>
    </S.ButtonWrapperOuter>
  );
};

export default React.memo(DropdownButton);
