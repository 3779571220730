import React from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
import {IconProps} from '@brightlive/shared/helpers/interfaces';

const RaiseHand = ({
  size = 24,
  contentColor = 'default',
  theme,
  ...props
}: IconProps & {
  theme: DefaultTheme;
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M12.5 24c-2.367 0-4.375-.825-6.025-2.475C4.825 19.875 4 17.867 4 15.5V7c0-.7.242-1.292.725-1.775C5.208 4.742 5.8 4.5 6.5 4.5a2.615 2.615 0 0 1 .5.05V4c0-.7.242-1.292.725-1.775C8.208 1.742 8.8 1.5 9.5 1.5c.117 0 .23.008.338.025.108.017.22.042.337.075a2.44 2.44 0 0 1 .9-1.162A2.422 2.422 0 0 1 12.5 0c.617 0 1.154.196 1.613.587.458.392.737.88.837 1.463A2.57 2.57 0 0 1 15.5 2c.7 0 1.292.242 1.775.725C17.758 3.208 18 3.8 18 4.5v3.55a2.696 2.696 0 0 1 .5-.05c.7 0 1.292.242 1.775.725.483.483.725 1.075.725 1.775v5c0 2.367-.825 4.375-2.475 6.025C16.875 23.175 14.867 24 12.5 24Zm0-2c.9 0 1.746-.17 2.538-.512A6.57 6.57 0 0 0 17.1 20.1a6.57 6.57 0 0 0 1.388-2.062A6.344 6.344 0 0 0 19 15.5v-5a.48.48 0 0 0-.15-.35.48.48 0 0 0-.7 0 .48.48 0 0 0-.15.35v2.925c0 .417-.1.779-.3 1.087-.2.309-.525.571-.975.788a3.012 3.012 0 0 0-.987.75c-.275.317-.48.675-.613 1.075-.083.267-.22.479-.412.637a.978.978 0 0 1-.638.238c-.317 0-.571-.108-.763-.325a.797.797 0 0 1-.187-.75c.183-.8.525-1.504 1.025-2.113A4.847 4.847 0 0 1 16 13.425V4.5a.48.48 0 0 0-.15-.35.48.48 0 0 0-.7 0 .48.48 0 0 0-.15.35V10c0 .283-.096.52-.287.712A.968.968 0 0 1 14 11a.965.965 0 0 1-.712-.288A.965.965 0 0 1 13 10V2.5a.48.48 0 0 0-.15-.35.48.48 0 0 0-.7 0 .48.48 0 0 0-.15.35V10c0 .283-.096.52-.287.712A.968.968 0 0 1 11 11a.965.965 0 0 1-.712-.288A.965.965 0 0 1 10 10V4a.48.48 0 0 0-.15-.35.48.48 0 0 0-.7 0A.48.48 0 0 0 9 4v7c0 .283-.096.52-.287.712A.968.968 0 0 1 8 12a.968.968 0 0 1-.713-.288A.967.967 0 0 1 7 11V7a.48.48 0 0 0-.15-.35.48.48 0 0 0-.7 0A.48.48 0 0 0 6 7v8.5c0 .9.171 1.746.513 2.538A6.555 6.555 0 0 0 7.9 20.1a6.566 6.566 0 0 0 2.063 1.388A6.337 6.337 0 0 0 12.5 22Z"
      fill={
        contentColor.indexOf('#') > -1
          ? contentColor
          : theme.contentColor[contentColor]
      }
    />
  </svg>
);

export default withTheme(RaiseHand);
