import {createLocalTracks} from 'livekit-client';
import {VideoUtils} from './VideoUtils';

export class LivekitVideoUtils extends VideoUtils {
  async createLocalTracks(opts: {
    audio: boolean;
    video: boolean;
  }): Promise<unknown> {
    return await createLocalTracks(opts);
  }
}
