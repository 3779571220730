import styled, {DefaultTheme} from 'styled-components';
import {focus, hover, active} from '../../helpers/style';
import {Sizes, ButtonTypes} from './index';
import {colors} from '../Button/style';

const S: Record<string, React.ElementType> = {};

const size = {
  small: '32px',
  medium: '48px',
};

const getBackgroundColor = (
  theme: DefaultTheme,
  disabled: boolean,
  type: ButtonTypes
) => {
  if (disabled) {
    const themeKey = colors.background.disabled[type].themeKey;
    const value = colors.background.disabled[type].value;
    return theme[themeKey][value];
  } else {
    const themeKey = colors.background.default[type].themeKey;
    const value = colors.background.default[type].value;
    return theme[themeKey][value];
  }
};

S.ButtonWrapper = styled.div(
  (props: {
    $size: Sizes;
    $type: ButtonTypes;
    disabled: boolean;
    theme: DefaultTheme;
  }) => `
  display: inline-flex;
  position: relative;
  height: ${size[props.$size]};
  width: ${size[props.$size]};
  cursor: ${props.disabled ? 'auto' : 'pointer'};

  ${focus({
    theme: props.theme,
    disabled: props.disabled,
    borderRadius: '100px',
  })}
  ${hover({
    backgroundColor: props.theme.actionColor[colors.overlay.hover[props.$type]],
    disabled: props.disabled,
    borderRadius: '50%',
  })}
  ${active({
    backgroundColor:
      props.theme.actionColor[colors.overlay.pressed[props.$type]],
    disabled: props.disabled,
    borderRadius: '50%',
  })}
`
);

S.Button = styled.div(
  (props: {
    theme: DefaultTheme;
    $type: ButtonTypes;
    disabled: boolean;
    $size: Sizes;
  }) => `
  height: ${size[props.$size]};
  width: ${size[props.$size]};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
  background-color: ${getBackgroundColor(
    props.theme,
    props.disabled,
    props.$type
  )};
`
);

S.Icon = styled.img`
  height: 24px;
  width: 24px;
  object-fit: contain;
  position: relative;
`;

S.TopRightContent = styled.img`
  position: absolute;
  top: 0;
  right: 0;
`;

S.LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default S;
