import {
  Timestamp,
  QueryDocumentSnapshot,
  DocumentData,
  updateDoc,
  DocumentReference,
} from 'firebase/firestore';
import {Role, ViewMode} from 'bright-livekit';
import {IParticipant} from './participant/IParticipant';
import _ from 'lodash';
export enum TrackSubscriptionMode {
  VideoOnly,
  AudioOnly,
  Both,
  None,
}
export enum TrackType {
  ScreenShare,
  ScreenShareAudio,
  Video,
  Audio,
}

interface RecordingStatus {
  onStage?: boolean;
  isVideoSubscribed?: boolean;
  isAudioSubscribed?: boolean;
  isVideoMuted?: boolean;
  isAudioMuted?: boolean;
  connectionQuality?: string;
  audioBitrate?: number;
  audiokHz?: number;
  videoBitrate?: number;
  videoResolution?: number;
  videoFramerate?: number;
}

export class BrightParticipant {
  id = '';
  avatar = '';
  displayName = '';
  isFirstSession = false;
  isShowRunner = false;
  joined = false;
  role = Role.GreenRoom;
  position: number | null = null;
  question?: string;
  questionAnswered?: boolean;
  questionTimestamp?: number;
  roleChanged?: Timestamp;
  viewMode?: ViewMode;
  videoParticipant?: IParticipant;
  firestore?: QueryDocumentSnapshot<DocumentData>;
  recordingStatus: RecordingStatus = {
    onStage: false,
    isVideoSubscribed: false,
    isAudioSubscribed: false,
    isVideoMuted: false,
    isAudioMuted: false,
    connectionQuality: 'unknown',
    audioBitrate: 0,
    audiokHz: 0,
    videoBitrate: 0,
    videoResolution: 0,
    videoFramerate: 0,
  };
  errorMessage = '';
  _updateRecorderStatus: (
    ref: DocumentReference<DocumentData>,
    status: RecordingStatus
  ) => void;

  constructor(
    doc: QueryDocumentSnapshot<DocumentData>,
    livekit?: IParticipant
  ) {
    this.update(doc, livekit);
    this._updateRecorderStatus = _.debounce(
      async (ref: DocumentReference<DocumentData>, status: RecordingStatus) => {
        await updateDoc(ref, {
          recordingStatus: status,
        });
      }
    );
  }
  /**
   * Update the participant object
   *
   * @param   {QueryDocumentSnapshot<DocumentData>}  doc           Document data to set
   * @param   {Participant}                          livekit       Livekit participant object
   *
   */
  update(doc: QueryDocumentSnapshot<DocumentData>, livekit?: IParticipant) {
    this.id = doc.id;
    this.avatar = doc.data().avatar;
    this.displayName = doc.data().displayName;
    this.isShowRunner = doc.data().isShowRunner;
    this.role = doc.data().role;
    this.position = doc.data().position;
    this.question = doc.data().question;
    this.questionAnswered = doc.data().questionAnswered;
    this.questionTimestamp = doc.data().questionTimestamp;
    this.roleChanged = doc.data().roleChanged;
    this.viewMode = doc.data().viewMode;
    this.joined = doc.data().joined;
    this.isFirstSession = doc.data().isFirstSession === true;
    this.recordingStatus.onStage = doc.data().recordingStatus?.onStage === true;
    this.recordingStatus.isVideoSubscribed =
      doc.data().recordingStatus?.isVideoSubscribed === true;
    this.recordingStatus.isAudioSubscribed =
      doc.data().recordingStatus?.isAudioSubscribed === true;
    this.recordingStatus.isVideoMuted =
      doc.data().recordingStatus?.isVideoMuted === true;
    this.recordingStatus.isAudioMuted =
      doc.data().recordingStatus?.isAudioMuted === true;
    this.recordingStatus.connectionQuality =
      doc.data().recordingStatus?.connectionQuality ?? 'unknown';
    this.recordingStatus.audioBitrate =
      doc.data().recordingStatus?.audioBitrate ?? 0;
    this.recordingStatus.audiokHz = doc.data().recordingStatus?.audiokHz ?? 0;
    this.recordingStatus.onStage = doc.data().recordingStatus?.onStage ?? false;
    this.recordingStatus.videoBitrate =
      doc.data().recordingStatus?.videoBitrate ?? 0;
    this.recordingStatus.videoFramerate =
      doc.data().recordingStatus?.videoFramerate ?? 0;
    this.recordingStatus.videoResolution =
      doc.data().recordingStatus?.videoResolution ?? 0;
    this.firestore = doc;
    this.videoParticipant = livekit;
  }
  /**
   * Set the current track subscription mode
   *
   * @param   {TrackSubscriptionMode}  mode  Mode to set
   *
   */
  setTrackSubscriptions(mode: TrackSubscriptionMode) {
    this.videoParticipant?.setTrackSubscriptions(mode);
  }
  /**
   * Ensure the correct tracks are subscribed to based on the subscription mode.
   * Call when a new track is published for this user
   *
   */
  ensureSubscription() {
    this.videoParticipant?.ensureSubscription();
  }

  private roundBitrate(i) {
    return i > 0 ? 1 : 0;
    // Currently we have no need for exact bitrate so we're just setting 1 if there is a measurable bitrate or 0 if there is not
    /*
      if (i <= 0) {
        return 0;
      }
      i = i / 1000;
      const digits = Math.floor(Math.log10(i));
      const unit = Math.pow(10, digits);
      return Math.ceil(i / unit) * unit;
    */
  }

  async updateRecorderStatus(status: RecordingStatus) {
    if (status.videoBitrate) {
      status.videoBitrate = this.roundBitrate(status.videoBitrate);
    }
    if (status.audioBitrate) {
      status.audioBitrate = this.roundBitrate(status.audioBitrate);
    }

    const mergedStatus = {...this.recordingStatus, ...status};
    if (this.firestore && !_.isEqual(mergedStatus, this.recordingStatus)) {
      this.recordingStatus = mergedStatus;
      this._updateRecorderStatus(this.firestore.ref, mergedStatus);
    }
  }
}
