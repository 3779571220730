import styled, {DefaultTheme} from 'styled-components';
import {motion} from 'framer-motion';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag from '@brightlive/shared/styles/typography-v3';

const S = {
  LivekitWrapper: styled.div`
    height: 100%;
  `,
  ModalTitle: styled(Ag.HeadingMD)`
    text-align: center;
  `,
  DesktopTopNav: styled.div`
    display: flex;
    height: ${props => props.theme.sizing.desktop.navHeight};
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 ${props => props.theme.margin.desktop.page};
    flex: 1;
    z-index: 3;
    pointer-events: none;
  `,
  LogoWrapper: styled.a`
    margin-right: ${props => props.theme.spacing['2XL']};
  `,
  LivekitTop: styled.div`
    background-color: ${props => props.theme.backgroundColor.body};
    display: flex;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
    z-index: 1;
    @media (min-width: 320px) and (max-width: 1023px) and (orientation: landscape) {
      height: 100vh;
    }
  `,
  LivekitStage: styled.div`
    position: relative;
    width 100%;
    background-color: ${props => props.theme.backgroundColor.body};
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: ${props => props.theme.sizing.desktop.navHeight} ${props =>
    props.theme.spacing.LG} 0;
    box-sizing: border-box;

    ${media.MD} {
      flex: initial;
      flex-direction: column;
    }
  `,
  LivekitTopRecording: styled.div`
    background-color: ${props => props.theme.backgroundColor.body};
    display: flex;
    height: 100vh;
  `,
  StageViewContainer: styled.div(
    (props: {$fillScreen: boolean; theme: DefaultTheme}) => `
    overflow-x: ${props.$fillScreen ? 'hidden' : 'auto'};
    overflow-y: ${props.$fillScreen ? 'hidden' : 'auto'};
    width: 100%;
    position: relative;
    ${media.MD} {
      height: 100vh;
    }
    @media (min-width: 320px) and (max-width: 1023px) and (orientation: landscape) {
      height: 100vh;
    }
  `
  ),
  PostSessionContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  `,
  CaptionsContainer: styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: ${props => props.theme.spacing.SM};
    z-index: 5;
    font-size: ${props => props.theme.fontSize[300]};
    line-height: ${props => props.theme.lineHeight[300]};
    text-shadow: 2px 2px 3px #000000;
    background: rgba(0, 0, 0, 0.4);
  `,
  CaptionLine: styled.div`
    padding: ${props => props.theme.spacing['2XS']};
  `,
  LowerControlsContainer: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${props => props.theme.backgroundColor.body};
    position: relative;
  `,
  LowerControls: styled.div`
    display: flex;
    position: relative;
    align-items: center;
    gap: ${props => props.theme.spacing.MD};
    height: ${props => props.theme.sizing.desktop.footerControlsHeight};
    padding-left: ${props => props.theme.spacing.MD};

    @media (min-width: 320px) and (max-width: 1023px) and (orientation: landscape) {
      display: none;
    }
  `,
  LowerControlsLeft: styled.div`
    position: absolute;
    left: 0;
    top: ${props => props.theme.spacing.MD};
  `,
  TooltipWrapper: styled.div``,
  RecordingButton: styled.div`
    position: relative;
    display: inline-block;
  `,
  MainControlWrapper: styled(motion.div)``,
  SessionControlsLeaveContainer: styled.div`
    padding: ${props => props.theme.spacing.XS};
    position: absolute;
    bottom: ${props => props.theme.spacing.MD};
    margin-bottom: ${props => props.theme.spacing['5XL']};
    background-color: ${props => props.theme.backgroundColor.primary};
    left: ${props => props.theme.spacing.MD};
    width: 320px;
    z-index: 2000;
    border-radius: ${props => props.theme.borderRadius.MD};
  `,
  SessionControlsLeaveContainerInner: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${props => props.theme.spacing.MD};
    margin: ${props => props.theme.spacing.XL}
      ${props => props.theme.spacing.MD};
  `,
  SessionControlsLeaveContainerBottom: styled.div`
    border-top: 1px solid ${props => props.theme.borderColor.subdued};
    display: flex;
    justify-content: flex-end;
    padding-top: ${props => props.theme.spacing.XS};
  `,
};

export default S;
