import Ag from '@brightlive/shared/styles/typography-v3';
import styled, {DefaultTheme} from 'styled-components';
import {media} from '@brightlive/shared/styles/breakpoints-v3';

const S = {
  ModalContent: styled.div`
    display: flex;
    flex-direction: column;
    justify-contents: center;
  `,

  ModalTitle: styled(Ag.HeadingMD)`
    text-align: center;
  `,

  ModalBody: styled(Ag.ParagraphMD)`
    margin-top: ${props => props.theme.spacing.XL};
    text-align: center;
  `,

  ModalCountdown: styled(Ag.ParagraphMD)`
    color: ${props => props.theme.contentColor.warning};
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  Icon: styled.div`
    margin-right: ${props => props.theme.spacing['2XS']};
  `,

  NotificationBanner: styled.div(
    (props: {
      $navExpanded: boolean;
      $warning: boolean;
      theme: DefaultTheme;
    }) => `
      display: flex;
      flex-direction: column;
      min-height: 48px;
      color: ${props.theme.contentColor.black};
      z-index: 2000;
      position: absolute;
      width: 100%;
      background-color: ${
        props.$warning
          ? props.theme.backgroundColor.warning
          : props.theme.backgroundColor.inversePrimary
      };
      ${media.MD} {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: ${
          props.$navExpanded
            ? 'calc((100vw / 3) * 2 + 15px)'
            : `calc(100% - ${props.theme.spacing['4XL']})`
        };
      }
    `
  ),

  NotificationBannerContent: styled(Ag.HeadingMD)(
    (props: {$centered: boolean; $warning: boolean; theme: DefaultTheme}) => `
      color: ${
        props.$warning
          ? props.theme.contentColor.black
          : props.theme.contentColor.inverse
      };
      flex-direction: column;
      align-items: ${props.$centered ? 'center' : 'flex-start'};
      gap: ${props.theme.spacing.MD};
      display: flex;
      padding: ${props.theme.spacing.MD};
      ${media.MD} {
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    `
  ),

  NotificationBannerContentRight: styled.div`
    position: absolute;
    right: ${props => props.theme.spacing.XS};
    padding: ${props => props.theme.spacing.XS};
  `,
};

export default S;
