import styled, {DefaultTheme} from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';
import {getInputBorder} from '@brightlive/shared/helpers/style';

const S: Record<string, React.ElementType> = {};

const getColor = (disabled, hasValue) => {
  if (disabled) {
    return 'disabled';
  } else if (!hasValue) {
    return 'subdued';
  } else {
    return 'default';
  }
};

S.SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: 0;
  cursor: pointer;
`;

S.DropdownIcon = styled.div(
  (props: {open: boolean; theme: DefaultTheme}) => `
  right: ${props.theme.spacing.MD};
  top: 50%;
  transform: translateY(-50%) ${props.open ? 'scaleY(-1)' : ''};
  position: absolute;
`
);

S.Label = styled(Ag.ParagraphXS)(
  (props: {disabled: boolean; theme: DefaultTheme}) => `
    color: ${
      props.disabled
        ? props.theme.contentColor.disabled
        : props.theme.contentColor.default
    };
    position: absolute;
    z-index: 2;
    top: ${props.theme.spacing.XS};
    left: ${props.theme.spacing.MD};
`
);

S.Select = styled(Ag.ParagraphMD)(
  (props: {
    $isFocused: boolean;
    $hasValue: boolean;
    $hasLabel: boolean;
    error: boolean;
    disabled: boolean;
    theme: DefaultTheme;
  }) => `
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    border: ${getInputBorder(
      props.$isFocused,
      props.error,
      props.disabled,
      props.theme
    )};
    border-radius: ${props.theme.borderRadius.MD};
    padding: ${
      !props.$hasLabel
        ? `${props.theme.spacing.MD} ${props.theme.spacing['5XL']} ${props.theme.spacing.MD} ${props.theme.spacing.MD}`
        : `${props.theme.spacing.LG} ${props.theme.spacing['5XL']} ${props.theme.spacing.XS} ${props.theme.spacing.MD}`
    };
    box-sizing: border-box;
    background-color: ${
      props.disabled
        ? props.theme.backgroundColor.disabled
        : props.theme.backgroundColor.primary
    };
    color: ${
      props.theme.contentColor[getColor(props.disabled, props.$hasValue)]
    };

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    option {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${
        props.theme.contentColor[getColor(props.disabled, props.$hasValue)]
      };
    }

    &::placeholder {
      color: ${
        props.disabled
          ? props.theme.contentColor.disabled
          : props.theme.contentColor.subdued
      };
    }

    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
`
);

S.ErrorWrapper = styled.div(
  (props: {error: boolean; theme: DefaultTheme}) => `
  padding-top: ${props.theme.spacing.XS};
  visibility: ${props.error ? 'visible' : 'hidden'};
  display: flex;
`
);

S.HelperText = styled(Ag.ParagraphSM)`
  color: ${props => props.theme.contentColor.subdued};
  padding-top: ${props => props.theme.spacing.XS};
`;

export default S;
