import styled, {DefaultTheme} from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';

const S = {
  MenuWrapper: styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2001;
    background-color: ${props => props.theme.backgroundColor.primary};
    display: flex;
    flex-direction: column;
  `,

  TopBar: styled.div`
    height: ${props => props.theme.sizing.mobile.navHeight};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${props => props.theme.margin.mobile.page};
  `,

  Menu: styled.div`
    padding-top: ${props => props.theme.spacing.MD};
    flex: 1;
    display: flex;
    flex-direction: column;
  `,
  MenuItem: styled(Ag.HeadingMD)(
    (props: {$active?: boolean; theme: DefaultTheme}) => `
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${props.theme.spacing.MD} ${props.theme.margin.mobile.page};
      position: relative;

      ${
        props.$active &&
        `&:after {
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        width: ${props.theme.borderWidth[4]};
        background: ${props.theme.borderColor.primary};
        position: absolute;
      }`
      }
    }
  `
  ),

  Divider: styled.div`
    background-color: ${props => props.theme.borderColor.subdued};
    height: 1px;
    margin: 0 ${props => props.theme.margin.mobile.page};
    width: calc(100% - (${props => props.theme.margin.mobile.page} * 2));
  `,

  SignUpButton: styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    padding: ${props => props.theme.spacing.MD}
      ${props => props.theme.margin.mobile.page};
    box-sizing: border-box;
  `,
};

export default S;
