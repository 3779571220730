import React from 'react';
//Components
import {BrightParticipant} from 'bright-livekit';
import Modal from '@brightlive/shared/components/Modal';
// Styles
import S from './style';
import {RoomState} from 'bright-livekit';
import {TrackingService} from 'bright-livekit/services/TrackingService';

export const FlagUserModal = ({
  sessionID,
  participant,
  roomState,
  onClose,
}: {
  sessionID: string;
  participant: BrightParticipant;
  roomState: RoomState;
  onClose: () => void;
}) => {
  return (
    <Modal
      closeModal={() => {
        onClose();
      }}
      buttons={{
        button1Text: 'Cancel',
        button2Text: 'Flag',
        button2OnClick: () => {
          TrackingService.fire({
            event: 'flagged',
            currentUser: roomState.currentUser,
            data: {
              session_id: sessionID,
              target_uid: participant.id,
              target_role: participant.role,
              time: new Date(new Date().getTime()),
            },
          });
          roomState.videoUtils?.ban(sessionID, participant.id);
          onClose();
        },
      }}
    >
      <S.ModalContent>
        <S.ModalTitle>Flag and disable {participant.displayName}?</S.ModalTitle>
        <S.ModalBody>
          Participant's camera will be turned off and actions disabled. This
          cannot be undone. Are you sure you want to flag and disable?
        </S.ModalBody>
      </S.ModalContent>
    </Modal>
  );
};
