import styled, {DefaultTheme} from 'styled-components';
import {Property} from 'csstype';
import Ag from '@brightlive/shared/styles/typography-v3';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
// Helpers
import {toRgbaString} from '@brightlive/shared/helpers/colorFormatter';
// Types
import {Hex} from '@brightlive/shared/helpers/interfaces';
import {NameDisplayTypes} from 'bright-livekit';

const S = {
  ParticipantBar: styled.div(
    (props: {$nameDisplay: NameDisplayTypes; theme: DefaultTheme}) => `

    box-sizing: border-box;
    position: absolute;
    z-index: 5;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: ${
      props.$nameDisplay === NameDisplayTypes.Block
        ? `${props.theme.spacing.XS} 0 0`
        : `${props.theme.spacing.XS}`
    };
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${media.MD} {
      padding: ${
        props.$nameDisplay === NameDisplayTypes.Block
          ? `${props.theme.spacing.MD}
      ${props.theme.spacing.MD} 0 0`
          : `
      ${props.theme.spacing.MD}`
      };
    }
  `
  ),
  AudioIconBackground: styled.div`
    height: 24px;
    width: 24px;
    background: ${props => props.theme.backgroundColor.blackTransparent};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: ${props => props.theme.spacing.MD};
  `,

  ParticipantHoverContainer: styled.div(
    (props: {$background?: boolean; theme: DefaultTheme}) => `
    z-index: 5;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    background: ${
      props.$background
        ? toRgbaString(props.theme.backgroundColor.primary, 0.8)
        : 'unset'
    };
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: ${props.theme.spacing.XS};
    box-sizing: border-box;
    height: 100%;
    width: 100%;
  `
  ),

  ParticipantHoverControlsItem: styled.div`
    margin-bottom: ${props => props.theme.spacing.MD};
  `,

  ParticipantHoverControlsItemShare: styled.div`
    margin-top: ${props => props.theme.spacing.MD};
  `,

  ParticipantMoreSection: styled.div(
    (props: {$mode: 'share' | 'mobile' | 'desktop'; theme: DefaultTheme}) => `
    background-color: ${props.theme.backgroundColor.blackTransparent};
    border-radius: ${props.theme.borderRadius['2XL']};
    position: ${props.$mode !== 'share' ? 'absolute' : 'relative'};
    top: ${props.$mode !== 'share' ? props.theme.spacing.MD : 'unset'};
    right: ${props.$mode !== 'share' ? props.theme.spacing.MD : 'unset'};
    z-index: 2000;
  `
  ),

  ParticipantMoreOptions: styled.div(
    (props: {position: 'top-left' | 'bottom-left'; theme: DefaultTheme}) => `
  position: absolute;
  top: ${props.position === 'top-left' ? '-160px' : '55px'};
  right: ${props.position === 'top-left' ? '-10px' : '0'};
  z-index: 2000;
`
  ),

  AttendeeAvatarContainer: styled.div(
    (props: {
      $borderTopLeftRadius?: Property.BorderRadius;
      $borderTopRightRadius?: Property.BorderRadius;
      $borderBottomLeftRadius?: Property.BorderRadius;
      $borderBottomRightRadius?: Property.BorderRadius;
      $brandColor: Hex;
      theme: DefaultTheme;
    }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: ${props.$brandColor};
  border-top-right-radius: ${
    props.$borderTopRightRadius ? props.$borderTopRightRadius : '0'
  };
  border-bottom-right-radius: ${
    props.$borderBottomRightRadius ? props.$borderBottomRightRadius : '0'
  };
  border-top-left-radius: ${
    props.$borderTopLeftRadius ? props.$borderTopLeftRadius : '0'
  };
  border-bottom-left-radius: ${
    props.$borderBottomLeftRadius ? props.$borderBottomLeftRadius : '0'
  };
`
  ),

  ParticipantQuestionContainer: styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    background: ${props =>
      toRgbaString(props.theme.backgroundColor.primary, 0.9)};
  `,

  ParticipantQuestionText: styled(Ag.ParagraphLG)(
    (props: {theme: DefaultTheme}) => `
    overflow-wrap: break-word;
    padding: ${props.theme.spacing.XS};
    color: ${props.theme.contentColor.default};
    padding-bottom: ${props.theme.spacing['3XL']};
    ${media.MD} {
      padding: ${props.theme.spacing.MD};
      padding-bottom: ${props.theme.spacing['3XL']};
    }
  `
  ),
};
export default S;
