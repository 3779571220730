import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AuthReducer} from 'redux/auth/reducer';
import {RoomState} from 'bright-livekit/types/RoomState';
import {
  DeviceSelect,
  useParticipant,
  VideoRenderer,
  Role,
  NewUserModal,
} from 'bright-livekit';
// Components
import Button from '@brightlive/shared/components/Button';
import Alert from '@brightlive/shared/components/Alert';
import ShareLink from '@brightlive/shared/components/ShareLink';
import Avatar from '@brightlive/shared/components/Avatar';
import IconButton from '@brightlive/shared/components/IconButton';
import {Grid, Row, Col} from '@brightlive/shared/components/Grid';
import Breadcrumb from '@brightlive/shared/components/Breadcrumb';
import TextInput from '@brightlive/shared/components/TextInput';
import Tag from '@brightlive/shared/components/Tag';
import Select from '@brightlive/shared/components/Select';
import NavigationBar from 'components/shared/Navigation/NavigationBar';
import Spinner from '@brightlive/shared/components/Spinner';
import Checkbox from '@brightlive/shared/components/Checkbox';
// Redux
import {updateSession} from 'redux/session/actions';
import {toggleToast} from 'redux/ui/actions';
// Helpers
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
import {useInterval} from '@brightlive/shared/hooks/useInterval';
// Styles
import S from './style';

export interface PreSessionProps {
  roomState: RoomState;
  needsSessionTour?: boolean;
}

export const PreSession = ({roomState, needsSessionTour}: PreSessionProps) => {
  const {isMD, isLG} = useWindowSize();
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state: AuthReducer) => state.auth.currentUser
  );
  const sessionLink = `${process.env.NEXT_PUBLIC_BOOKING_URL}/join/${roomState.session.id}`;
  const [inviteMode, setInviteMode] = useState<'audience' | 'guest' | 'mod'>(
    'guest'
  );

  const getInviteLink = () => {
    if (inviteMode === 'audience') {
      return `${sessionLink}`;
    } else {
      return `${sessionLink}?t=${
        inviteMode === 'mod'
          ? roomState.session.inviteTokenMod
          : roomState.session.inviteTokenGuest
      }`;
    }
  };
  const getCheckboxLabel = () => {
    return <>I consent to being recorded.</>;
  };
  useInterval(() => {
    const part = roomState.videoService?.localParticipant;
    if (!part) {
      return;
    }
    if (part.videoTracks.length > 0) {
      const vidTrack = part.videoTracks[0];
      setResolution(vidTrack.dimensions.height);
      setFps(vidTrack.framerate);
    }
    if (part.audioTracks.length > 0) {
      const audTrack = part.audioTracks[0];
      setAudioHz(audTrack.audioHz);
    }
    setConnQuality(part.connectionQuality);
  }, 1000);
  const [sessionName, setSessionName] = useState(roomState.session.title);
  const [sessionNameError, setSessionNameError] = useState('');
  const [displayName, setDisplayName] = useState(currentUser.displayName);
  const [displayNameError, setDisplayNameError] = useState('');
  const [firstSessionModal, setFirstSessionModal] = useState(needsSessionTour);
  const [boxChecked, setBoxChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [audioHz, setAudioHz] = useState(0);
  const [resolution, setResolution] = useState(720);
  const [fps, setFps] = useState(0);
  const [connQuality, setConnQuality] = useState('');
  const {cameraPublication, isAudioMuted, isVideoMuted} = useParticipant(
    roomState.selfParticipant?.videoParticipant
  );

  const join = async () => {
    setLoading(true);
    if (displayName.length === 0) {
      setDisplayNameError('Please enter a name');
    }
    if (sessionName.length === 0) {
      setSessionNameError('Please enter a session name');
    }

    if (sessionName !== roomState.session.title) {
      await dispatch(
        updateSession(currentUser.id, roomState.session.id, {
          title: sessionName,
        })
      );
    }

    if (!sessionNameError && !displayNameError) {
      await roomState.join(displayName);
    }
    setLoading(false);
  };

  const onDisplayNameChange = async e => {
    e.preventDefault();
    setDisplayName(e.target.value);
    setDisplayNameError('');
  };

  const onSessionNameChange = async e => {
    e.preventDefault();
    setSessionName(e.target.value);
    setSessionNameError('');
  };

  const everyoneElse = roomState.participantsPresent.filter(
    p => p.id !== currentUser.id
  );
  const creator = everyoneElse.find(p => p.role === Role.Creator);
  const participantCount = everyoneElse.length;
  const participantCountWithoutCreator = creator
    ? participantCount - 1
    : participantCount;
  const isCreator = roomState.currentUser.id === roomState.session.creator?.id;
  const handleInviteModeChange = (value: 'mod' | 'guest' | 'audience') => {
    setInviteMode(value);
  };
  const dropdownOptions = [
    // {
    //   label: 'Audience',
    //   description: 'asdfas dfas df s',
    //   onClick: () => setInviteMode('audience'),
    // },
    {
      value: 'guest',
      label: 'Guest',
      description:
        'Guests can actively participate in the live conversation off stage, and are recorded if brought on stage',
    },
    {
      value: 'mod',
      label: 'Moderator',
      description:
        'Moderators have full access to controls to support you during the Session',
    },
  ];
  return (
    <>
      <NavigationBar />
      <S.PreSession>
        <Grid>
          {roomState.currentUser.id === roomState.session.creator?.id && (
            <Row>
              <Col>
                <S.PreSessionNavLink>
                  <Breadcrumb
                    text="Back to Sessions"
                    href={'/dashboard/sessions'}
                  />
                </S.PreSessionNavLink>
              </Col>
            </Row>
          )}

          <Row>
            <Col xs={4} sm={8} md={12} lg={4} last={['XS', 'SM', 'MD']}>
              <S.PreSessionTitleDesktop>
                Let's get your equipment ready before joining!
              </S.PreSessionTitleDesktop>
              <S.PreSessionForm>
                {isCreator && (
                  <S.InputWrapper>
                    <TextInput
                      label="Session Name"
                      maxLength={80}
                      placeholder={`${currentUser.displayName}'s Session`}
                      onChange={onSessionNameChange}
                      value={sessionName}
                      error={sessionNameError}
                    />
                  </S.InputWrapper>
                )}
                <S.InputWrapper>
                  <TextInput
                    label="Name"
                    placeholder="Michael Scott"
                    onChange={onDisplayNameChange}
                    value={displayName}
                    error={displayNameError}
                  />
                </S.InputWrapper>
              </S.PreSessionForm>

              {isCreator && (
                <>
                  <S.PreSessionTitle>Invite People</S.PreSessionTitle>
                  <S.PreSessionText>
                    Share the link to invite people to your Session.
                  </S.PreSessionText>
                  {isLG ? (
                    <>
                      <S.ShareWrapper>
                        <Select
                          desktopWidth="400px"
                          menuDirection="right"
                          hasDesktopMenuStyling={true}
                          hasMobileMenuStyling={true}
                          placeholder="Select..."
                          value={inviteMode}
                          onChange={handleInviteModeChange}
                          options={dropdownOptions}
                        />
                        <S.ProfileLink>
                          <S.ProfileLinkText>
                            {getInviteLink()}
                          </S.ProfileLinkText>
                        </S.ProfileLink>
                      </S.ShareWrapper>
                      <S.PreSessionCopyLink>
                        <Button
                          text="Copy link"
                          onClick={() => {
                            navigator.clipboard.writeText(getInviteLink());

                            dispatch(toggleToast(true, 'Link copied!'));
                          }}
                          size="medium"
                          type="secondary"
                        />
                      </S.PreSessionCopyLink>
                    </>
                  ) : (
                    <>
                      <S.ShareWrapper>
                        <Select
                          hasDesktopMenuStyling={true}
                          hasMobileMenuStyling={true}
                          placeholder="Select..."
                          value={inviteMode}
                          onChange={handleInviteModeChange}
                          options={dropdownOptions}
                        />
                      </S.ShareWrapper>
                      <ShareLink
                        text={getInviteLink()}
                        onClick={() => {
                          navigator.clipboard.writeText(getInviteLink());
                          dispatch(toggleToast(true, 'Link copied!'));
                        }}
                      />
                    </>
                  )}
                </>
              )}
              <S.PreSessionUserCount>
                {participantCount > 0 ? (
                  <>{`${creator?.displayName || ''}
              ${creator && participantCountWithoutCreator > 0 ? ' and' : ''}
              ${
                participantCountWithoutCreator > 0
                  ? `${participantCountWithoutCreator} other guest${
                      participantCountWithoutCreator > 1 ? 's' : ''
                    }`
                  : ''
              }
               ${participantCount > 1 ? 'are' : 'is'} here`}</>
                ) : (
                  'No one else is here yet'
                )}
              </S.PreSessionUserCount>
              {!isCreator && (
                <S.CheckboxWrapper>
                  <Checkbox
                    label={getCheckboxLabel()}
                    onCheck={() => {
                      setBoxChecked(!boxChecked);
                    }}
                    checked={boxChecked}
                  />
                </S.CheckboxWrapper>
              )}
              <S.PreSessionJoinButton>
                <Button
                  type="lightning"
                  text="Join Session"
                  disabled={
                    !roomState.isConnected || (!isCreator && !boxChecked)
                  }
                  loading={loading}
                  onClick={join}
                  width="100%"
                  size="medium"
                />
              </S.PreSessionJoinButton>
            </Col>
            <Col xs={4} sm={8} md={12} lg={7} lgOffset={1}>
              <S.PreSessionTitleMobile>
                Let's get your equipment ready before joining!
              </S.PreSessionTitleMobile>

              {cameraPublication?.isSubscribed && cameraPublication?.track ? (
                <>
                  <S.PreSessionVideoWrapper>
                    <S.PreSessionVidStatWrapper>
                      {cameraPublication?.isSubscribed &&
                      cameraPublication?.track &&
                      !cameraPublication?.isMuted ? (
                        <VideoRenderer
                          track={cameraPublication.track}
                          isLocal={true}
                          objectFit="cover"
                          width={isMD ? '760px' : '343px'}
                          height={isMD ? '428px' : '192px'}
                        />
                      ) : (
                        <S.PreSessionAvatarContainer>
                          <Avatar
                            size={isMD ? 'large' : 'medium'}
                            avatar={currentUser.avatar}
                            displayName={currentUser.displayName}
                            screenshare={
                              roomState.screenShareUser === currentUser.id
                            }
                            userFlagged={false}
                            videoMuted={false}
                          />
                        </S.PreSessionAvatarContainer>
                      )}
                      <S.PreSessionStats>
                        <Tag
                          color="black"
                          text={`${audioHz / 1000} hHz`}
                          type="basic"
                        />
                        <Tag
                          color="black"
                          text={`${resolution}p`}
                          type="basic"
                        />
                        <Tag color="black" text={`${fps} fps`} type="basic" />
                      </S.PreSessionStats>
                    </S.PreSessionVidStatWrapper>
                    <S.PreSessionDeviceToggle>
                      <S.PreSessionAudioButton>
                        <IconButton
                          type={isAudioMuted ? 'negative' : 'secondary'}
                          icon={isAudioMuted ? 'MicOff' : 'MicOn'}
                          size="medium"
                          onClick={() => {
                            if (roomState.videoService)
                              roomState.videoService.localParticipant?.setMicrophoneEnabled(
                                isAudioMuted
                              );
                          }}
                        />
                      </S.PreSessionAudioButton>
                      <S.PreSessionVideoButton>
                        <IconButton
                          type={isVideoMuted ? 'negative' : 'secondary'}
                          icon={isVideoMuted ? 'VideocamOff' : 'VideocamOn'}
                          size="medium"
                          onClick={() => {
                            if (roomState.videoService)
                              roomState.videoService.localParticipant?.setCameraEnabled(
                                isVideoMuted
                              );
                          }}
                        />
                      </S.PreSessionVideoButton>
                    </S.PreSessionDeviceToggle>
                  </S.PreSessionVideoWrapper>
                  {'poor' === connQuality && (
                    <S.PreSessionQualityMessage>
                      <Alert
                        type="informational"
                        text="Your internet connection is unstable. Check your internet
                        connection before joining the Session."
                      />
                    </S.PreSessionQualityMessage>
                  )}
                  <S.PreSessionControls>
                    {roomState?.videoService && (
                      <DeviceSelect videoService={roomState.videoService} />
                    )}
                  </S.PreSessionControls>
                </>
              ) : (
                <S.PreSessionVideoWrapper>
                  <Spinner size="large" />
                </S.PreSessionVideoWrapper>
              )}
            </Col>
          </Row>
        </Grid>
      </S.PreSession>
      {firstSessionModal && (
        <NewUserModal onClose={() => setFirstSessionModal(false)} />
      )}
    </>
  );
};
