import styled from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';

const S = {
  ModalContent: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,

  ModalTitle: styled(Ag.HeadingMD)`
    text-align: center;
  `,

  ModalBody: styled(Ag.ParagraphMD)`
    margin-top: ${props => props.theme.spacing.MD};
    margin-bottom: ${props => props.theme.spacing['2XL']};
  `,

  Buttons: styled.div`
    display: flex;
    justify-content: center;
    gap: ${props => props.theme.spacing.MD};
  `,
};
export default S;
