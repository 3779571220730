import React, {ReactElement, RefObject, useEffect, useRef} from 'react';
// Components
import {RoomState, useParticipant, VideoRenderer} from 'bright-livekit';
import Avatar from '@brightlive/shared/components/Avatar';
import {EmojiPopup} from 'bright-livekit';
// Helpers
import {Role} from 'bright-livekit/types/Enums';
// Styles
import S from './style';

const MAX_CHARS = 140;

export const QuestionInput = ({
  questionText,
  setQuestionText,
  sendQuestion,
  roomState,
  textareaRef,
}: {
  questionText: string;
  setQuestionText: Function;
  sendQuestion: Function;
  roomState: RoomState;
  textareaRef: RefObject<HTMLTextAreaElement>;
}) => {
  useEffect(() => {
    handleScroll();
  }, [questionText]);

  const backdropRef = useRef<HTMLDivElement>(null);

  const {cameraPublication, isLocal} = useParticipant(
    roomState.selfParticipant?.videoParticipant
  );

  const handleTextareaChange = e => {
    setQuestionText(e.target.value);
  };

  const handleEmojiSelect = emoji => {
    setQuestionText(questionText + emoji);
  };
  const getHightlightContent = () => {
    if (questionText.length <= 140) return;
    const textArray = [
      questionText.slice(0, MAX_CHARS),
      questionText.slice(MAX_CHARS),
    ];
    return (
      <>
        {textArray[0]}
        <S.Mark>{textArray[1]}</S.Mark>
      </>
    );
  };

  const handleScroll = () => {
    if (textareaRef?.current && backdropRef.current) {
      const scrollTop = textareaRef.current.scrollTop;
      backdropRef.current.scrollTop = scrollTop;
    }
  };

  const onTextareaKeyDown = e => {
    if (e.keyCode === 13) {
      sendQuestion();
      e.preventDefault();
    }
  };

  let mainElement: ReactElement;
  if (
    roomState?.selfParticipant?.role !== Role.OnStage &&
    roomState?.selfParticipant?.role !== Role.Creator &&
    cameraPublication?.isSubscribed &&
    cameraPublication?.track &&
    !cameraPublication?.isMuted
  ) {
    mainElement = (
      <VideoRenderer
        track={cameraPublication.track}
        isLocal={isLocal}
        objectFit="cover"
        width="120px"
        height="100%"
      />
    );
  } else {
    mainElement = (
      <S.ChatInputAvatar>
        <Avatar
          size="large"
          avatar={roomState.selfParticipant?.avatar}
          displayName={roomState.selfParticipant?.displayName}
          videoMuted={
            !cameraPublication?.isSubscribed ||
            !cameraPublication?.track ||
            cameraPublication?.isMuted
          }
        />
      </S.ChatInputAvatar>
    );
  }

  return (
    <S.ChatInput>
      <S.ChatInputInner>
        <S.ChatInputVideoPreview>{mainElement}</S.ChatInputVideoPreview>
        <S.TextAreaWrapperOuter>
          <S.TextAreaWrapperInner>
            <S.Backdrop ref={backdropRef}>
              <S.Highlights as="div">{getHightlightContent()}</S.Highlights>
            </S.Backdrop>
            <S.TextArea
              onChange={e => {
                handleTextareaChange(e);
              }}
              onKeyDown={onTextareaKeyDown}
              placeholder="Add your question or comment for everyone to see"
              value={questionText}
              ref={textareaRef}
              as="textarea"
              onInput={ele => {
                if (textareaRef?.current) {
                  textareaRef.current.style.height = '95px';
                  textareaRef.current.style.height = `${
                    (ele.target as HTMLTextAreaElement).scrollHeight
                  }px`;
                }
              }}
              onScroll={handleScroll}
            />
          </S.TextAreaWrapperInner>
          <S.TextAreaBottomRow>
            <S.EmojiButton>
              <EmojiPopup onClick={handleEmojiSelect} />
            </S.EmojiButton>
            <S.CharCount $hasError={questionText.length > 140}>
              {questionText.length}/{MAX_CHARS}
            </S.CharCount>
          </S.TextAreaBottomRow>
        </S.TextAreaWrapperOuter>
      </S.ChatInputInner>
    </S.ChatInput>
  );
};
