import React from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
import {IconProps} from '@brightlive/shared/helpers/interfaces';

const Forward10 = ({
  size = 24,
  contentColor = 'default',
  theme,
  ...props
}: IconProps & {
  theme: DefaultTheme;
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22c-1.25 0-2.42-.237-3.512-.712a9.142 9.142 0 0 1-2.85-1.926 9.143 9.143 0 0 1-1.926-2.85A8.709 8.709 0 0 1 3 13c0-1.25.237-2.421.712-3.513a9.158 9.158 0 0 1 1.926-2.85 9.138 9.138 0 0 1 2.85-1.924A8.698 8.698 0 0 1 12 4h.15l-.85-.85a1.012 1.012 0 0 1-.3-.675c-.017-.267.083-.508.3-.725.2-.2.438-.304.713-.313a.932.932 0 0 1 .712.288L15.3 4.3c.1.1.171.208.213.325.041.117.062.242.062.375s-.02.258-.062.375a.883.883 0 0 1-.213.325l-2.575 2.575c-.183.183-.42.27-.712.262a1.006 1.006 0 0 1-.713-.287.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l.85-.85H12c-1.95 0-3.604.679-4.962 2.037C5.679 9.396 5 11.05 5 13c0 1.95.68 3.604 2.038 4.962C8.396 19.321 10.05 20 12 20c1.783 0 3.325-.57 4.625-1.712 1.3-1.142 2.067-2.571 2.3-4.288.033-.283.15-.521.35-.713.2-.191.442-.287.725-.287s.52.1.712.3a.82.82 0 0 1 .238.7c-.25 2.267-1.22 4.167-2.912 5.7C16.346 21.233 14.333 22 12 22Zm-1.14-6.06v-4.27h-.09L9 12.3v.69l1.01-.31v3.26h.85Zm1.39-1.76v-.74c0-1.845 1.213-1.824 1.422-1.82h.033c.195-.003 1.425-.021 1.425 1.82v.74c0 1.845-1.213 1.824-1.422 1.82h-.033c-.195.003-1.425.021-1.425-1.82Zm2.04.11v-.97c0-.75-.22-1.02-.6-1.01-.37 0-.59.26-.59 1.01v.97c0 .77.22 1.03.6 1.03s.59-.26.59-1.03Z"
      fill={
        contentColor.indexOf('#') > -1
          ? contentColor
          : theme.contentColor[contentColor]
      }
    />
  </svg>
);

export default withTheme(Forward10);
