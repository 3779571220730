import styled, {DefaultTheme} from 'styled-components';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag, {getHeadingMD} from '@brightlive/shared/styles/typography-v3';

const S: Record<string, React.ElementType> = {};

S.ImageCropper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;

  ${media.MD} {
    padding-top: 0;
  }
`;

S.UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

S.FileInput = styled.input`
  display: none;
`;

S.Header = styled.div`
  position: absolute;
  top: -44px; // 64 = modal header - 20px from top
  left: ${props => props.theme.margin.mobile.page};
  z-index: 1000;
  pointer-events: none;
  ${media.MD} {
    position: static;
    margin-bottom: ${props => props.theme.spacing.MD};
  }
`;

S.Title = styled(Ag.HeadingSM)(
  (props: {$backIsVisible: boolean; theme: DefaultTheme}) => `
  ${props.$backIsVisible ? `margin-left: ${props.theme.spacing['5XL']};` : ''}

  ${media.MD} {
    margin-left: 0;
    text-align: center;
    ${getHeadingMD}
  }
`
);

S.Subtitle = styled(Ag.ParagraphMD)`
  margin-top: ${props => props.theme.spacing.SM};

  ${media.MD} {
    margin-top: ${props => props.theme.spacing['2XS']};
    text-align: center;
  }
`;

S.CropperWrapper = styled.div`
  width: 100vw;
  height: 100vw;
  position: relative;
  margin: 0 auto;
  max-height: calc(100vh - 300px);
  max-width: calc(100vh - 300px);

  ${media.MD} {
    width: 375px;
    height: 375px;
    max-height: calc(
      95vh - 64px - 240px
    ); // 95vh = max modal height - height of top and bottom of modal
    max-width: calc(95vh - 64px - 240px);
  }
`;

S.ChangeImage = styled(Ag.ParagraphMD)`
  text-align: center;
  margin-top: ${props => props.theme.spacing.MD};
`;

S.ChangeImageLink = styled(Ag.Link)``;

S.Slider = styled.div`
  display: none;

  ${media.MD} {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: ${props => props.theme.spacing.SM};
  }
`;

S.SliderWrapper = styled.div`
  padding: 0 ${props => props.theme.spacing.XL};
`;

S.CancelButton = styled.div`
  margin-right: 16px;
`;

S.Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

S.ErrorWrapper = styled.div`
  padding: 0 ${props => props.theme.margin.mobile.page}
    ${props => props.theme.spacing.MD};

  ${media.MD} {
    padding: 0 0 ${props => props.theme.spacing.MD};
  }
`;

export default S;
