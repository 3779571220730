import styled, {DefaultTheme} from 'styled-components';
import {focus} from '../../helpers/style';
import {getHeadingXS} from '@brightlive/shared/styles/typography-v3';
import {
  ActionColor,
  ContentColor,
} from '@brightlive/shared/styles/theme-declarations/theme-mode.d';

export type TabTypes = 'default' | 'inverse';

interface Colors {
  background: {
    hovered: {
      [key in TabTypes]: keyof ActionColor;
    };
    pressed: {
      [key in TabTypes]: keyof ActionColor;
    };
  };
  text: {
    default: {
      [key in TabTypes]: keyof ContentColor;
    };
    hovered: {
      [key in TabTypes]: keyof ContentColor;
    };
    pressed: {
      [key in TabTypes]: keyof ContentColor;
    };
    selected: {
      [key in TabTypes]: keyof ContentColor;
    };
  };
}

export const colors: Colors = {
  background: {
    hovered: {
      default: 'hover',
      inverse: 'inverseHover',
    },
    pressed: {
      default: 'pressed',
      inverse: 'inversePressed',
    },
  },
  text: {
    default: {
      default: 'moreSubdued',
      inverse: 'moreSubdued',
    },
    hovered: {
      default: 'default',
      inverse: 'inverse',
    },
    pressed: {
      default: 'default',
      inverse: 'inverse',
    },
    selected: {
      default: 'default',
      inverse: 'inverse',
    },
  },
};

export const getColor = ({
  type,
  selected,
  pressed,
  hovered,
}: {
  type: TabTypes;
  selected: boolean;
  pressed: boolean;
  hovered: boolean;
}) => {
  if (selected) {
    return colors.text.selected[type];
  }
  if (pressed) {
    return colors.text.pressed[type];
  }
  if (hovered) {
    return colors.text.hovered[type];
  }
  return colors.text.default[type];
};

const S = {
  Tab: styled.div(
    (props: {
      $type: TabTypes;
      $selected: boolean;
      $hovered: boolean;
      $pressed: boolean;
      theme: DefaultTheme;
    }) => `
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: ${props.theme.spacing.MD} ${props.theme.spacing.SM};

    ${
      props.$hovered
        ? `
      background-color: ${
        props.theme.actionColor[colors.background.hovered[props.$type]]
      };
    `
        : ''
    }

    ${
      props.$pressed
        ? `
      background-color: ${
        props.theme.actionColor[colors.background.pressed[props.$type]]
      };
    `
        : ''
    }

    ${
      props.$selected
        ? `
          &:after {
            content: '';
            background-color: ${props.theme.actionColor.brand};
            height: ${props.theme.borderWidth[4]};
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
          }
      `
        : ''
    }

    ${focus({
      theme: props.theme,
      disabled: false,
      condensed: true,
    })}
  `
  ),

  Icon: styled.div`
    margin-right: ${props => props.theme.spacing.XS};
    display: flex;
  `,

  Text: styled.div(
    (props: {
      $type: TabTypes;
      $selected: boolean;
      $hovered: boolean;
      $pressed: boolean;
      theme: DefaultTheme;
    }) => `
    ${getHeadingXS(props.theme)}
    color: ${
      props.theme.contentColor[
        getColor({
          type: props.$type,
          selected: props.$selected,
          hovered: props.$hovered,
          pressed: props.$pressed,
        })
      ]
    };
    white-space: nowrap;
  `
  ),
};

export default S;
