import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag from '@brightlive/shared/styles/typography-v3';
import styled from 'styled-components';

const S = {
  SessionInfoContainer: styled.div`
    padding: ${props => props.theme.margin.mobile.page};
    height: 100%;
  `,
  SessionInfoName: styled.div`
    margin-top: ${props => props.theme.spacing.SM};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  SessionInfoInvite: styled.div`
    margin-top: ${props => props.theme.spacing['3XL']};
  `,
  SessionInfoInviteLink: styled.div`
    margin-top: ${props => props.theme.spacing.MD};
    ${media.MD} {
      margin-top: ${props => props.theme.spacing.LG};
      position: relative;
    }
  `,
  SessionInfoShareViaLink: styled.div`
    margin-top: ${props => props.theme.spacing.LG};
  `,
  SessionInfoHeader: styled(Ag.HeadingXS)``,
  SessionInfoParagraph: styled(Ag.ParagraphMD)`
    margin-top: ${props => props.theme.spacing['2XS']};
    ${media.MD} {
      margin-top: ${props => props.theme.spacing.XS};
    }
  `,
  CopyButton: styled.div`
    position: relative;
    display: inline-block;
    z-index: 0;
  `,
  TooltipWrapper: styled.div`
    display: none;

    ${media.MD} {
      display: block;
    }
  `,
  EditContainer: styled.div`
    display: flex;
    flex-direction: column;
    padding-top: ${props => props.theme.spacing.SM};
    padding-bottom: ${props => props.theme.spacing.SM};
  `,

  EditButtons: styled.div`
    display: flex;
    align-self: flex-end;
    margin-top: ${props => props.theme.spacing.LG};
    gap: ${props => props.theme.spacing.SM};
  `,

  ModalContent: styled.div`
    display: flex;
    flex-direction: column;
    justify-contents: center;
  `,

  ModalTitle: styled(Ag.HeadingMD)`
    text-align: center;
    margin-bottom: ${props => props.theme.spacing.XL};
  `,
  SessionInfoShareWrapper: styled.div`
    padding-bottom: ${props => props.theme.spacing.MD};

    ${media.MD} {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.spacing.MD};
    }

    ${media.LG} {
      flex-direction: row;
      padding-bottom: ${props => props.theme.spacing.LG};
      position: relative;
      z-index: 2;
    }
  `,
  CopyButtonWrapper: styled.div`
    position: relative;
    display: flex;
    z-index: 0;
  `,
  SessionInfoProfileLink: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${props => props.theme.spacing.XS}
      ${props => props.theme.spacing.SM};
    background: ${props => props.theme.backgroundColor.disabled};
    border-radius: ${props => props.theme.borderRadius.MD};
    border: ${props => props.theme.borderWidth[1]} solid
      ${props => props.theme.borderColor.subdued};
    min-width: 0;
  `,

  SessionInfoProfileLinkText: styled(Ag.ParagraphMD)`
    padding-right: ${props => props.theme.spacing.MD};
    flex: 1;
    width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
  `,
};

export default S;
