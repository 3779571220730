import {
  LocalTrackPublication,
  LocalVideoTrack,
  RemoteAudioTrackPublication,
  RemoteTrackPublication,
  TrackPublication,
} from 'twilio-video';
import {TrackType} from '../BrightParticipant';
import {ITrack} from './ITrack';
import {ITrackPublication} from './ITrackPublication';
import {TwilioTrack} from './TwilioTrack';

export class TwilioTrackPublication implements ITrackPublication {
  private _twilioPublication: LocalTrackPublication | RemoteTrackPublication;
  private _track?: TwilioTrack;
  constructor(track: TrackPublication) {
    this._twilioPublication = track as LocalTrackPublication; //Workaround
  }
  public get hasTrack(): boolean {
    return !!this._twilioPublication.track;
  }
  public get isSubscribed(): boolean {
    if (!this.isLocal) {
      return (
        !!(this._twilioPublication as RemoteTrackPublication).isSubscribed &&
        this.isEnabled
      );
    } else {
      return this.isEnabled;
    }
  }
  public get sid(): string | undefined {
    return this._twilioPublication.trackSid;
  }
  public get isMuted(): boolean {
    return !this._twilioPublication.isTrackEnabled;
  }
  public get isEnabled(): boolean {
    return this._twilioPublication.isTrackEnabled;
  }
  public get isLocal(): boolean {
    return this._twilioPublication.track instanceof LocalVideoTrack;
  }
  public get audioHz(): number {
    return 0;
  }
  public get framerate(): number {
    return 30;
  }
  public get simulcasted(): boolean {
    return false;
  }
  public get currentBitrate(): number {
    return -1;
  }
  public get dimensions(): {width: number; height: number} {
    return {width: 0, height: 0};
  }
  public get track(): ITrack | undefined {
    if (
      this._twilioPublication?.track &&
      !this._track?.isEqual(this._twilioPublication?.track)
    ) {
      this._track = new TwilioTrack(this._twilioPublication.track);
    }
    return this._track;
  }
  public get kind(): TrackType {
    if (this._twilioPublication.trackName === 'screenshare') {
      return TrackType.ScreenShare;
    }
    switch (this._twilioPublication.kind) {
      case 'audio':
        return TrackType.Audio;
      case 'video':
        return TrackType.Video;
      default:
        return TrackType.Audio;
    }
  }
  public start() {
    if (!this.isLocal && this._twilioPublication.kind === 'audio') {
      (this._twilioPublication as RemoteAudioTrackPublication).track?.attach();
    }
  }
}
