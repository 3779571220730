export const toDropShadow = (elevation: string) => {
  const shadows = elevation.split('), ');
  const newShadows = shadows.map(shadow => {
    const splitShadow = shadow.split('px');
    splitShadow.splice(3, 1);
    return `drop-shadow(${splitShadow.join('px')})`;
  });

  return newShadows.join(') ');
};

export const toTextShadow = (elevation: string) => {
  const shadows = elevation.split('), ');
  const newShadows = shadows.map(shadow => {
    const splitShadow = shadow.split('px');
    splitShadow.splice(3, 1);
    return `${splitShadow.join('px')}`;
  });

  return newShadows.join(') ');
};
