import styled, {DefaultTheme} from 'styled-components';
import {focusStyles, hover, active} from '../../helpers/style';
import Ag from '@brightlive/shared/styles/typography-v3';
import {colors} from '../Button/style';
import {ButtonTypes} from './index';

const S: Record<string, React.ElementType> = {};

const getColor = (
  theme: DefaultTheme,
  disabled: boolean,
  type: ButtonTypes,
  position: string
) => {
  if (disabled) {
    if (position === 'text') {
      return theme.contentColor[colors[position].disabled[type]];
    }
    if (position === 'background') {
      const themeKey = colors[position].disabled[type].themeKey;
      const value = colors[position].disabled[type].value;
      return theme[themeKey][value];
    }
  } else {
    if (position === 'text') {
      return theme.contentColor[colors[position].default[type]];
    }
    if (position === 'background') {
      const themeKey = colors[position].default[type].themeKey;
      const value = colors[position].default[type].value;
      return theme[themeKey][value];
    }
  }
};

// Needed in case the button is inside of a column flex and width is auto
// Otherwise S.ButtonWrapper by itself will take up the whole width of it's parent and make the focus outline extend that far too
S.ButtonWrapperOuter = styled.div(
  (props: {$width: string; disabled: boolean}) => `
  width: ${props.$width || 'auto'};
  cursor: ${props.disabled ? 'auto' : 'pointer'};

  &:focus {
    outline: none;
  }
`
);

S.ButtonWrapper = styled.div(
  (props: {
    $width: string;
    $type: ButtonTypes;
    disabled: boolean;
    theme: DefaultTheme;
  }) => `
  display: inline-flex;
  position: relative;
  width: ${props.$width || 'auto'};
  border-radius: 100px;
  background-color: ${getColor(
    props.theme,
    props.disabled,
    props.$type,
    'background'
  )};
  ${hover({
    backgroundColor: props.theme.actionColor[colors.overlay.hover[props.$type]],
    disabled: props.disabled,
    borderRadius: '100px',
  })}
  ${active({
    backgroundColor:
      props.theme.actionColor[colors.overlay.pressed[props.$type]],
    disabled: props.disabled,
    borderRadius: '100px',
  })}
`
);

S.FocusBorder = styled.div(
  (props: {theme: DefaultTheme; disabled: boolean}) => `
    ${focusStyles({
      theme: props.theme,
      disabled: props.disabled,
      borderRadius: '100px',
    })}
`
);

S.Button = styled.div(
  (props: {
    theme: DefaultTheme;
    $width: string;
    $type: ButtonTypes;
    disabled: boolean;
  }) => `
  width: ${props.$width || 'auto'};
  padding: ${props.theme.spacing.SM} ${props.theme.spacing.MD} ${
    props.theme.spacing.SM
  } ${props.theme.spacing.LG};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
`
);

S.TextWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

S.Icon = styled.img(
  (props: {$open: boolean; theme: DefaultTheme}) => `
  height: 24px;
  width: 24px;
  margin-left: ${props.theme.spacing['2XS']};
  ${props.$open ? 'transform: scaleY(-1);' : ''}
`
);

S.Text = styled(Ag.ParagraphMD)(
  (props: {$type: ButtonTypes; disabled: boolean; theme: DefaultTheme}) => `
  color: ${getColor(props.theme, props.disabled, props.$type, 'text')};
  font-weight: ${props.theme.fontWeight.medium};
  white-space: nowrap;
`
);

export default S;
