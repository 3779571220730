import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DefaultTheme, withTheme} from 'styled-components';
import Link from 'next/link';
import {useRouter} from 'next/router';
// Components
import HamburgerMenu from '@brightlive/shared/components/navigation/HamburgerMenu';
import Button from '@brightlive/shared/components/Button';
import {LiveSessionIndicator} from 'bright-livekit';
import Menu from '@brightlive/shared/components/Menu';
import Avatar from '@brightlive/shared/components/Avatar';
// Images
import Logo from '@brightlive/shared/components/Logo';
// Icons
import ArrowDropDown from '@brightlive/shared/icons/ArrowDropDown';
// import Search from '@brightlive/shared/icons/Search';
// import Close from '@brightlive/shared/icons/Close';
import Hamburger from '@brightlive/shared/icons/HamburgerMenu';
// Redux
import {AuthReducer} from 'redux/auth/reducer';
import {CreatorReducer} from 'redux/creator/reducer';
import {SessionReducer} from 'redux/session/reducer';
import {logout} from 'redux/auth/actions';
// Helpers
import {branchInitIdentity, branchLogout} from 'library/helpers/branch';
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
// Style
import S from '@brightlive/shared/components/navigation/navigationBarStyle';

const scrollVariants = {
  visible: {
    top: 0,
  },
  hidden: {
    top: -100,
    transition: {
      duration: 0.6,
    },
  },
};

const TOP_SCROLL_BUFFER = 96;

interface NavigationBarProps {
  liveSession?: boolean;
  recordingStart?: Date | null;
  isAuthScreen?: boolean;
  navStyle?: 'transparent' | 'solid';
  theme: DefaultTheme;
}

const NavigationBar = ({
  liveSession = false,
  isAuthScreen = false,
  recordingStart = null,
  navStyle = 'solid',
}: NavigationBarProps) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const {isMobile} = useWindowSize();

  const userRef = useRef<HTMLDivElement>(null);

  const currentUser = useSelector(
    (state: AuthReducer) => state.auth.currentUser
  );
  const activeSession = useSelector(
    (state: SessionReducer) => state.session.activeSession
  );
  const activeCreator = useSelector(
    (state: CreatorReducer) => state.creator.activeCreator
  );

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  // const [focused, setFocused] = useState(false);
  const [scrolledUp, setScrolledUp] = useState(true);
  const [searchOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [searchValue, setSearchValue] = useState('');
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isSolid, setIsSolid] = useState(navStyle === 'solid');

  useEffect(() => {
    setLastScrollY(window.scrollY);
  }, [isMobile, router, activeCreator, activeSession]);

  useEffect(() => {
    if (currentUser?.id) {
      branchInitIdentity(currentUser.id);
    }
  }, [currentUser]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    checkForSolid();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY, navStyle, scrolledUp, searchOpen]);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    if (scrollY < TOP_SCROLL_BUFFER) {
      setScrolledUp(true);
    } else if (scrollY < lastScrollY) {
      setScrolledUp(true);
    } else {
      setScrolledUp(false);
    }
    setLastScrollY(scrollY);
  };

  const handleLogout = async () => {
    setDropdownOpen(false);
    await dispatch(logout());
    setHamburgerOpen(false);
    branchLogout();
    router.push('/login');
  };

  const handleUserClick = () => {
    if (isMobile) {
      toggleMenu();
    } else {
      toggleDropdown();
    }
  };

  const toggleMenu = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // const getSearchIconColor = () => {
  //   if (searchOpen) return 'subdued';
  //   if (isTransparentNav) return 'white';
  //   return 'default';
  // };

  const checkForSolid = () => {
    // if the nav is by default transparent and the user did not scroll up and and not mobile search open
    // or if the the nav is by default transparent  and you are at the top of the page and  and not mobile search open
    // the nav should not display solid
    const mobileSearchOpen = isMobile && searchOpen;
    if (
      (navStyle === 'transparent' && !scrolledUp && !mobileSearchOpen) ||
      (navStyle === 'transparent' &&
        lastScrollY < TOP_SCROLL_BUFFER &&
        !mobileSearchOpen)
    ) {
      setIsSolid(false);
      // if the nav by default is solid
      // or if its by default transparent and you are scrolled up
      // or if mobile search is open
    } else if (
      navStyle === 'solid' ||
      (scrolledUp && lastScrollY > TOP_SCROLL_BUFFER) ||
      mobileSearchOpen
    ) {
      setIsSolid(true);
    }
  };

  const creatorDropdownOptions = [
    {
      label: 'Settings',
      href: '/settings',
      value: '/settings',
    },
    {
      label: 'Help Center',
      onClick: () => {
        window.open(
          'https://brightlive.zendesk.com',
          '_blank',
          'noopener noreferrer'
        );
      },
    },
    {
      isLine: true,
    },
    {
      label: 'Sign out',
      onClick: handleLogout,
    },
  ];

  const supporterDropdownOptions = [
    {
      label: 'Settings',
      href: '/settings',
      value: '/settings',
    },
    {
      label: 'Help',
      onClick: () => {
        window.open(
          'https://brightlive.zendesk.com',
          '_blank',
          'noopener noreferrer'
        );
      },
    },
    {
      isLine: true,
    },
    {
      label: 'Sign out',
      onClick: handleLogout,
    },
  ];

  const isUser = currentUser?.id && !currentUser?.roles?.includes('anon');

  return (
    <>
      <S.NavigationBar
        animate={scrolledUp ? 'visible' : 'hidden'}
        onAnimationStart={checkForSolid}
        variants={scrollVariants}
      >
        <S.Background $isSolid={isSolid} $hamburgerOpen={hamburgerOpen} />
        <S.Content>
          <S.LeftContent $searchOpen={false}>
            <S.LogoWrapper
              title="home page"
              target={liveSession ? '_blank' : '_self'}
              href={isUser ? '/dashboard/sessions' : '/'}
            >
              <Logo height={48} contentColor="default" />
            </S.LogoWrapper>
            {!liveSession && (
              <S.DesktopNavItems>
                {currentUser.id && (
                  <Link href="/dashboard/sessions" passHref>
                    <S.NavItem
                      $isActive={router.pathname === '/dashboard/sessions'}
                    >
                      Sessions & recordings
                    </S.NavItem>
                  </Link>
                )}
              </S.DesktopNavItems>
            )}
            {liveSession && recordingStart && !isMobile && (
              <LiveSessionIndicator startedAt={recordingStart} />
            )}
          </S.LeftContent>
          {!isAuthScreen && (
            <S.RightContent>
              {(!isMobile && currentUser?.email) ||
              (isMobile &&
                currentUser?.email &&
                !searchOpen &&
                !hamburgerOpen) ? (
                <S.User
                  onClick={handleUserClick}
                  $dropdownOpen={dropdownOpen}
                  ref={userRef}
                >
                  <S.UserAvatar>
                    <Avatar
                      size="small"
                      avatar={currentUser?.avatar}
                      displayName={currentUser?.displayName}
                    />
                  </S.UserAvatar>
                  <S.DisplayName>{currentUser?.displayName}</S.DisplayName>
                  <S.Arrow $dropdownOpen={dropdownOpen}>
                    <ArrowDropDown contentColor="default" size={20} />
                  </S.Arrow>
                  {dropdownOpen && (
                    <S.Menu>
                      <Menu
                        value={router.pathname}
                        menuItems={
                          isUser
                            ? creatorDropdownOptions
                            : supporterDropdownOptions
                        }
                        isMobile={false}
                        onClose={() => {
                          setDropdownOpen(false);
                        }}
                        type="default"
                        hideCheck={true}
                        triggerRef={userRef}
                        desktopWidth="200px"
                        menuDirection="right"
                      />
                    </S.Menu>
                  )}
                </S.User>
              ) : (
                <>
                  {!searchOpen && (
                    <S.HamburgerIcon onClick={toggleMenu}>
                      <Hamburger size={24} contentColor="default" />
                    </S.HamburgerIcon>
                  )}
                  {!router.pathname.includes('joinbright') && (
                    <S.DesktopNavItems>
                      <S.LoginButton>
                        <Link href="/login" passHref>
                          <S.NavItem as="a" $isActive={false}>
                            Log in
                          </S.NavItem>
                        </Link>
                      </S.LoginButton>
                      <Button
                        type="lightning"
                        size="medium"
                        text="Get started for free"
                        href="/login?signup=true"
                      />
                    </S.DesktopNavItems>
                  )}
                </>
              )}
            </S.RightContent>
          )}
        </S.Content>
      </S.NavigationBar>
      {hamburgerOpen && (
        <HamburgerMenu
          toggleMenu={toggleMenu}
          handleLogout={handleLogout}
          currentUser={currentUser}
          pathname={router.pathname}
          liveSession={liveSession}
        />
      )}
    </>
  );
};

export default React.memo(withTheme(NavigationBar));
