import styled, {DefaultTheme} from 'styled-components';
import {focusStyles, hover, active} from '../../helpers/style';
// Helpers
import {ButtonTypes} from '../../helpers/interfaces';
import {Sizes} from './index';
import {
  getButtonLabelSM,
  getButtonLabelMD,
} from '@brightlive/shared/styles/typography-v3';
import {
  ActionColor,
  ContentColor,
  BackgroundColor,
} from '@brightlive/shared/styles/theme-declarations/theme-mode.d';

const S: Record<string, React.ElementType> = {};

const lineHeight = {
  small: '20px',
  medium: '24px',
  large: '24px',
};

const getFont = size => {
  if (size === 'small') {
    return getButtonLabelSM();
  } else {
    return getButtonLabelMD();
  }
};

const getMinWidth = (size: Sizes, theme: DefaultTheme) => {
  // min-width = button height / 2
  // height = line height + top padding + bottom padding
  return `${
    (parseInt(lineHeight[size], 10) +
      parseInt(getPadding(size, theme).split(' ')[0], 10)) *
    2
  }px`;
};

const getPadding = (size: Sizes, theme: DefaultTheme) => {
  switch (size) {
    case 'small':
      return `6px ${theme.spacing.MD}`;
    case 'medium':
      return `${theme.spacing.SM} ${theme.spacing.LG}`;
    case 'large':
      return `${theme.spacing.MD} ${theme.spacing.XL}`;
  }
};

interface Colors {
  background: {
    disabled: {
      [key in ButtonTypes]: {
        themeKey: 'backgroundColor' | 'actionColor';
        value: keyof BackgroundColor | keyof ActionColor;
      };
    };
    default: {
      [key in ButtonTypes]: {
        themeKey: 'backgroundColor' | 'actionColor';
        value: keyof BackgroundColor | keyof ActionColor;
      };
    };
  };
  overlay: {
    hover: {
      [key in ButtonTypes]: keyof ActionColor;
    };
    pressed: {
      [key in ButtonTypes]: keyof ActionColor;
    };
  };
  text: {
    disabled: {
      [key in ButtonTypes]: keyof ContentColor;
    };
    default: {
      [key in ButtonTypes]: keyof ContentColor;
    };
  };
}

export const colors: Colors = {
  background: {
    disabled: {
      primary: {themeKey: 'backgroundColor', value: 'disabled'},
      'primary inverse': {
        themeKey: 'backgroundColor',
        value: 'inverseDisabled',
      },
      secondary: {themeKey: 'backgroundColor', value: 'disabled'},
      'secondary inverse': {
        themeKey: 'backgroundColor',
        value: 'inverseDisabled',
      },
      tertiary: {themeKey: 'backgroundColor', value: 'transparent'},
      'tertiary inverse': {themeKey: 'backgroundColor', value: 'transparent'},
      lightning: {themeKey: 'backgroundColor', value: 'disabled'},
      negative: {themeKey: 'backgroundColor', value: 'disabled'},
    },
    default: {
      primary: {themeKey: 'backgroundColor', value: 'inversePrimary'},
      'primary inverse': {themeKey: 'backgroundColor', value: 'primary'},
      secondary: {themeKey: 'backgroundColor', value: 'tertiary'},
      'secondary inverse': {
        themeKey: 'backgroundColor',
        value: 'inverseSecondary',
      },
      tertiary: {themeKey: 'backgroundColor', value: 'transparent'},
      'tertiary inverse': {themeKey: 'backgroundColor', value: 'transparent'},
      lightning: {themeKey: 'actionColor', value: 'brand'},
      negative: {themeKey: 'backgroundColor', value: 'negative'},
    },
  },
  overlay: {
    hover: {
      primary: 'inverseHover',
      'primary inverse': 'hover',
      secondary: 'hover',
      'secondary inverse': 'inverseHover',
      tertiary: 'hover',
      'tertiary inverse': 'inverseHover',
      lightning: 'hover',
      negative: 'inverseHover',
    },
    pressed: {
      primary: 'inversePressed',
      'primary inverse': 'pressed',
      secondary: 'pressed',
      'secondary inverse': 'inversePressed',
      tertiary: 'pressed',
      'tertiary inverse': 'inversePressed',
      lightning: 'pressed',
      negative: 'inversePressed',
    },
  },
  text: {
    disabled: {
      primary: 'disabled',
      'primary inverse': 'inverseDisabled',
      secondary: 'disabled',
      'secondary inverse': 'inverseDisabled',
      tertiary: 'disabled',
      'tertiary inverse': 'inverseDisabled',
      lightning: 'inverseDisabled',
      negative: 'inverseDisabled',
    },
    default: {
      primary: 'inverse',
      'primary inverse': 'default',
      secondary: 'default',
      'secondary inverse': 'inverse',
      tertiary: 'accent',
      'tertiary inverse': 'inverse',
      lightning: 'inverse',
      negative: 'inverse',
    },
  },
};

const getColor = (
  theme: DefaultTheme,
  disabled: boolean,
  type: ButtonTypes,
  position: string
) => {
  if (disabled) {
    if (position === 'text') {
      return theme.contentColor[colors[position].disabled[type]];
    }
    if (position === 'background') {
      const themeKey = colors[position].disabled[type].themeKey;
      const value = colors[position].disabled[type].value;
      return theme[themeKey][value];
    }
  } else {
    if (position === 'text') {
      return theme.contentColor[colors[position].default[type]];
    }
    if (position === 'background') {
      const themeKey = colors[position].default[type].themeKey;
      const value = colors[position].default[type].value;
      return theme[themeKey][value];
    }
  }
};

// Needed in case the button is inside of a column flex and width is auto
// Otherwise S.ButtonWrapper by itself will take up the whole width of it's parent and make the focus outline extend that far too
S.ButtonWrapperOuter = styled.div(
  (props: {$width: string; disabled: boolean}) => `
  width: ${props.$width || 'auto'};
  cursor: ${props.disabled ? 'auto' : 'pointer'};

  &:focus {
    outline: none;
  }
`
);

S.ButtonWrapper = styled.div(
  (props: {
    $width: string;
    $type: ButtonTypes;
    disabled: boolean;
    theme: DefaultTheme;
  }) => `
  display: inline-flex;
  position: relative;
  width: ${props.$width || 'auto'};
  border-radius: 100px;
  background-color: ${getColor(
    props.theme,
    props.disabled,
    props.$type,
    'background'
  )};
  ${hover({
    backgroundColor: props.theme.actionColor[colors.overlay.hover[props.$type]],
    disabled: props.disabled,
    borderRadius: '100px',
  })}
  ${active({
    backgroundColor:
      props.theme.actionColor[colors.overlay.pressed[props.$type]],
    disabled: props.disabled,
    borderRadius: '100px',
  })}
`
);

S.FocusBorder = styled.div(
  (props: {theme: DefaultTheme; disabled: boolean}) => `
    ${focusStyles({
      theme: props.theme,
      disabled: props.disabled,
      borderRadius: '100px',
    })}
`
);

S.Button = styled.div(
  (props: {
    theme: DefaultTheme;
    $width: string;
    $type: ButtonTypes;
    disabled: boolean;
    $isLoading: boolean;
    $size: Sizes;
    $justifyContent: string;
  }) => `
  width: ${props.$width || 'auto'};
  min-width: ${getMinWidth(props.$size, props.theme)};
  padding: ${getPadding(props.$size, props.theme)};
  display: inline-flex;
  justify-content: ${props.$justifyContent || 'center'};
  align-items: center;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
`
);

S.TextWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

S.Icon = styled.div`
  margin-right: ${props => props.theme.spacing.XS};
  display: flex;
`;

S.ImgIcon = styled.img`
  margin-right: ${props => props.theme.spacing.XS};
  width: 24px;
`;

S.Text = styled.p(
  (props: {
    $type: ButtonTypes;
    disabled: boolean;
    $isLoading: boolean;
    $size: Sizes;
    theme: DefaultTheme;
  }) => `
  ${getFont(props.$size)}
  color: ${getColor(props.theme, props.disabled, props.$type, 'text')};
  opacity: ${props.$isLoading ? 0 : 1};
  white-space: nowrap;
`
);

S.LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default S;
