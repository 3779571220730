import React, {useEffect, useMemo, useState} from 'react';
// Icons
import ChevronDown from '../../icons/ChevronDown';
// Style
import S from './style';

export interface AccordionProps {
  text: string | React.ReactNode;
  description?: string;
  size: 'xs' | 'small' | 'medium';
  open?: boolean;
  onClick?: Function;
  content?: React.ReactNode;
  smPadding?: boolean;
}

const Accordion = ({
  text,
  description,
  size,
  open = false,
  onClick,
  content,
  smPadding = false,
}: AccordionProps) => {
  const TextEl = useMemo(() => {
    if (size === 'xs') return S.TextXS;
    if (size === 'small') return S.TextSmall;
    return S.TextMedium;
  }, [size]);

  const [isOpen, setIsOpen] = useState(open);

  // for optional manual control via parent component
  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClick = () => {
    // for optional manual control via parent component
    if (onClick) {
      onClick();
      return;
      // for internal control (if no control via parent provided)
    } else {
      setIsOpen(!isOpen);
    }
  };

  return (
    <S.Accordion>
      <S.AccordionHeader $smPadding={smPadding} onClick={handleClick}>
        <S.AccordionHeaderText>
          <TextEl as="div">{text}</TextEl>
          {description && <S.TextDescription>{description}</S.TextDescription>}
        </S.AccordionHeaderText>
        <S.Icon $open={isOpen}>
          <ChevronDown contentColor="default" size={24} />
        </S.Icon>
      </S.AccordionHeader>
      {isOpen && content && <S.Content>{content}</S.Content>}
    </S.Accordion>
  );
};

export default React.memo(Accordion);
