import React, {Dispatch, SetStateAction, useEffect, useRef} from 'react';
// Components
import {
  StageProps,
  StageView,
  useRoom,
  ParticipantProps,
  IVideoService,
  Backends,
  ControlsProps,
} from 'bright-livekit';
// Redux
import {UserInitState} from 'redux/auth/reducer';
import {InitialSessionState} from 'redux/session/reducer';
import {VideoServiceFactory} from 'bright-livekit/services/VideoServiceFactory';
import {useEventListener} from 'usehooks-ts';

export interface RoomProps {
  url: string;
  token: string;
  /**
   * when set to true, optimize bandwidth (and room capacity) by
   * * disabling receiving video when participant is hidden
   * * use lower quality video when participant is displayed as thumbnail
   */
  adaptiveVideo?: Boolean;
  // when first connected to room
  onConnected?: (videoService: IVideoService) => void;
  // when user leaves the room
  onLeave?: (videoService: IVideoService) => void;
  stageRenderer?: (props: StageProps) => React.ReactElement | null;
  participantRenderer?: (props: ParticipantProps) => React.ReactElement | null;
  controlRenderer?: (props: ControlsProps) => React.ReactElement | null;
  rosterRenderer?: (props: ParticipantProps) => React.ReactElement | null;
  setRecordingStart?: Dispatch<SetStateAction<Date | null>>;
  isRecorder?: boolean;
  isTestUser?: boolean;
  needsSessionTour?: boolean;
  sessionID: string;
  session: InitialSessionState;
  currentUser: UserInitState;
  setIsLive?: Function;
  setAttendeeCount?: Function;
  countdownRunning?: string;
  recordingMode?: string;
  backend: Backends;
}

export const SessionRoom = ({
  url,
  token,
  stageRenderer,
  participantRenderer,
  rosterRenderer,
  controlRenderer,
  onConnected,
  onLeave,
  setRecordingStart,
  sessionID,
  currentUser,
  session,
  isRecorder = false,
  setIsLive,
  setAttendeeCount,
  countdownRunning,
  backend = Backends.Livekit,
  recordingMode = 'stage',
  isTestUser = false,
  needsSessionTour = false,
}: RoomProps) => {
  const roomState = useRoom({
    currentUser,
    isRecorder,
    sessionID,
    session,
    backend,
    isTestUser,
  });

  // Room state ref for window event listeners below
  const warnRef = useRef(false);
  const disconnectRef = useRef(roomState.videoService?.disconnect);

  useEffect(() => {
    warnRef.current =
      roomState.sessionState === 'in-session' &&
      !!roomState.isRecording &&
      !!roomState.onStage &&
      !isRecorder &&
      !isTestUser;

    disconnectRef.current = roomState.videoService?.disconnect;
  }, [
    roomState.isRecording,
    roomState.sessionState,
    roomState.onStage,
    roomState.videoService?.disconnect,
  ]);

  // Display navigation warning if user attempts to leave the page while a recording is in progress
  // This listener preempts all other beforeunload events (true passed in as 4th parameter) and prevents propagation of the event any further
  // This is because Livekit adds a beforeunload listener that disconnects you from the room so we need to stop that from happening.
  // To re-enable this disconnect functionality we add an unload event below
  useEventListener(
    'beforeunload',
    e => {
      if (warnRef.current) {
        const confirmationMessage =
          'A recording is in progress, are you sure you want to leave?';

        (e || window.event).returnValue = confirmationMessage; //Gecko + IE

        e.stopImmediatePropagation();
        e.stopPropagation();
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
      }
      return;
    },
    undefined,
    true
  );

  // Call disconnect on the livekit service when user leaves the page
  // This is to replace the default functionality of livekit after we overwrote the functionality above
  useEventListener(
    'unload',
    () => {
      disconnectRef.current?.();
    },
    undefined,
    true
  );

  useEffect(() => {
    if (!roomState.videoService) {
      return;
    }
    if (
      roomState.sessionState === 'in-session' ||
      roomState.sessionState === 'pre-session'
    ) {
      // If we are already connected (videoService is already created) don't re-connect
      if (roomState.isConnected) {
        return;
      }
      const utils = VideoServiceFactory.getVideoUtils(backend);
      console.log('Connecting...');
      if (isRecorder) {
        roomState.connect(url, token).then(videoService => {
          if (!videoService) {
            return;
          }
          if (onConnected) {
            onConnected(videoService);
          }
          return () => {
            videoService.disconnect();
          };
        });
      } else {
        utils
          .createLocalTracks({
            audio: true,
            video: true,
          })
          .finally(() => {
            roomState.connect(url, token).then(videoService => {
              if (!videoService) {
                return;
              }
              videoService.localParticipant?.setCameraEnabled(true);
              videoService.localParticipant?.setMicrophoneEnabled(true);
              if (onConnected) {
                onConnected(videoService);
              }
              return () => {
                videoService.disconnect();
              };
            });
          });
      }
    }
  }, [roomState.sessionState, roomState.videoService]);

  useEffect(() => {
    if (setAttendeeCount) {
      setAttendeeCount(roomState.participantsPresent.length);
    }
  }, [roomState.participantsPresent]);

  useEffect(() => {
    if (!setRecordingStart) {
      return;
    }
    if (roomState.startedAt && roomState.isRecording) {
      console.log(roomState.startedAt);
      setRecordingStart(roomState.startedAt);
    } else {
      setRecordingStart(null);
    }
  }, [roomState.sessionDocument, roomState.isRecording]);

  const selectedStageRenderer = stageRenderer ?? StageView;

  return selectedStageRenderer({
    roomState,
    participantRenderer,
    controlRenderer,
    rosterRenderer,
    onLeave,
    sessionID,
    isRecorder,
    setIsLive,
    countdownRunning,
    recordingMode,
    needsSessionTour,
  });
};
