import React from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
import {camelCase} from 'lodash';
import icons, {IconNames} from '../../icons';
import Close from '../../icons/Close';
// Helpers
import {IconProps} from '../../helpers/interfaces';
// Style
import S from './style';

export type BasicColors =
  | 'neutral'
  | 'red bold'
  | 'red'
  | 'green bold'
  | 'green'
  | 'orange bold'
  | 'orange'
  | 'pink bold'
  | 'pink'
  | 'blue bold'
  | 'blue'
  | 'black';

interface CommonProps {
  text: string | React.ReactNode;
  theme: DefaultTheme;
}

type TypeProps =
  | {type: 'basic'; color: BasicColors; icon?: IconNames; handleRemove?: never}
  | {type: 'removable'; color?: never; icon?: never; handleRemove: Function}
  | {type: 'special new'; color?: never; icon?: never; handleRemove?: never}
  | {type: 'special live'; color?: never; icon?: never; handleRemove?: never};

type TagProps = CommonProps & TypeProps;

const Tag = ({
  color,
  text,
  icon,
  type = 'basic',
  handleRemove,
  theme,
}: TagProps) => {
  const getIconColor = () => {
    if (type === 'special live') {
      return theme.tag.specialLive.iconColor;
    } else if (type === 'special new') {
      return theme.tag.specialNew.iconColor;
    } else if (type === 'removable') {
      return theme.tag.removable.iconColor;
    } else {
      return theme.tag[camelCase(color)].iconColor;
    }
  };

  const getIcon = () => {
    let Icon: React.FC<IconProps> | null = icon ? icons[icon] : null;
    if (type === 'special live') {
      Icon = icons.Bolt;
    } else if (type === 'special new') {
      Icon = icons.New;
    }
    if (Icon) {
      return (
        <S.Icon>
          <Icon contentColor={getIconColor()} size={16} />
        </S.Icon>
      );
    }
    return null;
  };

  return (
    <S.Tag $type={type} $color={color}>
      {getIcon()}
      <S.TagText $type={type} $color={color}>
        {text}
      </S.TagText>
      {type === 'removable' && handleRemove && (
        <S.XIconCircle
          onClick={() => {
            handleRemove(text);
          }}
        >
          <Close size={16} contentColor={theme.tag.removable.iconColor} />
        </S.XIconCircle>
      )}
    </S.Tag>
  );
};

export default withTheme(Tag);
