import styled, {DefaultTheme} from 'styled-components';
import {motion} from 'framer-motion';
import Ag, {getBold} from '@brightlive/shared/styles/typography-v3';

const S = {
  LivekitWrapper: styled.div(
    (props: {height: number}) => `
    width: 100%;
    height: ${props.height}px;
    display: grid;
    grid-template-rows: auto min-content min-content;
    min-height: 0;
  `
  ),

  LivekitTop: styled.div`
    background-color: ${props => props.theme.backgroundColor.primary};
    display: flex;
    height: 100vh;

    @media (min-width: 320px) and (max-width: 1023px) and (orientation: landscape) {
      height: 100vh;
    }
  `,

  LivekitStage: styled.div(
    (props: {
      $isVertical: boolean;
      $stageHeight: number;
      theme: DefaultTheme;
    }) => `
    position: relative;
    height: ${props.$stageHeight}px;
    width 100%;
    background-color: ${props.theme.backgroundColor.body};
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    flex-direction: ${props.$isVertical ? 'column' : 'row'};
    justify-content: ${props.$isVertical ? 'flex-start' : 'flex-end'};
  `
  ),

  Creator: styled.div(
    (props: {$reducedCreator: boolean; theme: DefaultTheme}) => `
    flex: ${props.$reducedCreator ? '2' : '1'};
  `
  ),

  AttendeesStage: styled.div(
    (props: {$reducedCreator: boolean; theme: DefaultTheme}) => `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: ${props.$reducedCreator ? '3' : '1'};
    height: 1px; // Hack for mobile for 6 participants on stage
  `
  ),

  CaptionsContainer: styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: ${props => props.theme.spacing.SM};
    z-index: 5;
    font-size: ${props => props.theme.fontSize[300]};
    line-height: ${props => props.theme.lineHeight[300]};
    text-shadow: 2px 2px 3px #000000;
    background: rgba(0, 0, 0, 0.4);
  `,

  CaptionLine: styled.div`
    padding: ${props => props.theme.spacing['2XS']};
  `,

  LivekitStatusMessage: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    gap: ${props => props.theme.spacing.MD};
  `,

  LivekitStatusHeader: styled(Ag.HeadingLG)`
    text-align: center;
  `,

  MobileScreenShareWrapper: styled.div`
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  `,

  ScreenShareCamera: styled.div(
    (props: {
      height: number;
      $menuIsOpen: boolean;
      $screenShareHeight: number;
      theme: DefaultTheme;
    }) => `
    position: absolute;
    top: ${
      props.$menuIsOpen
        ? '0'
        : `calc(50% - (${props.$screenShareHeight}px / 2) - ${props.theme.spacing.MD} - ${props.height}px)`
    };
    left: ${props.theme.spacing.MD};
  `
  ),

  ScreenShareOnStageParticipants: styled.div(
    (props: {
      $menuIsOpen: boolean;
      $screenShareHeight: number;
      theme: DefaultTheme;
    }) => `
      position: absolute;
      width: ${
        props.$menuIsOpen ? 'unset' : `calc(100% - ${props.theme.spacing.MD})`
      };
      top: ${
        props.$menuIsOpen
          ? 'auto'
          : `calc(50% + (${props.$screenShareHeight}px / 2) + ${props.theme.spacing.MD})`
      };
      bottom: ${props.$menuIsOpen ? '0' : 'auto'};
      left: ${props.$menuIsOpen ? '50%' : props.theme.spacing.MD};
      transform: ${props.$menuIsOpen ? 'translateX(-50%)' : 'unset'};
      display: flex;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    `
  ),
  ModalContent: styled.div``,

  ModalTitle: styled(Ag.HeadingMD)`
    text-align: center;
  `,

  ModalDescription: styled(Ag.ParagraphMD)`
    text-align: center;
  `,

  PostSessionContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  `,
  MainControlWrapper: styled(motion.div)``,

  NavWrapper: styled(motion.div)`
    height: ${props => props.theme.sizing.mobile.navHeight};
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2000;
  `,

  LiveIndicatorWrapper: styled(motion.div)`
    position: fixed;
    top: ${props => props.theme.spacing.MD};
    right: 0;
    left: 0;
    z-index: 2000;
    display: flex;
  `,

  SendButtonContainer: styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
  `,

  VideoPreview: styled.div``,

  // Comments
  CommentsWrapper: styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: calc(70vh - ${props => props.theme.sizing.mobile.navHeight});
  `,
  CommentInputWrapper: styled.div`
    padding-top: ${props => props.theme.spacing.MD};
    display: flex;
  `,

  // Attendee
  AttendeeWrapper: styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: calc(70vh - ${props => props.theme.sizing.mobile.navHeight});
  `,

  ReplaceButton: styled.div`
    padding: 0 ${props => props.theme.margin.mobile.page};
  `,
  TopIcons: styled.div`
    position: fixed;
    top: ${props => props.theme.spacing.XS};
    right: ${props => props.theme.spacing.MD};
    z-index: 3002;
    display: flex;
    gap: ${props => props.theme.spacing.MD};
  `,
  TopIcon: styled.div`
    position: relative;
    background-color: ${props => props.theme.backgroundColor.blackTransparent};
    border-radius: ${props => props.theme.borderRadius['2XL']};
  `,
  TooltipWrapper: styled.div`
    position: absolute;
    transform: translate(0, 100%);
    bottom: -${props => props.theme.spacing['2XS']};
    right: 50%;
    z-index: 10;
  `,
  SubmitRow: styled.div`
    padding: 0 ${props => props.theme.margin.mobile.page};
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,

  CameraButton: styled.div``,

  EditCommentWrapper: styled(motion.div)`
    padding: ${props => props.theme.margin.mobile.page};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    min-height: calc(100vh - ${props => props.theme.margin.mobile.page} * 2);
    background-color: ${props => props.theme.backgroundColor.primary};
    z-index: 3003;
    flex-direction: column;
  `,

  EditCommentTopBar: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${props => props.theme.spacing.LG};
    margin-left: -10px};
  `,

  EditCommentContent: styled.div`
    display: flex;
    flex: 1;
  `,

  Flex: styled.div`
    flex-direction: column;
    display: flex;
    flex: 1;
  `,

  CameraToggle: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-items: center;
  `,

  CameraToggleHeader: styled(Ag.HeadingXS)`
    padding-bottom: ${props => props.theme.spacing['2XS']};
  `,
  CameraToggleDescription: styled(Ag.ParagraphSM)``,

  BottomDivider: styled.div`
    margin-bottom: ${props => props.theme.spacing.SM};
  `,
  RightContent: styled.div`
    display: flex;
    align-items: center;
  `,

  CharCount: styled(Ag.ParagraphXS)(
    (props: {$hasError: boolean; theme: DefaultTheme}) => `
    margin-right: ${props.theme.spacing.XS};
    color: ${
      props.$hasError
        ? props.theme.contentColor.negative
        : props.theme.contentColor.default
    };
  `
  ),
  CalloutWrapper: styled(motion.div)`
    justify-content: flex-start;
    white-space: nowrap;
    display: flex;
  `,

  CalloutText: styled(Ag.ParagraphSM)`
    color: ${props => props.theme.contentColor.inverse};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  CalloutName: styled(Ag.ParagraphSM)`
    color: ${props => props.theme.contentColor.inverse};
    ${getBold()};
    margin-right: ${props => props.theme.spacing['2XS']};
  `,
};

export default S;
