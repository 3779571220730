import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
// Components
import {
  IVideoService,
  RoomState,
  StatsDashboard,
  useParticipant,
  Role,
} from 'bright-livekit';
import Modal from '@brightlive/shared/components/Modal';
import TooltipWrapper from '@brightlive/shared/components/TooltipWrapper';
import Menu from '@brightlive/shared/components/Menu';
import IconButton from '@brightlive/shared/components/IconButton';
// Actions
import {toggleSettingsModal} from 'redux/ui/actions';
import {MenuItem} from '@brightlive/shared/helpers/interfaces';
// Styles
import S from './style';
import {ErrorModal} from '../ErrorModal';
import {TrackingService} from 'bright-livekit/services/TrackingService';

export interface ControlsPropsDesktop {
  videoService: IVideoService;
  onStage?: boolean;
  onLeave?: (videoService: IVideoService) => void;
  roomState: RoomState;
  toggleCC: Function;
  showCC: boolean;
  captionsAvail: boolean;
}
export const ControlsViewDesktop = ({
  videoService,
  onStage,
  roomState,
  toggleCC,
}: // showCC,
// captionsAvail,
ControlsPropsDesktop) => {
  const dispatch = useDispatch();
  const [statsOpen, setStatsOpen] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [overlayPosition, setOverlayPosition] = useState(0);
  // Even if we don't need the isSpeaking property we need to still include useParticipant here because use participant will trigger a state change when the user's mic or camera status changes. We need that state change to update the mic and camera css classes
  const {isAudioMuted, isVideoMuted} = useParticipant(
    videoService.localParticipant
  );

  const checkKeyPress = useCallback(
    (e: KeyboardEvent) => {
      const {key} = e;
      const target = e.target as HTMLElement | undefined;
      const tagName = target?.tagName.toUpperCase() || '';
      // Don't respond to keyboard shortcuts if user is in a textarea or input
      if (['INPUT', 'TEXTAREA'].includes(tagName)) {
        return;
      }
      if (key === '`' && !e.metaKey) {
        setStatsOpen(!statsOpen);
      } else if (key === ' ' || (key === 'd' && e.ctrlKey)) {
        videoService.localParticipant?.setMicrophoneEnabled(
          !videoService.localParticipant.isMicrophoneEnabled
        );
      } else if (e.ctrlKey && key === 'e') {
        videoService.localParticipant?.setCameraEnabled(
          !videoService.localParticipant.isCameraEnabled
        );
      } else if (
        toggleCC &&
        e.key === 'c' &&
        !e.ctrlKey &&
        !e.altKey &&
        !e.metaKey
      ) {
        return toggleCC(e);
      }
    },
    [statsOpen, toggleCC, videoService]
  );
  useEffect(() => {
    window.addEventListener('keydown', checkKeyPress);
    return () => {
      window.removeEventListener('keydown', checkKeyPress);
    };
  }, [checkKeyPress]);

  const toggleAudio = async () => {
    if (roomState.selfParticipant?.joined && !onStage) {
      videoService.localParticipant?.setMicrophoneEnabled(false);
      return;
    }
    try {
      videoService.localParticipant?.setMicrophoneEnabled(isAudioMuted);
    } catch (err) {
      setErrorMessage('Could not access your microphone');
    }
  };

  const toggleVideo = async () => {
    const enabled = videoService.localParticipant?.isCameraEnabled;
    TrackingService.fire({
      event: 'camera',
      currentUser: roomState.currentUser,
      data: {
        action: enabled ? 'on' : 'off',
        session_id: roomState.session.id,
      },
    });
    try {
      await videoService.localParticipant?.setCameraEnabled(isVideoMuted);
    } catch (err) {
      console.error(err);
      setErrorMessage('Could not access your camera');
    }
  };

  const menuItems = () => {
    const items: MenuItem[] = [
      // {
      //   label: showCC ? 'Hide closed captions' : 'Show closed captions',
      //   icon: showCC ? 'ClosedCaptionOn' : 'ClosedCaptionOff',
      //   disabled: !captionsAvail,
      //   onClick: () => toggleCC(!showCC),
      // },
      // {isLine: true},
      {
        label: 'Settings',
        icon: 'Settings',
        onClick: () => dispatch(toggleSettingsModal(true)),
      },
      {
        label: 'Help',
        icon: 'Help',
        onClick: () => {
          window.open(
            'https://brightlive.zendesk.com',
            '_blank',
            'noopener noreferrer'
          );
        },
      },
    ];
    return items;
  };

  const screenShareDisabled =
    !!roomState.screenShareUser &&
    roomState.screenShareUser !== roomState.selfParticipant?.id;

  return (
    <>
      {statsOpen && (
        <Modal
          desktopWidth="80%"
          closeModal={() => {
            setStatsOpen(false);
          }}
        >
          <StatsDashboard roomState={roomState}></StatsDashboard>
        </Modal>
      )}

      {errorMessage && (
        <ErrorModal
          message={errorMessage}
          closeModal={() => setErrorMessage('')}
        />
      )}
      <S.ControlsWrapper>
        <TooltipWrapper
          tooltipContent={!isAudioMuted ? 'Mute' : 'Unmute'}
          referenceContent={
            <IconButton
              type={isAudioMuted ? 'negative' : 'secondary'}
              size="medium"
              icon={!isAudioMuted ? 'MicOnFilled' : 'MicOff'}
              disabled={
                roomState.selfParticipant?.role
                  ? [Role.GreenRoom, Role.Banned].includes(
                      roomState.selfParticipant?.role
                    )
                  : true
              }
              onClick={toggleAudio}
            />
          }
        />
        <TooltipWrapper
          tooltipContent={!isVideoMuted ? 'Turn off camera' : 'Turn on camera'}
          referenceContent={
            <IconButton
              type={isVideoMuted ? 'negative' : 'secondary'}
              size="medium"
              icon={!isVideoMuted ? 'VideocamOnFilled' : 'VideocamOff'}
              onClick={toggleVideo}
            />
          }
        />
        {roomState.modControls && (
          <TooltipWrapper
            tooltipContent={
              screenShareDisabled
                ? 'You can’t screenshare when someone else is sharing their screen.'
                : roomState.screenShareUser === roomState.selfParticipant?.id &&
                  roomState.screenShareTrack?.isSubscribed
                ? 'Stop share screen'
                : 'Share screen'
            }
            referenceContent={
              <IconButton
                type="secondary"
                size="medium"
                disabled={screenShareDisabled}
                icon={
                  roomState.screenShareUser === roomState.selfParticipant?.id &&
                  roomState.screenShareTrack?.isSubscribed
                    ? 'StopScreenShare'
                    : 'ScreenShare'
                }
                onClick={async () => {
                  const localParticipant =
                    roomState.videoService?.localParticipant;
                  if (!localParticipant) {
                    return;
                  }
                  const screenShareStatus =
                    roomState.screenShareUser ===
                      roomState.selfParticipant?.id &&
                    roomState.screenShareTrack?.isSubscribed;
                  TrackingService.fire({
                    event: 'sharescreen',
                    currentUser: roomState.currentUser,
                    data: {
                      session_id: roomState.session.id,
                      action: screenShareStatus ? 'off' : 'on',
                    },
                  });
                  if (
                    roomState.screenShareUser ===
                      roomState.selfParticipant?.id &&
                    roomState.screenShareTrack?.isSubscribed
                  ) {
                    roomState.videoService?.toggleScreenshare(false, true);
                  } else {
                    roomState.videoService?.toggleScreenshare(true, true);
                  }
                }}
              />
            }
          />
        )}
        <S.MoreOptions>
          <TooltipWrapper
            tooltipContent="More actions"
            referenceContent={
              <IconButton
                type="secondary"
                size="medium"
                icon="AndroidMore"
                onClick={() => setShowOptions(!showOptions)}
              />
            }
          />
          {showOptions && (
            <S.AttendeeHoverContainer
              onMouseLeave={() => setShowOptions(false)}
              style={{
                bottom: overlayPosition,
              }}
            >
              <Menu
                onClose={() => {
                  setShowOptions(false);
                }}
                isMobile={false}
                onMount={menuHeight => {
                  setOverlayPosition(menuHeight);
                }}
                type="default"
                menuItems={menuItems()}
              />
            </S.AttendeeHoverContainer>
          )}
        </S.MoreOptions>
      </S.ControlsWrapper>
    </>
  );
};
