import styled, {DefaultTheme} from 'styled-components';
import {motion} from 'framer-motion';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag from '@brightlive/shared/styles/typography-v3';
import {MenuTypes} from './index';

const calcLeftPosition = (
  menuDirection,
  menuPositionRight?: number,
  menuPositionLeft?: number
) => {
  if (menuDirection === 'left') {
    if (menuPositionLeft) return `${menuPositionLeft}px`;
    return 'auto';
  } else {
    if (menuPositionRight) return `${menuPositionRight}px`;
    return '0';
  }
};
const calcTopPosition = (
  type: string,
  theme: DefaultTheme,
  menuTop?: number
) => {
  if (type === 'select' || type === 'dropdown button') {
    if (menuTop) return `${menuTop}px`;
    return theme.spacing['5XL'];
  } else if (type === 'kebab') {
    return theme.spacing['4XL'];
  } else {
    return '0';
  }
};

const S: Record<string, React.ElementType> = {};

S.MenuBackground = styled.div(
  (props: {$noBackground: boolean; theme: DefaultTheme}) => `
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${
      props.$noBackground ? 'unset' : props.theme.backgroundColor.scrim
    };
    z-index: 3001;


    ${media.MD} {
      display: none;
    }
  `
);

S.MenuHandle = styled.div`
  background: ${props => props.theme.borderColor.default};
  width: 32px;
  height: 4px;
  border-radius: ${props => props.theme.borderRadius['2XL']};

  ${media.MD} {
    display: none;
  }
`;

S.MenuHandleWrapper = styled.div`
  padding: ${props => props.theme.spacing.SM} 0;
  display: flex;
  justify-content: center;

  ${media.MD} {
    display: none;
  }
`;

S.Menu = styled(motion.div)(
  (props: {
    $desktopWidth: string;
    $type: MenuTypes;
    $menuDirection: 'left' | 'right';
    $menuPosition?: {top: number; left: number; right: number};
    theme: DefaultTheme;
  }) => `
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding-bottom: ${props.theme.spacing.MD};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: ${props.theme.backgroundColor.primary};
  z-index: 3002;
  display: flex;
  flex-direction: column;

  ${media.MD} {
    border-radius: ${props.theme.borderRadius.MD};
    position: ${props.$menuPosition ? 'fixed' : 'absolute'};
    width: ${props.$desktopWidth || '100%'};
    box-shadow: ${props.theme.elevation.level4};
    left: ${calcLeftPosition(
      props.$menuDirection,
      props?.$menuPosition?.left,
      props?.$menuPosition?.right
    )};
    bottom: auto;
    top: ${calcTopPosition(
      props.$type,
      props.theme,
      props?.$menuPosition?.top
    )};
    right: ${props.$menuDirection === 'right' ? 'auto' : 0};
    padding: 0;
    overflow: hidden;
  }
`
);

S.Header = styled.div`
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.XS};
  border-bottom: ${props => props.theme.borderWidth[1]} solid
    ${props => props.theme.borderColor.subdued};

  ${media.MD} {
    display: none;
  }
`;

S.Title = styled(Ag.HeadingSM)``;

S.Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

S.CustomTopContent = styled.div`
  ${media.MD} {
    display: none;
  }
`;

S.MenuItemsWrapper = styled.div(
  (props: {
    $maxHeightMobile: string;
    $maxHeightDesktop: string;
    $hasCustomTopContent: boolean;
    theme: DefaultTheme;
  }) => `
  ${
    props.$maxHeightMobile
      ? `max-height: ${props.$maxHeightMobile}; overflow-y: auto;`
      : ''
  }
  ${props.$hasCustomTopContent ? `padding-top: ${props.theme.spacing.LG};` : ''}
  ${media.MD} {
    ${
      props.$maxHeightDesktop
        ? `max-height: ${props.$maxHeightDesktop}; overflow-y: auto;`
        : ''
    }
  }
`
);

S.MenuItem = styled.div`
  cursor: pointer;
  padding: ${props => props.theme.spacing.SM} ${props => props.theme.spacing.MD};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &:hover {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${props => props.theme.actionColor.hover};
    }
  }

  &:active {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${props => props.theme.actionColor.pressed};
    }
  }
`;

S.LeftWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

S.LeadingIcon = styled.div`
  margin-right: ${props => props.theme.spacing.SM};
  display: flex;
  align-items: center;
`;

S.TextWrapper = styled.div``;

const getLabelColor = (negative, disabled) => {
  if (negative) {
    return 'negative';
  } else if (disabled) {
    return 'disabled';
  } else {
    return 'default';
  }
};

S.Label = styled(Ag.ParagraphMD)(
  (props: {
    $selected: string;
    $negative: boolean;
    disabled: boolean;
    theme: DefaultTheme;
  }) => `
  && {
    font-weight: ${
      props.$selected
        ? props.theme.fontWeight.bold
        : props.theme.fontWeight.regular
    };
    color: ${
      props.theme.contentColor[getLabelColor(props.$negative, props.disabled)]
    }
  }
`
);

S.Description = styled(Ag.ParagraphSM)`
  && {
    color: ${props => props.theme.contentColor.subdued};
  }
`;

S.RightWrapper = styled.div`
  margin-left: ${props => props.theme.spacing.SM};
  display: flex;
  align-items: center;
`;

S.Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${props => props.theme.borderColor.subdued};
`;

export default S;
