import React from 'react';
// Components
import ContextualAlert from '../ContextualAlert';
// Images
import Check from '../../icons/Check';
import Minus from '../../icons/Minus';
// Style
import S from './style';

export interface CheckboxProps {
  checked: boolean;
  onCheck: Function;
  label?: string | JSX.Element;
  error?: string;
  helperText?: string;
  disabled?: boolean;
  indeterminate?: boolean;
}

const Checkbox = ({
  checked,
  onCheck,
  label,
  error,
  helperText,
  disabled,
  indeterminate = false,
}: CheckboxProps) => {
  return (
    <>
      <S.CheckboxWrapper onClick={onCheck}>
        <S.Checkbox role="checkbox" disabled={disabled} $error={!!error}>
          {checked && !indeterminate && (
            <Check size={24} contentColor={disabled ? 'disabled' : 'default'} />
          )}
          {indeterminate ? (
            <Minus size={24} contentColor={disabled ? 'disabled' : 'default'} />
          ) : null}
        </S.Checkbox>
        {!!label && <S.Label disabled={disabled}>{label}</S.Label>}
      </S.CheckboxWrapper>
      {helperText && !error && <S.HelperText>{helperText}</S.HelperText>}
      {!!error && <ContextualAlert text={error} type="error" />}
    </>
  );
};

export default React.memo(Checkbox);
