import styled from 'styled-components';

const S = {
  ControlsWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${props => props.theme.spacing.MD};
    position: relative;
  `,

  MoreOptions: styled.div`
    position: relative;
  `,

  AttendeeHoverContainer: styled.div`
    position: absolute;
    left: 55px;
    width: 250px;
    z-index: 2000;
  `,
};

export default S;
