import React from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
import {IconProps} from '@brightlive/shared/helpers/interfaces';

const VideoLibrary = ({
  size = 24,
  contentColor = 'default',
  theme,
  ...props
}: IconProps & {
  theme: DefaultTheme;
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6Zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2Zm-8 12.5v-9l6 4.5-6 4.5Z"
      fill={
        contentColor.indexOf('#') > -1
          ? contentColor
          : theme.contentColor[contentColor]
      }
    />
  </svg>
);

export default withTheme(VideoLibrary);
