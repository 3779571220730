import {
  addDoc,
  collection,
  doc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import {
  NotificationType,
  Role,
  ViewMode,
  LayoutUpdateProps,
} from 'bright-livekit';
import Api from 'library/api';

export const VideoConfig = {
  EnableInvites: false,
};
/**
 * Livekit Utils
 */
export abstract class VideoUtils {
  public readonly MAX_STAGE_USERS = 7;
  private commandRef(sessionID: string) {
    return collection(getFirestore(), 'sessions', sessionID, 'commands');
  }
  private notificationsRef(sessionID: string) {
    return collection(getFirestore(), 'sessions', sessionID, 'notifications');
  }
  private modChatRef(sessionID: string) {
    return collection(getFirestore(), 'sessions', sessionID, 'modChat');
  }

  private chatRef(sessionID: string) {
    return collection(getFirestore(), 'sessions', sessionID, 'chat');
  }

  private sessionRef(sessionID: string) {
    return doc(getFirestore(), 'sessions', sessionID);
  }

  private participantRef(sessionID: string, participantID: string) {
    return doc(
      getFirestore(),
      'sessions',
      sessionID,
      'participants',
      participantID
    );
  }
  private questionRef(sessionID: string, participantID: string) {
    return collection(
      getFirestore(),
      'questions',
      sessionID,
      'users',
      participantID,
      'messages'
    );
  }
  public async muteUser(
    sessionID: string,
    participantID: string,
    trackType: 'video' | 'audio',
    authToken: string
  ) {
    const body = {
      sessionID: sessionID,
      subjectUid: participantID,
      trackType,
    };
    Api.post(`/video/${sessionID}/mute`, body).execute(authToken);
    return true;
  }
  async submitFeedback(
    sessionID: string,
    data: Record<string, string | number>,
    authToken: string
  ) {
    Api.post(`/sessions/${sessionID}/feedback`, data).execute(authToken);
    return true;
  }
  async startRecording(sessionID: string, authToken: string) {
    await Api.post(
      `/video/${sessionID}/start-recording?simulcast=true`
    ).execute(authToken);
    return true;
  }
  async stopRecording(sessionID: string, authToken: string) {
    await Api.post(`/video/${sessionID}/stop-recording`).execute(authToken);
    return true;
  }
  async askToLeaveStage(
    sessionID: string,
    senderID: string,
    recipientID: string
  ) {
    addDoc(this.notificationsRef(sessionID), {
      type: NotificationType.AskToLeaveStage,
      sender: senderID,
      recipient: recipientID,
      timestamp: serverTimestamp(),
    });
  }
  async askToUnmute(sessionID: string, senderID: string, recipientID: string) {
    addDoc(this.notificationsRef(sessionID), {
      type: NotificationType.AskToUnmute,
      sender: senderID,
      recipient: recipientID,
      timestamp: serverTimestamp(),
    });
  }
  async askToTurnOnCamera(
    sessionID: string,
    senderID: string,
    recipientID: string
  ) {
    addDoc(this.notificationsRef(sessionID), {
      type: NotificationType.AskToTurnOnCamera,
      sender: senderID,
      recipient: recipientID,
      timestamp: serverTimestamp(),
    });
  }
  async inviteOnStage(
    sessionID: string,
    senderID: string,
    recipientID: string,
    viewMode: ViewMode = ViewMode.Video
  ) {
    if (VideoConfig.EnableInvites) {
      return await addDoc(this.notificationsRef(sessionID), {
        type: NotificationType.InviteToStage,
        sender: senderID,
        recipient: recipientID,
        timestamp: serverTimestamp(),
        viewMode: viewMode,
      });
    } else {
      this.moveOnStage(sessionID, recipientID, viewMode);
      return await addDoc(this.notificationsRef(sessionID), {
        type: NotificationType.JoinedStage,
        sender: senderID,
        recipient: recipientID,
        timestamp: serverTimestamp(),
        viewMode: viewMode,
      });
    }
  }
  async removeFromStage(
    sessionID: string,
    senderID: string,
    recipientID: string,
    isMod: boolean
  ) {
    if (isMod) {
      this.moveOffStage(sessionID, recipientID);
    } else {
      addDoc(this.notificationsRef(sessionID), {
        type: NotificationType.LeaveStage,
        sender: senderID,
        recipient: recipientID,
        timestamp: serverTimestamp(),
      });
    }
  }

  async acceptStageInvite(
    sessionID: string,
    senderID: string,
    invitationID: string
  ) {
    addDoc(this.notificationsRef(sessionID), {
      type: NotificationType.AcceptStage,
      sender: senderID,
      recipient: senderID,
      timestamp: serverTimestamp(),
      notificationReference: invitationID,
    });
  }

  async declineStageInvite(
    sessionID: string,
    senderID: string,
    invitationID: string
  ) {
    addDoc(this.notificationsRef(sessionID), {
      type: NotificationType.DeclinedStage,
      sender: senderID,
      recipient: senderID,
      timestamp: serverTimestamp(),
      notificationReference: invitationID,
    });
  }
  async joinStage(
    sessionID: string,
    senderID: string,
    viewMode: ViewMode = ViewMode.Video
  ) {
    addDoc(this.notificationsRef(sessionID), {
      type: NotificationType.JoinedStage,
      sender: senderID,
      recipient: senderID,
      timestamp: serverTimestamp(),
      viewMode,
    });
  }
  async join(
    sessionID: string,
    participantID: string,
    joined: boolean,
    displayName?: string
  ) {
    const props = {
      joined,
      displayName,
    };
    if (!displayName) {
      delete props.displayName;
    }
    if (participantID) {
      updateDoc(this.participantRef(sessionID, participantID), props);
    }
  }
  async userJoinedToast(
    sessionID: string,
    participantID: string,
    displayName: string
  ) {
    // Send notification that user has joined to all users
    addDoc(this.notificationsRef(sessionID), {
      type: NotificationType.JoinedSession,
      sender: participantID,
      senderName: displayName,
      timestamp: serverTimestamp(),
    });
  }
  async updateDisplayName(
    sessionID: string,
    participantID: string,
    displayName: string
  ) {
    const props = {
      displayName,
    };
    if (participantID) {
      updateDoc(this.participantRef(sessionID, participantID), props);
    }
  }
  async moveOnStage(
    sessionID: string,
    participantID: string,
    viewMode: ViewMode = ViewMode.Video
  ) {
    updateDoc(this.participantRef(sessionID, participantID), {
      role: Role.OnStage,
      roleChanged: serverTimestamp(),
      viewMode,
    });
  }
  async moveOffStage(sessionID: string, participantID: string) {
    updateDoc(this.participantRef(sessionID, participantID), {
      role: Role.GreenRoom,
      roleChanged: serverTimestamp(),
      questionAnswered: true,
      question: '',
    });
  }
  async ban(sessionID: string, participantID: string) {
    updateDoc(this.participantRef(sessionID, participantID), {
      role: Role.Banned,
      roleChanged: serverTimestamp(),
      questionAnswered: true,
    });
  }
  async unban(sessionID: string, participantID: string) {
    updateDoc(this.participantRef(sessionID, participantID), {
      role: Role.GreenRoom,
      roleChanged: serverTimestamp(),
    });
  }
  async changeViewMode(
    sessionID: string,
    participantID: string,
    viewMode: ViewMode
  ) {
    updateDoc(this.participantRef(sessionID, participantID), {viewMode});
  }
  async startSession(sessionID) {
    addDoc(this.commandRef(sessionID), {
      command: 'start',
      bot: false,
    });
  }
  async endSession(sessionID) {
    addDoc(this.commandRef(sessionID), {
      command: 'end',
      bot: false,
    });
  }

  async updateLayout<K extends keyof LayoutUpdateProps>({
    sessionID,
    key,
    value,
  }: {
    key: K;
    value: LayoutUpdateProps[K];
    sessionID: string;
  }) {
    // Nest settings in map object in firestore
    const nestedKey = `layoutSettings.${key}`;
    updateDoc(this.sessionRef(sessionID), {
      [nestedKey]: value,
    });
  }

  async sendQuestion(
    sessionID: string,
    participantID: string,
    questionText: string
  ) {
    addDoc(this.questionRef(sessionID, participantID), {
      text: questionText,
    });
  }
  async removeOwnQuestion(sessionID: string, participantID: string) {
    addDoc(this.questionRef(sessionID, participantID), {
      text: '',
      remove: true,
    });
  }
  async removeQuestion(sessionID: string, participantID: string) {
    updateDoc(this.participantRef(sessionID, participantID), {
      questionAnswered: true,
      question: '',
    });
  }

  async submitChat(
    sessionID: string,
    participantID: string,
    displayName: string,
    messageText: string
  ) {
    addDoc(this.chatRef(sessionID), {
      participantID,
      displayName,
      messageText,
      timestamp: serverTimestamp(),
    });
  }

  async submitModChat(
    sessionID: string,
    participantID: string,
    displayName: string,
    messageText: string
  ) {
    addDoc(this.modChatRef(sessionID), {
      participantID,
      displayName,
      messageText,
      timestamp: serverTimestamp(),
    });
  }

  abstract createLocalTracks(opts: {
    audio: boolean;
    video: boolean;
  }): Promise<unknown>;
}
