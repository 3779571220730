import Api from 'library/api';
import {EditAssetConfig} from './reducer';
import types from './types';

export const createEdit = (
  sessionId: string,
  assetConfig: EditAssetConfig[]
) => ({
  type: types.CREATE_EDIT,
  promise: Api.post(`/recordings/${sessionId}/edit`, assetConfig),
});

export const getRecording = (sessionId: string, recordingId: string) => ({
  type: types.GET_RECORDING,
  promise: Api.get(`/recordings/${sessionId}/recording/${recordingId}`),
});

export const updateRecording = (
  sessionId: string,
  recordingId: string,
  title: string
) => ({
  type: types.UPDATE_RECORDING,
  promise: Api.patch(`/recordings/${sessionId}/recording/${recordingId}`, {
    title,
  }),
});

export const deleteRecording = (sessionId: string, recordingId: string) => ({
  type: types.DELETE_RECORDING,
  promise: Api.delete(`/recordings/${sessionId}/recording/${recordingId}`),
});
