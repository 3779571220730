import styled from 'styled-components';
import Ag, {getHeadingLGDesktop} from '@brightlive/shared/styles/typography-v3';

const T = {
  Description: styled(Ag.ParagraphMD)`
    margin-bottom: ${props => props.theme.spacing['2XL']};
  `,
};

const S = {
  Title: styled.h1`
    ${props => getHeadingLGDesktop(props.theme)}
    margin-bottom: ${props => props.theme.spacing['2XL']};
  `,

  Description: T.Description,
  DescriptionLeft: styled(T.Description)`
    text-align: left;
  `,

  ReadOnly: styled.div`
    padding: ${props => props.theme.spacing.SM}
      ${props => props.theme.spacing.XS};
  `,
  CheckboxWrapper: styled.div`
    margin-top: ${props => props.theme.spacing.LG};
  `,
  TermsLink: styled(Ag.Link)``,
  Label: styled(Ag.HeadingXS)``,

  Value: styled(Ag.ParagraphMD)``,

  Input: styled.div`
    margin-bottom: ${props => props.theme.spacing.LG};
  `,

  InputTop: styled.div`
    margin-top: ${props => props.theme.spacing['3XL']};
    margin-bottom: ${props => props.theme.spacing.LG};
  `,

  Divider: styled.div`
    position: relative;
    margin: ${props => props.theme.spacing['2XL']} 0;
  `,

  Or: styled(Ag.ParagraphSM)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${props => props.theme.backgroundColor.body};
    padding: 0 ${props => props.theme.spacing.XS};
  `,

  GoogleButton: styled.div`
    margin-bottom: ${props => props.theme.spacing.MD};
  `,

  AppleButton: styled.div`
    margin-bottom: ${props => props.theme.spacing['2XL']};
  `,

  ContinueButton: styled.div`
    padding-top: ${props => props.theme.spacing.MD};
  `,

  CreateAccountButton: styled.div`
    margin-top: ${props => props.theme.spacing['2XL']};
  `,

  ResetButton: styled.div`
    margin-top: ${props => props.theme.spacing['2XL']};
  `,

  Terms: styled(Ag.ParagraphXS)`
    color: ${props => props.theme.contentColor.moreSubdued};
    margin-bottom: ${props => props.theme.spacing['2XL']};
  `,

  AltText: styled(Ag.ParagraphMD)``,

  ForgotPassword: styled(Ag.ParagraphMD)`
    margin: ${props => props.theme.spacing.LG} 0
      ${props => props.theme.spacing['2XL']};
    text-align: left;
  `,

  Link: styled(Ag.Link)``,

  Bold: styled(Ag.Bold)``,
};
export default S;
