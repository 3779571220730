import React, {useCallback, useEffect, useRef, useState} from 'react';
import type {EmojiSelection, EmojiPicker} from 'picmo';
import {darkTheme, createPicker, NativeRenderer} from 'picmo';
// Icon
import SentimentSatisfied from '@brightlive/shared/icons/SentimentSatisfied';
// Style
import S from './style';

interface EmojiPopupProps {
  onClick: (emoji: string) => void;
}

export const EmojiPopup = ({onClick}: EmojiPopupProps) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const [popupVisible, setPopupVisibility] = useState(false);
  const rootElement = useRef<HTMLDivElement>(null);
  const picker = useRef<EmojiPicker>();

  const listener = useCallback(
    (selection: EmojiSelection) => {
      setPopupVisibility(false);
      onClick && onClick(selection.emoji);
    },
    [onClick]
  );

  useEffect(() => {
    if (popupVisible && rootElement.current) {
      picker.current = createPicker({
        rootElement: rootElement.current,
        className: 'picmo-picker',
        theme: darkTheme,
        renderer: new NativeRenderer(),
        emojiSize: '20px',
        showPreview: false,
        showCategoryTabs: false,
      });

      picker.current.addEventListener('emoji:select', listener);
    }

    return () => {
      if (picker.current) {
        picker.current.removeEventListener('emoji:select', listener);
      }
    };
  }, [rootElement, popupVisible]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Element;
    if (rootElement.current && !rootElement.current.contains(target)) {
      setPopupVisibility(false);
    }
  };

  const onButtonClick = useCallback(() => {
    setPopupVisibility(!popupVisible);
  }, [popupVisible]);

  return (
    <S.EmojiPicker>
      <div ref={buttonRef} onClick={onButtonClick}>
        <SentimentSatisfied size={24} />
      </div>

      {popupVisible && (
        <S.Popup>
          <div ref={rootElement} />
        </S.Popup>
      )}
    </S.EmojiPicker>
  );
};
