import React from 'react';
// Components
import IconButton from '@brightlive/shared/components/IconButton';
// Style
import S from './style';

export interface ShareLinkProps {
  text: string;
  onClick: Function;
  width?: number;
}

const ShareLink = ({text, width, onClick}: ShareLinkProps) => {
  return (
    <S.ShareLink width={width}>
      <S.Text>{text}</S.Text>
      <IconButton
        icon="ContentCopy"
        size="small"
        type="tertiary"
        onClick={onClick}
      />
    </S.ShareLink>
  );
};

export default React.memo(ShareLink);
