import styled from 'styled-components';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag from '@brightlive/shared/styles/typography-v3';
import {Hex} from '@brightlive/shared/helpers/interfaces';

const S = {
  ImageCropper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${props => props.theme.spacing['3XL']};

    ${media.MD} {
      padding-top: 0;
    }
  `,

  Title: styled(Ag.HeadingMD)`
    margin-bottom: ${props => props.theme.spacing.XL};
  `,

  PreviewArea: styled.div`
    width: 100%;
    height: calc(100% * 9 / 16);
    position: relative;
  `,

  CropperWrapper: styled.div(
    (props: {$cropArea: 'top' | 'bottom'; $backgroundColor: Hex}) => `
    width: 100%;
    padding-bottom: 8.33%;
    position: absolute;
    bottom: ${props.$cropArea === 'bottom' ? '0' : 'auto'};
    top: ${props.$cropArea === 'top' ? '0' : 'auto'};
    left: 0;
    background-color: ${props.$backgroundColor};
  `
  ),

  PreviewImage: styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
  `,

  Slider: styled.div`
    display: none;

    ${media.MD} {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: ${props => props.theme.spacing.XL};
    }
  `,

  SliderWrapper: styled.div`
    padding: 0 ${props => props.theme.spacing.LG};
  `,

  ErrorWrapper: styled.div`
    padding: 0 ${props => props.theme.margin.mobile.page}
      ${props => props.theme.spacing.MD};

    ${media.MD} {
      padding: 0 0 ${props => props.theme.spacing.MD};
    }
  `,
};

export default S;
