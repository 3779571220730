import React from 'react';
// Images
import Info from '../../icons/Info';
import Warning from '../../icons/Warning';
import Critical from '../../icons/Critical';

// Style
import S, {AlertTypes} from './style';

export interface AlertProps {
  title?: string;
  text: string | React.ReactNode;
  type: AlertTypes;
}

const Alert = ({text, title, type}: AlertProps) => {
  const icons = {
    informational: Info,
    warning: Warning,
    critical: Critical,
  };

  const Icon = icons[type];

  return (
    <S.Alert
      type={type}
      aria-errormessage={type === 'critical' && text}
      role="alert"
    >
      <Icon size={24} />

      <S.Content>
        {title && <S.Title>{title}</S.Title>}
        <S.Text>{text}</S.Text>
      </S.Content>
    </S.Alert>
  );
};

export default React.memo(Alert);
