import styled from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';
import {media} from '@brightlive/shared/styles/breakpoints-v3';

const S = {
  LivestreamSettings: styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${props => props.theme.spacing.MD};
  `,
  LivestreamParagraph: styled(Ag.ParagraphMD)`
    margin-bottom: ${props => props.theme.spacing['2XL']};
    ${media.MD} {
      margin-bottom: ${props => props.theme.spacing.XL};
    }
  `,
};

export default S;
