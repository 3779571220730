import styled from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';
import {media} from '@brightlive/shared/styles/breakpoints-v3';

const S: Record<string, React.ElementType> = {};

S.FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
S.FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

S.GeneralSettings = styled(S.FlexColumn)`
  margin-top: ${props => props.theme.spacing.MD};
`;
S.GeneralProfile = styled(S.FlexColumn)`
  margin-bottom: ${props => props.theme.spacing.XL};
  ${media.MD} {
    width: 441px;
  }
`;
S.GeneralAppearance = styled(S.FlexColumn)``;
S.GeneralStage = styled(S.FlexColumn)`
  margin-top: ${props => props.theme.spacing.XL};
`;

S.GeneralHeader = styled(Ag.HeadingXS)`
  margin-bottom: ${props => props.theme.spacing.SM};
`;

S.GeneralParagraph = styled(Ag.ParagraphMD)``;

S.GeneralProfileText = styled(S.GeneralParagraph)`
  margin-bottom: ${props => props.theme.spacing.LG};
`;

S.GeneralRadio = styled.div`
  margin-bottom: ${props => props.theme.spacing.MD};
`;

S.GeneralSubText = styled(Ag.ParagraphSM)``;

S.GeneralQuestionText = styled.div`
  width: 75%;
`;

S.GeneralQuestionsPreview = styled(S.FlexRow)`
  padding: ${props => props.theme.spacing.SM} ${props => props.theme.spacing.XS};
`;
S.GeneralQuestionsPreviewText = styled(S.GeneralParagraph)`
  margin-bottom: ${props => props.theme.spacing['2XS']};
`;
S.GeneralSessionControls = styled(S.FlexRow)`
  padding: ${props => props.theme.spacing.SM} ${props => props.theme.spacing.XS};
`;

export default S;
