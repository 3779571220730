import styled, {DefaultTheme} from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';
import {ContentColor} from '@brightlive/shared/styles/theme-declarations/theme-mode.d';

const S: Record<string, React.ElementType> = {};

export type ContextualAlertTypes = 'error' | 'warning' | 'success' | 'info';

type ContentColorProps = {
  [key in ContextualAlertTypes]: keyof ContentColor;
};

export const contentColor: ContentColorProps = {
  error: 'negative',
  warning: 'warning',
  success: 'positive',
  info: 'moreSubdued',
};

S.AlertWrapper = styled.div`
  padding-top: ${props => props.theme.spacing.XS};
  display: flex;
`;

S.AlertIcon = styled.div`
  margin-right: ${props => props.theme.spacing.XS};
  padding-top: 2px;
`;

S.AlertText = styled(Ag.ParagraphSM)(
  (props: {theme: DefaultTheme; $type: string}) => `
  && {
    color: ${
      (props.$type === 'error' && props.theme.contentColor.negative) ||
      (props.$type === 'warning' && props.theme.contentColor.warning) ||
      (props.$type === 'success' && props.theme.contentColor.positive) ||
      props.theme.contentColor.subdued
    };
  }
`
);

export default S;
