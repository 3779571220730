import Api from 'library/api';
import types from './types';
/**
 * Fetch individual session
 *
 * @return {Record<string, string>}
 */
export const getSession = (id: unknown) => ({
  type: types.GET_SESSION,
  promise: Api.get(`/sessions/${id}`),
});
/**
 * Fetch studio session
 *
 * @return {Record<string, string>}
 */
export const getStudioSession = (userID: string, id: string) => ({
  type: types.GET_STUDIO_SESSION,
  promise: Api.get(`/creator-tools/${userID}/session/${id}`),
});
/**
 * Fetch all sessions
 *
 * @return {Array<Record<string, string>>}
 */
export const getAllSessions = (userID: string) => ({
  type: types.GET_ALL_SESSIONS,
  promise: Api.get(`/creator-tools/${userID}/sessions`),
});
/**
 * Update individual session
 *
 * @return {Record<string, string>}
 */
export const updateSession = (
  userId: string,
  sessionId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
) => ({
  type: types.UPDATE_SESSION,
  promise: Api.patch(`/creator-tools/${userId}/session/${sessionId}`, data),
});
/**
 * Create individual session
 *
 * @return {Record<string, string>}
 */
export const createSession = (
  data: Record<string, string | number | boolean>
) => ({
  type: types.CREATE_SESSION,
  promise: Api.post(`/creator-tools/${data?.creator}/session/`, data),
});
/**
 * update session form
 *
 * @return {Record<string, string>}
 */
export const updateSessionForm = (data: Record<string, unknown>) => ({
  type: types.UPDATE_SESSION_FORM,
  data,
});
/**
 * reset session form
 *
 * @return {Record<string, string>}
 */
export const resetSessionForm = () => ({
  type: types.RESET_SESSION_FORM,
});
/**
 * Fetch individual session recordings
 *
 * @return {Record<string, string>}
 */
export const getSessionRecordings = (sessionID: string, userID: string) => ({
  type: types.GET_SESSION_RECORDINGS,
  promise: Api.get(`/creator-tools/${userID}/session/${sessionID}/recordings`),
});

/**
 * Get Legacy session recordings
 *
 * @param   {string}  id  sessionID
 *
 */
export const getLegacySessionRecordings = (id: unknown) => ({
  type: types.GET_LEGACY_SESSION_RECORDINGS,
  promise: Api.get(`/sessions/${id}/legacy-recordings?format=hls`),
});

/**
 * Set Active Session
 *
 * @return {void}
 */
export const setActiveSession = data => ({
  type: types.SET_ACTIVE_SESSION,
  data,
});
/**
 * Set Session Code
 *
 * @return {void}
 */
export const setSessionCode = (code: string) => ({
  type: types.SET_SESSION_CODE,
  code,
});
/**
 * Reset Active ticket
 *
 * @return {void}
 */
export const resetActivePayment = () => ({
  type: types.RESET_ACTIVE_PAYMENT,
});
/**
 * Create checkout session for stripe card payment
 *
 * @return {Record <string, string>}
 */
export const createPaymentIntent = (data: Record<string, string>) => ({
  type: types.CREATE_PAYMENT_INTENT,
  promise: Api.post('/tickets/payment-intent', data),
});

export const checkAvailability = (id: string) => ({
  type: types.CHECK_AVAILABILITY,
  promise: Api.get(`/sessions/${id}/availability`),
});

/**
 * Add user to waitlist
 *
 * @return {Record <string, string>}
 */

export const addToWaitlist = data => ({
  type: types.ADD_TO_WAITLIST,
  promise: Api.post(`/sessions/${data.sessionId}/waitlist`, data),
});

/**
 * Remove user to waitlist
 *
 * @return {Record <string, string>}
 */

export const removeFromWaitlist = data => ({
  type: types.REMOVE_FROM_WAITLIST,
  promise: Api.post(`/sessions/${data.sessionId}/waitlist`, data),
});

/**
 * Add user to waitlist
 *
 * @return {void}
 */
export const resetWaitlistErrors = () => ({
  type: types.RESET_WAITLIST_ERRORS,
});

/**
 * fetch livestream keys
 *
 * @return {Record <string, string>}
 */

export const getLivestreamKeys = sessionId => ({
  type: types.GET_LIVESTREAM_KEYS,
  promise: Api.get(`/video/${sessionId}/simulcast`),
});

/**
 * update simulcast keys
 *
 * @return {Record <string, string>}
 */

export const updateSimulcastKey = data => ({
  type: types.UPDATE_SIMULCAST_KEY,
  promise: Api.patch(`/video/${data.sessionId}/simulcast`, data),
});
