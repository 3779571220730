import React from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
import {IconProps} from '@brightlive/shared/helpers/interfaces';

const SpeakerViewFilled = ({
  size = 24,
  contentColor = 'default',
  theme,
  ...props
}: IconProps & {
  theme: DefaultTheme;
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.588 19.413C2.979 19.804 3.45 20 4 20h16c.55 0 1.021-.196 1.413-.587.391-.392.587-.863.587-1.413V6c0-.55-.196-1.02-.587-1.412A1.927 1.927 0 0 0 20 4H4c-.55 0-1.02.196-1.412.588A1.923 1.923 0 0 0 2 6v12c0 .55.196 1.021.588 1.413Zm11.537-7.288A2.893 2.893 0 0 1 12 13a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 9 10c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 12 7c.833 0 1.542.292 2.125.875S15 9.167 15 10s-.292 1.542-.875 2.125ZM16 15.062A7.619 7.619 0 0 1 18.9 18H5.1A7.619 7.619 0 0 1 8 15.062 7.9 7.9 0 0 1 12 14a7.9 7.9 0 0 1 4 1.062Z"
      fill={
        contentColor.indexOf('#') > -1
          ? contentColor
          : theme.contentColor[contentColor]
      }
    />
  </svg>
);

export default withTheme(SpeakerViewFilled);
