import React, {useEffect, useState} from 'react';
import Draggable from 'react-draggable';
// Style
import S, {HANDLE_SIZE} from './style';

export interface SliderProps {
  sliderWidth: number;
  minValue: number;
  maxValue: number;
  value: number;
  setValue: Function;
}

const Slider = ({
  sliderWidth,
  minValue,
  maxValue,
  value,
  setValue,
}: SliderProps) => {
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const [handleX, setHandleX] = useState(0);

  useEffect(() => {
    const valueDistance = maxValue - minValue; // amount between the two values
    const scaledValue = value - minValue;
    const scrolledPerc = scaledValue / valueDistance;
    setScrollbarWidth(scrolledPerc * 100);
    const totalWidth = sliderWidth - HANDLE_SIZE; // total that drag will go - width of bar minus handle
    setHandleX(scrolledPerc * totalWidth);
  }, [value]);

  const handleDrag = (e, data) => {
    const totalWidth = sliderWidth - HANDLE_SIZE; // total that drag will go - width of bar minus handle
    const scrolledPerc = data.x / totalWidth; // percentage moved on the slider
    const valueDistance = maxValue - minValue; // amount between the two values
    const newValue = minValue + scrolledPerc * valueDistance; // new value
    setValue(newValue);
  };

  return (
    <S.Slider $width={sliderWidth}>
      <S.SliderFilled $width={scrollbarWidth} />
      <Draggable
        axis="x"
        handle=".handle"
        defaultPosition={{x: 0, y: 0}}
        position={{x: handleX, y: 0}}
        bounds="parent"
        scale={1}
        onDrag={handleDrag}
      >
        <S.Handle className="handle" />
      </Draggable>
    </S.Slider>
  );
};

export default React.memo(Slider);
