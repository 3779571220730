import React from 'react';
//Components
import Modal from '@brightlive/shared/components/Modal';
// Styles
import S from './style';
import Spinner from '@brightlive/shared/components/Spinner';

export const RecordingStartingModal = ({
  isCreator,
  creatorName,
}: {
  isCreator: boolean;
  creatorName?: string;
}) => {
  return (
    <Modal>
      <S.ModalContent>
        <S.ModalTitle>
          {isCreator
            ? "We're setting things up."
            : `${creatorName ?? 'The host'} has started a recording.`}
        </S.ModalTitle>
        <S.ModalBody>
          {isCreator
            ? "We're getting your recording ready, it will start in a few seconds."
            : 'Sit tight while we get things ready, this can take a couple of seconds.'}
        </S.ModalBody>
        <Spinner size="large" />
      </S.ModalContent>
    </Modal>
  );
};
