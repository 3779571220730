import styled from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';
import {media} from '@brightlive/shared/styles/breakpoints-v3';

const Flex = {
  Column: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Row: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
};

const S = {
  LivestreamService: styled(Flex.Column)`
    padding: ${props => props.theme.spacing.MD} 0;
  `,
  LivestreamInputs: styled(Flex.Column)`
    gap: ${props => props.theme.spacing.LG};
    margin: ${props => props.theme.spacing.MD};
    ${media.MD} {
      width: 425px;
    }
  `,
  LivestreamRow: styled(Flex.Row)``,
  LivestreamHeader: styled(Ag.HeadingXS)``,
  LivestreamParagraph: styled(Ag.ParagraphMD)`
    margin-bottom: ${props => props.theme.spacing['2XL']};
    ${media.MD} {
      margin-bottom: ${props => props.theme.spacing.XL};
    }
  `,
};

export default S;
