import styled, {DefaultTheme} from 'styled-components';

const S: Record<string, React.ElementType> = {};

export const HANDLE_SIZE = 24;

S.Slider = styled.div(
  (props: {$width: string; theme: DefaultTheme}) => `
  height: 4px;
  background: ${props.theme.backgroundColor.tertiary};
  width: ${props.$width}px;
  position: relative;
  border-radius: ${props => props.theme.borderRadius.MD};
`
);

S.SliderFilled = styled.div(
  (props: {$width: string; theme: DefaultTheme}) => `
  position: absolute;
  top: 0;
  left: 0;
  width: ${props.$width}%;
  height: 4px;
  background: ${props.theme.actionColor.brand};
  border-radius: ${props.theme.borderRadius.MD};
`
);

S.Handle = styled.div`
  width: ${HANDLE_SIZE}px;
  height: ${HANDLE_SIZE}px;
  border: ${props => props.theme.borderWidth[1]} solid
    ${props => props.theme.borderColor.default};
  background: ${props => props.theme.backgroundColor.primary};
  border-radius: 50%;
  position: relative;
  top: -10px;
  box-sizing: border-box;
`;

export default S;
