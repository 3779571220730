import styled from 'styled-components';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag from '@brightlive/shared/styles/typography-v3';

const S: Record<string, React.ElementType> = {};

S.Uploader = styled.div`
  border: ${props => props.theme.borderWidth[1]} dashed
    ${props => props.theme.borderColor.default};
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${props => props.theme.spacing.LG} ${props => props.theme.spacing.MD}
    ${props => props.theme.spacing['2XL']};
  box-sizing: border-box;
  cursor: pointer;
`;

S.Icon = styled.div`
  margin-bottom: ${props => props.theme.spacing.LG};
`;

S.Label = styled(Ag.ParagraphMD)`
  margin-bottom: ${props => props.theme.spacing.MD};
  display: none;

  ${media.MD} {
    display: block;
  }
`;

S.LabelMobile = styled(S.Label)`
  display: block;

  ${media.MD} {
    display: none;
  }
`;

S.FileInput = styled.input`
  display: none;
`;

S.HelperText = styled(Ag.ParagraphSM)`
  color: ${props => props.theme.contentColor.subdued};
  padding-top: ${props => props.theme.spacing.XS};
`;

S.Button = styled.div`
  pointer-events: none;
`;

export default S;
