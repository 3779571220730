import React from 'react';
import type {Placement} from '@floating-ui/react';
// Components
import Button from '@brightlive/shared/components/Button';
import {Sizes} from '@brightlive/shared/components/IconButton';
// Style
import S, {Size} from './style';

export interface TooltipProps {
  text: string | React.ReactNode;
  width?: string;
  position?: Placement;
  inverse?: boolean;
  callout?: boolean;
  size?: Size;
  btnSize?: Sizes;
  dismiss?: Function;
}

const Tooltip = ({
  text,
  width,
  position = 'top',
  inverse = true,
  callout = false,
  size = 'small',
  btnSize = 'medium',
  dismiss,
}: TooltipProps) => {
  const Text = size === 'small' ? S.SmallText : S.LargeText;
  return (
    <S.TooltipWrapper>
      <S.Tooltip
        width={width}
        $callout={callout}
        $size={size}
        $inverse={inverse}
      >
        <Text $inverse={inverse} $callout={callout}>
          {text}
        </Text>
        {dismiss && (
          <S.ButtonWrapper>
            <Button
              type="tertiary inverse"
              size="small"
              text="Got it"
              onClick={dismiss}
            />
          </S.ButtonWrapper>
        )}
        <S.Caret
          $position={position}
          $inverse={inverse}
          $callout={callout}
          $size={btnSize}
        />
      </S.Tooltip>
    </S.TooltipWrapper>
  );
};

export default React.memo(Tooltip);
