import React, {useEffect, useRef, useState} from 'react';
import {collection, getFirestore, query, orderBy} from 'firebase/firestore';
import {useCollection} from 'react-firebase-hooks/firestore';
import {useDispatch} from 'react-redux';
// Components
import {RoomState} from 'bright-livekit';
import RecordingItem from 'components/recordings/RecordingItem';
// Actions
import {toggleToast} from 'redux/ui/actions';
// Styles
import S from './style';

export const RecordingsView = ({roomState}: {roomState: RoomState}) => {
  const dispatch = useDispatch();
  const listContainer = useRef<HTMLDivElement>(null);
  const [numRecordings, setNumRecordings] = useState(0);
  const [processingRecordings, setProcessingRecordings] = useState<
    Array<string>
  >([]);
  const [showEmpty, setShowEmpty] = useState(false);
  const [recordingsCollection] = useCollection(
    query(
      collection(getFirestore(), `sessions/${roomState.session.id}/recordings`),
      orderBy('createdAt', 'desc')
    )
  );

  useEffect(() => {
    if (recordingsCollection?.empty && !recordingsCollection?.docs?.length) {
      setShowEmpty(true);
    }
    // Clear all unseen recordings
    if (
      recordingsCollection?.docs.length &&
      recordingsCollection?.docs.length > 0
    ) {
      // Save the last recording seen while this component is being viewed
      const latestRecording =
        recordingsCollection?.docs[
          recordingsCollection?.docs.length - 1
        ].data();
      if (latestRecording.createdAt) {
        // Set to session storage in the event of refresh or close tab
        roomState.setLastSeenRecordingIndex(
          recordingsCollection?.docs.length - 1
        );
      }
    }
    const processing: Array<string> = [];
    let savedRecordingsCount = 0;
    // Find recordings with assets that are still processing
    for (const recording of recordingsCollection?.docs || []) {
      const recordingData = recording.data();
      if (recordingData.deleted) continue;
      for (const asset in recordingData.assets) {
        if (
          recordingData.assets[asset] &&
          !recordingData.assets[asset].staticAssetsReady &&
          !processing.includes(recording.id)
        ) {
          processing.push(recording.id);
        }
      }
      savedRecordingsCount++;
    }
    // Check if any assets are newly finished meaning they exist in the processingRecordings state object but not in the newly computed processing array
    const completed = processingRecordings.filter(r => !processing.includes(r));
    if (completed.length > 0) {
      const completedRecording = recordingsCollection?.docs.find(
        r => r.id === completed[0]
      );
      dispatch(
        toggleToast(
          true,
          `${
            completedRecording?.data().title || 'Recording'
          } finished processing`,
          'default',
          undefined,
          'top'
        )
      );
    }
    setProcessingRecordings(processing);

    // If there are new recordings now scroll to the top
    if (recordingsCollection && savedRecordingsCount > numRecordings) {
      listContainer.current?.scrollBy({
        top: listContainer.current.scrollHeight * -1,
      });
    }
    setNumRecordings(savedRecordingsCount || 0);
    if (savedRecordingsCount <= 0) {
      setShowEmpty(true);
    } else {
      setShowEmpty(false);
    }
  }, [recordingsCollection]);

  return (
    <>
      {!showEmpty && recordingsCollection?.docs?.length ? (
        <S.RecordingListContainer ref={listContainer}>
          {recordingsCollection?.docs.map(recording => {
            const recordingData = recording.data();
            // filter our deleted recordings
            if (recordingData.deleted) return;
            return (
              <S.Recording key={recording.id}>
                <RecordingItem
                  sessionID={roomState.session.id}
                  createdAt={recordingData.createdAt}
                  recordingID={recording.id}
                  title={recordingData.title}
                  timestamp={parseInt(recording.id)}
                  assets={recordingData.assets}
                />
              </S.Recording>
            );
          })}
        </S.RecordingListContainer>
      ) : null}
      {showEmpty &&
        recordingsCollection &&
        recordingsCollection?.docs?.length > 0 && (
          <S.NoRecordings>
            <S.NoRecordingsIcon>🎥</S.NoRecordingsIcon>
            <S.NoRecordingsText>No recordings yet</S.NoRecordingsText>
          </S.NoRecordings>
        )}
    </>
  );
};
