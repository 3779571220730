import {DefaultTheme} from 'styled-components';
import {backgroundColor as backgroundColorLight} from '../styles/theme/theme-light';
import {backgroundColor as backgroundColorDark} from '../styles/theme/theme-dark';

export const getTheme = (theme: DefaultTheme) => {
  if (theme.backgroundColor.body === backgroundColorLight.body) {
    return 'light';
  } else if (theme.backgroundColor.body === backgroundColorDark.body) {
    return 'dark';
  }
  return undefined;
};
