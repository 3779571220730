import styled, {DefaultTheme} from 'styled-components';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag from '@brightlive/shared/styles/typography-v3';

const S = {
  PermissionRequest: styled.div(
    (props: {$pageHeight: number; theme: DefaultTheme}) => `
  background-color: ${props.theme.backgroundColor.body};
  min-height: ${props.$pageHeight}px;
`
  ),
  PermissionRequestButton: styled.div`
    display: flex;
    justify-content: center;
  `,

  PermissionRequestPreview: styled.img`
    width: 100%;
    border-radius: ${props => props.theme.borderRadius['2XL']};
    margin-bottom: ${props => props.theme.spacing['2XL']};
  `,

  Breadcrumb: styled.div`
    margin-bottom: ${props => props.theme.spacing['3XL']};

    ${media.MD} {
      margin-bottom: 118px;
    }
  `,

  PermissionRequestEmoji: styled.div`
    text-align: center;
    margin-bottom: ${props => props.theme.spacing.MD};
  `,

  PermissionRequestTitle: styled(Ag.HeadingLG)`
    text-align: center;
    margin-bottom: ${props => props.theme.spacing.SM};
  `,

  PermissionRequestMessage: styled(Ag.ParagraphMD)`
    text-align: center;
    margin-bottom: ${props => props.theme.spacing['2XL']};
  `,

  Link: styled(Ag.Link)``,
  HelpCenterLink: styled(Ag.Link)`
    font-weight: ${props => props.theme.fontWeight.medium};
  `,
};
export default S;
