import {RemoteAudioTrack, Track, TrackPublication} from 'livekit-client';
import {LocalTrackPublication} from 'twilio-video';
import {TrackType} from '../BrightParticipant';
import {ITrack} from './ITrack';
import {ITrackPublication} from './ITrackPublication';
import {LivekitTrack} from './LivekitTrack';

export class LivekitTrackPublication implements ITrackPublication {
  private _livekitPublication: TrackPublication;
  private _track?: LivekitTrack;
  constructor(track: TrackPublication) {
    this._livekitPublication = track;
  }
  public get hasTrack(): boolean {
    return !!this._livekitPublication.track;
  }
  public get isSubscribed(): boolean {
    return (
      !!this._livekitPublication.track && this._livekitPublication.isSubscribed
    );
  }
  public get sid(): string | undefined {
    return this._livekitPublication.track?.sid;
  }
  public get isMuted(): boolean {
    return this._livekitPublication.isMuted;
  }
  public get isEnabled(): boolean {
    return this._livekitPublication.isEnabled;
  }
  public get isLocal(): boolean {
    return !!(this._livekitPublication as unknown as LocalTrackPublication)
      .unpublish;
  }
  public get audioHz(): number {
    return (
      this._livekitPublication.audioTrack?.mediaStreamTrack.getSettings()
        .sampleRate || 0
    );
  }
  public get framerate(): number {
    return (
      this._livekitPublication.videoTrack?.mediaStreamTrack.getSettings()
        .frameRate || 30
    );
  }
  public get simulcasted(): boolean {
    return this._livekitPublication.simulcasted || false;
  }
  public get currentBitrate(): number {
    return this._livekitPublication.track?.currentBitrate || -1;
  }
  public get dimensions(): {width: number; height: number} {
    return this._livekitPublication.dimensions || {width: 0, height: 0};
  }
  public get track(): ITrack | undefined {
    if (
      this._livekitPublication?.track &&
      !this._track?.isEqual(this._livekitPublication?.track)
    ) {
      this._track = new LivekitTrack(this._livekitPublication.track);
    }
    return this._track;
  }
  public get kind(): TrackType {
    switch (this._livekitPublication.kind) {
      case Track.Kind.Audio:
        return TrackType.Audio;
      case Track.Kind.Video:
        return TrackType.Video;
      default:
        return TrackType.Audio;
    }
  }
  public start() {
    if (this._livekitPublication.audioTrack instanceof RemoteAudioTrack) {
      this._livekitPublication.audioTrack.start();
    }
  }
}
