import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag from '@brightlive/shared/styles/typography-v3';
import styled from 'styled-components';

const S = {
  QuestionsContainer: styled.div`
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    ${media.MD} {
      display: block;
      height: 100%;
      padding: ${props => props.theme.spacing.MD};
      background-color: ${props => props.theme.backgroundColor.primary};
    }
  `,

  ChatMessages: styled.div`
    overflow-y: auto;
    height: 100%;
    margin-bottom: ${props => props.theme.spacing.XS};

    ${media.MD} {
      margin-bottom: 0;
    }
  `,

  EmptyContainer: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${media.MD} {
      height: 100%;
    }
  `,

  EmptyText: styled(Ag.ParagraphMD)`
    color: ${props => props.theme.contentColor.subdued};
  `,

  EmptyIconContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: ${props => props.theme.spacing.LG};
    font-size: 48px;
  `,
};
export default S;
