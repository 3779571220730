import styled from 'styled-components';

const S: Record<string, React.ElementType> = {};

S.Emoji = styled.span(
  (props: {$size?: number}) => `
  display: inline-block;
  ${props.$size ? `font-size: ${props.$size}px;` : ''}}
`
);

export default S;
