import styled, {DefaultTheme} from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';
import {getInputBorder} from '@brightlive/shared/helpers/style';

const S: Record<string, React.ElementType> = {};

const getPadding = (isFocused, hasValue, hasLabel, theme) => {
  const horizontalPadding = theme.spacing.MD;
  const verticalPadding =
    (hasLabel && isFocused) || (hasLabel && hasValue)
      ? theme.spacing.XS
      : theme.spacing.MD;
  return `${verticalPadding} ${horizontalPadding} ${verticalPadding} ${horizontalPadding}`;
};

S.Border = styled.div(
  (props: {
    $isFocused: boolean;
    $error: boolean;
    disabled: boolean;
    theme: DefaultTheme;
  }) => `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: ${props.theme.borderRadius.MD};
  border: ${getInputBorder(
    props.$isFocused,
    props.$error,
    props.disabled,
    props.theme
  )};
  pointer-events: none;
  `
);

S.WrapperOuter = styled.div`
  position: relative;
`;

S.Wrapper = styled.div(
  (props: {
    $isFocused: boolean;
    $hasValue: boolean;
    $hasLabel: boolean;
    $error: boolean;
    disabled: boolean;
    theme: DefaultTheme;
  }) => `
  position: relative;
  padding: ${getPadding(
    props.$isFocused,
    props.$hasValue,
    props.$hasLabel,
    props.theme
  )};
  background-color: ${
    props.disabled
      ? props.theme.backgroundColor.disabled
      : props.theme.backgroundColor.primary
  };
  border-radius: ${props.theme.borderRadius.MD};
  display: flex;
  justify-content: space-between;
  align-items: center;
`
);

S.Content = styled.div`
  flex: 1;
  text-align: left;
`;

S.WrapperInner = styled.div`
  display: flex;
  flex: 1;
`;

S.Label = styled(Ag.ParagraphXS)(
  (props: {$hasValue: boolean; disabled: boolean; theme: DefaultTheme}) => `
  color: ${
    props.disabled
      ? props.theme.contentColor.disabled
      : props.theme.contentColor.default
  };
  display: ${props.$hasValue ? 'block' : 'none'};
  z-index: 10;
`
);

S.TextInput = styled(Ag.ParagraphMD)(
  (props: {$isDate: boolean; disabled: boolean; theme: DefaultTheme}) => `
    border: none;
    padding: 0;
    margin: 0;
    color: ${
      props.disabled
        ? props.theme.contentColor.disabled
        : props.theme.contentColor.default
    };
    text-transform: ${props.$isDate ? 'uppercase' : 'none'};
    flex: 1;
    background: transparent;

    &::placeholder {
      color: ${
        props.disabled
          ? props.theme.contentColor.disabled
          : props.theme.contentColor.subdued
      };
    }

    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }

    &::-webkit-date-and-time-value {
      text-align: left;
      background-color: transparent;
    }

    &:valid {
      background-color: transparent;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }

    &:invalid::-webkit-datetime-edit {
      color: ${
        props.disabled
          ? props.theme.contentColor.disabled
          : props.theme.contentColor.subdued
      };
    }

    &[type="date"]{
      -webkit-appearance: none;
    }
  }
`
);

S.HelperText = styled(Ag.ParagraphSM)`
  color: ${props => props.theme.contentColor.subdued};
  padding-top: ${props => props.theme.spacing.XS};
`;

S.Prefix = styled(Ag.ParagraphMD)(
  (props: {disabled: boolean; theme: DefaultTheme}) => `
  && {
    margin-right: ${props.theme.spacing['2XS']};
    color: ${
      props.disabled
        ? props.theme.contentColor.disabled
        : props.theme.contentColor.default
    };
  }
`
);

S.Icon = styled.div`
  margin-left: ${props => props.theme.spacing.MD};
`;

S.CharCount = styled(Ag.ParagraphXS)(
  (props: {disabled: boolean; theme: DefaultTheme}) => `
  margin-left: ${props.theme.spacing.SM};
  color: ${
    props.disabled
      ? props.theme.contentColor.disabled
      : props.theme.contentColor.subdued
  };
`
);

export default S;
