import styled, {DefaultTheme} from 'styled-components';
import {media} from '../../../shared/styles/breakpoints-v3';

const S = {
  Modal: styled.div(
    (props: {mobileCentered: boolean; embed: boolean}) => `
  ${
    !props.embed
      ? `position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;`
      : 'position: relative'
  };
  display: flex;
  justify-content: center;
  z-index: 3005;
  align-items: ${props.mobileCentered ? 'center' : 'flex-end'};

  ${media.MD} {
    align-items: center;
  }
`
  ),

  BackgroundOverlay: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => props.theme.backgroundColor.scrim};
    cursor: pointer;
    z-index: 1;
  `,

  ModalWrapper: styled.div(
    (props: {
      mobileHeight: string;
      desktopHeight: string;
      desktopWidth: string;
      mobileCentered: boolean;
      theme: DefaultTheme;
    }) => `
    max-height: 100vh;
    overflow-y: auto;
    background: ${props.theme.backgroundColor.primary};
    position: relative;
    z-index: 2;
    width: ${props.mobileCentered ? 'calc(100vw - 30px)' : '100%'};
    height: ${props.mobileHeight || 'auto'};
    box-sizing: border-box;
    border-top-left-radius: ${props.mobileHeight === '100%' ? '0' : '15px'};
    border-top-right-radius: ${props.mobileHeight === '100%' ? '0' : '15px'};
    border-bottom-left-radius: ${props.mobileCentered ? '15px' : '0'};
    border-bottom-right-radius: ${props.mobileCentered ? '15px' : '0'};

    ${media.MD} {
      max-height: 95vh;
      width: ${props.desktopWidth || '500px'};
      height: ${props.desktopHeight || 'auto'};
      min-width: ${props.desktopWidth || '500px'};
      max-width: calc(100vw - ${props.theme.spacing['2XL']} * 2);
      border-radius: 10px;
    }
`
  ),

  ModalWrapperInner: styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: ${props => props.theme.elevation.level5};
  `,

  TopWrapper: styled.div`
    background: ${props => props.theme.backgroundColor.primary};
    position: relative;
    padding: ${props => props.theme.spacing.XS};
    display: flex;
    justify-content: space-between;
  `,

  IconPlaceholder: styled.div`
    height: 48px;
  `,

  ConfettiWrapper: styled.div`
    flex: 1;
    overflow: hidden;
    position: absolute;
    height: 100%;
    width: 100%;
  `,

  ContentWrapper: styled.div(
    (props: {overflowMobile: boolean; theme: DefaultTheme}) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  position: relative;
  z-index: 100;
  padding: 0 ${props.overflowMobile ? '0' : props.theme.margin.mobile.page};
  overflow: ${props.overflowMobile ? 'visible' : 'hidden'};

  & > div:first-child {
    padding-bottom: 40px;
  }

  ${media.MD} {
    padding: 0 40px;
    overflow: hidden;

    & > div:first-child {
      padding-bottom: ${props.theme.spacing['3XL']};
    }
  }
`
  ),

  Buttons: styled.div`
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: ${props => props.theme.borderWidth[1]} solid
      ${props => props.theme.borderColor.subdued};
    background: ${props => props.theme.backgroundColor.primary};

    ${media.MD} {
      padding: 24px 40px;
    }
  `,

  BottomContent: styled.div`
    padding: ${props => props.theme.spacing.MD};
    border-top: ${props => props.theme.borderWidth[1]} solid
      ${props => props.theme.borderColor.subdued};
    background: ${props => props.theme.backgroundColor.primary};

    ${media.MD} {
      padding: 24px 40px;
    }
  `,

  CancelButton: styled.div`
    margin-right: 16px;
  `,
};
export default S;
