import {Backends, IVideoService} from 'bright-livekit';
import {LivekitVideoUtils} from './utils/LivekitVideoUtils';
import {VideoUtils} from './utils/VideoUtils';
import {TwilioVideoUtils} from './utils/TwilioVideoUtils';
import {LivekitVideoService} from './video/LivekitVideoService';
import {TwilioVideoService} from './video/TwilioVideoService';

export class VideoServiceFactory {
  static getVideoService(backend: Backends): IVideoService {
    switch (backend) {
      case Backends.Livekit:
        return new LivekitVideoService();
      case Backends.Twilio:
        return new TwilioVideoService();
    }
  }
  static getVideoUtils(backend: Backends): VideoUtils {
    switch (backend) {
      case Backends.Livekit:
        return new LivekitVideoUtils();
      case Backends.Twilio:
        return new TwilioVideoUtils();
    }
  }
}
