import React, {
  CSSProperties,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import {Property} from 'csstype';
// eslint-disable-next-line node/no-extraneous-import
import {AspectRatio} from 'react-aspect-ratio';
import {useDispatch, useSelector} from 'react-redux';
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
import {withTheme, DefaultTheme} from 'styled-components';
// Components
import {
  ParticipantProps,
  Role,
  TrackType,
  useParticipant,
  VideoRenderer,
  ViewMode,
  NameDisplay,
} from 'bright-livekit';
import Button from '@brightlive/shared/components/Button';
import Avatar from '@brightlive/shared/components/Avatar';
import Menu from '@brightlive/shared/components/Menu';
import IconButton from '@brightlive/shared/components/IconButton';
// Actions
import {AuthReducer} from 'redux/auth/reducer';
import {toggleFlagUserModal} from 'redux/livekit/actions';
import {toggleAlertBanner, toggleToast} from 'redux/ui/actions';
// Hooks
import {useInterval} from '@brightlive/shared/hooks/useInterval';
import {useEventListener} from 'usehooks-ts';
// Styles
import S from './style';
import {
  getHeight,
  getHeightMobile,
  getMargin,
  getWidth,
  getWidthMobile,
} from 'bright-livekit/styles/layout';

const SessionStageParticipantViewComponent = ({
  participant,
  shareHeight,
  shareWidth,
  aspectWidth,
  aspectHeight,
  stageSize,
  totalStageParticipants,
  stagePosition,
  modControls,
  sessionID,
  roomState,
  mode = 'desktop',
  condensedView = false,
  visible = true,
  borderRadius,
  theme,
}: ParticipantProps & {theme: DefaultTheme}) => {
  const authToken =
    useSelector((state: AuthReducer) => state.auth.auth.authToken) || '';
  const dispatch = useDispatch();
  const {isMobile} = useWindowSize();
  const {
    cameraPublication,
    isLocal,
    isAudioMuted,
    isVideoMuted,
    isAudioSubscribed,
    isVideoSubscribed,
  } = useParticipant(participant?.videoParticipant);
  const [showParticipantLeaveStage, setShowParticipantLeaveStage] =
    useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [buttonLoading, setButtonLoading] = useState('');
  const [checkAudioCount, setCheckAudioCount] = useState(0);
  const [checkVideoCount, setCheckVideoCount] = useState(0);
  const [unstableToastSeen, setUnstableToastSeen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const hasQuestion = !!participant?.question;
  const displayQuestion =
    hasQuestion && participant?.viewMode === ViewMode.Question;
  let x = 0;
  let y = 0;
  let height: Property.Height = shareHeight ?? 0;
  let width: Property.Width = shareWidth ?? height;

  useInterval(() => {
    const part = participant?.videoParticipant;
    if (!part || !roomState.videoUtils) {
      return;
    }
    if (!unstableToastSeen && part.connectionQuality === 'poor') {
      // Set banner if currentUser
      if (participant.id === roomState.currentUser.id) {
        dispatch(
          toggleAlertBanner(
            'Your internet connection is unstable',
            true,
            'warning'
          )
        );
      } else {
        if (modControls) {
          // Send toast otherwise
          dispatch(
            toggleToast(
              true,
              `${participant.displayName}'s internet connection unstable`,
              'default',
              undefined,
              'top'
            )
          );
        }
      }
      setUnstableToastSeen(true);
    } else if (unstableToastSeen && part.connectionQuality !== 'poor') {
      setUnstableToastSeen(false);
      dispatch(toggleAlertBanner('', false, 'warning'));
    }
  }, 1000);

  // Cleanup if recorder exits
  useEventListener('beforeunload', () => {
    if (roomState.isRecorder) {
      participant?.updateRecorderStatus({
        isAudioSubscribed: false,
        isVideoSubscribed: false,
        onStage: false,
      });
    }
  });

  // Cleanup/unmount control
  useEffect(
    () => () => {
      // On unmount reset recording status for this user
      if (roomState.isRecorder) {
        participant?.updateRecorderStatus({
          isAudioSubscribed: false,
          isVideoSubscribed: false,
          onStage: false,
        });
      }
    },
    []
  );

  useInterval(() => {
    // Only moderators and recorders need to report user recording status
    if (!roomState.isRecorder && !roomState.modControls) {
      return;
    }
    // If the participant is not set this is not a real user (fake user for testing, do not report)
    if (!participant?.videoParticipant) {
      return;
    }
    const vidTrack = participant.videoParticipant?.getTrack(TrackType.Video);
    const audioTrack = participant.videoParticipant?.getTrack(TrackType.Audio);
    // If this is a recorder we want to report this stage user's connection status to firestore
    if (roomState.isRecorder) {
      participant.updateRecorderStatus({
        connectionQuality: participant.videoParticipant.connectionQuality,
        audioBitrate: Math.ceil(audioTrack?.currentBitrate ?? 0),
        videoBitrate: Math.ceil(vidTrack?.currentBitrate ?? 0),
        videoResolution: vidTrack?.dimensions?.height ?? 0,
        videoFramerate: Math.ceil(vidTrack?.framerate ?? 0),
        audiokHz: Math.ceil(audioTrack?.audioHz ?? 0),
      });
      // If this is a moderator we want to compare the local status of the user's audio and video to the recorder's status
    } else {
      // Check the user's audio and video, if they are subscribed here locally but not on the server send an alert
      const hasVideoRecordingProblem =
        (participant.recordingStatus.isVideoMuted && !isVideoMuted) ||
        (!participant.recordingStatus.isVideoSubscribed && isVideoSubscribed);
      const hasAudioRecordingProblem =
        (participant.recordingStatus.isAudioMuted && !isAudioMuted) ||
        (!participant.recordingStatus.isAudioSubscribed && isAudioSubscribed);
      if (roomState.isRecording && hasVideoRecordingProblem) {
        setCheckVideoCount(checkVideoCount + 1);
      } else {
        setCheckVideoCount(0);
        participant.errorMessage = '';
      }
      if (roomState.isRecording && hasAudioRecordingProblem) {
        setCheckAudioCount(checkAudioCount + 1);
      } else {
        setCheckAudioCount(0);
        if (!hasVideoRecordingProblem) {
          participant.errorMessage = '';
        }
      }
    }
  }, 1000);

  useEffect(() => {
    // Only mods get alerts
    if (!roomState.modControls) {
      return;
    }
    // Check for audio or video problems, we check every second so if the error is present for over 30 seconds send the alert
    let problem = '';
    if (checkVideoCount > 30) {
      problem = 'video';
    }
    if (checkAudioCount > 30) {
      problem += `${problem ? ' and ' : ''}audio`;
    }
    if (problem && participant) {
      participant.errorMessage = `Error recording this user's ${problem}`;
    }
  }, [checkVideoCount, checkAudioCount]);

  useInterval(() => {
    const part = participant?.videoParticipant;
    if (!part || !roomState.videoUtils) {
      return;
    }
    if (!unstableToastSeen && part.connectionQuality === 'poor') {
      // Set banner if currentUser
      if (participant.id === roomState.currentUser.id) {
        dispatch(
          toggleAlertBanner(
            'Your internet connection is unstable',
            true,
            'warning'
          )
        );
      } else {
        if (modControls) {
          // Send toast otherwise
          dispatch(
            toggleToast(
              true,
              `${participant.displayName}'s internet connection unstable`,
              'default',
              undefined,
              'top'
            )
          );
        }
      }
      setUnstableToastSeen(true);
    } else if (unstableToastSeen && part.connectionQuality !== 'poor') {
      setUnstableToastSeen(false);
      dispatch(toggleAlertBanner('', false, 'warning'));
    }
  }, 1000);

  if (!participant || !participant.videoParticipant || !roomState.videoUtils) {
    return <></>;
  }

  // If a user somehow makes it on to stage when the stage is already full do not render them
  if (
    stagePosition &&
    roomState.videoUtils &&
    stagePosition > roomState.videoUtils.MAX_STAGE_USERS - 1
  ) {
    return <></>;
  }

  // If too many users somehow make it on stage only render the correct max number of users
  if (
    totalStageParticipants &&
    totalStageParticipants > roomState.videoUtils.MAX_STAGE_USERS
  ) {
    totalStageParticipants = roomState.videoUtils.MAX_STAGE_USERS;
  }

  // If the current user is a recorder report each user's subscription status to firestore
  if (roomState.isRecorder && participant.firestore) {
    const updatedStatus = {
      isAudioSubscribed,
      isVideoSubscribed,
      isAudioMuted,
      isVideoMuted,
      onStage: true,
    };
    participant.updateRecorderStatus(updatedStatus);
  }

  let containerStyles: CSSProperties = {
    left: 0,
    top: 0,
    height: '100px',
    width: '100px',
    position: 'relative',
    backgroundColor: theme.backgroundColor.secondary,
  };

  if (mode === 'mobile' && stageSize && totalStageParticipants) {
    containerStyles = {
      left: 0,
      top: 0,
      height:
        getHeightMobile(
          stageSize,
          totalStageParticipants - 1,
          stagePosition || 0
        ) + 'px',
      width:
        getWidthMobile(
          stageSize,
          totalStageParticipants - 1,
          stagePosition || 0
        ) + 'px',
      position: 'relative',
      backgroundColor: theme.backgroundColor.secondary,
    };
  } else if (mode === 'share') {
    containerStyles = {
      height: height,
      minHeight: height,
      width: width,
      minWidth: width,
      position: 'relative',
      backgroundColor: theme.backgroundColor.secondary,
    };
  } else if (mode === 'desktop') {
    if (
      stageSize &&
      typeof stagePosition !== 'undefined' &&
      stagePosition >= 0 &&
      totalStageParticipants
    ) {
      width =
        getWidth(
          roomState.layout,
          stageSize,
          totalStageParticipants - 1,
          stagePosition
        ) + 'px';
      height =
        getHeight(
          roomState.layout,
          stageSize,
          totalStageParticipants - 1,
          stagePosition
        ) + 'px';
      const margin = getMargin(
        roomState.layout,
        stageSize,
        totalStageParticipants - 1,
        stagePosition
      );
      x = margin.Left;
      y = margin.Top;
    }

    containerStyles = {
      width: width,
      height: height,
      left: x,
      top: y,
      position: 'absolute',
      backgroundColor: theme.backgroundColor.secondary,
    };
  }
  containerStyles.visibility = visible ? 'visible' : 'hidden';

  const objectFit: Property.ObjectFit = 'cover';
  let mainElement: ReactElement;

  if (
    cameraPublication?.isSubscribed &&
    cameraPublication?.track &&
    !cameraPublication?.isMuted
  ) {
    mainElement = (
      <VideoRenderer
        track={cameraPublication.track}
        isLocal={isLocal}
        objectFit={objectFit}
        name={participant.id}
        width="100%"
        height="100%"
        borderRadius={borderRadius}
      />
    );
  } else {
    const getAvatarSize = () => {
      if (condensedView) return 'medium';
      const height = containerRef.current?.clientHeight ?? 100;
      const width = containerRef.current?.clientWidth ?? 100;
      if (width < 105 || height < 105) return 'small';
      if (width < 130 || height < 130) return 'medium';
      if (width < 200 || height < 200) return 'large';

      return 'xlarge';
    };
    mainElement = (
      <S.AttendeeAvatarContainer
        $borderTopLeftRadius={borderRadius?.topLeft}
        $borderTopRightRadius={borderRadius?.topRight}
        $borderBottomLeftRadius={borderRadius?.bottomLeft}
        $borderBottomRightRadius={borderRadius?.bottomRight}
        $brandColor={roomState.brandColor}
      >
        <Avatar
          size={getAvatarSize()}
          avatar={participant.avatar}
          displayName={participant.displayName}
        />
      </S.AttendeeAvatarContainer>
    );
  }

  const ShowMoreMenuItems = () => {
    const items: {
      label?: string;
      isLine?: boolean;
      disabled?: boolean;
      onClick?: Function;
    }[] = [
      {
        label: 'Take offstage',
        onClick: () => {
          setButtonLoading('takeOffstage');

          roomState.videoUtils?.removeFromStage(
            sessionID,
            roomState.currentUser.id,
            participant.id,
            !!roomState.modControls
          );
          setShowOptions(!showOptions);
        },
      },
    ];
    if (mode !== 'share' && hasQuestion) {
      items.push({
        label: `${
          participant.viewMode === ViewMode.Question ? 'Hide' : 'Show'
        } Comment`,
        onClick: () => {
          roomState.videoUtils?.changeViewMode(
            sessionID,
            participant.id,
            participant.viewMode === ViewMode.Question
              ? ViewMode.Video
              : ViewMode.Question
          );
          setShowOptions(!showOptions);
        },
      });
    }
    if (participant.id !== roomState.currentUser.id) {
      items.push({
        label: isAudioMuted ? 'Ask to unmute' : 'Mute',
        onClick: () => {
          if (isAudioMuted) {
            roomState.videoUtils?.askToUnmute(
              sessionID,
              roomState.currentUser.id,
              participant.id
            );
          } else {
            roomState.videoUtils?.muteUser(
              sessionID,
              participant.id,
              'audio',
              authToken
            );
          }
          setShowOptions(!showOptions);
        },
      });
    } else {
      items.push({
        label: isAudioMuted ? 'Unmute' : 'Mute',
        onClick: () => {
          roomState.videoService?.localParticipant?.setMicrophoneEnabled(
            isAudioMuted
          );
        },
      });
    }
    items.push({isLine: true});
    items.push({
      label: 'Flag',
      onClick: () =>
        dispatch(
          toggleFlagUserModal({toggled: true, participant: participant})
        ),
    });
    return items;
  };

  const showSelfControls =
    roomState.currentUser.id === participant.id &&
    roomState.selfParticipant?.role !== Role.Creator &&
    !roomState.modControls;

  const getModControls = () => {
    if (!showSelfControls && modControls) {
      if (showOverlay && !isMobile && participant.role !== Role.Creator) {
        return (
          <S.ParticipantHoverContainer $background={false}>
            <S.ParticipantMoreSection $mode={mode}>
              <IconButton
                icon="AndroidMore"
                size="medium"
                type="tertiary inverse"
                onClick={() => setShowOptions(!showOptions)}
              ></IconButton>
              {showOptions && (
                <S.ParticipantMoreOptions
                  position={mode === 'share' ? 'top-left' : 'bottom-left'}
                >
                  <Menu
                    type="default"
                    menuItems={ShowMoreMenuItems()}
                    isMobile={false}
                    desktopWidth="200px"
                    onClose={() => setShowOptions(false)}
                  />
                </S.ParticipantMoreOptions>
              )}
            </S.ParticipantMoreSection>
          </S.ParticipantHoverContainer>
        );
      }
      if (showOptions && isMobile) {
        return (
          <Menu
            type="default"
            menuItems={ShowMoreMenuItems()}
            isMobile={true}
            desktopWidth="200px"
            onClose={() => setShowOptions(false)}
          />
        );
      }
    }
    return;
  };

  const handleLeaveStage = () => {
    setButtonLoading('leaveStage');
    roomState.videoUtils?.removeFromStage(
      sessionID,
      roomState.currentUser.id,
      participant.id,
      !!roomState.modControls
    );
  };

  const getParticipantControls = () => {
    if (showSelfControls) {
      if (!isMobile) {
        return (
          <>
            {showOverlay && mode !== 'share' && (
              <S.ParticipantHoverContainer $background={true}>
                <Button
                  type="secondary"
                  size="medium"
                  text={'Leave stage'}
                  width="auto"
                  loading={buttonLoading === 'leaveStage'}
                  onClick={handleLeaveStage}
                />
              </S.ParticipantHoverContainer>
            )}
            {showOverlay && mode === 'share' && (
              <S.ParticipantHoverContainer>
                <IconButton
                  type="tertiary inverse"
                  size="medium"
                  icon="Close"
                  loading={buttonLoading === 'leaveStage'}
                  onClick={handleLeaveStage}
                />
              </S.ParticipantHoverContainer>
            )}
          </>
        );
      }
      if (isMobile && showParticipantLeaveStage) {
        return (
          <Menu
            type="default"
            menuItems={[
              {
                label: 'Leave stage',
                onClick: handleLeaveStage,
              },
            ]}
            isMobile={true}
            desktopWidth="200px"
            onClose={() => setShowOptions(false)}
          />
        );
      }
    }
    return;
  };

  const handleMobileContainerClick = e => {
    if (!showSelfControls && modControls && participant.role !== Role.Creator) {
      e.stopPropagation();
      setShowOptions(!showOptions);
    } else if (showSelfControls) {
      e.stopPropagation();
      setShowParticipantLeaveStage(!showParticipantLeaveStage);
    }
  };

  return (
    <div
      style={containerStyles}
      onMouseEnter={() => setShowOverlay(true)}
      onMouseLeave={() => {
        setShowOverlay(false);
        setShowOptions(false);
      }}
      onClick={isMobile ? handleMobileContainerClick : undefined}
      ref={containerRef}
    >
      {aspectWidth && aspectHeight && (
        <AspectRatio ratio={aspectWidth / aspectHeight}>
          {mainElement}
        </AspectRatio>
      )}
      {(!aspectWidth || !aspectHeight) && mainElement}
      {!condensedView && mode !== 'share' && (
        <NameDisplay
          type={roomState.nameDisplay}
          displayName={participant.displayName}
          brandColor={roomState.brandColor}
          nameDisplayVisible={roomState.nameDisplayVisible}
          isAudioMuted={isAudioMuted}
        />
      )}
      {displayQuestion && mode !== 'share' && (
        <S.ParticipantQuestionContainer>
          <S.ParticipantQuestionText>
            {participant.question}
          </S.ParticipantQuestionText>
        </S.ParticipantQuestionContainer>
      )}
      {/* Participant Controls */}
      {getParticipantControls()}
      {/* Creator/Moderator Controls */}
      {getModControls()}
    </div>
  );
};

export const SessionStageParticipantView = withTheme(
  SessionStageParticipantViewComponent
);
