import Color from 'tinycolor2';
// Helpers
import {Hex} from '@brightlive/shared/helpers/interfaces';

export const toRgb = (hex: string) => {
  const {r, g, b} = Color(hex).toRgb();
  return {r, g, b};
};

export const toRgbaString = (token: string, opacity: string | number) => {
  // safe guard against tokens that already are in rgba format
  if (token.indexOf('rgba') > -1) {
    return token;
  }
  // safe guard against tokens that are in rgb format
  if (token.indexOf('rgb') > -1) {
    token = token.replace('rgb', 'rgba');
    return token.replace(')', `, ${opacity})`);
  }
  const {r, g, b} = toRgb(token);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const contrastRatio = (luminance1, luminance2) => {
  const lighterLum = Math.max(luminance1, luminance2);
  const darkerLum = Math.min(luminance1, luminance2);

  return (lighterLum + 0.05) / (darkerLum + 0.05);
};

const luminance = ({r, g, b}: {r: number; g: number; b: number}) => {
  const [lumR, lumG, lumB] = [r, g, b].map(component => {
    const proportion = component / 255;

    return proportion <= 0.03928
      ? proportion / 12.92
      : Math.pow((proportion + 0.055) / 1.055, 2.4);
  });

  return 0.2126 * lumR + 0.7152 * lumG + 0.0722 * lumB;
};

export const contrastIsLight = ({
  whiteColor,
  baseColor,
}: {
  whiteColor: Hex;
  baseColor: Hex;
}) => {
  const [luminance1, luminance2] = [baseColor, whiteColor].map(color => {
    const rgb = toRgb(color);
    return luminance(rgb);
  });
  const ratio = contrastRatio(luminance1, luminance2);
  const minRatio = 4.5;
  return ratio >= minRatio;
};
