import React, {ReactElement} from 'react';
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
import {isMobileOnly} from 'react-device-detect';
// Components
import {
  StageProps,
  MobileStage,
  DesktopStage,
  Role,
  RecordingStage,
} from 'bright-livekit';
// Styles
import S from 'bright-livekit/styles/style';
import {ITrackPublication} from 'bright-livekit/types/track/ITrackPublication';
import {AudioRenderer} from '../AudioRenderer';

export const StageView = (stageProps: StageProps) => {
  // State
  const {isMobile} = useWindowSize();

  let mainElement: ReactElement;

  if (stageProps.isRecorder) {
    mainElement = <RecordingStage {...stageProps} />;
  } else if (isMobile || isMobileOnly) {
    // ensures mobile landscape renders mobile stage
    mainElement = <MobileStage {...stageProps} />;
  } else {
    mainElement = <DesktopStage {...stageProps} />;
  }

  // Render out all audio tracks of on stage participants who are not the current user
  const audioTracks: ITrackPublication[] = [];

  // Only render audio if user is in session
  if (stageProps.roomState.sessionState === 'in-session') {
    for (const part of stageProps.roomState.participantsPresent) {
      if (
        part.id !== stageProps.roomState.selfParticipant?.id &&
        (part.role === Role.OnStage || part.role === Role.Creator)
      ) {
        for (const track of part.videoParticipant?.audioTracks || []) {
          if (!track.isEnabled) {
            track.start();
          }
          audioTracks.push(track);
        }
      }
    }
  }

  return (
    <S.StageViewContainer
      $fillScreen={stageProps.roomState.sessionState === 'in-session'}
    >
      {mainElement}
      {audioTracks.map(
        (track, index) =>
          track.track && (
            <AudioRenderer
              key={`${index}-${track.sid}`}
              track={track.track}
              isLocal={false}
            />
          )
      )}
      {stageProps.roomState.screenShareAudioTrack?.track && (
        <AudioRenderer
          key={`ss-${stageProps.roomState.screenShareAudioTrack.track.sid}`}
          track={stageProps.roomState.screenShareAudioTrack.track}
          isLocal={
            stageProps.roomState.screenShareUser ===
            stageProps.roomState.selfParticipant?.id
          }
        />
      )}
    </S.StageViewContainer>
  );
};
