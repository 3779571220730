import React, {useState} from 'react';
import {RoomState} from 'bright-livekit/types/RoomState';
// Components
import IconButton from '@brightlive/shared/components/IconButton';
import TooltipWrapper from '@brightlive/shared/components/TooltipWrapper';
import {useParticipant, Role, OnboardingSteps} from 'bright-livekit';
import {ErrorModal} from '../ErrorModal';
// Redux
import {IVideoService} from 'bright-livekit/services/video/IVideoService';
import {TrackingService} from 'bright-livekit/services/TrackingService';
// Styles
import S from './style';

export interface ControlsPropsMobile {
  videoService: IVideoService;
  roomState: RoomState;
  enableScreenShare?: boolean;
  enableAudio?: boolean;
  enableVideo?: boolean;
  onStage?: boolean;
  handleLeaveSession: () => void;
  handleRecordingClick: () => void;
  toggleMoreOptions: () => void;
  cameraEnabled: boolean;
  toggleVideo: Function;
  onboardingStep: OnboardingSteps;
  continueOnboarding: (onboardingStep: OnboardingSteps) => void;
}

export const ControlsViewMobile = ({
  videoService,
  roomState,
  enableAudio = true,
  enableVideo = true,
  onStage,
  toggleMoreOptions,
  handleLeaveSession,
  handleRecordingClick,
  toggleVideo,
  onboardingStep,
  continueOnboarding,
}: ControlsPropsMobile) => {
  // State
  const {isAudioMuted, isVideoMuted} = useParticipant(
    videoService.localParticipant
  );
  const [errorMessage, setErrorMessage] = useState('');

  const toggleAudio = async () => {
    if (!videoService.localParticipant) {
      return;
    }
    if (roomState.selfParticipant?.joined && !onStage) {
      videoService.localParticipant?.setMicrophoneEnabled(false);
      return;
    }
    try {
      await videoService.localParticipant.setMicrophoneEnabled(isAudioMuted);
    } catch {
      setErrorMessage('Could not access your microphone');
      return;
    }
  };

  const MuteButton = () => {
    if (
      enableAudio &&
      roomState.selfParticipant?.role &&
      [Role.Creator, Role.OnStage].includes(roomState.selfParticipant.role)
    ) {
      return (
        <IconButton
          icon={!isAudioMuted ? 'MicOn' : 'MicOff'}
          size="medium"
          type="secondary"
          onClick={toggleAudio}
        />
      );
    }
    return;
  };

  const VideoButton = () => {
    if (enableVideo) {
      return (
        <IconButton
          icon={!isVideoMuted ? 'VideocamOn' : 'VideocamOff'}
          size="medium"
          type="secondary"
          onClick={async cameraEnabled => {
            TrackingService.fire({
              event: 'camera',
              currentUser: roomState.currentUser,
              data: {
                action: cameraEnabled ? 'on' : 'off',
                session_id: roomState.session.id,
              },
            });
            try {
              await toggleVideo();
            } catch {
              setErrorMessage('Could not access your camera');
            }
          }}
        />
      );
    }
    return;
  };

  const unreadCount = roomState.unreadQuestions + roomState.unreadChatMessages;

  return (
    <S.MobileControlsContainer>
      {MuteButton()}
      {VideoButton()}
      {roomState.modControls && (
        <S.ButtonWrapper>
          <TooltipWrapper
            tooltipContent="When you’re ready, hit the Record button to begin recording your podcast."
            position="top"
            delayMount={onboardingStep === 'record-start'}
            disableTooltip={onboardingStep !== 'record-start'}
            width="288px"
            callout={true}
            dismiss={() => continueOnboarding('layout')}
            size="large"
            referenceContent={
              <IconButton
                icon={roomState.isRecording ? 'StopCircle' : 'Record'}
                size="medium"
                type={roomState.isRecording ? 'negative' : 'secondary'}
                onClick={handleRecordingClick}
              />
            }
          />
        </S.ButtonWrapper>
      )}
      <S.ButtonWrapper>
        {onboardingStep === 'recordings' && (
          <TooltipWrapper
            tooltipContent="Congratulations on recording your first video! Hit the More button and choose “Recordings” to find your recordings."
            position="top-end"
            callout={true}
            delayMount={true}
            dismiss={() => {
              continueOnboarding(null);
            }}
            size="large"
            width="265px"
            referenceContent={<S.TooltipWrapper />}
          />
        )}
        <IconButton
          icon="AndroidMore"
          size="medium"
          type="secondary"
          onClick={toggleMoreOptions}
          topRightContent={
            unreadCount > 0 && (
              <S.UnreadFlag>
                {unreadCount > 99 ? '99+' : unreadCount}
              </S.UnreadFlag>
            )
          }
        />
      </S.ButtonWrapper>
      <IconButton
        icon="CallEnd"
        size="medium"
        type="negative"
        onClick={handleLeaveSession}
      />
      {errorMessage && (
        <ErrorModal
          message={errorMessage}
          closeModal={() => setErrorMessage('')}
        />
      )}
    </S.MobileControlsContainer>
  );
};
