import React, {useState, useEffect} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
// Components
import ImageCropper from '@brightlive/shared/components/ImageCropper';
import ImageCropperWithPreview from '@brightlive/shared/components/ImageCropperWithPreview';
// Types
import {Hex} from '@brightlive/shared/helpers/interfaces';

interface ImageFile extends File {
  preview: string;
}
interface ImageUploaderProps {
  baseImage: ImageFile | null;
  setBaseImage: Function;
  closeModal: Function;
  url: string;
  serverError: boolean;
  cropShape?: 'rect' | 'round';
  aspect: number;
  imageUploadPath: string;
  handleCropSuccess: Function;
  setServerError: Function;
  title?: string;
  subtitle?: string;
  handleBack?: Function;
  backIsVisible?: boolean;
  previewImage?: string;
  desktopWidth?: string;
  cropArea?: 'top' | 'bottom';
  imageBackgroundColor?: Hex;
}

const ImageUploader = ({
  baseImage,
  setBaseImage,
  closeModal,
  url,
  imageUploadPath,
  handleCropSuccess,
  serverError,
  setServerError,
  cropShape,
  aspect,
  title,
  subtitle,
  handleBack,
  backIsVisible = false,
  previewImage,
  desktopWidth,
  cropArea = 'bottom',
  imageBackgroundColor = '#252526',
}: ImageUploaderProps) => {
  const storageRef = firebase.storage().ref() || null;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (baseImage?.preview) URL.revokeObjectURL(baseImage.preview);
    };
  }, [baseImage]);

  const handleCrop = async blob => {
    setLoading(true);
    setServerError(false);
    const metadata = {
      contentType: blob.type,
    };
    try {
      await storageRef
        .child(`${imageUploadPath}${Date.now()}_avatar`)
        .put(blob, metadata)
        .then(snapshot => {
          // Push only the path to the storage bucket in case we need to swap CDN domain
          handleCropSuccess(snapshot.ref.fullPath);
          setLoading(false);
        });
    } catch {
      setServerError(true);
      setLoading(false);
    }
  };

  if (previewImage) {
    return (
      <ImageCropperWithPreview
        cropArea={cropArea}
        baseImage={baseImage}
        setBaseImage={setBaseImage}
        closeModal={closeModal}
        url={url}
        setServerError={setServerError}
        serverError={serverError}
        handleCrop={handleCrop}
        loading={loading}
        aspect={aspect}
        title={title}
        desktopWidth={desktopWidth}
        previewImage={previewImage}
        imageBackgroundColor={imageBackgroundColor}
      />
    );
  }

  return (
    <ImageCropper
      baseImage={baseImage}
      setBaseImage={setBaseImage}
      closeModal={closeModal}
      url={url}
      setServerError={setServerError}
      serverError={serverError}
      handleCrop={handleCrop}
      loading={loading}
      cropShape={cropShape}
      aspect={aspect}
      title={title}
      subtitle={subtitle}
      handleBack={handleBack}
      backIsVisible={backIsVisible}
      desktopWidth={desktopWidth}
    />
  );
};

export default ImageUploader;
