import {ITrack} from 'bright-livekit/types/track/ITrack';
import {Property} from 'csstype';
import React, {useEffect, useRef} from 'react';
// Styles
import S from './style';

export interface VideoRendererProps {
  track?: ITrack;
  isLocal: boolean;
  borderRadius?: {
    topLeft?: Property.BorderRadius;
    topRight?: Property.BorderRadius;
    bottomLeft?: Property.BorderRadius;
    bottomRight?: Property.BorderRadius;
  };
  objectFit?: Property.ObjectFit;
  className?: string;
  name?: string;
  width?: Property.Width;
  height?: Property.Height;
  maxHeight?: Property.MaxHeight;
}

export const VideoRenderer = ({
  track,
  isLocal,
  objectFit,
  width,
  height,
  maxHeight,
  borderRadius,
}: VideoRendererProps) => {
  const ref = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }
    el.muted = true;
    track?.attach(el);
    return () => {
      track?.detach(el);
    };
  }, [track, ref]);
  return (
    <S.Video
      width={width || 'inherit'}
      height={height || 'inherit'}
      $maxHeight={maxHeight || 'inherit'}
      $borderTopLeftRadius={borderRadius?.topLeft}
      $borderTopRightRadius={borderRadius?.topRight}
      $borderBottomLeftRadius={borderRadius?.bottomLeft}
      $borderBottomRightRadius={borderRadius?.bottomRight}
      $doRotate={isLocal}
      $objectFit={objectFit}
      ref={ref}
    />
  );
};
