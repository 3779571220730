import styled, {DefaultTheme} from 'styled-components';
import Imgix from 'react-imgix';
import Ag from '@brightlive/shared/styles/typography-v3';

import {Sizes} from './index';

const fontSizes = {
  small: '17.8px',
  medium: '40px',
  large: '53.3px',
  xlarge: '66.7px',
};

const T = {
  FlaggedBackground: styled.div(
    (props: {$videoMuted?: boolean; theme: DefaultTheme}) => `
border-radius: 50%;
position: ${props.$videoMuted ? 'absolute' : 'relative'};
bottom: ${props.$videoMuted ? '24px' : 'unset'};;
display: flex;
align-items: center;
justify-content: center;
padding: ${props.theme.spacing['2XS']};
border: 5px solid ${props.theme.borderColor.inversePrimary};
background-color: ${props.theme.backgroundColor.negative};
z-index: 2;
`
  ),
  Avatar: styled(Imgix)(
    (props: {$size: Sizes; alt?: string}) => `
  width: ${props.$size}px;
  height: ${props.$size}px;
  border-radius: 50%;
  max-width: 100%;
  max-height: 100%;
`
  ),
};

const S = {
  RelativeContainer: styled.div(
    (props: {$size: Sizes}) => `
  position: relative;
  width: ${props.$size}px;
  height: ${props.$size}px;
`
  ),

  Avatar: T.Avatar,

  AvatarCircle: styled(T.Avatar)(
    (props: {$color: {color: string; value: string}; theme: DefaultTheme}) => `
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${
    props.theme.color[props.$color.color][props.$color.value]
  };
  `
  ),

  StatusIconsWrapper: styled.div`
    position: absolute;
    bottom: -8px;
    right: -8px;
  `,

  VideoMutedBackground: styled.div(
    (props: {$shared?: boolean; theme: DefaultTheme}) => `
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props.$shared ? `-${props.theme.spacing.XS}` : 0};
  padding: ${props.theme.spacing['2XS']};
  border: 5px solid ${props.theme.borderColor.inversePrimary};
  background-color: ${props.theme.backgroundColor.tertiary};
  z-index: 1;
`
  ),

  FlaggedBackground: T.FlaggedBackground,

  ScreenShareBackground: styled(T.FlaggedBackground)`
    background-color: ${props => props.theme.backgroundColor.tertiary};
  `,

  Initials: styled(Ag.DisplayXL)(
    (props: {
      $size: Sizes;
      $color: {color: string; value: string};
      theme: DefaultTheme;
    }) => `
  font-size: ${
    typeof props.$size === 'string'
      ? fontSizes[props.$size]
      : `${props.$size * 0.55}px`
  };
  color: ${props.theme.color[props.$color.color][props.$color.value]};
`
  ),
};
export default S;
