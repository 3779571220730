import styled, {DefaultTheme} from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';

const S: Record<string, React.ElementType> = {};

const getTextAreaBorder = (
  isFocused: boolean,
  error: boolean,
  disabled: boolean,
  theme: DefaultTheme
) => {
  if (disabled) {
    return `${theme.borderWidth[1]} solid ${theme.borderColor.subdued}`;
  } else if (error && isFocused) {
    return `${theme.borderWidth[2]} solid ${theme.borderColor.negative}`;
  } else if (error) {
    return `${theme.borderWidth[1]} solid ${theme.borderColor.negative}`;
  } else if (isFocused) {
    return `${theme.borderWidth[2]} solid ${theme.borderColor.primary}`;
  } else {
    return `${theme.borderWidth[1]} solid ${theme.borderColor.default}`;
  }
};

const getPadding = (
  isFocused: boolean,
  hasValue: boolean,
  hasLabel: boolean,
  hasCounter: boolean,
  theme: DefaultTheme
) => {
  const sidePadding = theme.spacing.MD;
  let topPadding = theme.spacing.MD;
  let bottomPadding = theme.spacing.MD;
  if (hasCounter) bottomPadding = theme.spacing['2XL'];
  if ((hasLabel && isFocused) || (hasLabel && hasValue)) topPadding = '24px';
  return `${topPadding} ${sidePadding} ${bottomPadding}`;
};

S.TextAreaWrapper = styled.div`
  width: 100%;
  position: relative;
`;

S.TextAreaWrapperInner = styled.div(
  (props: {
    $isFocused: boolean;
    $hasValue: boolean;
    $hasLabel: boolean;
    $hasCounter: boolean;
    $hasError: boolean;
    disabled: boolean;
    theme: DefaultTheme;
  }) => `
  position: relative;
  padding: ${getPadding(
    props.$isFocused,
    props.$hasValue,
    props.$hasLabel,
    props.$hasCounter,
    props.theme
  )};
  border-radius: ${props.theme.borderRadius.MD};
  box-sizing: border-box;
  background-color: ${
    props.disabled
      ? props.theme.backgroundColor.disabled
      : props.theme.backgroundColor.primary
  };
  border: ${getTextAreaBorder(
    props.$isFocused,
    props.$hasError,
    props.disabled,
    props.theme
  )};
  height: 144px;
`
);

S.Label = styled(Ag.ParagraphXS)(
  (props: {
    $isFocused: boolean;
    $hasValue: boolean;
    disabled: boolean;
    theme: DefaultTheme;
  }) => `
  && {
    color: ${
      props.disabled
        ? props.theme.contentColor.disabled
        : props.theme.contentColor.default
    };
    display: ${props.$isFocused || props.$hasValue ? 'block' : 'none'};
    position: absolute;
    top: ${props.theme.spacing.XS};
    left: ${props.theme.spacing.MD};
  }
`
);

S.TextArea = styled(Ag.ParagraphMD)(
  (props: {disabled: boolean; theme: DefaultTheme}) => `
  && {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    resize: none;

    color: ${
      props.disabled
        ? props.theme.contentColor.disabled
        : props.theme.contentColor.default
    };

    &::placeholder {
      color: ${
        props.disabled
          ? props.theme.contentColor.disabled
          : props.theme.contentColor.subdued
      };
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
`
);

S.HelperText = styled(Ag.ParagraphSM)`
  && {
    color: ${props => props.theme.contentColor.subdued};
    padding-top: ${props => props.theme.spacing.XS};
  }
`;

S.Requirement = styled(Ag.ParagraphXS)`
  color: ${props => props.theme.contentColor.subdued};
  position: absolute;
  left: ${props => props.theme.spacing.MD};
  bottom: ${props => props.theme.spacing.MD};
`;

S.OverRequirement = styled(S.Requirement)`
  color: ${props => props.theme.contentColor.negative};
`;

export default S;
