import styled from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';

const S = {
  AttendeesListContainer: styled.div`
    background-color: ${props => props.theme.backgroundColor.primary};
    height: 100%;
    overflow-y: auto;
  `,
  OnStageAttendeesContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${props => props.theme.spacing.LG};
  `,
  OffStageAttendeesContainer: styled.div`
    display: flex;
    flex-wrap: wrap;
  `,
  AttendeesHeader: styled(Ag.HeadingSM)`
    margin-bottom: ${props => props.theme.spacing.MD};
  `,
  AttendeesHeaderSecondary: styled(Ag.HeadingSM)`
    margin-bottom: ${props => props.theme.spacing.MD};
    margin-top: ${props => props.theme.spacing['2XL']};
  `,
  AttendeesListHeader: styled(Ag.HeadingXS)`
    padding: ${props => props.theme.margin.mobile.page}
      ${props => props.theme.margin.mobile.page}
      ${props => props.theme.spacing.XS};
  `,
  NoAttendeesWrapper: styled(Ag.HeadingXS)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  `,
  NoAttendeesIcon: styled.p`
    font-size: ${props => props.theme.fontSize[900]};
    line-height: ${props => props.theme.fontSize[900]};
    margin-bottom: ${props => props.theme.spacing.MD};
  `,
  NoAttendeesText: styled(Ag.ParagraphMD)``,
};
export default S;
