import React from 'react';
// Style
import S from './style';

export interface SwitchProps {
  toggled: boolean;
  onToggle: Function;
  disabled?: boolean;
}

const variants = {
  on: {
    x: 24,
    transition: {
      duration: 0.1,
      ease: 'easeInOut',
    },
  },
  off: {
    x: 0,
    transition: {
      duration: 0.1,
      ease: 'easeInOut',
    },
  },
};

const Switch = ({toggled, onToggle, disabled = false}: SwitchProps) => {
  return (
    <S.Switch disabled={disabled} $toggled={toggled} onClick={onToggle}>
      <S.Knob
        initial={toggled ? 'on' : 'off'}
        animate={toggled ? 'on' : 'off'}
        variants={variants}
      />
    </S.Switch>
  );
};

export default React.memo(Switch);
