import React from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
import {IconProps} from '@brightlive/shared/helpers/interfaces';

const FormatListNumbered = ({
  size = 24,
  contentColor = 'default',
  theme,
  ...props
}: IconProps & {
  theme: DefaultTheme;
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M3.5 20a.48.48 0 0 1-.35-.15.48.48 0 0 1 0-.7c.1-.1.217-.15.35-.15H5v-.5h-.5a.48.48 0 0 1-.35-.15.48.48 0 0 1 0-.7c.1-.1.217-.15.35-.15H5V17H3.5a.48.48 0 0 1-.35-.15.48.48 0 0 1 0-.7c.1-.1.217-.15.35-.15h2c.133 0 .25.05.35.15.1.1.15.217.15.35v3a.48.48 0 0 1-.15.35.48.48 0 0 1-.35.15h-2Zm1-12a.48.48 0 0 1-.35-.15A.48.48 0 0 1 4 7.5V5h-.5a.48.48 0 0 1-.35-.15.48.48 0 0 1 0-.7c.1-.1.217-.15.35-.15h1c.133 0 .25.05.35.15.1.1.15.217.15.35v3a.48.48 0 0 1-.15.35.48.48 0 0 1-.35.15Zm-1 6a.48.48 0 0 1-.35-.15.48.48 0 0 1-.15-.35v-.225c0-.067.042-.175.125-.325L4.8 11H3.5a.48.48 0 0 1-.35-.15.48.48 0 0 1 0-.7c.1-.1.217-.15.35-.15h2c.133 0 .25.05.35.15.1.1.15.217.15.35v.225c0 .067-.042.175-.125.325L4.2 13h1.3c.133 0 .25.05.35.15a.48.48 0 0 1 0 .7.48.48 0 0 1-.35.15h-2ZM9 19a.965.965 0 0 1-.712-.288A.965.965 0 0 1 8 18c0-.283.096-.52.288-.712A.965.965 0 0 1 9 17h11c.283 0 .52.096.712.288A.965.965 0 0 1 21 18c0 .283-.096.52-.288.712A.965.965 0 0 1 20 19H9Zm0-6a.965.965 0 0 1-.712-.288A.965.965 0 0 1 8 12c0-.283.096-.521.288-.713A.967.967 0 0 1 9 11h11c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.712A.965.965 0 0 1 20 13H9Zm0-6a.967.967 0 0 1-.712-.287A.968.968 0 0 1 8 6c0-.283.096-.521.288-.713A.967.967 0 0 1 9 5h11c.283 0 .52.096.712.287.192.192.288.43.288.713a.968.968 0 0 1-.288.713A.967.967 0 0 1 20 7H9Z"
      fill={
        contentColor.indexOf('#') > -1
          ? contentColor
          : theme.contentColor[contentColor]
      }
    />
  </svg>
);

export default withTheme(FormatListNumbered);
