import styled from 'styled-components';
import {focus, active} from '@brightlive/shared/helpers/style';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag from '@brightlive/shared/styles/typography-v3';

const S = {
  PostSession: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 ${props => props.theme.margin.mobile.page};
    min-height: calc(
        100vh -
          (
            ${props => props.theme.sizing.mobile.navHeight} +
              ${props => props.theme.margin.mobile.page}
          )
      ),
      ${media.MD} {
      padding: 0;
      min-height: calc(
        100vh - ${props => props.theme.sizing.desktop.navHeight}
      );
    }
  `,

  Header: styled(Ag.HeadingMD)`
    margin-bottom: ${props => props.theme.spacing.XL};
    margin-top: ${props => props.theme.spacing['5XL']};
    text-align: center;
  `,

  Description: styled(Ag.ParagraphMD)`
    margin-bottom: ${props => props.theme.spacing.MD};
    text-align: center;
  `,

  StarWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${props => props.theme.spacing.XL};
  `,

  Star: styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: ${props => props.theme.spacing.SM}
  box-sizing: border-box;
  border-radius: 50%;
  ${props => focus({theme: props.theme, disabled: false, borderRadius: '50%'})}
  ${props =>
    active({
      backgroundColor: props.theme.actionColor.pressed,
      disabled: false,
      borderRadius: '50%',
    })}
`,

  TextAreaWrapper: styled.div`
    margin-bottom: ${props => props.theme.spacing['2XL']};
  `,

  ButtonWrapper: styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: ${props => props.theme.spacing['2XL']};
  `,

  Footer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${props => props.theme.spacing.MD};
    ${media.MD} {
      margin-bottom: ${props => props.theme.spacing['3XL']};
    }
  `,

  FooterText: styled(Ag.ParagraphMD)`
    text-align: center;
  `,

  FooterLink: styled(Ag.Link)``,
};
export default S;
