import styled, {DefaultTheme} from 'styled-components';
import type {Placement} from '@floating-ui/react';
import {toDropShadow} from '@brightlive/shared/helpers/elevationConverter';
import {
  getParagraphSM,
  getParagraphXS,
} from '@brightlive/shared/styles/typography-v3';

const CARET_SIZE = 6;

export type Size = 'small' | 'large';

const getPos = (theme, cssPos, userPos, size) => {
  const offsetSM = '10px';
  const offsetLG = '18px';
  const positions = {
    bottom: {
      top: `-${CARET_SIZE}px`,
      'top-start': `-${CARET_SIZE}px`,
      'top-end': `-${CARET_SIZE}px`,
      'right-start': `${CARET_SIZE}px`,
      'left-start': `${CARET_SIZE}px`,
      bottom: 'auto',
    },
    top: {
      top: 'auto',
      bottom: `-${CARET_SIZE}px`,
      'bottom-start': `-${CARET_SIZE}px`,
      'bottom-end': `-${CARET_SIZE}px`,
      'right-end': `${CARET_SIZE}px`,
      'left-end': `${CARET_SIZE}px`,
      left: '50%',
      right: '50%',
    },
    left: {
      top: '50%',
      bottom: '50%',
      'top-start': size === 'small' ? offsetSM : offsetLG,
      'bottom-start': size === 'small' ? offsetSM : offsetLG,
      left: 'auto',
      'left-start': 'auto',
      right: `-${CARET_SIZE}px`,
      'right-end': `-${CARET_SIZE}px`,
      'right-start': `-${CARET_SIZE}px`,
    },
    right: {
      right: 'auto',
      top: 'auto',
      'top-end': size === 'small' ? offsetSM : offsetLG,
      'bottom-end': size === 'small' ? offsetSM : offsetLG,
      left: `-${CARET_SIZE}px`,
      'left-start': `-${CARET_SIZE}px`,
      'left-end': `-${CARET_SIZE}px`,
    },
  };
  return positions[cssPos][userPos];
};

const getBorder = (theme, borderPos, pos, callout, inverse) => {
  const backgroundColor = getBackgroundColor(callout, theme, inverse);
  let left = 'none';
  let right = 'none';
  let bottom = 'none';
  let top = 'none';

  const positionTop = ['top', 'top-start', 'top-end'].includes(pos);
  const positionBottom = ['bottom', 'bottom-start', 'bottom-end'].includes(pos);
  const positionLeft = ['left', 'left-start', 'left-end'].includes(pos);
  const positionRight = ['right', 'right-start', 'right-end'].includes(pos);

  // bottom/top caret calculator
  if (positionTop || positionBottom) {
    left = `${CARET_SIZE}px solid transparent`;
    right = `${CARET_SIZE}px solid transparent`;
    if (positionTop) {
      top = `${CARET_SIZE}px solid ${backgroundColor}`;
    }
    if (positionBottom) {
      bottom = `${CARET_SIZE}px solid ${backgroundColor}`;
    }
  } else if (positionRight || positionLeft) {
    bottom = `${CARET_SIZE}px solid transparent`;
    top = `${CARET_SIZE}px solid transparent`;
    if (positionRight) {
      right = `${CARET_SIZE}px solid ${backgroundColor}`;
    }
    if (positionLeft) {
      left = `${CARET_SIZE}px solid ${backgroundColor}`;
    }
  }

  const border = {
    left,
    right,
    top,
    bottom,
  };

  return border[borderPos];
};

const getBackgroundColor = (
  callout: boolean,
  theme: DefaultTheme,
  inverse?: boolean
) => {
  if (callout) {
    return theme.actionColor.brand;
  }
  if (inverse) {
    return theme.backgroundColor.inversePrimary;
  } else {
    return theme.backgroundColor.primary;
  }
};

const getColor = (callout: boolean, theme: DefaultTheme, inverse?: boolean) => {
  if (callout || inverse) {
    return theme.contentColor.inverse;
  } else {
    return theme.contentColor.default;
  }
};

const getTransform = (position: string) => {
  if (['left', 'right'].includes(position)) {
    return 'translateY(-50%)';
  }
  if (['bottom', 'top'].includes(position)) {
    return 'translateX(-50%)';
  }
  return 'none';
};

const getWidth = (width, callout) => {
  if (width) return width;
  if (callout) return '288px';
  return 'auto';
};

const S = {
  TooltipWrapper: styled.div`
    position: relative;
  `,

  Tooltip: styled.div(
    (props: {
      width?: string;
      $callout: boolean;
      $size: Size;
      $inverse: boolean;
      theme: DefaultTheme;
    }) => `
    padding: ${
      props.$size === 'large' ? props.theme.spacing.SM : props.theme.spacing.XS
    };
    white-space: ${props.width || props.$callout ? 'normal' : 'nowrap'};
    background: ${getBackgroundColor(
      props.$callout,
      props.theme,
      props.$inverse
    )};
    box-sizing: border-box;
    border-radius: ${props.theme.borderRadius.SM};
    width: ${getWidth(props.width, props.$callout)};
    filter: ${toDropShadow(props.theme.elevation.level3)};
    will-change: filter;
    position: relative;
  `
  ),

  SmallText: styled.div(
    (props: {$callout: boolean; $inverse: boolean; theme: DefaultTheme}) => `
    ${getParagraphXS(props.theme)}
    color: ${getColor(props.$callout, props.theme, props.$inverse)};
  `
  ),

  LargeText: styled.div(
    (props: {$callout: boolean; $inverse: boolean; theme: DefaultTheme}) => `
    ${getParagraphSM(props.theme)}
    color: ${getColor(props.$callout, props.theme, props.$inverse)};
  `
  ),

  ButtonWrapper: styled.div`
    margin-top: ${props => props.theme.spacing.XS};
    display: flex;
    justify-content: flex-end;
  `,

  Caret: styled.div(
    (props: {
      $position: Placement;
      $size: 'small' | 'medium';
      $inverse: boolean;
      $callout: boolean;
      theme: DefaultTheme;
    }) => `
    width: 0;
    height: 0;

    border-left: ${getBorder(
      props.theme,
      'left',
      props.$position,
      props.$callout,
      props.$inverse
    )};
    border-right: ${getBorder(
      props.theme,
      'right',
      props.$position,
      props.$callout,
      props.$inverse
    )};
    border-top: ${getBorder(
      props.theme,
      'top',
      props.$position,
      props.$callout,
      props.$inverse
    )};
    border-bottom: ${getBorder(
      props.theme,
      'bottom',
      props.$position,
      props.$callout,
      props.$inverse
    )};
    position: absolute;
    top: ${getPos(props.theme, 'top', props.$position, props.$size)};
    bottom: ${getPos(props.theme, 'bottom', props.$position, props.$size)};
    left: ${getPos(props.theme, 'left', props.$position, props.$size)};
    right: ${getPos(props.theme, 'right', props.$position, props.$size)};
    transform: ${getTransform(props.$position)};
  `
  ),
};

export default S;
