import {useState, useEffect} from 'react';
import {WindowSize} from '../helpers/interfaces';
import {maxXS, minSM, minMD, minLG, minXL} from '../styles/breakpoints-v3';

// Hook
export const useWindowSize = (): WindowSize => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
    isMobile: true,
    isXSOnly: false,
    isSM: true,
    isMD: false,
    isLG: false,
    isXL: false,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth < minMD,
        isXSOnly: window.innerWidth <= maxXS,
        isSM: window.innerWidth >= minSM,
        isMD: window.innerWidth >= minMD,
        isLG: window.innerWidth >= minLG,
        isXL: window.innerWidth >= minXL,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};
