import {VideoRenderer, useParticipant} from 'bright-livekit';
import React from 'react';
import {RoomState} from 'bright-livekit';
// Components
import Avatar from '@brightlive/shared/components/Avatar';
// Icons
import Info from '@brightlive/shared/icons/Info';
// Styles
import S from './style';
import {theme} from '@brightlive/shared/styles/theme';

export interface MobileVideoPreviewProps {
  roomState: RoomState;
}

export const MobileVideoPreview = ({roomState}: MobileVideoPreviewProps) => {
  const {cameraPublication, isLocal} = useParticipant(
    roomState.selfParticipant?.videoParticipant
  );
  const Content = () => {
    if (
      cameraPublication?.track &&
      cameraPublication?.isEnabled &&
      !cameraPublication?.isMuted
    ) {
      return (
        <>
          <S.VideoWrapper>
            <VideoRenderer
              track={cameraPublication.track}
              isLocal={isLocal}
              objectFit="cover"
              width="100%"
              height={`calc(100vw - ${theme.spacing.MD} * 2)`}
            />
          </S.VideoWrapper>
          <S.PreviewTextWrapper>
            <Info size={20} contentColor="moreSubdued" />
            <S.PreviewText>
              Preview only. Your video will be seen by everyone after submitting
              a question, or when you’re on stage.
            </S.PreviewText>
          </S.PreviewTextWrapper>
        </>
      );
    } else {
      return (
        <>
          <S.NoVidTextWrapper>
            <Avatar
              size="large"
              avatar={roomState.selfParticipant?.avatar}
              displayName={roomState.selfParticipant?.displayName}
            />
          </S.NoVidTextWrapper>
          <S.PreviewTextWrapper>
            <Info size={20} contentColor="moreSubdued" />
            <S.PreviewText>
              Turn on camera to preview how you look
            </S.PreviewText>
          </S.PreviewTextWrapper>
        </>
      );
    }
  };
  return <S.VideoPreviewContainer>{Content()}</S.VideoPreviewContainer>;
};
