import React, {useState, useCallback, useEffect} from 'react';
import Cropper from 'react-easy-crop';
import {Point} from 'react-easy-crop/types';
// Components
import Modal from '../Modal';
import Alert from '../Alert';
import IconButton from '../IconButton';
import Slider from '../Slider';
// Helpers
import getCroppedImg from '../../helpers/cropImage';
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
import {Hex} from '@brightlive/shared/helpers/interfaces';
// Style
import S from './style';

interface ImageFile extends File {
  preview: string;
}

export interface ImageCropperProps {
  baseImage: ImageFile | null;
  setBaseImage: Function;
  closeModal: Function;
  url: string;
  setServerError: Function;
  serverError: boolean;
  loading: boolean;
  handleCrop: Function;
  cropShape?: 'rect' | 'round';
  aspect: number;
  backIsVisible?: boolean;
  previewImage: string;
  cropArea: 'top' | 'bottom';
  title?: string;
  subtitle?: string;
  desktopWidth?: string;
  imageBackgroundColor: Hex;
}

const MIN_ZOOM = 1;
const MAX_ZOOM = 2;

/**
 * ImageCropper
 *
 * @param   {object}  props
 *
 * @return  {JSX.Element}
 */
const ImageCropperWithPreview = ({
  baseImage,
  setBaseImage,
  closeModal,
  url,
  setServerError,
  serverError,
  loading,
  handleCrop,
  cropArea,
  aspect,
  backIsVisible = false,
  previewImage,
  title,
  desktopWidth,
  imageBackgroundColor,
}: ImageCropperProps) => {
  const {height: windowHeight = 0, width: windowWidth = 0} = useWindowSize();

  const [previewSize, setPreviewSize] = useState<{
    width: number;
    height: number;
  }>({width: 0, height: 0});
  const [crop, setCrop] = useState<Point>({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    const MODAL_WIDTH = 1000;
    const EMPTY_MODAL_HEIGHT = 349;
    const MODAL_HORIZONTAL_PAGE_MARGIN = 40;
    const MODAL_HORIZONTAL_INNER_MARGIN = 40;
    const MODAL_MAX_WIDTH = windowWidth - MODAL_HORIZONTAL_PAGE_MARGIN * 2;
    const maxHeightAvail = windowHeight * 0.95 - EMPTY_MODAL_HEIGHT;
    const maxWidthAvail =
      MODAL_WIDTH < MODAL_MAX_WIDTH
        ? MODAL_WIDTH - MODAL_HORIZONTAL_INNER_MARGIN * 2
        : MODAL_MAX_WIDTH - MODAL_HORIZONTAL_INNER_MARGIN * 2;
    let previewWidth = maxWidthAvail;
    let previewHeight = (maxWidthAvail * 9) / 16;
    if (previewHeight > maxHeightAvail) {
      previewHeight = maxHeightAvail;
      previewWidth = (previewHeight * 16) / 9;
    }
    setPreviewSize({height: previewHeight, width: previewWidth});
  }, [windowWidth, windowHeight]);

  const createBlob = useCallback(async () => {
    try {
      const blob = await getCroppedImg(
        baseImage?.preview || `${process.env.NEXT_PUBLIC_IMAGE_PREFIX}${url}`,
        croppedAreaPixels
      );
      if (!blob) return;
      handleCrop(blob);
    } catch {
      setServerError(true);
    }
  }, [croppedAreaPixels]);

  const handleCancelClick = () => {
    setBaseImage(null);
    closeModal();
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const changeZoom = (direction: string) => {
    const zoomDistance = MAX_ZOOM - MIN_ZOOM;
    const step = 0.2;
    let newZoom: number;
    if (direction === 'add') {
      newZoom = zoom + zoomDistance * step;
      if (newZoom > MAX_ZOOM) newZoom = MAX_ZOOM;
    } else {
      newZoom = zoom - zoomDistance * step;
      if (newZoom < MIN_ZOOM) newZoom = MIN_ZOOM;
    }
    setZoom(newZoom);
  };

  return (
    <Modal
      mobileHeight="100%"
      closeModal={closeModal}
      backVisible={backIsVisible}
      overflowMobile={true}
      desktopWidth={desktopWidth}
      buttons={{
        button1Text: 'Cancel',
        button1OnClick: handleCancelClick,
        button2Text: 'Save',
        button2OnClick: createBlob,
        button2Loading: loading,
      }}
    >
      <S.ImageCropper>
        {serverError && (
          <S.ErrorWrapper>
            <Alert
              type="critical"
              title="Something went wrong"
              text="An error occurred while saving your changes. Please try again. If the
        problem persists, refresh the page or come back later."
            />
          </S.ErrorWrapper>
        )}
        <S.Title>{title}</S.Title>
        <S.PreviewArea
          style={{
            height: `${previewSize.height}px`,
            width: `${previewSize.width}px`,
          }}
        >
          <S.PreviewImage src={previewImage} />
          <S.CropperWrapper
            $cropArea={cropArea}
            $backgroundColor={imageBackgroundColor}
          >
            <Cropper
              image={
                baseImage
                  ? baseImage.preview
                  : `${process.env.NEXT_PUBLIC_IMAGE_PREFIX}${url}`
              }
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              restrictPosition={false}
              objectFit="horizontal-cover"
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape="rect"
              showGrid={false}
              style={{
                cropAreaStyle: {
                  color: 'rgba(33,31,48,0.8)',
                  boxShadow: 'none',
                  border: 'none',
                },
              }}
            />
          </S.CropperWrapper>
        </S.PreviewArea>
        <S.Slider>
          <IconButton
            icon="Minus"
            size="medium"
            type="tertiary"
            disabled={zoom === MIN_ZOOM}
            onClick={() => {
              changeZoom('minus');
            }}
          />
          <S.SliderWrapper>
            <Slider
              minValue={MIN_ZOOM}
              maxValue={MAX_ZOOM}
              sliderWidth={196}
              value={zoom}
              setValue={setZoom}
            />
          </S.SliderWrapper>
          <IconButton
            icon="Add"
            size="medium"
            type="tertiary"
            disabled={zoom === MAX_ZOOM}
            onClick={() => {
              changeZoom('add');
            }}
          />
        </S.Slider>
      </S.ImageCropper>
    </Modal>
  );
};

export default ImageCropperWithPreview;
