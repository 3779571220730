import React from 'react';
//Components
import Modal from '@brightlive/shared/components/Modal';
import Button from '@brightlive/shared/components/Button';
// Styles
import S from './style';

interface NewUserModalProps {
  onClose: Function;
}

export const NewUserModal = ({onClose}: NewUserModalProps) => {
  return (
    <Modal
      closeModal={onClose}
      confetti={true}
      desktopWidth="648px"
      mobileCentered={true}
    >
      <S.ModalContent>
        <S.ModalTitle>Welcome to your first session</S.ModalTitle>
        <S.ModalBody>
          We went ahead and created a session so you can jump right in and try
          it out. <br />
          <br />
          Set up your camera and microphone here then click the "Join Session"
          button to get started!
        </S.ModalBody>
        <Button
          type="primary"
          size="medium"
          text="Continue"
          onClick={onClose}
          width="100%"
        />
      </S.ModalContent>
    </Modal>
  );
};
