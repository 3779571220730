import styled from 'styled-components';
import Ag, {getBold} from '@brightlive/shared/styles/typography-v3';

const Flex = {
  Row: styled.div`
    display: flex;
    align-items: center;
  `,
};

const S = {
  Attendee: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  AttendeeTopBar: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    margin: ${props => props.theme.spacing.SM} 0;
    box-sizing: border-box;
  `,
  AttendeeTopBarActions: styled(Flex.Row)`
    gap: ${props => props.theme.spacing.XS};
    flex: 1;
    justify-content: flex-end;
  `,
  AccordionContainer: styled.div``,
  AttendeeAvatarContainer: styled.div`
    display: flex;
    position: relative;
    width: 40px;
    margin-right: ${props => props.theme.spacing.SM};
    padding: ${props => props.theme.spacing.XS}
      ${props => props.theme.spacing.XS} ${props => props.theme.spacing.XS} 0;
    box-sizing: border-box;
  `,
  AttendeeInfo: styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
    width: 100%;
    margin-right: ${props => props.theme.spacing.SM};
  `,
  AttendeeNameWrapper: styled(Flex.Row)``,
  AttendeeName: styled(Ag.ParagraphMD)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: ${props => props.theme.spacing['2XS']};
  `,
  AttendeeYou: styled(Ag.ParagraphMD)``,
  AttendeeRole: styled(Ag.ParagraphMD)`
    color: ${props => props.theme.contentColor.moreSubdued};
  `,
  AttendeeHoverButton: styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
  `,
  MenuHoverContainer: styled.div`
    position: absolute;
    width: 250px;
    z-index: 2000;
    right: ${props => props.theme.spacing.XS};
  `,
  ConnectionDetails: styled.div`
    background-color: ${props => props.theme.backgroundColor.body};
    padding: ${props => props.theme.spacing.LG}
      ${props => props.theme.spacing.MD};
    border-radius: ${props => props.theme.borderRadius.LG};
  `,
  InternetConnection: styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  `,
  InternetConnBars: styled.div`
    position: relative;
  `,
  InternetConnBar: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `,
  ConnectionAlert: styled.div`
    margin-top: ${props => props.theme.spacing['2XS']};
  `,
  ConnectionEyebrow: styled(Ag.EyebrowSM)`
    margin-top: ${props => props.theme.spacing['2XS']};
  `,
  QualityHeader: styled(Ag.ParagraphLG)`
    ${getBold()}
  `,
  QualityStat: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,

  QualityContainer: styled.div`
    display: flex;
    justify-content: space-around;
    flex: 1;
    margin-top: ${props => props.theme.spacing.LG};
  `,
  Devices: styled(Ag.ParagraphLG)`
    margin-top: ${props => props.theme.spacing.SM};
  `,
  DeviceWrapper: styled(Ag.ParagraphLG)`
    display: flex;
    align-items: center;
    padding: ${props => props.theme.spacing.SM}
      ${props => props.theme.spacing.XS};
  `,
  DeviceInfo: styled(Ag.ParagraphLG)`
    margin-left: ${props => props.theme.spacing.SM};
  `,
  DeviceHeader: styled(Ag.ParagraphSM)`
    ${getBold()}
  `,
  DeviceText: styled(Ag.ParagraphSM)`
    margin-top: ${props => props.theme.spacing['2XS']};
  `,

  EditContainer: styled.div`
    display: flex;
    flex-direction: column;
    padding: ${props => props.theme.spacing.SM}
      ${props => props.theme.spacing.MD};
  `,
  GuestContainer: styled.div`
    padding: 0 ${props => props.theme.spacing.MD};
  `,

  EditButtons: styled.div`
    display: flex;
    align-self: flex-end;
    margin-top: ${props => props.theme.spacing.LG};
    gap: ${props => props.theme.spacing.SM};
  `,

  ActionBar: styled.div`
    background-color: ${props => props.theme.backgroundColor.primary};
    border: ${props => props.theme.borderWidth[1]} solid
      ${props => props.theme.borderColor.subdued};
    border-radius: ${props => props.theme.borderRadius.MD};
    padding: ${props => props.theme.spacing['2XS']};
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: 3000;
    right: ${props => props.theme.spacing.XS};
  `,
  ModalContent: styled.div`
    display: flex;
    flex-direction: column;
    justify-contents: center;
  `,

  ModalTitle: styled(Ag.HeadingMD)`
    text-align: center;
    margin-bottom: ${props => props.theme.spacing.XL};
  `,
};
export default S;
