import Ag from '@brightlive/shared/styles/typography-v3';
import styled from 'styled-components';

const S = {
  ModChatBanner: styled.div`
    display: flex;
    justify-contents: center;
    color: ${props => props.theme.contentColor.black};
    background-color: ${props => props.theme.backgroundColor.white};
    padding: ${props => props.theme.spacing.SM}
      ${props => props.theme.spacing.MD};
    border-radius: ${props => props.theme.spacing.XS};
    z-index: 2000;
    position: absolute;
    max-width: calc(70% - 200px);
    left: 150px;
    top: calc(
      (
          ${props => props.theme.sizing.desktop.navHeight} -
            ${props => props.theme.spacing.LG}
        ) * -1
    );
  `,

  ModChatText: styled(Ag.ParagraphMD)`
    margin-left: ${props => props.theme.spacing.SM};
    color: ${props => props.theme.contentColor.black};
  `,

  ModChatTextBold: styled(Ag.Bold)``,
};

export default S;
