import React, {RefObject, useState} from 'react';
import {isSafari} from 'react-device-detect';
// Components
import ContextualAlert from '@brightlive/shared/components/ContextualAlert';
// Style
import S from './style';

export interface TextAreaProps {
  value: string;
  label?: string;
  placeholder?: string;
  error?: string;
  helperText?: string;
  maxLength?: number;
  minLength?: number;
  disabled?: boolean;
  onChange: Function;
  onKeyDown?: Function;
  textareaRef?: RefObject<HTMLTextAreaElement>;
}

const TextArea = ({
  value,
  label,
  placeholder,
  error,
  helperText,
  maxLength,
  minLength,
  disabled = false,
  onChange,
  textareaRef,
  onKeyDown,
}: TextAreaProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const onInputFocus = () => {
    if (!disabled) setIsFocused(true);
  };

  const onInputBlur = () => {
    setIsFocused(false);
  };

  const getRequirement = () => {
    let charCount = value.length || 0;
    if (isSafari && value) {
      charCount = charCount - (value.match(/\n/g) || []).length;
    }
    if (minLength && charCount < minLength) {
      return (
        <S.Requirement>
          {minLength - charCount} characters under requirement
        </S.Requirement>
      );
    } else if (maxLength && charCount <= maxLength) {
      return <S.Requirement>{`${charCount}/${maxLength}`}</S.Requirement>;
    } else if (maxLength && charCount > maxLength) {
      return (
        <S.OverRequirement>
          {charCount - maxLength} characters over limit
        </S.OverRequirement>
      );
    }
    return null;
  };

  return (
    <S.TextAreaWrapper>
      <S.TextAreaWrapperInner
        $hasError={!!error}
        $isFocused={isFocused}
        disabled={disabled}
        aria-disabled={disabled}
        $hasValue={value}
        $hasLabel={!!label}
        $hasCounter={!!maxLength || !!minLength}
      >
        {label && (
          <S.Label
            $isFocused={isFocused}
            $hasValue={!!value}
            disabled={disabled}
          >
            {label}
          </S.Label>
        )}
        <S.TextArea
          value={value}
          aria-label={label}
          placeholder={isFocused || !label ? placeholder : label}
          aria-placeholder={placeholder}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          onKeyDown={onKeyDown}
          onChange={e => onChange(e.target.value)}
          as="textarea"
          disabled={disabled}
          ref={textareaRef}
        ></S.TextArea>
        {getRequirement()}
      </S.TextAreaWrapperInner>
      {helperText && !error && <S.HelperText>{helperText}</S.HelperText>}
      {!!error && <ContextualAlert type="error" text={error} />}
    </S.TextAreaWrapper>
  );
};

export default TextArea;
