import styled from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';
import {media} from '@brightlive/shared/styles/breakpoints-v3';

const S: Record<string, React.ElementType> = {};

S.ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

S.ModalTitle = styled(Ag.HeadingMD)`
  text-align: center;
`;

S.ModalTabs = styled(Ag.HeadingMD)`
  width: 100%;
  border-bottom: ${props => props.theme.borderWidth[1]} solid
    ${props => props.theme.borderColor.subdued};
`;

S.ModalBody = styled.div`
  margin-top: ${props => props.theme.spacing.MD};
  margin-bottom: ${props => props.theme.spacing['2XL']};
  width: 100%;
  ${media.MD} {
    margin-bottom: 0;
  }
`;
S.ModalBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex; 1;
  ${media.MD} {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
 `;
S.ModalBottomHeader = styled(Ag.ParagraphMD)`
  margin-bottom: ${props => props.theme.spacing.MD};
  color: ${props => props.theme.contentColor.moreSubdued};
  ${media.MD} {
    margin-bottom: 0;
  }
`;
S.ModalBottomText = styled(Ag.ParagraphSM)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.contentColor.subdued};
`;

S.ModalBottomLink = styled(Ag.Link)`
  display: flex;
  align-items: center;
`;

export default S;
