import {useEffect, useState} from 'react';
import {IParticipant} from 'bright-livekit/types/participant/IParticipant';
import {ITrackPublication} from 'bright-livekit/types/track/ITrackPublication';
import {TrackType} from 'bright-livekit/types/BrightParticipant';
import {BrightParticipantEvent} from 'bright-livekit';

export interface ParticipantState {
  isAudioMuted: boolean;
  isVideoMuted: boolean;
  isAudioSubscribed: boolean;
  isVideoSubscribed: boolean;
  isSpeaking: boolean;
  isLocal: boolean;
  metadata?: string;
  publications: ITrackPublication[];
  subscribedTracks: ITrackPublication[];
  cameraPublication?: ITrackPublication;
  microphonePublication?: ITrackPublication;
  screenSharePublication?: ITrackPublication;
}

export function useParticipant(participant?: IParticipant): ParticipantState {
  const [isAudioMuted, setAudioMuted] = useState(true);
  const [isVideoMuted, setVideoMuted] = useState(true);
  const [isAudioSubscribed, setAudioSubscribed] = useState(true);
  const [isVideoSubscribed, setVideoSubscribed] = useState(true);
  const [isSpeaking, setSpeaking] = useState(false);
  const [publications, setPublications] = useState<ITrackPublication[]>([]);
  const [subscribedTracks, setSubscribedTracks] = useState<ITrackPublication[]>(
    []
  );

  useEffect(() => {
    if (!participant) {
      return;
    }
    const onMuted = (pub: ITrackPublication) => {
      if (pub.kind === TrackType.Audio) {
        setAudioMuted(true);
      } else if (pub.kind === TrackType.Video) {
        setVideoMuted(true);
      }
    };
    const onUnmuted = (pub: ITrackPublication) => {
      if (pub.kind === TrackType.Audio) {
        setAudioMuted(false);
      } else if (pub.kind === TrackType.Video) {
        setVideoMuted(false);
      }
    };
    const onIsSpeakingChanged = () => {
      setSpeaking(participant?.isSpeaking || false);
    };

    const onPublicationsChanged = () => {
      setPublications(participant.tracks);
      setSubscribedTracks(
        participant.tracks.filter(pub => {
          return pub.isSubscribed;
        })
      );
    };

    // register listeners
    participant.on(BrightParticipantEvent.TrackMuted, onMuted);
    participant.on(BrightParticipantEvent.TrackUnmuted, onUnmuted);
    participant.on(
      BrightParticipantEvent.IsSpeakingChanged,
      onIsSpeakingChanged
    );
    participant.on(
      BrightParticipantEvent.TrackPublished,
      onPublicationsChanged
    );
    participant.on(
      BrightParticipantEvent.TrackUnpublished,
      onPublicationsChanged
    );
    participant.on(
      BrightParticipantEvent.TrackSubscribed,
      onPublicationsChanged
    );
    participant.on(
      BrightParticipantEvent.TrackUnsubscribed,
      onPublicationsChanged
    );
    participant.on(
      BrightParticipantEvent.LocalTrackPublished,
      onPublicationsChanged
    );
    participant.on(
      BrightParticipantEvent.LocalTrackUnpublished,
      onPublicationsChanged
    );

    onIsSpeakingChanged();
    onPublicationsChanged();

    return () => {
      // cleanup
      participant.off(BrightParticipantEvent.TrackMuted, onMuted);
      participant.off(BrightParticipantEvent.TrackUnmuted, onUnmuted);
      participant.off(
        BrightParticipantEvent.IsSpeakingChanged,
        onIsSpeakingChanged
      );
      participant.off(
        BrightParticipantEvent.TrackPublished,
        onPublicationsChanged
      );
      participant.off(
        BrightParticipantEvent.TrackUnpublished,
        onPublicationsChanged
      );
      participant.off(
        BrightParticipantEvent.TrackSubscribed,
        onPublicationsChanged
      );
      participant.off(
        BrightParticipantEvent.TrackUnsubscribed,
        onPublicationsChanged
      );
      participant.off(
        BrightParticipantEvent.LocalTrackPublished,
        onPublicationsChanged
      );
      participant.off(
        BrightParticipantEvent.LocalTrackUnpublished,
        onPublicationsChanged
      );
    };
  }, [participant]);
  if (!participant) {
    return {
      isLocal: false,
      isSpeaking,
      publications,
      subscribedTracks,
      isAudioMuted,
      isVideoMuted,
      isAudioSubscribed,
      isVideoSubscribed,
    };
  }
  let muted: boolean | undefined;
  let audioSubscribed: boolean | undefined;
  participant?.audioTracks.forEach(pub => {
    muted = pub.isMuted;
    audioSubscribed = pub.isSubscribed;
  });
  if (audioSubscribed === undefined) {
    audioSubscribed = false;
  }
  if (isAudioSubscribed !== audioSubscribed) {
    setAudioSubscribed(audioSubscribed);
  }
  if (muted === undefined) {
    muted = true;
  }
  if (isAudioMuted !== muted) {
    setAudioMuted(muted);
  }

  let videoMuted: boolean | undefined;
  let videoSubscribed: boolean | undefined;
  participant.videoTracks.forEach(pub => {
    if (pub.kind === TrackType.Video && videoMuted !== true) {
      videoMuted = pub.isMuted;
      videoSubscribed = pub.isSubscribed;
    }
  });
  if (videoSubscribed === undefined) {
    videoSubscribed = false;
  }
  if (isVideoSubscribed !== videoSubscribed) {
    setVideoSubscribed(videoSubscribed);
  }
  if (videoMuted === undefined) {
    videoMuted = true;
  }
  if (isVideoMuted !== videoMuted) {
    setVideoMuted(videoMuted);
  }

  return {
    isLocal: participant.isLocal,
    isSpeaking,
    publications,
    subscribedTracks,
    isAudioMuted,
    isVideoMuted,
    isAudioSubscribed,
    isVideoSubscribed,
    cameraPublication: participant.getTrack(TrackType.Video),
    microphonePublication: participant.getTrack(TrackType.Audio),
    screenSharePublication: participant.getTrack(TrackType.ScreenShare),
  };
}
