import React from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
import {IconProps} from '@brightlive/shared/helpers/interfaces';

const IosFlipCamera = ({
  size = 24,
  contentColor = 'default',
  theme,
  ...props
}: IconProps & {
  theme: DefaultTheme;
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M15.45 13.4a3.418 3.418 0 0 1-1.125 2.213c-.65.591-1.425.887-2.325.887a3.215 3.215 0 0 1-.8-.1.916.916 0 0 0-.425 0 .746.746 0 0 0-.375.2.697.697 0 0 0-.2.662c.05.259.192.43.425.513.233.083.462.142.687.175A4.7 4.7 0 0 0 12 18c1.317 0 2.45-.446 3.4-1.337.95-.892 1.467-1.996 1.55-3.313l.225.2c.15.133.325.2.525.2s.367-.067.5-.2c.15-.15.23-.33.238-.538a.693.693 0 0 0-.213-.537L16.95 11.2a.948.948 0 0 0-.7-.275.948.948 0 0 0-.7.275l-1.275 1.275a.72.72 0 0 0-.225.525c0 .2.075.375.225.525a.72.72 0 0 0 .525.225.72.72 0 0 0 .525-.225l.125-.125Zm-8.4-.75-.225-.2a.766.766 0 0 0-.525-.2c-.2 0-.367.067-.5.2a.782.782 0 0 0-.238.537.694.694 0 0 0 .213.538L7.05 14.8a.948.948 0 0 0 .7.275.948.948 0 0 0 .7-.275l1.275-1.275A.72.72 0 0 0 9.95 13a.72.72 0 0 0-.225-.525.72.72 0 0 0-.525-.225.72.72 0 0 0-.525.225l-.125.125c.1-.883.475-1.621 1.125-2.213C10.325 9.796 11.1 9.5 12 9.5a3.221 3.221 0 0 1 .8.1.915.915 0 0 0 .425 0c.15-.033.275-.1.375-.2a.697.697 0 0 0 .2-.662c-.05-.259-.192-.43-.425-.513a3.623 3.623 0 0 0-.687-.175A4.696 4.696 0 0 0 12 8c-1.317 0-2.45.446-3.4 1.337-.95.892-1.467 1.996-1.55 3.313ZM4 21c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 19V7c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 5h3.15L8.4 3.65c.183-.217.404-.38.662-.488A2.09 2.09 0 0 1 9.875 3h4.25c.283 0 .554.054.813.162.258.109.479.271.662.488L16.85 5H20c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 21H4Zm16-2V7H4v12h16Z"
      fill={
        contentColor.indexOf('#') > -1
          ? contentColor
          : theme.contentColor[contentColor]
      }
    />
  </svg>
);

export default withTheme(IosFlipCamera);
