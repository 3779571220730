/* eslint-disable node/no-extraneous-import */
import React, {FC, useEffect, useRef} from 'react';
import videojs from 'video.js';
import 'videojs-mux';
// eslint-disable-next-line node/no-extraneous-import
import * as thumbnails from 'videojs-vtt-thumbnails';
// Styles
import S from './style';

const initialOptions: videojs.PlayerOptions = {
  controls: true,
  autoplay: false,
  muted: true,
  fluid: true,
  preload: 'auto',
  userActions: {
    hotkeys: true,
  },
  playbackRates: [2, 1.5, 1, 0.75, 0.5],
  controlBar: {
    volumePanel: {
      inline: false,
      vertical: true,
    },
  },
};

const muxInitialData = {
  env_key: process.env.NEXT_PUBLIC_MUX_DATA_KEY, // required

  player_name: 'Bright Player',
  player_init_time: new Date().getTime(),
};

interface IVideoPlayerProps {
  videoKey: string;
  options: videojs.PlayerOptions;
  setVertical?: (vertical: boolean) => void;
  isMuted?: boolean;
  onReady?: (playerRef: videojs.Player) => void;
  shouldAutoplayNoControls?: boolean;
}

const VideoPlayer: FC<IVideoPlayerProps> = ({
  videoKey,
  options,
  setVertical,
  isMuted,
  onReady,
  shouldAutoplayNoControls,
}) => {
  const videoNode = useRef<HTMLVideoElement>(null);
  const player = useRef<videojs.Player>(null);

  useEffect(() => {
    if (!videoNode.current) return;
    if (options?.plugins?.mux) {
      options.plugins.mux.debug = false;
    }
    if (options?.plugins?.mux?.data) {
      options.plugins.mux.data = {
        ...muxInitialData,
        ...options.plugins.mux.data,
      };
    }

    if (!videojs.getPlugin('videojs-vtt-thumbnails')) {
      videojs.registerPlugin('videojs-vtt-thumbnails', thumbnails.default);
    }

    // If video url ends with .m3u8 -http live stream (hls) video- force video type to the correct type application/x-mpegURL
    for (const source of options.sources) {
      if (/\/\w+\.m3u8/g.exec(source.src)) {
        source.type = 'application/x-mpegURL';
      }
    }

    player.current = videojs(
      videoNode.current,
      {
        ...initialOptions,
        ...options,
      },
      () => {
        // Player is ready
        player.current.on('loadedmetadata', () => {
          onReady && onReady(player);
          setDimensions();
        });
        player.current.on('error', () => {
          player.current.errorDisplay.fillWith(
            'Sorry, we’re having trouble playing this video. Try refreshing the page.'
          );
        });
      }
    );
    if (window.innerWidth < 1024) {
      window.addEventListener('scroll', checkForPause);
    }
    return () => {
      window.removeEventListener('scroll', checkForPause);
      player.current.dispose();
    };
  }, [videoKey]);

  useEffect(() => {
    if (!player.current) return;

    if (isMuted) {
      player.current.muted(true);
    } else {
      player.current.muted(false);
    }
  }, [isMuted]);

  useEffect(() => {
    if (!player.current) return;
    if (shouldAutoplayNoControls) {
      player.current.playsinline(true);
      player.current.autoplay('muted');
    }
  }, [shouldAutoplayNoControls]);

  const checkForPause = () => {
    if (!videoNode.current || !player.current) return;
    const rect = videoNode.current.getBoundingClientRect();
    const isInViewport =
      rect.top >= -rect.height &&
      rect.bottom <= window.innerHeight + rect.height;
    // pause videos when not on screen
    if (!isInViewport) player.current.pause();
    // if back on screen and is an auto play video - play video again
    if (isInViewport && options.autoplay === 'muted') {
      player.current.muted(true);
      player.current.play();
    }
  };

  const setDimensions = () => {
    if (player.current && setVertical) {
      const height = player.current.videoHeight();
      const width = player.current.videoWidth();
      const vertical = height > width;
      setVertical(vertical);
    }
  };

  // on mobile when autoplaying with no sound - expand to fullscreen on tap
  const handleAutoplayNoControlClick = () => {
    if (!player.current) return;
    player.current.requestFullscreen();
  };

  return (
    <S.VideoWrapper>
      <div data-vjs-player key={videoKey}>
        <video ref={videoNode} className="video-js vjs-big-play-centered" />
      </div>
      {shouldAutoplayNoControls && (
        <S.AutoplayOverlay onClick={handleAutoplayNoControlClick} />
      )}
    </S.VideoWrapper>
  );
};

export default VideoPlayer;
