import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
// Components
import Button from '@brightlive/shared/components/Button';
import TooltipWrapper from '@brightlive/shared/components/TooltipWrapper';
import {
  Role,
  SettingsModal,
  NotificationsView,
  StageProps,
  ControlsViewDesktop,
  BannedModal,
  Countdown,
  ModChatBanner,
  FlagUserModal,
  DeleteCommentModal,
  DesktopSidebar,
  LiveSessionIndicator,
  StageLayout,
  OnboardingSteps,
  AlertBanner,
  LayoutUpdateProps,
  PreSession,
  PermissionRequest,
  StatusMessage,
  RecordingStartingModal,
} from 'bright-livekit';
import {InviteModal} from 'components/shared/InviteModal';
import Logo from '@brightlive/shared/components/Logo';
import IconButton from '@brightlive/shared/components/IconButton';
// Hooks
import {useLocalStorage} from '@brightlive/shared/hooks/useLocalStorage';
// Services
import {TrackingService} from 'bright-livekit/services/TrackingService';
// Redux
import {VideoReducer} from 'redux/livekit/reducer';
import {
  toggleFlagUserModal,
  toggleDeleteCommentModal,
} from 'redux/livekit/actions';
import {AuthReducer} from 'redux/auth/reducer';
import {toggleToast} from 'redux/ui/actions';
import {UIReducer} from 'redux/ui/reducer';
// Styles
import S from 'bright-livekit/styles/style';

export const DesktopStage = ({
  roomState,
  participantRenderer,
  controlRenderer,
  onLeave,
  sessionID,
  setIsLive,
  countdownRunning,
  needsSessionTour,
}: StageProps) => {
  const dispatch = useDispatch();
  const [flagModalClosed, setFlagModalClosed] = useLocalStorage(
    'flag-modal-closed',
    false
  );
  const [displayQuestionPreview] = useLocalStorage(
    'display-question-preview',
    true
  );
  const {isConnecting, error, videoService, captions} = roomState;
  const [questionText, setQuestionText] = useState('');
  const [buttonLoading, setButtonLoading] = useState('');
  const [isLeaveControlsOpen, setIsLeaveControlsOpen] = useState(false);
  const [stageWrapperWidth, setStageWrapperWidth] = useState(0);
  const [replaceQuestion, setReplaceQuestion] = useState(false);
  const [showQuestionPreview, setShowQuestionPreview] = useState(
    displayQuestionPreview
  );
  const [toggleInviteModal, setToggleInviteModal] = useState(false);
  const [currentTab, setCurrentTab] = useState<
    'Session info' | 'Recordings' | 'Attendees' | 'Chat' | 'Questions' | null
  >(null);
  const [onboardingStep, setOnboardingStep] = useState<OnboardingSteps>(
    needsSessionTour ? 'share' : null
  );
  const [toggleFlagModal, setToggleFlagModal] = useState(
    roomState.selfParticipant?.role === Role.Banned
  );
  const [showCC, setShowCC] = useState(false);
  const authToken =
    useSelector((state: AuthReducer) => state.auth.auth.authToken) || '';
  const alertBanner = useSelector((state: UIReducer) => state.ui.alertBanner);
  const currentUser = useSelector(
    (state: AuthReducer) => state.auth.currentUser
  );

  useEffect(() => {
    // Check if user is banned
    if (roomState.selfParticipant?.role === Role.Banned && !flagModalClosed) {
      setToggleFlagModal(true);
    }
    if (roomState.selfParticipant?.role !== Role.Banned && flagModalClosed) {
      setFlagModalClosed(false);
    } // remove flag from local storage
  }, [roomState]);

  const flagUserModal = useSelector(
    (state: VideoReducer) => state.video.flagUserModal
  );
  const deleteCommentModal = useSelector(
    (state: VideoReducer) => state.video.deleteCommentModal
  );
  const settingsModalVisible = useSelector(
    (state: UIReducer) => state.ui.settingsModalVisible
  );

  if (roomState.sessionState === 'permission-request') {
    return <PermissionRequest roomState={roomState} />;
  }

  if (roomState.sessionState === 'pre-session') {
    return (
      <PreSession roomState={roomState} needsSessionTour={needsSessionTour} />
    );
  }

  if (error) {
    console.error(error);
    return (
      <StatusMessage
        text="Error: Please Refresh"
        brandColor={roomState.brandColor}
      />
    );
  }
  if (roomState.sessionState === 'loading' || !videoService || isConnecting) {
    return (
      <StatusMessage
        text="Loading"
        brandColor={roomState.brandColor}
        loading={true}
      />
    );
  }

  const ControlRenderer = controlRenderer ?? ControlsViewDesktop;

  const closeBannedModal = () => {
    setFlagModalClosed(true);
    setToggleFlagModal(false);
  };

  const closeDeleteCommentModal = () => {
    dispatch(toggleDeleteCommentModal({toggled: false, participant: null}));
  };

  const continueOnboarding = (step: OnboardingSteps) => {
    if (!needsSessionTour) return;
    const stepOrder: OnboardingSteps[] = [
      'share',
      'record-start',
      'layout',
      'record-end',
      'recordings',
      null,
    ];
    const currentStepIdx = stepOrder.indexOf(onboardingStep);
    const newStepIdx = stepOrder.indexOf(step);
    // make sure order is enforced and don't let people skip steps
    // unless their recording ends and they haven't seen 'recordings' then skip the rest and show them 'recordings'
    if (step === 'recordings' && onboardingStep !== null) {
      setOnboardingStep('recordings');
    }
    if (newStepIdx === currentStepIdx + 1) {
      setOnboardingStep(step);
    }
  };

  const sendQuestion = () => {
    if (questionText) {
      TrackingService.fire({
        event: 'submit_qc',
        currentUser: roomState.currentUser,
        data: {
          session_id: sessionID,
          time: new Date(new Date().getTime()),
        },
      });
      roomState.videoUtils?.sendQuestion(
        sessionID,
        roomState.currentUser.id,
        questionText
      );
      setReplaceQuestion(false);
    }
  };

  const setLayout = <K extends keyof LayoutUpdateProps>({
    key,
    value,
  }: {
    key: K;
    value: LayoutUpdateProps[K];
  }) => {
    roomState.videoUtils?.updateLayout({key, value, sessionID});
  };

  const handleRecordingClick = async () => {
    setButtonLoading('recording');
    try {
      if (roomState.isRecording) {
        await roomState.videoUtils?.stopRecording(
          roomState.session.id,
          authToken
        );

        TrackingService.fire({
          event: 'stop_recording',
          currentUser: roomState.currentUser,
          data: {
            session_id: roomState.session.id,
            minutes: moment().diff(moment(roomState?.startedAt), 'minutes'),
          },
        });
        continueOnboarding('recordings');
      } else {
        await roomState.videoUtils?.startRecording(
          roomState.session.id,
          authToken
        );
        TrackingService.fire({
          event: 'spinup_recording',
          currentUser: roomState.currentUser,
          data: {
            session_id: roomState.session.id,
          },
        });
        TrackingService.fire({
          event: 'spinup_livestream',
          currentUser: roomState.currentUser,
          data: {
            session_id: roomState.session.id,
          },
        });
        continueOnboarding('layout');
      }
    } catch (err) {
      console.error(err);
      dispatch(
        toggleToast(
          true,
          `There was a problem ${
            roomState.isRecording ? 'stopping' : 'starting'
          } the recording. Please try again.`,
          'default',
          undefined,
          'top'
        )
      );
    }
    setButtonLoading('');
  };

  const isUser = !currentUser?.roles?.includes('anon');

  return (
    <S.LivekitWrapper>
      <S.DesktopTopNav>
        <S.LogoWrapper
          title="home page"
          target="_blank"
          href={isUser ? '/dashboard/sessions' : '/'}
        >
          <Logo height={48} contentColor="default" />
        </S.LogoWrapper>
        {roomState.startedAt && roomState.isRecording && (
          <LiveSessionIndicator startedAt={roomState.startedAt} />
        )}
      </S.DesktopTopNav>

      <ModChatBanner roomState={roomState} />
      <AlertBanner
        navExpanded={!!currentTab && currentTab.length > 0}
        type={alertBanner.alertType}
        text={alertBanner.text}
        persist={alertBanner.persist}
      />
      <NotificationsView
        sessionID={sessionID}
        roomState={roomState}
        navExpanded={!!currentTab && currentTab.length > 0}
      ></NotificationsView>
      <S.LivekitTop>
        <S.LivekitStage style={{width: `${stageWrapperWidth}px`}}>
          <StageLayout
            roomState={roomState}
            participantRenderer={participantRenderer}
            sessionID={sessionID}
            currentTab={currentTab}
            setStageWrapperWidth={setStageWrapperWidth}
            stageWrapperWidth={stageWrapperWidth}
          />

          {showCC && captions && captions.length > 0 && (
            <S.CaptionsContainer>
              {captions.slice(-3).map(val => {
                return (
                  <S.CaptionLine key={val.userID}>
                    {val.name}: {val.transcript}
                  </S.CaptionLine>
                );
              })}
            </S.CaptionsContainer>
          )}
          <S.LowerControlsContainer>
            <S.LowerControlsLeft>
              <TooltipWrapper
                tooltipContent="Leave session"
                referenceContent={
                  <IconButton
                    type="negative"
                    size="medium"
                    icon="Logout"
                    onClick={() => {
                      if (roomState.isRecording && roomState.modControls) {
                        setIsLeaveControlsOpen(!isLeaveControlsOpen);
                      } else {
                        setIsLeaveControlsOpen(false);
                        roomState.videoService?.disconnect();
                        if (onLeave && roomState.videoService) {
                          onLeave(roomState.videoService);
                        }
                      }
                    }}
                  />
                }
              />
              {isLeaveControlsOpen && (
                <S.SessionControlsLeaveContainer>
                  <S.SessionControlsLeaveContainerInner>
                    <S.ModalTitle>Recording is in progress</S.ModalTitle>
                    <Button
                      width="100%"
                      type="primary"
                      size="medium"
                      text="Stop recording and leave"
                      icon="EndSession"
                      onClick={async () => {
                        setIsLeaveControlsOpen(false);
                        await roomState.videoUtils?.stopRecording(
                          roomState.session.id,
                          authToken
                        );
                        await roomState.videoService?.disconnect();
                        if (onLeave && roomState.videoService) {
                          onLeave(roomState.videoService);
                        }
                      }}
                    />
                    <Button
                      width="100%"
                      type="secondary"
                      size="medium"
                      text="Leave"
                      icon="Logout"
                      onClick={() => {
                        setIsLeaveControlsOpen(false);
                        roomState.videoService?.disconnect();
                        if (onLeave && roomState.videoService) {
                          onLeave(roomState.videoService);
                        }
                      }}
                    />
                  </S.SessionControlsLeaveContainerInner>
                  <S.SessionControlsLeaveContainerBottom>
                    <Button
                      type="tertiary"
                      size="medium"
                      text="Cancel"
                      onClick={() => setIsLeaveControlsOpen(false)}
                    />
                  </S.SessionControlsLeaveContainerBottom>
                </S.SessionControlsLeaveContainer>
              )}
            </S.LowerControlsLeft>
            <S.LowerControls>
              {roomState.modControls && (
                <S.RecordingButton>
                  {onboardingStep === 'record-start' && (
                    <TooltipWrapper
                      tooltipContent="When you’re ready, hit the Record button to begin recording your podcast."
                      position="top"
                      delayMount={true}
                      width="288px"
                      callout={true}
                      dismiss={() => continueOnboarding('layout')}
                      size="large"
                      referenceContent={<S.TooltipWrapper />}
                    />
                  )}
                  <Button
                    type={roomState.isRecording ? 'tertiary' : 'lightning'}
                    size="medium"
                    loading={
                      buttonLoading === 'recording' || roomState.isStarting
                    }
                    text={
                      roomState.isRecording
                        ? 'Stop recording'
                        : 'Start recording'
                    }
                    onClick={handleRecordingClick}
                  />
                </S.RecordingButton>
              )}
              <ControlRenderer
                enableScreenShare={false}
                videoService={videoService}
                onStage={roomState.onStage}
                roomState={roomState}
                toggleCC={() => setShowCC(!showCC)}
                showCC={showCC}
                captionsAvail={!!captions && captions.length > 0}
              />
            </S.LowerControls>
          </S.LowerControlsContainer>
        </S.LivekitStage>
        <DesktopSidebar
          roomState={roomState}
          sessionID={sessionID}
          setToggleInviteModal={setToggleInviteModal}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          sendQuestion={sendQuestion}
          questionText={questionText}
          setQuestionText={setQuestionText}
          replaceQuestion={replaceQuestion}
          setReplaceQuestion={setReplaceQuestion}
          setLayout={setLayout}
          onboardingStep={onboardingStep}
          continueOnboarding={continueOnboarding}
          needsSessionTour={needsSessionTour}
          showQuestionPreview={showQuestionPreview}
        />
      </S.LivekitTop>
      {!!countdownRunning && setIsLive && (
        <Countdown
          onComplete={async () => {
            await roomState.videoUtils?.startSession(sessionID);
            setIsLive(true);
          }}
        />
      )}
      {toggleFlagModal && <BannedModal onClose={closeBannedModal} />}
      {flagUserModal.toggled && flagUserModal.participant && (
        <FlagUserModal
          onClose={() =>
            dispatch(toggleFlagUserModal({toggled: false, participant: null}))
          }
          participant={flagUserModal.participant}
          roomState={roomState}
          sessionID={sessionID}
        />
      )}
      {deleteCommentModal.toggled && deleteCommentModal.participant && (
        <DeleteCommentModal
          onClose={closeDeleteCommentModal}
          participant={deleteCommentModal.participant}
          sessionID={sessionID}
          roomState={roomState}
        />
      )}
      {settingsModalVisible && (
        <SettingsModal
          sessionID={sessionID}
          roomState={roomState}
          setShowQuestionPreview={setShowQuestionPreview}
        />
      )}
      {toggleInviteModal && (
        <InviteModal onClose={() => setToggleInviteModal(false)} />
      )}
      {roomState.isStarting && (
        <RecordingStartingModal
          creatorName={roomState.session.creator?.displayName}
          isCreator={roomState.isCreator}
        />
      )}
    </S.LivekitWrapper>
  );
};
