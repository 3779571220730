import styled, {DefaultTheme} from 'styled-components';
import Ag, {
  getBold,
  getParagraphMD,
} from '@brightlive/shared/styles/typography-v3';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import {NameDisplayTypes} from 'bright-livekit';
import {contrastIsLight} from '@brightlive/shared/helpers/colorFormatter';
import {Hex} from '@brightlive/shared/helpers/interfaces';

const S = {
  ParticipantBar: styled.div(
    (props: {
      $nameDisplay: NameDisplayTypes;
      $nameDisplayVisible: boolean;
      theme: DefaultTheme;
    }) => `

    box-sizing: border-box;
    position: absolute;
    z-index: 5;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: ${
      props.$nameDisplay === NameDisplayTypes.Block && props.$nameDisplayVisible
        ? `${props.theme.spacing.XS} ${props.theme.spacing.XS} 0 0`
        : `${props.theme.spacing.XS}`
    };
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
  ),
  AudioIconBackground: styled.div`
    height: 24px;
    width: 24px;
    background: ${props => props.theme.backgroundColor.blackTransparent};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: ${props => props.theme.spacing.XS};
  `,
  NameDisplay: styled.div(
    (props: {$isAudioMuted: boolean; theme: DefaultTheme}) => `
    padding: ${props.theme.spacing['2XS']}
      ${props.theme.spacing.XS};
    position: relative;
    max-width: ${
      props.$isAudioMuted ? `calc(100% - ${props.theme.spacing.XL})` : '100%'
    };
    box-sizing: border-box;
    ${media.LG} {
      padding: ${props.theme.spacing.XS}
      ${props.theme.spacing.MD};
    }
  `
  ),
  Text: styled(Ag.ParagraphXS)(
    (props: {
      $type: NameDisplayTypes;
      $brandColor: Hex;
      theme: DefaultTheme;
    }) => `
    ${getBold()}
    color: ${
      contrastIsLight({
        baseColor: props.$brandColor,
        whiteColor: props.theme.color.white as Hex,
      })
        ? props.theme.color.white
        : props.theme.color.black
    };
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    z-index: 2;

    ${media.LG} {
      ${getParagraphMD(props.theme)}
      ${getBold()}
      color: ${
        contrastIsLight({
          baseColor: props.$brandColor,
          whiteColor: props.theme.color.white as Hex,
        })
          ? props.theme.color.white
          : props.theme.color.black
      };
    }
  `
  ),
  Background: styled.div(
    (props: {
      $type: NameDisplayTypes;
      $brandColor: Hex;
      theme: DefaultTheme;
    }) => `
      background-color: ${props.$brandColor};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: ${
        props.$type === NameDisplayTypes.Angled
          ? 'skew(-7deg, 0deg) translate(2px, 0) scaleX(0.99)'
          : 'none'
      };

      border-radius: ${
        props.$type === NameDisplayTypes.Pills
          ? props.theme.borderRadius['2XL']
          : '0'
      };
      z-index: 1;
    `
  ),
};

export default S;
