import styled from 'styled-components';

const S: Record<string, React.ElementType> = {
  ScreenShareContainer: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: flex;
  `,
};
export default S;
