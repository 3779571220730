import React from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
import {IconProps} from '@brightlive/shared/helpers/interfaces';

const Forward5 = ({
  size = 24,
  contentColor = 'default',
  theme,
  ...props
}: IconProps & {
  theme: DefaultTheme;
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.488 21.288A8.708 8.708 0 0 0 12 22c2.333 0 4.346-.767 6.038-2.3 1.691-1.533 2.662-3.433 2.912-5.7a.82.82 0 0 0-.238-.7A.946.946 0 0 0 20 13a1.01 1.01 0 0 0-.725.287c-.2.192-.317.43-.35.713-.233 1.717-1 3.146-2.3 4.288C15.325 19.429 13.783 20 12 20c-1.95 0-3.604-.68-4.962-2.038C5.679 16.604 5 14.95 5 13c0-1.95.68-3.604 2.038-4.963C8.396 6.679 10.05 6 12 6h.15l-.85.85a.948.948 0 0 0-.275.7c0 .283.092.517.275.7.183.183.421.279.713.287.291.009.529-.079.712-.262L15.3 5.7c.1-.1.171-.208.213-.325.041-.117.062-.242.062-.375s-.02-.258-.062-.375A.883.883 0 0 0 15.3 4.3l-2.575-2.575a.932.932 0 0 0-.712-.288 1.022 1.022 0 0 0-.713.313c-.217.217-.317.458-.3.725.017.267.117.492.3.675l.85.85H12c-1.25 0-2.42.238-3.512.713a9.138 9.138 0 0 0-2.85 1.924 9.158 9.158 0 0 0-1.926 2.85A8.716 8.716 0 0 0 3 13c0 1.25.237 2.42.712 3.512a9.143 9.143 0 0 0 1.926 2.85 9.142 9.142 0 0 0 2.85 1.926Zm2.942-6.468c.02.25.16.56.6.56.559 0 .59-.601.598-.756l.002-.034c0-.52-.29-.75-.69-.75-.29 0-.44.1-.57.23l-.67-.17.25-2.17h2.39v.71h-1.7l-.11.92c.21-.11.44-.16.64-.16.33 0 1.3.11 1.3 1.44 0 .8-.51 1.43-1.44 1.43-.65 0-1.41-.4-1.44-1.25h.84Z"
      fill={
        contentColor.indexOf('#') > -1
          ? contentColor
          : theme.contentColor[contentColor]
      }
    />
  </svg>
);

export default withTheme(Forward5);
