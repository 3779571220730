import React, {Dispatch, RefObject, useCallback, useMemo} from 'react';
// Components
import {RoomState, useParticipant, VideoRenderer} from 'bright-livekit';
import Avatar from '@brightlive/shared/components/Avatar';
// Styles
import S from './style';

const MAX_CHARS = 140;

export const MobileQuestionInput = ({
  questionText,
  setQuestionText,
  sendQuestion,
  roomState,
  textareaRef,
}: {
  questionText: string;
  setQuestionText: Dispatch<React.SetStateAction<string>>;
  sendQuestion: Function;
  roomState: RoomState;
  textareaRef: RefObject<HTMLTextAreaElement>;
}) => {
  const {cameraPublication, isLocal} = useParticipant(
    roomState.selfParticipant?.videoParticipant
  );

  const handleTextareaChange = e => {
    setQuestionText(e.target.value);
  };

  const onTextareaKeyDown = e => {
    if (e.keyCode === 13) {
      sendQuestion();
      e.preventDefault();
    }
  };

  const getMainElement = useCallback(() => {
    if (
      cameraPublication?.isSubscribed &&
      cameraPublication?.track &&
      !cameraPublication?.isMuted
    ) {
      return (
        <VideoRenderer
          track={cameraPublication.track}
          isLocal={isLocal}
          objectFit="cover"
          width="96px"
          height="96px"
        />
      );
    } else {
      return (
        <S.ChatInputAvatar>
          <Avatar
            size="large"
            avatar={roomState.selfParticipant?.avatar}
            displayName={roomState.selfParticipant?.displayName}
          />
        </S.ChatInputAvatar>
      );
    }
  }, [cameraPublication]);

  const getRequirement = useMemo(() => {
    const charCount = questionText.length || 0;
    if (charCount <= MAX_CHARS) {
      return (
        <S.CharCount
          $hasError={false}
        >{`${charCount}/${MAX_CHARS}`}</S.CharCount>
      );
    } else if (charCount > MAX_CHARS) {
      return (
        <S.CharCount $hasError={true}>
          {charCount - MAX_CHARS} characters over limit
        </S.CharCount>
      );
    }
    return null;
  }, [questionText]);

  return (
    <S.ChatInput>
      <S.TextAreaWrapperOuter>
        <S.TextAreaWrapperInner>
          <S.TextArea
            onChange={e => {
              handleTextareaChange(e);
            }}
            onKeyDown={onTextareaKeyDown}
            placeholder="Add a comment or question..."
            value={questionText}
            ref={textareaRef}
            as="textarea"
            max={140}
          />
          {getRequirement}
        </S.TextAreaWrapperInner>
      </S.TextAreaWrapperOuter>
      <S.ChatInputVideoPreview>{getMainElement()}</S.ChatInputVideoPreview>
    </S.ChatInput>
  );
};
