import Ag from '@brightlive/shared/styles/typography-v3';
import styled from 'styled-components';

const S = {
  ModalContent: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,

  FlagBackground: styled.div`
    background-color: ${props => props.theme.backgroundColor.negative};
    border-radius: 50%;
    width: 54px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${props => props.theme.spacing['2XL']};
  `,

  ModalTitle: styled(Ag.HeadingMD)`
    text-align: center;
  `,

  ModalBody: styled(Ag.ParagraphMD)`
    margin-top: ${props => props.theme.spacing.MD};
    margin-bottom: ${props => props.theme.spacing['2XL']};
  `,

  Link: styled(Ag.Link)``,
};
export default S;
