import {
  LocalAudioTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteVideoTrack,
  AudioTrack,
  VideoTrack,
  LocalTrack,
  RemoteTrack,
} from 'twilio-video';
import {ITrack} from './ITrack';

export class TwilioTrack implements ITrack {
  track: AudioTrack | VideoTrack;
  constructor(
    track:
      | LocalVideoTrack
      | LocalAudioTrack
      | RemoteVideoTrack
      | RemoteAudioTrack
      | RemoteTrack
      | LocalTrack
  ) {
    this.track = track as AudioTrack; // Workaround
  }
  public get isMuted(): boolean {
    return this.track.isEnabled;
  }
  public get sid(): string | undefined {
    return this.track.mediaStreamTrack.id;
  }
  public attach(element: HTMLMediaElement): HTMLMediaElement | undefined {
    return this.track.attach(element);
  }
  public detach(element?: HTMLMediaElement): HTMLMediaElement[] | undefined {
    if (!element) {
      return this.track.detach();
    } else {
      return this.track.detach(element);
    }
  }
  public isEqual(track: unknown): boolean {
    return this.track === track;
  }
}
