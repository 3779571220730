import styled, {DefaultTheme} from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';

const S: Record<string, React.ElementType> = {};

S.Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing.MD} 0
    ${props => props.theme.spacing.XS};
  position: relative;
  cursor: pointer;
`;

S.Text = styled(Ag.HeadingXS)(
  (props: {$underlined: boolean; theme: DefaultTheme}) => `
  text-decoration: ${props.$underlined ? 'underline' : 'none'};
  color: ${props.theme.contentColor.accent};
`
);

export default S;
