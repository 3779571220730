import React from 'react';
import {ITrackPublication} from 'bright-livekit/types/track/ITrackPublication';
import {LivekitTrackPublication} from 'bright-livekit/types/track/LivekitTrackPublication';
import {LivekitTrack} from 'bright-livekit/types/track/LivekitTrack';
import {VideoRenderer} from 'bright-livekit';
import S from './style';

export interface BrightScreenShareViewProps {
  trackPublication: ITrackPublication;
}

export const BrightScreenShareView = ({
  trackPublication,
}: BrightScreenShareViewProps) => {
  const publication = trackPublication as LivekitTrackPublication;
  const track = publication.track as LivekitTrack;
  if (track.track) {
    return (
      <S.ScreenShareContainer>
        <VideoRenderer
          track={track}
          isLocal={false}
          width="100%"
          height="100%"
        />
      </S.ScreenShareContainer>
    );
  }
  return <></>;
};
