import styled from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';

const S = {
  VideoPreviewContainer: styled.div`
    padding: ${props => props.theme.spacing.MD}
      ${props => props.theme.margin.mobile.page} 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  `,

  VideoWrapper: styled.div`
    width: 100%;
    background-color: ${props => props.theme.backgroundColor.secondary};
    display: flex;
    justify-content: center;
  `,

  PreviewTextWrapper: styled.div`
    display: flex;
    background-color: ${props => props.theme.backgroundColor.primary};
    padding-top: ${props => props.theme.spacing.MD};
  `,

  NoVidTextWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.backgroundColor.secondary};
    height: calc((100vw - (${props => props.theme.spacing.MD} * 2)) + 20px);
    width: 100%;
  `,

  PreviewText: styled(Ag.ParagraphSM)`
    margin-left: ${props => props.theme.spacing.XS};
    color: ${props => props.theme.contentColor.subdued};
  `,
};
export default S;
