import React, {useState} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
import {OnboardingSteps, RoomState} from 'bright-livekit';
// Components
import TextInput from '@brightlive/shared/components/TextInput';
import Button from '@brightlive/shared/components/Button';
import IconButton from '@brightlive/shared/components/IconButton';
import ShareLink from '@brightlive/shared/components/ShareLink';
import Modal from '@brightlive/shared/components/Modal';
import Select from '@brightlive/shared/components/Select';
import TooltipWrapper from '@brightlive/shared/components/TooltipWrapper';
// Helpers
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
// Redux
import {useDispatch} from 'react-redux';
import {toggleToast} from 'redux/ui/actions';
import {updateSession} from 'redux/session/actions';
// Styles
import S from './style';

const SessionInfoViewComponent = ({
  roomState,
  onboardingStep,
  continueOnboarding,
}: {
  roomState: RoomState;
  onboardingStep?: OnboardingSteps;
  continueOnboarding?: (onboardingStep: OnboardingSteps) => void;
  theme: DefaultTheme;
}) => {
  const dispatch = useDispatch();

  const [showEdit, setShowEdit] = useState(false);
  const [sessionName, setSessionName] = useState(roomState.session.title);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [inviteMode, setInviteMode] = useState<'mod' | 'guest'>('guest');

  const {isMobile, isLG} = useWindowSize();

  const handleSave = async () => {
    setLoading(true);
    if (sessionName.length === 0) {
      setError('Please enter a name');
      setLoading(false);
      return;
    }

    await dispatch(
      updateSession(roomState.currentUser.id, roomState.session.id, {
        title: sessionName,
      })
    );
    setLoading(false);
    setShowEdit(false);
    return;
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(getInviteLink());
    dispatch(toggleToast(true, 'Link copied!', 'default', undefined, 'top'));
    if (continueOnboarding) {
      continueOnboarding('record-start');
    }
  };

  const sessionLink = `${process.env.NEXT_PUBLIC_BOOKING_URL}/join/${roomState.session.id}`;

  const getInviteLink = () => {
    return `${sessionLink}?t=${
      inviteMode === 'mod'
        ? roomState.session.inviteTokenMod
        : roomState.session.inviteTokenGuest
    }`;
  };

  const dropdownOptions = [
    {
      value: 'guest',
      label: 'Guest',
      description:
        'Guests can actively participate in the live conversation off stage, and are recorded if brought on stage',
    },
    {
      value: 'mod',
      label: 'Moderator',
      description:
        'Moderators have full access to controls to support you during the Session',
    },
  ];

  return (
    <S.SessionInfoContainer>
      {showEdit && !isMobile ? (
        <S.EditContainer>
          <TextInput
            value={sessionName}
            label="Session name"
            maxLength={80}
            autoFocus
            onChange={e => {
              e.preventDefault();
              setSessionName(e.target.value);
            }}
            error={error}
            keyPressHandler={handleSave}
          />
          <S.EditButtons>
            <Button
              text="Cancel"
              size="small"
              type="tertiary"
              onClick={async () => {
                setShowEdit(false);
              }}
            />
            <Button
              text="Save"
              size="small"
              type="primary"
              loading={loading}
              onClick={handleSave}
            />
          </S.EditButtons>
        </S.EditContainer>
      ) : (
        <S.SessionInfoName>
          <div>
            <S.SessionInfoHeader>Session name</S.SessionInfoHeader>
            <S.SessionInfoParagraph>
              {roomState.sessionDocument?.data()?.title}
            </S.SessionInfoParagraph>
          </div>
          <IconButton
            icon="Edit"
            size="medium"
            type="tertiary"
            onClick={() => setShowEdit(true)}
          />
        </S.SessionInfoName>
      )}
      <S.SessionInfoInvite>
        <S.SessionInfoHeader>Invite people</S.SessionInfoHeader>
        <S.SessionInfoParagraph>
          Assign roles and copy the link to share and invite people to your
          Session.
        </S.SessionInfoParagraph>
        <S.SessionInfoInviteLink>
          {isMobile ? (
            <>
              <S.SessionInfoShareWrapper>
                <Select
                  hasDesktopMenuStyling={true}
                  hasMobileMenuStyling={true}
                  placeholder="Select..."
                  value={inviteMode}
                  onChange={setInviteMode}
                  options={dropdownOptions}
                />
              </S.SessionInfoShareWrapper>
              <ShareLink
                text={getInviteLink()}
                onClick={() => {
                  navigator.clipboard.writeText(getInviteLink());
                  dispatch(
                    toggleToast(
                      true,
                      'Link copied!',
                      'default',
                      undefined,
                      'top'
                    )
                  );
                }}
              />
              <S.SessionInfoShareViaLink>
                <Button
                  type="secondary"
                  size="medium"
                  text="Share via"
                  onClick={async () => {
                    if (navigator?.share) {
                      const shareData = {
                        title: 'Join my session',
                        url: getInviteLink(),
                      };
                      await navigator.share(shareData);
                      dispatch(
                        toggleToast(
                          true,
                          'Link shared successfully!',
                          'default',
                          undefined,
                          'top'
                        )
                      );
                    }
                  }}
                />
              </S.SessionInfoShareViaLink>
            </>
          ) : (
            <>
              <S.SessionInfoShareWrapper>
                <Select
                  desktopWidth={isLG ? '370px' : '100%'}
                  menuDirection="right"
                  hasDesktopMenuStyling={true}
                  hasMobileMenuStyling={true}
                  placeholder="Select..."
                  value={inviteMode}
                  onChange={setInviteMode}
                  options={dropdownOptions}
                />
                <S.SessionInfoProfileLink>
                  <S.SessionInfoProfileLinkText>
                    {getInviteLink()}
                  </S.SessionInfoProfileLinkText>
                </S.SessionInfoProfileLink>
              </S.SessionInfoShareWrapper>
              <S.CopyButton>
                <Button
                  text="Copy link"
                  onClick={handleCopyClick}
                  size="medium"
                  type="secondary"
                />
                {onboardingStep === 'share' && continueOnboarding && (
                  <TooltipWrapper
                    tooltipContent="Invite your friends and record video podcasts together!"
                    position="bottom-start"
                    width="300px"
                    callout={true}
                    dismiss={() => continueOnboarding('record-start')}
                    disableTooltip={onboardingStep !== 'share'}
                    size="large"
                    delayMount={true}
                    referenceContent={<S.TooltipWrapper />}
                  />
                )}
              </S.CopyButton>
            </>
          )}
        </S.SessionInfoInviteLink>
      </S.SessionInfoInvite>
      {showEdit && isMobile && (
        <Modal
          closeModal={() => {
            setShowEdit(false);
          }}
          buttons={{
            button1Text: 'Cancel',
            button2Text: 'Save',
            button2OnClick: handleSave,
          }}
        >
          <S.ModalContent>
            <S.ModalTitle>Edit Session name</S.ModalTitle>
            <TextInput
              value={sessionName}
              label="Session name"
              maxLength={80}
              autoFocus
              onChange={e => {
                e.preventDefault();
                setSessionName(e.target.value);
              }}
              error={error}
              keyPressHandler={handleSave}
            />
          </S.ModalContent>
        </Modal>
      )}
    </S.SessionInfoContainer>
  );
};

export const SessionInfoView = withTheme(SessionInfoViewComponent);
