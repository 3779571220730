import React from 'react';
// Components
import Modal from '@brightlive/shared/components/Modal';
// Style
import S from './style';
interface DeleteRecordingModalProps {
  closeModal: () => void;
  handleDeleteRequest: () => void;
  title: string;
  error?: boolean;
}

const DeleteRecordingModal = ({
  closeModal,
  handleDeleteRequest,
  title,
}: DeleteRecordingModalProps) => {
  return (
    <Modal
      closeModal={closeModal}
      buttons={{
        button1Text: 'Cancel',
        button2Text: 'Delete',
        button2TextNegative: true,
        button2OnClick: async () => {
          closeModal();
          handleDeleteRequest();
        },
      }}
    >
      <S.Content>
        <S.Title>Delete recording?</S.Title>
        <S.Body>
          {title} will be permanently deleted. This action cannot be undone.
          <br />
          <br />
          Are you sure you want to delete the recording?
        </S.Body>
      </S.Content>
    </Modal>
  );
};

export default React.memo(DeleteRecordingModal);
