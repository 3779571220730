import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
// Components
import {
  RoomState,
  ParticipantProps,
  Banner,
  StatusMessage,
} from 'bright-livekit';
import {ScreenshareLayout} from './Screenshare';
import {GeneralLayout} from './General';
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
// Styles
import S from './style';

const StageLayoutComponent = ({
  roomState,
  participantRenderer,
  currentTab = null,
  recordingMode,
  stageWrapperWidth,
  setStageWrapperWidth,
  theme,
}: {
  roomState: RoomState;
  participantRenderer?: (props: ParticipantProps) => React.ReactElement | null;
  rosterRenderer?: (props: ParticipantProps) => React.ReactElement | null;
  onStageRefChange?: (node: HTMLDivElement | null) => void;
  sessionID: string;
  totalStageParticipants?: number;
  isRecorder?: boolean;
  setIsLive?: Function;
  countdownRunning?: string;
  recordingMode?: string;
  currentTab?: string | null;
  stageWrapperWidth?: number;
  setStageWrapperWidth?: Function;
  theme: DefaultTheme;
}) => {
  const stageWrapperRef = useRef<HTMLDivElement>(null);
  const {width = 0} = useWindowSize();

  const [stageSize, setStageSize] = useState({
    height: 0,
    width: 0,
  });

  const updateStageWrapperSize = () => {
    // if not setting stage wrapper - this is a recording stage with no side bar so skip to setting size for that
    if (!setStageWrapperWidth) {
      updateRecordingStageSize();
      return;
    }
    // update stage wrapper width
    const spacingLG = theme?.spacing.LG ? parseInt(theme.spacing.LG, 10) : 24;
    const condensedSidebarWidth = 56;
    let openSidebarWidth = (width - spacingLG * 2) / 3;
    // adjust for min width for sidebar
    if (openSidebarWidth < 248) openSidebarWidth = 248;
    const sidebarOpenStageWrapperWidth = width - openSidebarWidth;
    const sidebarClosedStageWrapperWidth = width - condensedSidebarWidth;
    const newStageWrapperWidth = currentTab
      ? sidebarOpenStageWrapperWidth
      : sidebarClosedStageWrapperWidth;
    setStageWrapperWidth(Math.round(newStageWrapperWidth));

    if (stageWrapperRef?.current) {
      const availableHeight = stageWrapperRef?.current.clientHeight;
      const availableWidth = newStageWrapperWidth - spacingLG * 2; // account for left padding on video
      updateStageSize(availableHeight, availableWidth);
    }
  };

  const updateRecordingStageSize = () => {
    if (stageWrapperRef?.current) {
      const availableHeight = stageWrapperRef?.current.clientHeight;
      const availableWidth = stageWrapperRef?.current.clientWidth;
      updateStageSize(availableHeight, availableWidth);
    }
  };

  // update actual 9:16 stage size
  const updateStageSize = (availableHeight, availableWidth) => {
    let newWidth = availableWidth;
    let newHeight = (availableWidth * 9) / 16;
    if (newHeight > availableHeight) {
      // if 16:9 doesn't fit with 100% width - use 100% height
      newHeight = availableHeight;
      newWidth = (availableHeight * 16) / 9;
    }
    if (
      roomState.bannerImage &&
      roomState.bannerVisible &&
      roomState.sessionState === 'in-session'
    ) {
      const bannerHeight = Math.round(newWidth / 12);
      newHeight = newHeight - bannerHeight;
    }
    setStageSize({
      height: newHeight,
      width: newWidth,
    });
  };

  // Adjust stage size on resize and menu open
  useEffect(() => {
    updateStageWrapperSize();
  }, [currentTab, width, roomState, stageWrapperWidth]);

  let mainView: ReactElement;

  if (recordingMode === 'audio') {
    mainView = (
      <StatusMessage text="Audio Only" brandColor={roomState.brandColor} />
    );
  } else if (roomState.participantsOnStage.length === 0) {
    mainView = (
      <StatusMessage
        text="Your host will be here shortly"
        brandColor={roomState.brandColor}
      />
    );
  } else if (roomState.screenShareTrack) {
    mainView = (
      <ScreenshareLayout
        roomState={roomState}
        stageSize={stageSize}
        participantRenderer={participantRenderer}
      />
    );
  } else {
    mainView = (
      <GeneralLayout
        roomState={roomState}
        stageSize={stageSize}
        participantRenderer={participantRenderer}
      />
    );
  }

  return (
    <S.StageRefWrapper ref={stageWrapperRef}>
      <S.StageRefInnerWrapper>
        <S.StageRef
          style={{
            height: `${stageSize.height}px`,
            width: `${stageSize.width}px`,
          }}
        >
          {mainView}
        </S.StageRef>
        {roomState.sessionState === 'in-session' &&
          roomState.bannerVisible &&
          roomState.bannerImage && (
            <Banner
              image={roomState.bannerImage}
              position={roomState.bannerLayout}
              brandColor={roomState.brandColor}
            />
          )}
      </S.StageRefInnerWrapper>
    </S.StageRefWrapper>
  );
};

export const StageLayout = withTheme(StageLayoutComponent);
