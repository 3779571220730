import styled from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';

const S = {
  Header: styled(Ag.HeadingLG)`
    text-align: center;
    margin-bottom: ${props => props.theme.spacing.XL};
  `,

  Text: styled(Ag.ParagraphMD)`
    margin-bottom: ${props => props.theme.spacing.LG};
  `,

  CheckboxWrapper: styled.div`
    margin-bottom: ${props => props.theme.spacing['2XL']};
  `,
};
export default S;
