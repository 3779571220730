import styled, {DefaultTheme} from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';

const getBorderColor = (disabled, error) => {
  if (disabled) {
    return 'subdued';
  } else if (error) {
    return 'negative';
  }
  return 'default';
};

const S: Record<string, React.ElementType> = {};

S.RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

S.Radio = styled.div(
  (props: {disabled: boolean; $error: boolean; theme: DefaultTheme}) => `
  width: 24px;
  height: 24px;
  border: ${props.theme.borderWidth[1]} solid
    ${props.theme.borderColor[getBorderColor(props.disabled, props.$error)]};
  background-color: ${
    props.disabled
      ? props.theme.backgroundColor.disabled
      : props.theme.backgroundColor.primary
  };
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`
);

S.RadioInner = styled.div(
  (props: {checked: boolean; disabled: boolean; theme: DefaultTheme}) => `
  display: ${props.checked ? 'block' : 'none'};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${
    props.disabled
      ? props.theme.contentColor.disabled
      : props.theme.contentColor.default
  };
`
);

S.Label = styled(Ag.ParagraphMD)(
  (props: {disabled: boolean; theme: DefaultTheme}) => `
  flex: 1;
  margin-left: ${props.theme.spacing.SM};
  color: ${
    props.disabled
      ? props.theme.contentColor.disabled
      : props.theme.contentColor.default
  };
`
);

S.HelperText = styled(Ag.ParagraphSM)`
  color: ${props => props.theme.contentColor.subdued};
  padding-top: ${props => props.theme.spacing.XS};
`;

export default S;
