import React from 'react';
// Images
import Critical from '../../icons/Critical';
import Warning from '../../icons/Warning';
import Info from '../../icons/Info';
import CheckCircle from '../../icons/CheckCircle';
// Style
import S, {ContextualAlertTypes, contentColor} from './style';

export interface ContextualAlertProps {
  text: string;
  type: ContextualAlertTypes;
}

const ContextualAlert = ({text, type}: ContextualAlertProps) => {
  const getAlertIcon = () => {
    let Icon;
    switch (type) {
      case 'warning':
        Icon = Warning;
        break;
      case 'success':
        Icon = CheckCircle;
        break;
      case 'info':
        Icon = Info;
        break;
      case 'error':
      default:
        Icon = Critical;
        break;
    }
    return <Icon size={16} contentColor={contentColor[type]} />;
  };

  return (
    <S.AlertWrapper>
      <S.AlertIcon>{getAlertIcon()}</S.AlertIcon>
      <S.AlertText $type={type}>{text}</S.AlertText>
    </S.AlertWrapper>
  );
};

export default React.memo(ContextualAlert);
