import React, {useState, useCallback, useRef} from 'react';
import Cropper from 'react-easy-crop';
import {Point} from 'react-easy-crop/types';
// Components
import Modal from '../Modal';
import Alert from '../Alert';
import Button from '../Button';
import IconButton from '../IconButton';
import Slider from '../Slider';
// Helpers
import getCroppedImg from '../../helpers/cropImage';
/* eslint-disable  @typescript-eslint/no-explicit-any */

import S from './style';

interface ImageFile extends File {
  preview: string;
}

export interface ImageCropperProps {
  baseImage: ImageFile | null;
  setBaseImage: Function;
  closeModal: Function;
  url: string;
  setServerError: Function;
  serverError: boolean;
  loading: boolean;
  handleCrop: Function;
  cropShape?: 'rect' | 'round';
  aspect: number;
  backIsVisible?: boolean;
  handleBack?: Function;
  title?: string;
  subtitle?: string;
  desktopWidth?: string;
}

const MIN_ZOOM = 1;
const MAX_ZOOM = 2;

/**
 * ImageCropper
 *
 * @param   {object}  props
 *
 * @return  {JSX.Element}
 */
function ImageCropper({
  baseImage,
  setBaseImage,
  closeModal,
  url,
  setServerError,
  serverError,
  loading,
  handleCrop,
  cropShape = 'rect',
  aspect,
  backIsVisible = false,
  handleBack,
  title,
  subtitle,
  desktopWidth,
}: ImageCropperProps) {
  const fileInput = useRef<HTMLInputElement>(null);

  const [crop, setCrop] = useState<Point>({x: 0, y: 0});
  const [zoom, setZoom] = useState(1.5);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const createBlob = useCallback(async () => {
    try {
      const blob = await getCroppedImg(
        baseImage?.preview || `${process.env.NEXT_PUBLIC_IMAGE_PREFIX}${url}`,
        croppedAreaPixels
      );
      if (!blob) return;
      handleCrop(blob);
    } catch {
      setServerError(true);
    }
  }, [croppedAreaPixels]);

  const handleCancelClick = () => {
    setBaseImage(null);
    closeModal();
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const updateProfileImage = () => {
    if (fileInput && fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    if (!file) return;

    const fileWithPreview = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });

    setBaseImage(fileWithPreview);

    // if there are multiple images to crop and you arent on the first one - go back
    if (backIsVisible && handleBack) {
      handleBack();
    }
  };

  const changeZoom = (direction: string) => {
    const zoomDistance = MAX_ZOOM - MIN_ZOOM;
    const step = 0.2;
    let newZoom: number;
    if (direction === 'add') {
      newZoom = zoom + zoomDistance * step;
      if (newZoom > MAX_ZOOM) newZoom = MAX_ZOOM;
    } else {
      newZoom = zoom - zoomDistance * step;
      if (newZoom < MIN_ZOOM) newZoom = MIN_ZOOM;
    }
    setZoom(newZoom);
  };

  return (
    <Modal
      mobileHeight="100%"
      closeModal={closeModal}
      backVisible={backIsVisible}
      handleBack={handleBack}
      overflowMobile={true}
      desktopWidth={desktopWidth}
    >
      <S.ImageCropper>
        {serverError && (
          <S.ErrorWrapper>
            <Alert
              type="critical"
              title="Something went wrong"
              text="An error occurred while saving your changes. Please try again. If the
        problem persists, refresh the page or come back later."
            />
          </S.ErrorWrapper>
        )}
        {(title || subtitle) && (
          <S.Header>
            {title && <S.Title $backIsVisible={backIsVisible}>{title}</S.Title>}
            {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
          </S.Header>
        )}
        <S.FileInput
          type="file"
          ref={fileInput}
          onChange={handleFileChange}
          accept="image/*"
        />
        <S.CropperWrapper>
          <Cropper
            image={
              baseImage
                ? baseImage.preview
                : `${process.env.NEXT_PUBLIC_IMAGE_PREFIX}${url}`
            }
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape={cropShape}
            showGrid={false}
            style={{cropAreaStyle: {color: 'rgba(33,31,48,0.8)'}}}
          />
        </S.CropperWrapper>
        <S.ChangeImage onClick={updateProfileImage}>
          <S.ChangeImageLink>Change image</S.ChangeImageLink>
        </S.ChangeImage>
        <S.Slider>
          <IconButton
            icon="Minus"
            size="medium"
            type="tertiary"
            disabled={zoom === MIN_ZOOM}
            onClick={() => {
              changeZoom('minus');
            }}
          />
          <S.SliderWrapper>
            <Slider
              minValue={MIN_ZOOM}
              maxValue={MAX_ZOOM}
              sliderWidth={196}
              value={zoom}
              setValue={setZoom}
            />
          </S.SliderWrapper>
          <IconButton
            icon="Add"
            size="medium"
            type="tertiary"
            disabled={zoom === MAX_ZOOM}
            onClick={() => {
              changeZoom('add');
            }}
          />
        </S.Slider>
        <S.Buttons serverError={serverError}>
          <S.CancelButton>
            <Button
              type="secondary"
              size="medium"
              text="Cancel"
              onClick={handleCancelClick}
            />
          </S.CancelButton>
          <Button
            type="primary"
            size="medium"
            text={handleBack && !backIsVisible ? 'Next' : 'Save'}
            onClick={createBlob}
            loading={loading}
          />
        </S.Buttons>
      </S.ImageCropper>
    </Modal>
  );
}

export default ImageCropper;
