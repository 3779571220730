import styled, {DefaultTheme} from 'styled-components';
import {motion} from 'framer-motion';
import Ag from '@brightlive/shared/styles/typography-v3';

const S = {
  LivekitSidebar: styled.div`
    display: flex;
  `,
  LivekitSidebarInner: styled(motion.div)`
    position: absolute;
    right: -33vw;
    top: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: calc((100vw - ${props => props.theme.spacing.LG} * 2) / 3 - 56px);
    min-width: 248px;
    background-color: ${props => props.theme.backgroundColor.primary};
    z-index: 2;
    box-sizing: border-box;
  `,
  LivekitSidebarHeader: styled.div`
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    padding: ${props => props.theme.spacing.XS};
    border-bottom: ${props => props.theme.borderWidth[1]} solid
      ${props => props.theme.borderColor.subdued};
  `,
  LivekitSidebarHeaderText: styled(Ag.HeadingSM)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,

  LivekitSidebarNavigation: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.backgroundColor.body};
    position: relative;
    left: 0;
    z-index: 3;
  `,
  LivekitSidebarNavItem: styled.div(
    (props: {$active: boolean; theme: DefaultTheme}) => `
    padding: ${props.theme.spacing.MD};
    cursor: pointer;
    background-color: ${
      props.$active ? props.theme.actionColor.selected : 'unset'
    };
    position: relative;

    &:hover {
      background-color: ${props.theme.actionColor.hover};
    }
  `
  ),
  TooltipWrapper: styled.div`
    position: absolute;
    top: 50%;
  `,
  IconWrapper: styled.div`
    position: relative;
  `,
  ParticipantNavWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  ParticipantCount: styled(Ag.ParagraphXS)``,
  ChatContainer: styled.div`
    height: calc(100% - 72px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: auto;
    background-color: ${props => props.theme.backgroundColor.primary};
  `,
  UnreadFlag: styled(Ag.ParagraphXS)`
    min-width: ${props => props.theme.spacing.SM};
    padding: 2px ${props => props.theme.spacing['2XS']};
    position: absolute;
    top: -${props => props.theme.spacing.MD};
    right: -${props => props.theme.spacing.MD};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.backgroundColor.primary};
    background-color: ${props => props.theme.actionColor.brand};
    border: ${props => props.theme.borderWidth[4]} solid
      ${props => props.theme.backgroundColor.body};
    border-radius: ${props => props.theme.borderRadius.XL};
  `,
  ReplaceButton: styled.div`
    padding: ${props => props.theme.margin.mobile.page};
  `,
  SubmitQuestionButtonWrapper: styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: ${props => props.theme.margin.mobile.page};
  `,
  BoxShadow: styled.div`
    box-shadow: ${props => props.theme.elevation.level4};
    border-top-right-radius: ${props => props.theme.spacing.SM};
    border-top-left-radius: ${props => props.theme.spacing.SM};
  `,
  CancelButton: styled.div`
    margin-right: ${props => props.theme.spacing.XS};
  `,
  SettingsButton: styled.div`
    position: absolute;
    right: ${props => props.theme.spacing['2XS']};
    bottom: ${props => props.theme.spacing.MD};
    cursor: pointer;
  `,
};
export default S;
