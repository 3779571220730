import styled, {DefaultTheme} from 'styled-components';
import Imgix from 'react-imgix';
import {motion} from 'framer-motion';
import Ag, {
  getHeading2XS,
  getParagraphMD,
} from '@brightlive/shared/styles/typography-v3';
import {RecordingItemModes} from '.';
import {media} from '@brightlive/shared/styles/breakpoints-v3';

const S = {
  RecordingCard: styled.div(
    (props: {$mode: RecordingItemModes; theme: DefaultTheme}) => `
    width: 100%;
    display: flex;
    flex-direction: column;
    ${media.MD} {
      width: ${props.$mode === RecordingItemModes.Card ? '343px' : '100%'};
    }
  `
  ),

  Thumb: styled.div`
    height: 192px;
    overflow: hidden;
    position: relative;
  `,

  Image: styled(Imgix)`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,

  AnimatedImageWrapper: styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  `,

  New: styled.div`
    position: absolute;
    top: ${props => props.theme.spacing.MD};
    left: ${props => props.theme.spacing.MD};
  `,

  DurationTag: styled(Ag.ParagraphSM)`
    position: absolute;
    right: ${props => props.theme.spacing.SM};
    bottom: ${props => props.theme.spacing.SM};
    z-index: 2;
  `,

  PlayIcon: styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%),
    width: 50px;
    height: 50px;
    z-index: 2;
  `,

  Overlay: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  `,

  Content: styled.div(
    (props: {
      $preview?: boolean;
      $mode: RecordingItemModes;
      theme: DefaultTheme;
    }) => `
    padding: ${
      props.$mode === RecordingItemModes.List
        ? `${props.theme.spacing.SM} 0 ${props.theme.spacing.SM} ${props.theme.spacing.XS}`
        : 0
    };
    display: flex;
    flex-direction: ${
      props.$mode === RecordingItemModes.Card ? 'column' : 'row'
    };
    align-items: stretch;
    ${media.MD} {
        padding: ${
          props.$mode === RecordingItemModes.List
            ? `${props.theme.spacing.LG} ${props.theme.spacing.MD}`
            : 0
        };
      }
  `
  ),

  EditContainer: styled.div`
    display: flex;
    flex-direction: column;
    padding-top: ${props => props.theme.spacing.SM};
    padding-bottom: ${props => props.theme.spacing.SM};
  `,

  EditButtons: styled.div`
    display: flex;
    align-self: flex-end;
    margin-top: ${props => props.theme.spacing.LG};
    gap: ${props => props.theme.spacing.SM};
  `,

  RecordingCardTitle: styled.div(
    (props: {$mode: RecordingItemModes; theme: DefaultTheme}) => `
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: ${
        props.$mode === RecordingItemModes.Card ? '0' : props.theme.spacing.MD
      };
    `
  ),
  Title: styled(Ag.HeadingXS)(
    (props: {$mode: RecordingItemModes; theme: DefaultTheme}) => `
    ${props.$mode === RecordingItemModes.List && getHeading2XS(props.theme)};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${props.theme.contentColor.default};
    margin-top: ${
      props.$mode === RecordingItemModes.List ? 0 : props.theme.spacing.MD
    };
    margin-bottom: ${
      props.$mode === RecordingItemModes.List ? 0 : props.theme.spacing['2XS']
    };
  `
  ),
  RecordingMenuContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 250px;
    position: absolute;
    box-sizing: border-box;
    top: 60px;
    right: 0;
    margin-left: -200px;
  `,
  Description: styled(Ag.ParagraphSM)(
    (props: {$mode: RecordingItemModes; theme: DefaultTheme}) => `
    ${props.$mode === RecordingItemModes.Card && getParagraphMD(props.theme)};  
    color: ${props => props.theme.contentColor.subdued};
    margin-top: ${props => props.theme.spacing['2XS']};
  `
  ),

  DescriptionWrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  Buttons: styled.div(
    (props: {
      $mode: RecordingItemModes;
      $isMobile: boolean;
      theme: DefaultTheme;
    }) => `
    display: flex;
    align-items: center;
    gap: ${props.theme.spacing.XS};
    margin-bottom: ${
      props.$mode === RecordingItemModes.Card
        ? props.theme.spacing['2XL']
        : 'none'
    };
    margin-top: ${
      props.$mode === RecordingItemModes.Card ? props.theme.spacing.MD : 0
    };
    ${media.MD} {
      margin-bottom: ${
        props.$mode === RecordingItemModes.Card
          ? props.theme.spacing.MD
          : 'none'
      };
    }
  `
  ),
  Button: styled.div`
    position: relative;
  `,
  ImageContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: 48px;
  `,
  SpinnerContainer: styled.div(
    (props: {$mode: RecordingItemModes; theme: DefaultTheme}) => `
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props.theme.backgroundColor.secondary};
    margin-top: ${props.theme.spacing.XS};
    width: ${props.$mode === RecordingItemModes.Card ? '100%' : '85px'};
    height: ${props.$mode === RecordingItemModes.Card ? '202px' : '48px'};
  `
  ),

  ModalContent: styled.div`
    display: flex;
    flex-direction: column;
    justify-contents: center;
  `,

  ModalTitle: styled(Ag.HeadingMD)`
    text-align: center;
    margin-bottom: ${props => props.theme.spacing.XL};
  `,
};

export default S;
