import styled from 'styled-components';

const S = {
  EmojiPicker: styled.div`
    position: relative;
    z-index: 5;
  `,

  Popup: styled.div`
    position: absolute;
    bottom: 36px;
    left: 0;
    box-shadow: ${props => props.theme.elevation.level4};

    .picmo-picker {
      --ui-font: ${props => props.theme.typeface.ownersText};
      --background-color: ${props => props.theme.backgroundColor.primary};
      --secondary-background-color: ${props =>
        props.theme.backgroundColor.primary};
      --border-color: transparent;
      --category-name-background-color: ${props =>
        props.theme.backgroundColor.primary};
      --category-name-text-color: ${props => props.theme.contentColor.default};
      --hover-background-color: ${props => props.theme.actionColor.hover};
      --focus-indicator-color: ${props => props.theme.focusColor.default};
      --focus-indicator-background-color: ${props =>
        props.theme.focusColor.default};
      --text-color: ${props => props.theme.contentColor.default};
      --search-background-color: ${props =>
        props.theme.backgroundColor.secondary};
      --search-focus-background-color: ${props =>
        props.theme.backgroundColor.secondary};
      --search-placeholder-color: ${props => props.theme.contentColor.subdued};
      --search-icon-color: ${props => props.theme.contentColor.subdued};
      --scrollbar-color: ${props => props.theme.contentColor.moreSubdued};
    }

    .header {
      padding-bottom: 0;
    }
  `,
};
export default S;
