import React from 'react';
import Link from 'next/link';
import icons, {IconNames} from '../../icons';
// Components
import Spinner from '@brightlive/shared/components/Spinner';
// Helpers
import {IconProps} from '../../helpers/interfaces';
// Style
import S from './style';
import {ContentColor} from '@brightlive/shared/styles/theme-declarations/theme-mode.d';

export type Sizes = 'small' | 'medium';
export type ButtonTypes =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'primary inverse'
  | 'secondary inverse'
  | 'tertiary inverse'
  | 'positive'
  | 'negative';

export interface ButtonProps {
  type: ButtonTypes;
  disabled?: boolean;
  loading?: boolean;
  onClick?: Function;
  icon: IconNames;
  size: Sizes;
  href?: string;
  contentColor?:
    | 'default'
    | 'inverse'
    | 'inverseSubdued'
    | 'subdued'
    | 'negative';
  topRightContent?: React.ReactNode;
}

interface IconColors {
  default: {
    [key in ButtonTypes]: keyof ContentColor;
  };
  disabled: {
    [key in ButtonTypes]: keyof ContentColor;
  };
}

const IconButton = ({
  type,
  disabled = false,
  loading = false,
  onClick,
  icon,
  size,
  href,
  contentColor,
  topRightContent,
}: ButtonProps) => {
  const Icon: React.FC<IconProps> = icons[icon];

  const iconColors: IconColors = {
    default: {
      primary: 'inverse',
      'primary inverse': 'default',
      secondary: 'default',
      'secondary inverse': 'inverse',
      tertiary: 'subdued',
      'tertiary inverse': 'white',
      positive: 'white',
      negative: 'white',
    },
    disabled: {
      primary: 'disabled',
      'primary inverse': 'inverseDisabled',
      secondary: 'disabled',
      'secondary inverse': 'inverseDisabled',
      tertiary: 'disabled',
      'tertiary inverse': 'inverseDisabled',
      positive: 'disabled',
      negative: 'disabled',
    },
  };

  const handleClick = e => {
    e.stopPropagation();
    if (!onClick) {
      return null;
    }
    return onClick(e);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !disabled && onClick) {
      return onClick(e);
    }
  };

  const getSpinnerType = () => {
    if (
      ['primary inverse', 'secondary inverse', 'tertiary inverse'].includes(
        type
      )
    ) {
      return 'inverse';
    } else {
      return 'default';
    }
  };

  const getContent = () => {
    if (loading) {
      return (
        <S.LoaderWrapper>
          <Spinner size="small" type={getSpinnerType()} />
        </S.LoaderWrapper>
      );
    }
    const defaultColor = disabled
      ? iconColors.disabled[type]
      : iconColors.default[type];
    const overrideColor =
      type === 'tertiary' && contentColor ? contentColor : null;
    const iconColor = overrideColor || defaultColor;
    return (
      <S.Button $type={type} disabled={disabled} $size={size}>
        <Icon size={24} contentColor={iconColor} />
      </S.Button>
    );
  };

  // div with focus properties has to come immediately after <Link />
  if (href && !disabled) {
    return (
      <Link href={href} passHref>
        <S.ButtonWrapper
          tabIndex={0}
          $type={type}
          size={size}
          as="a"
          disabled={false}
        >
          {getContent()}
        </S.ButtonWrapper>
      </Link>
    );
  }

  return (
    <S.ButtonWrapper
      tabIndex={disabled ? -1 : 0}
      onKeyDown={handleKeyDown}
      $size={size}
      onClick={disabled ? null : handleClick}
      $type={type}
      disabled={disabled}
    >
      {topRightContent && (
        <S.TopRightContent as="div">{topRightContent}</S.TopRightContent>
      )}
      {getContent()}
    </S.ButtonWrapper>
  );
};

export default IconButton;
