import React from 'react';
import moment from 'moment';
import Linkify from 'linkify-react';
// Components
import Avatar from '@brightlive/shared/components/Avatar';
// Styles
import S from './style';

export const ChatMessage = ({
  text,
  displayName,
  timestamp,
  isMe,
  avatar,
}: {
  avatar: string;
  isMe: boolean;
  text: string;
  displayName: string;
  timestamp: Date;
}) => {
  moment.updateLocale('en', {
    relativeTime: {
      s: 'just now',
      ss: 'just now',
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
    },
  });

  return (
    <S.ChatMessage $isMe={isMe}>
      {!isMe && (
        <S.MessageTop>
          <S.MessageName>{displayName}</S.MessageName>
          <S.Timestamp>{moment(timestamp).fromNow(true)}</S.Timestamp>
        </S.MessageTop>
      )}
      <S.MessageBottom>
        {!isMe && (
          <S.AvatarContainer>
            <Avatar size="small" avatar={avatar} displayName={displayName} />
          </S.AvatarContainer>
        )}
        <S.MessageText $isMe={isMe}>
          <Linkify options={{target: '_blank', rel: 'noopener'}}>
            {text}
          </Linkify>
        </S.MessageText>
        {isMe && (
          <S.AvatarContainer>
            <Avatar size="small" avatar={avatar} displayName={displayName} />
          </S.AvatarContainer>
        )}
      </S.MessageBottom>
    </S.ChatMessage>
  );
};
