import React, {Dispatch, RefObject, useEffect, useRef, useState} from 'react';
// Components
import {EmojiPopup} from 'bright-livekit';
import IconButton from '@brightlive/shared/components/IconButton';
// Helpers
// Styles
import S from './style';

const MAX_CHARS = 140;

export const ChatInput = ({
  messageText,
  setMessageText,
  sendMessage,
  textareaRef,
}: {
  messageText: string;
  setMessageText: Dispatch<React.SetStateAction<string>>;
  sendMessage: Function;
  textareaRef: RefObject<HTMLTextAreaElement>;
}) => {
  const [originalHeight, setOriginalHeight] = useState('');
  useEffect(() => {
    handleScroll();
    if (!messageText && originalHeight && textareaRef.current) {
      textareaRef.current.style.height = originalHeight;
    }
  }, [messageText]);

  const backdropRef = useRef<HTMLDivElement>(null);

  const handleTextareaChange = e => {
    setMessageText(e.target.value);
    if (!originalHeight) {
      setOriginalHeight(e.target.style.height);
    }
    e.target.style.height = '';
    e.target.style.height = e.target.scrollHeight + 'px';
  };

  const handleEmojiSelect = emoji => {
    setMessageText(messageText + emoji);
  };
  const getHightlightContent = () => {
    if (messageText.length <= MAX_CHARS) return;
    const textArray = [
      messageText.slice(0, MAX_CHARS),
      messageText.slice(MAX_CHARS),
    ];
    return (
      <>
        {textArray[0]}
        <S.Mark>{textArray[1]}</S.Mark>
      </>
    );
  };

  const handleScroll = () => {
    if (textareaRef?.current && backdropRef.current) {
      const scrollTop = textareaRef.current.scrollTop;
      backdropRef.current.scrollTop = scrollTop;
    }
  };

  const onTextareaKeyDown = e => {
    if (e.keyCode === 13) {
      if (messageText.trim() && messageText.length <= MAX_CHARS) {
        sendMessage();
      }
      e.preventDefault();
    }
  };

  return (
    <S.ChatInput>
      <S.ChatInputInner>
        <S.TextAreaWrapperOuter $grow={true}>
          <S.TextAreaWrapperInner>
            <S.Backdrop ref={backdropRef}>
              <S.Highlights as="div">{getHightlightContent()}</S.Highlights>
            </S.Backdrop>
            <S.TextArea
              onChange={e => {
                handleTextareaChange(e);
              }}
              onKeyDown={onTextareaKeyDown}
              placeholder="Add a comment"
              value={messageText}
              ref={textareaRef}
              as="textarea"
              onScroll={handleScroll}
              $grow={true}
            />
          </S.TextAreaWrapperInner>
          <S.TextAreaBottomRow>
            <S.EmojiButton>
              <EmojiPopup onClick={handleEmojiSelect} />
            </S.EmojiButton>
            <S.CharCount $hasError={messageText.length > MAX_CHARS}>
              {messageText.length}/{MAX_CHARS}
            </S.CharCount>
          </S.TextAreaBottomRow>
        </S.TextAreaWrapperOuter>
        <S.ChatInputSubmit>
          <IconButton
            type="secondary"
            size="medium"
            icon="Send"
            onClick={sendMessage}
            disabled={!messageText.trim() || messageText.length > MAX_CHARS}
          />
        </S.ChatInputSubmit>
      </S.ChatInputInner>
    </S.ChatInput>
  );
};
