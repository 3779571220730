import {useEffect} from 'react';
import Link from 'next/link';
// Components
import Logo from '@brightlive/shared/components/Logo';
import IconButton from '@brightlive/shared/components/IconButton';
import Button from '@brightlive/shared/components/Button';
// Helpers
import {User} from '@brightlive/shared/helpers/interfaces';
// Styles
import S from './style';

interface HamburgerMenuProps {
  toggleMenu: Function;
  handleLogout: () => Promise<void>;
  currentUser: User;
  pathname: string;
  liveSession?: boolean;
}

const HamburgerMenu = ({
  toggleMenu,
  handleLogout,
  currentUser,
  pathname,
  liveSession,
}: HamburgerMenuProps) => {
  // const [creatorExpanded, setCreatorExpanded] = useState(isCreatorStudio);
  // const [settingsExpanded, setSettingsExpanded] = useState(false);

  const loggedIn = currentUser?.email;
  const isCreator =
    currentUser?.roles?.includes('creator') ||
    currentUser?.roles?.includes('admin') ||
    !!currentUser?.managerFor?.length;

  useEffect(() => {
    const body = document.querySelector('body') as HTMLBodyElement;
    if (body) {
      body.style.overflow = 'hidden';
    }
    return () => {
      if (body) {
        body.style.overflow = 'visible';
      }
    };
  }, []);

  // const navigateAndCloseMenu = (href: string, isCreatorLink: boolean) => {
  //   return async (e: {preventDefault: () => void}) => {
  //     e.preventDefault();
  //     // if opening a link on the same domain
  //     if (
  //       (isCreatorLink && isCreatorStudio) ||
  //       (!isCreatorLink && !isCreatorStudio)
  //     ) {
  //       return router.push(href).then(() => {
  //         toggleMenu();
  //       });
  //       // else if opening a link on a different domain
  //     } else {
  //       const baseUrl = isCreatorLink
  //         ? process.env.NEXT_PUBLIC_CREATOR_STUDIO_URL
  //         : process.env.NEXT_PUBLIC_BOOKING_URL;
  //       return window.open(`${baseUrl}${href}`, '_self');
  //     }
  //   };
  // };

  return (
    <S.MenuWrapper>
      <S.TopBar>
        <a
          title="home page"
          href={!isCreator ? '/' : '/dashboard/sessions'}
          target={liveSession ? '_blank' : '_self'}
        >
          <Logo height={48} />
        </a>
        <IconButton
          type="tertiary"
          size="medium"
          icon="Close"
          onClick={toggleMenu}
        />
      </S.TopBar>
      <S.Menu>
        {loggedIn ? (
          <>
            <Link href="/dashboard/sessions">
              <S.MenuItem $active={pathname === '/dashboard/sessions'}>
                Sessions
              </S.MenuItem>
            </Link>
            <Link href="/settings">
              <S.MenuItem $active={pathname === '/settings'}>
                Settings
              </S.MenuItem>
            </Link>
            <S.Divider />
            <S.MenuItem onClick={handleLogout}>Sign out</S.MenuItem>
          </>
        ) : (
          <Link href="/login">
            <S.MenuItem>Login</S.MenuItem>
          </Link>
        )}
        {!loggedIn && (
          <S.SignUpButton>
            <Button
              type="lightning"
              size="medium"
              text="Get started for free"
              href="/login?signup=true"
              width="100%"
            />
          </S.SignUpButton>
        )}
      </S.Menu>
    </S.MenuWrapper>
  );
};

HamburgerMenu.defaultProps = {
  homepage: false,
};

export default HamburgerMenu;
