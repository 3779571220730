import styled, {DefaultTheme} from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';

const S: Record<string, React.ElementType> = {};

S.ShareLink = styled.div(
  (props: {width: number; theme: DefaultTheme}) => `
  width: ${props.width ? `${props.width}px` : '100%'};
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props.theme.backgroundColor.disabled};
  border-radius: ${props.theme.borderRadius.SM};
  padding: ${props.theme.spacing.SM} ${props.theme.spacing.SM}
    ${props.theme.spacing.SM} ${props.theme.spacing.MD};
`
);

S.Text = styled(Ag.ParagraphMD)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: ${props => props.theme.spacing.XS};
`;

export default S;
