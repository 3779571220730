import React from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
import {IconProps} from '@brightlive/shared/helpers/interfaces';

const PictureInPicture = ({
  size = 24,
  contentColor = 'default',
  theme,
  ...props
}: IconProps & {
  theme: DefaultTheme;
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M12 17h6c.283 0 .52-.096.712-.288A.965.965 0 0 0 19 16v-4a.968.968 0 0 0-.288-.713A.967.967 0 0 0 18 11h-6a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 12v4c0 .283.096.52.288.712A.965.965 0 0 0 12 17Zm-8 3c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 4h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 20H4Zm0-2h16V6H4v12Z"
      fill={
        contentColor.indexOf('#') > -1
          ? contentColor
          : theme.contentColor[contentColor]
      }
    />
  </svg>
);

export default withTheme(PictureInPicture);
