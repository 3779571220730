import styled, {DefaultTheme} from 'styled-components';
import Ag, {getLink} from '@brightlive/shared/styles/typography-v3';
import {BackgroundColor} from '@brightlive/shared/styles/theme-declarations/theme-mode.d';

const S: Record<string, React.ElementType> = {};

export type AlertTypes = 'informational' | 'warning' | 'critical';

type Colors = {
  [key in AlertTypes]: keyof BackgroundColor;
};

const colors: Colors = {
  informational: 'subduedInformational',
  warning: 'warning',
  critical: 'subduedNegative',
};

S.Alert = styled.div(
  (props: {
    type: 'informational' | 'warning' | 'critical';
    theme: DefaultTheme;
  }) => `
  background: ${props.theme.backgroundColor[colors[props.type]]};
  display: flex;
  align-items: flex-start;
  padding: ${props.theme.spacing.MD};
  position: relative;
  z-index: 1;
`
);

S.Content = styled.div`
  margin-left: ${props => props.theme.spacing.MD};
  flex: 1;
`;

S.Text = styled(Ag.ParagraphMD)`
  flex: 1;
  display: flex;
  align-items: center;

  a {
    ${props => getLink(props.theme)}
  }
`;

S.Title = styled(Ag.HeadingXS)`
  margin-bottom: ${props => props.theme.spacing['2XS']};
`;

export default S;
