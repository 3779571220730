import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag from '@brightlive/shared/styles/typography-v3';
import styled from 'styled-components';

const S = {
  RecordingListContainer: styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.backgroundColor.primary};
    height: 100%;
    overflow-y: auto;
    position: relative;
    padding: 0 ${props => props.theme.margin.mobile.page}
      ${props => props.theme.margin.mobile.page};
  `,
  Recording: styled.div`
    display: flex;
    justify-content: center;

    &:not(:last-child) {
      border-bottom: ${props => props.theme.borderWidth[1]} solid
        ${props => props.theme.borderColor.subdued};
    }
    ${media.MD} {
      justify-content: space-between;
    }
  `,
  NoRecordings: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.backgroundColor.primary};
    height: 100%;
    padding: ${props => props.theme.margin.mobile.page}
      ${props => props.theme.margin.mobile.page};
  `,
  NoRecordingsIcon: styled.div`
    padding-bottom: ${props => props.theme.spacing.LG};
    font-size: ${props => props.theme.fontSize[900]};
  `,
  NoRecordingsText: styled(Ag.ParagraphMD)``,
};
export default S;
