import React, {useState} from 'react';
// Components
import Accordion from '@brightlive/shared/components/Accordion';
import LayoutItem from '../LayoutItem';
// Types
import {LayoutItemBase, LayoutBase} from '../index';
import {LayoutUpdateProps} from 'bright-livekit';
// Styles
import S from '../style';

interface LayoutSectionBase {
  title: string;
  items: LayoutItemBase[];
  updateKey: keyof LayoutUpdateProps;
}

type LayoutSectionProps = LayoutBase & LayoutSectionBase;

const LayoutSection = ({
  title,
  items,
  setLayout,
  currentValue,
  updateKey,
}: LayoutSectionProps) => {
  const [open, setOpen] = useState(true);

  return (
    <S.Section>
      <Accordion
        size="small"
        text={title}
        open={open}
        onClick={() => setOpen(!open)}
      />
      {open && (
        <S.SectionContent>
          {items.map((item, i) => {
            return (
              <LayoutItem
                key={`${title}-item-${i}`}
                label={item.label}
                image={item.image}
                value={item.value}
                updateKey={updateKey}
                setLayout={setLayout}
                currentValue={currentValue}
              />
            );
          })}
        </S.SectionContent>
      )}
    </S.Section>
  );
};

export default LayoutSection;
