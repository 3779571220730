import React from 'react';
// Icons
import Check from '@brightlive/shared/icons/Check';
// Types
import {LayoutItemBase, LayoutBase} from '../index';
import {LayoutUpdateProps} from 'bright-livekit';
// Helpers
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
// Styles
import S from '../style';

type LayoutItemProps = LayoutBase &
  LayoutItemBase & {updateKey: keyof LayoutUpdateProps};

const LayoutItem = ({
  currentValue,
  setLayout,
  updateKey,
  label,
  image,
  value,
}: LayoutItemProps) => {
  const {isLG} = useWindowSize();
  const selected = currentValue === value;

  const getImage = () => {
    if (typeof image === 'string') {
      return <S.Image src={image} />;
    } else {
      return image;
    }
  };

  return (
    <S.LayoutItem
      $selected={selected}
      onClick={() => {
        setLayout({key: updateKey, value});
      }}
      tabIndex={0}
    >
      {getImage()}
      <S.Label>
        <S.LabelText $selected={selected}>{label}</S.LabelText>
        <div>{selected && <Check size={isLG ? 24 : 16} />}</div>
      </S.Label>
    </S.LayoutItem>
  );
};

export default LayoutItem;
