import React, {useState} from 'react';
import {AuthReducer} from 'redux/auth/reducer';
import {useSelector} from 'react-redux';
//Components
import {
  RoomState,
  useParticipant,
  VideoRenderer,
  DeviceSelect,
} from 'bright-livekit';
import IconButton from '@brightlive/shared/components/IconButton';
import Tag from '@brightlive/shared/components/Tag';
import Avatar from '@brightlive/shared/components/Avatar';
// Hooks
import {useInterval} from '@brightlive/shared/hooks/useInterval';
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
// Styles
import S from './style';

export const DeviceSettings = ({roomState}: {roomState: RoomState}) => {
  const [audioHz, setAudioHz] = useState(0);
  const [resolution, setResolution] = useState(720);
  const [fps, setFps] = useState(0);

  const currentUser = useSelector(
    (state: AuthReducer) => state.auth.currentUser
  );

  const {isMobile} = useWindowSize();

  const {cameraPublication, isAudioMuted, isVideoMuted} = useParticipant(
    roomState.selfParticipant?.videoParticipant
  );

  useInterval(() => {
    const part = roomState.videoService?.localParticipant;
    if (!part) {
      return;
    }
    if (part.videoTracks.length > 0) {
      const vidTrack = part.videoTracks[0];
      setResolution(vidTrack.dimensions.height);
      setFps(vidTrack.framerate);
    }
    if (part.audioTracks.length > 0) {
      const audTrack = part.audioTracks[0];
      setAudioHz(audTrack.audioHz);
    }
  }, 1000);

  return (
    <S.DeviceSettings>
      <S.DeviceSettingsVideoWrapper>
        {cameraPublication?.isSubscribed &&
        cameraPublication?.track &&
        !cameraPublication?.isMuted ? (
          <VideoRenderer
            track={cameraPublication.track}
            isLocal={true}
            objectFit="cover"
            width={isMobile ? '343px' : '429px'}
            height={isMobile ? '192px' : '240px'}
          />
        ) : (
          <S.DeviceSettingsAvatarContainer>
            <Avatar
              size={isMobile ? 'medium' : 'large'}
              avatar={currentUser.avatar}
              displayName={currentUser.displayName}
              screenshare={roomState.screenShareUser === currentUser.id}
              userFlagged={false}
              videoMuted={false}
            />
          </S.DeviceSettingsAvatarContainer>
        )}
        <S.DeviceSettingsStats>
          <Tag color="black" text={`${audioHz / 1000} hHz`} type="basic" />
          <Tag color="black" text={`${resolution}p`} type="basic" />
          <Tag color="black" text={`${fps} fps`} type="basic" />
        </S.DeviceSettingsStats>
        <S.DeviceSettingsDeviceToggle>
          <S.DeviceSettingsAudioButton>
            <IconButton
              type={isAudioMuted ? 'negative' : 'secondary'}
              icon={isAudioMuted ? 'MicOff' : 'MicOn'}
              size="medium"
              onClick={() => {
                if (roomState.videoService)
                  roomState.videoService.localParticipant?.setMicrophoneEnabled(
                    isAudioMuted
                  );
              }}
            />
          </S.DeviceSettingsAudioButton>
          <S.DeviceSettingsVideoButton>
            <IconButton
              type={isVideoMuted ? 'negative' : 'secondary'}
              icon={isVideoMuted ? 'VideocamOff' : 'VideocamOn'}
              size="medium"
              onClick={() => {
                if (roomState.videoService)
                  roomState.videoService.localParticipant?.setCameraEnabled(
                    isVideoMuted
                  );
              }}
            />
          </S.DeviceSettingsVideoButton>
        </S.DeviceSettingsDeviceToggle>
      </S.DeviceSettingsVideoWrapper>
      <S.DeviceSettingsControls>
        {roomState?.videoService && (
          <DeviceSelect videoService={roomState.videoService} />
        )}
      </S.DeviceSettingsControls>
    </S.DeviceSettings>
  );
};
