import styled, {DefaultTheme} from 'styled-components';
import {media} from '@brightlive/shared/styles/breakpoints-v3';

const S: Record<string, React.ElementType> = {};

S.VideoWrapper = styled.div(
  (props: {theme: DefaultTheme}) => `
  height: 100%;
  width: 100%;

  .video-js.video-js .vjs-big-play-button {
    background: url('/images/play-button.svg') 25% center/contain no-repeat;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    width: 50px;
    height: 50px;
    border: none;

    ${media.MD} {
      width: 72px;
      height: 72px;
    }
  }

  /* Kill default play icon */
  .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    content: none;
  }

  .vjs-fluid {
    overflow: hidden;
  }

  /* top progress bar/seek bar */
  .video-js .vjs-control-bar {
    background-color: ${props.theme.backgroundColor.blackTransparent};
    padding-left: 0;
    padding-right: 0;
    padding-top: ${props.theme.spacing.XL};
    height: 74px;
  }

  .video-js .vjs-progress-control {
    position: absolute;
    top: ${props.theme.spacing.LG};
    left: ${props.theme.spacing.SM};
    right: ${props.theme.spacing.SM};
    height: 2px;
    color: ${props.theme.contentColor.inverse};
    width: calc(100% - ${props.theme.spacing.LG});
  }

  .video-js .vjs-progress-control  .vjs-progress-holder {
    background-color: rgba(255,255,255,0.24);
    margin: 0;
  }

  .video-js .vjs-play-progress {
    background-color: ${props.theme.backgroundColor.white};
  }

  /* current time / duration */
  .video-js .vjs-time-control {
    padding-top: 10px;
    padding-left: ${props.theme.spacing['2XS']};
    padding-right: 0;
    min-width: 0;
    font-family: ${props.theme.typeface.ownersText};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }

  .video-js .vjs-current-time {
    display: block;
    padding-left: 8px;
  }

  .video-js .vjs-time-divider {
    display: block;
  }

  .video-js .vjs-remaining-time {
    display: none;
  }

  .vjs-button > .vjs-icon-placeholder:before {
    font-size: 2.2em;
  }

  .video-js .vjs-duration {
    display: block;
    padding-left: ${props.theme.spacing['2XS']};
  }

  /* Full screen control */
  .video-js .vjs-fullscreen-control {
    position: absolute;
    right: 2px;
  }

  /* Playback rates menu */
  .video-js .vjs-playback-rate {
    position: absolute;
    top: 42px;
    right: 35px;
  }

  .vjs-playback-rate .vjs-playback-rate-value {
    font-family: ${props.theme.typeface.ownersText};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }

  /* Picture in Picture control */
  .video-js .vjs-picture-in-picture-control {
    display: block;
    position: absolute;
    right: 72px;
  }

  .video-js .vjs-vtt-thumbnails {
    display: block;
  }

  .video-js .vjs-vtt-thumbnail-display {
    position: absolute;
    transition: opacity 0.2s;
    bottom: 85%;
    pointer-events: none;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.6);
  }

  .vjs-error .vjs-error-display:before {
    content: '';
  }

  .vjs-error .vjs-loading-spinner {
    display: none;
  }

  // AG.ParapraphMedium - todo: swap when style tokens when avail
  .vjs-error .vjs-error-display .vjs-modal-dialog-content {
    font-family: ${props.theme.typeface.ownersText};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${props.theme.contentColor.white};
    padding: ${props.theme.spacing.MD};
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 365px;
    text-align: center;
    margin: 0 auto;
    position: static;
  }

  // ensure fullscreen when on fullscreen mode on desktop as well
  // !important used to overwrite all media queries
  .video-js.vjs-fullscreen:not(.vjs-ios-native-fs) {
    .vjs-tech{
      width: 100% !important;
      height: 100% !important;
      padding-top: 0 !important;
    }
  }
`
);

S.AutoplayOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export default S;
