import styled from 'styled-components';
import {BannerLayoutTypes} from 'bright-livekit';
import {Hex} from '@brightlive/shared/helpers/interfaces';

const S = {
  Banner: styled.div(
    (props: {$position: BannerLayoutTypes; $brandColor: Hex}) => `
      width: 100%;
      object-fit: cover;
      order: ${props.$position === BannerLayoutTypes.Top ? '0' : '2'};
      background-color: ${props.$brandColor};

      img {
        width: 100%;
      }
    `
  ),
};

export default S;
