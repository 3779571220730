import React from 'react';
// Style
import S, {Align} from './style';
import {MediaSize} from '@brightlive/shared/styles/breakpoints-v3';

export interface ColProps {
  xs?: 1 | 2 | 3 | 4;
  sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  xl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  xsOffset?: 0 | 1 | 2 | 3;
  smOffset?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
  mdOffset?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  lgOffset?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  xlOffset?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  collapse?: MediaSize[];
  first?: MediaSize[];
  last?: MediaSize[];
  children: JSX.Element | Array<JSX.Element | false | undefined> | false;
}

export interface RowProps {
  align?: Align;
  smAlign?: Align;
  mdAlign?: Align;
  lgAlign?: Align;
  xlAlign?: Align;
  children: JSX.Element | Array<JSX.Element | false | undefined> | false;
}

export const Grid = ({children}) => <S.Grid>{children}</S.Grid>;

export const Row = ({
  align,
  smAlign,
  mdAlign,
  lgAlign,
  xlAlign,
  children,
}: RowProps) => (
  <S.Row
    $align={align}
    $smAlign={smAlign}
    $mdAlign={mdAlign}
    $lgAlign={lgAlign}
    $xlAlign={xlAlign}
  >
    {children}
  </S.Row>
);

export const Col = ({
  xs,
  sm,
  md,
  lg,
  xl,
  xsOffset,
  smOffset,
  mdOffset,
  lgOffset,
  xlOffset,
  collapse,
  first,
  last,
  children,
}: ColProps) => {
  return (
    <S.Col
      $xs={xs}
      $sm={sm}
      $md={md}
      $lg={lg}
      $xl={xl}
      $xsOffset={xsOffset}
      $smOffset={smOffset}
      $mdOffset={mdOffset}
      $lgOffset={lgOffset}
      $xlOffset={xlOffset}
      $collapse={collapse}
      $first={first}
      $last={last}
    >
      {children}
    </S.Col>
  );
};
