import styled from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';
import {media} from '@brightlive/shared/styles/breakpoints-v3';

const S = {
  InviteModalContent: styled.div`
    display: flex;
    flex-direction: column;
  `,

  FlexWrapper: styled.div`
    display: flex;
    flex-direction: column;
    ${media.MD} {
      flex-direction: row;
      align-items: center;
      gap: ${props => props.theme.spacing.MD};
      margin-bottom: ${props => props.theme.spacing.LG};
    }
  `,

  InviteModalTitle: styled(Ag.HeadingMD)`
    text-align: center;
  `,
  InviteModalBody: styled(Ag.ParagraphMD)`
    margin-top: ${props => props.theme.spacing.XL};
    width: 100%;
  `,

  InviteModalHeader: styled(Ag.HeadingXS)``,

  InviteModalDescription: styled(Ag.ParagraphMD)`
    margin-top: ${props => props.theme.spacing.XS};
    margin-bottom: ${props => props.theme.spacing.LG};
  `,

  InviteModalLinkWrapper: styled.div`
    margin-top: ${props => props.theme.spacing.MD};
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${props => props.theme.backgroundColor.disabled};
    border-radius: ${props => props.theme.borderRadius.SM};
    border: ${props => props.theme.borderWidth[1]} solid
      ${props => props.theme.borderColor.subdued};
    padding: ${props => props.theme.spacing.XS}
      ${props => props.theme.spacing.MD};
    margin-bottom: ${props => props.theme.spacing.LG};
    ${media.MD} {
      width: 376px;
      margin: 0;
    }
  `,
  InviteModalLink: styled(Ag.ParagraphMD)`
    white-space: nowrap;
    overflow-x: scroll;
  `,
  CopyLink: styled(Ag.ParagraphMD)`
    display: flex;
  `,
};

export default S;
