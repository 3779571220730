import Ag from '@brightlive/shared/styles/typography-v3';
import styled from 'styled-components';

const S = {
  ModMessageContainer: styled.div`
    position: absolute;
    bottom: 62px;
    left: 0;
    width: 320px;
    z-index: 2000;
    border-radius: ${props => props.theme.borderRadius.MD};
    background-color: ${props => props.theme.backgroundColor.primary};
  `,

  Inner: styled.div`
    padding: ${props => props.theme.spacing.LG}
      ${props => props.theme.spacing.MD};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${props => props.theme.spacing.XS};
  `,

  Bottom: styled.div`
    border-top: 1px solid ${props => props.theme.borderColor.subdued};
    padding: ${props => props.theme.spacing.MD};
    display: flex;
    justify-content: flex-end;
  `,

  Header: styled(Ag.HeadingXS)``,

  CloseButton: styled.div`
    margin-right: ${props => props.theme.spacing.XS};
  `,
};
export default S;
