import styled, {DefaultTheme} from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';
import {media} from '@brightlive/shared/styles/breakpoints-v3';

const S = {
  ChatInput: styled.div`
    border-top-left-radius: ${props => props.theme.borderRadius.SM};
    border-top-right-radius: ${props => props.theme.borderRadius.SM};
    margin-bottom: ${props => props.theme.spacing.MD};
    padding: 0 ${props => props.theme.margin.mobile.page};
    display: flex;

    ${media.MD} {
      margin: 0;
      padding: 0;
    }
  `,

  ChatInputInner: styled.div`
    display: flex;
    width: 100%;
  `,

  TextAreaWrapperOuter: styled.div`
    flex: 1;
    padding: ${props => props.theme.spacing.MD};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  `,

  TextAreaWrapperInner: styled.div`
    flex: 1;
    display: flex;
    position: relative;
  `,

  Backdrop: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    overflow: auto;
    pointer-events: none;
  `,

  Highlights: styled(Ag.ParagraphMD)`
    color: transparent;
    white-space: pre-wrap;
    word-wrap: break-word;
  `,

  Mark: styled.mark`
    background: ${props => props.theme.backgroundColor.negative};
    color: ${props => props.theme.contentColor.inverse};
  `,

  TextArea: styled(Ag.ParagraphMD)`
    color: ${props => props.theme.contentColor.default};
    background: transparent;
    border: none;
    flex: 1;
    box-sizing: border-box;
    resize: none;
    overflow: auto;
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 0;
    min-height: 95px;

    &::placeholder {
      color: ${props => props.theme.contentColor.subdued};
    }
  `,

  TextAreaBottomRow: styled.div`
    display: none;

    ${media.MD} {
      display: flex;
      align-items: center;
      padding-top: ${props => props.theme.spacing.XS};
    }
  `,

  EmojiButton: styled.div`
    cursor: pointer;
    margin-right: ${props => props.theme.spacing.MD};
  `,

  CharCount: styled(Ag.ParagraphXS)(
    (props: {$hasError: boolean; theme: DefaultTheme}) => `
    color: ${
      props.$hasError
        ? props.theme.contentColor.negative
        : props.theme.contentColor.default
    };
`
  ),

  ChatInputVideoPreview: styled.div`
    display: flex;
    align-items: center;
  `,

  ChatInputAvatar: styled.div`
    padding: 0 ${props => props.theme.spacing.MD};
  `,
};
export default S;
