import styled, {DefaultTheme} from 'styled-components';
import {BorderColor} from '@brightlive/shared/styles/theme-declarations/theme-mode.d';

import {DividerType, Direction} from './';

const S: Record<string, React.ElementType> = {};

type BackgroundColor = {
  [key in DividerType]: keyof BorderColor;
};

const backgroundColor: BackgroundColor = {
  default: 'subdued',
  inverse: 'inverseSubdued',
};

S.Divider = styled.div(
  (props: {$type: DividerType; $direction: Direction; theme: DefaultTheme}) => `
  width: ${props.$direction === 'horizontal' ? '100%' : '1px'};
  height: ${props.$direction === 'horizontal' ? '1px' : '100%'};
  background-color: ${props.theme.borderColor[backgroundColor[props.$type]]};
`
);

export default S;
