import React from 'react';
import {RoomState} from 'bright-livekit/types/RoomState';
//Components
import Divider from '@brightlive/shared/components/Divider';
import LivestreamToggle, {
  LivestreamServices,
} from 'components/session/LivestreamToggle';
// Styles
import S from './style';

interface LiveStreamProps {
  sessionID: string;
  roomState: RoomState;
}

export const LivestreamSettings = ({sessionID, roomState}: LiveStreamProps) => {
  return (
    <S.LivestreamSettings>
      <S.LivestreamParagraph>
        {roomState.isRecording
          ? 'These settings will take effect next time you start a recording'
          : 'Once you start recording, broadcast live to streaming platforms.'}
      </S.LivestreamParagraph>
      <LivestreamToggle
        service={LivestreamServices.youtube}
        title="Youtube"
        sessionID={sessionID}
      />
      <Divider />
      <LivestreamToggle
        service={LivestreamServices.twitch}
        title="Twitch"
        sessionID={sessionID}
      />
      <Divider />
      <LivestreamToggle
        service={LivestreamServices.facebook}
        title="Facebook"
        sessionID={sessionID}
      />
      <Divider />
      <LivestreamToggle
        service={LivestreamServices.other}
        title="Other"
        sessionID={sessionID}
      />
    </S.LivestreamSettings>
  );
};
