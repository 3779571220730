import styled, {DefaultTheme} from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';
import {media} from '@brightlive/shared/styles/breakpoints-v3';

const S: Record<string, React.ElementType> = {};

S.ChatInput = styled.div`
  padding: 0 ${props => props.theme.margin.mobile.page};
  display: flex;
  flex-grow: 1;

  ${media.MD} {
    margin: ${props => props.theme.spacing.MD};
    padding: 0;
  }
`;

S.ChatInputInner = styled.div`
  border-radius: ${props => props.theme.borderRadius.MD};
  display: flex;
  width: 100%;
  background: ${props => props.theme.backgroundColor.tertiary};
`;
S.ChatInputSubmit = styled.div`
  align-self: center;
  margin-right: ${props => props.theme.spacing['2XS']};
`;
S.TextAreaWrapperOuter = styled.div`
  flex: 1;
  padding: ${props => props.theme.spacing.MD};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

S.TextAreaWrapperInner = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

S.Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  overflow: auto;
  pointer-events: none;
`;

S.Highlights = styled(Ag.ParagraphMD)`
  color: transparent;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

S.Mark = styled.mark`
  background: ${props => props.theme.backgroundColor.negative};
  color: ${props => props.theme.contentColor.inverse};
`;

S.TextArea = styled(Ag.ParagraphMD)`
  color: ${props => props.theme.contentColor.default};
  background: transparent;
  border: none;
  flex: 1;
  box-sizing: border-box;
  resize: none;
  overflow: auto;
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
  height: ${props => props.theme.lineHeight[200]};

  &::placeholder {
    color: ${props => props.theme.contentColor.subdued};
  }
`;

S.TextAreaBottomRow = styled.div`
  display: none;

  ${media.MD} {
    display: flex;
    align-items: center;
    padding-top: ${props => props.theme.spacing.XS};
  }
`;

S.EmojiButton = styled.div`
  cursor: pointer;
  margin-right: ${props => props.theme.spacing.MD};
`;

S.CharCount = styled(Ag.ParagraphXS)(
  (props: {$hasError: boolean; theme: DefaultTheme}) => `
    color: ${
      props.$hasError
        ? props.theme.contentColor.negative
        : props.theme.contentColor.default
    };
`
);

S.ChatInputVideoPreview = styled.div`
  border-radius: ${props => props.theme.borderRadius.MD};
  // overflow: hidden;
  display: flex;
  align-items: center;
`;

S.ChatInputAvatar = styled.div`
  padding: 0 ${props => props.theme.spacing.SM};
`;
export default S;
