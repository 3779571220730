import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
//Components
import TextInput from '@brightlive/shared/components/TextInput';
import Switch from '@brightlive/shared/components/Switch';
// Actions
import useDebounce from '@brightlive/shared/hooks/useDebounce';
import {SessionReducer} from 'redux/session/reducer';
import {updateSimulcastKey} from 'redux/session/actions';
// Styles
import S from './style';

export enum LivestreamServices {
  youtube = 'youtube',
  twitch = 'twitch',
  facebook = 'facebook',
  other = 'other',
}

interface LiveStreamProps {
  sessionID: string;
  service: LivestreamServices;
  title: string;
}

const LivestreamToggle = ({sessionID, service, title}: LiveStreamProps) => {
  const dispatch = useDispatch();

  const livestreams = useSelector(
    (state: SessionReducer) => state.session.livestreams.simulcasts || []
  );
  // Youtube State
  const [displayLivestream, setDisplayLivestream] = useState(false);
  const [streamKey, setStreamKey] = useState('');
  const [streamUrl, setStreamUrl] = useState('');
  const [loaded, setLoaded] = useState(false);
  const debouncedDisplayLivestream = useDebounce<boolean>(
    displayLivestream,
    500
  );
  const debouncedStreamKey = useDebounce<string>(streamKey, 500);
  const debouncedStreamUrl = useDebounce<string>(streamUrl, 500);

  useEffect(() => {
    // Set keys if available
    if (livestreams?.length) {
      const existingStream = livestreams.find(c => c.service === service);
      if (existingStream) {
        setDisplayLivestream(existingStream.enabled);
        setStreamKey(existingStream.key);
        setStreamUrl(existingStream.url);
      }
    }
    setLoaded(true);
  }, []);

  const updateService = async (
    target: string,
    enabled: boolean,
    streamKey: string,
    streamUrl: string
  ) => {
    const existing = livestreams.find(s => s.service === target);

    // Don't save if the services are not yet loaded
    if (!loaded) {
      return;
    }

    // Don't save if enabled and no stream key yet
    if (enabled && !streamKey) {
      return;
    }

    // Don't save "other" stream if both the url and streamkey are not set
    if (enabled && target === 'other' && (!streamKey || !streamUrl)) {
      return;
    }

    // Don't save if the values are the same
    if (
      existing?.enabled === enabled &&
      existing.key === streamKey &&
      existing.url === streamUrl
    ) {
      return;
    }
    await dispatch(
      updateSimulcastKey({
        target,
        enabled,
        streamKey,
        streamUrl,
        sessionId: sessionID,
      })
    );
  };

  useEffect(() => {
    // Triggers when display or key changes
    updateService(service, displayLivestream, streamKey, streamUrl);
  }, [debouncedDisplayLivestream, debouncedStreamKey, debouncedStreamUrl]);

  return (
    <S.LivestreamService>
      <S.LivestreamRow>
        <S.LivestreamHeader>{title}</S.LivestreamHeader>
        <Switch
          toggled={displayLivestream}
          onToggle={async () => {
            await setDisplayLivestream(!displayLivestream);
          }}
        />
      </S.LivestreamRow>
      {displayLivestream && (
        <S.LivestreamInputs>
          <TextInput
            value={streamKey}
            label={`${title} Stream Key`}
            onChange={e => {
              setStreamKey(e.target.value);
            }}
          />
          {service === LivestreamServices.other && (
            <TextInput
              value={streamUrl}
              label={`${title} Stream Url`}
              onChange={e => {
                setStreamUrl(e.target.value);
              }}
            />
          )}
        </S.LivestreamInputs>
      )}
    </S.LivestreamService>
  );
};

export default React.memo(LivestreamToggle);
