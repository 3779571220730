import React from 'react';
// Components
import ContextualAlert from '../ContextualAlert';
// Style
import S from './style';

export interface RadioProps {
  onCheck: Function;
  checked: boolean;
  label: string;
  helperText?: string;
  error?: string;
  disabled?: boolean;
}

const Radio = ({
  checked,
  onCheck,
  label,
  helperText,
  error,
  disabled,
}: RadioProps) => {
  return (
    <>
      <S.RadioWrapper onClick={onCheck} role="radio">
        <S.Radio disabled={disabled} $error={!!error}>
          <S.RadioInner checked={checked} disabled={disabled} />
        </S.Radio>
        <S.Label disabled={disabled}>{label}</S.Label>
      </S.RadioWrapper>
      {helperText && !error && <S.HelperText>{helperText}</S.HelperText>}
      {!!error && <ContextualAlert text={error} type="error" />}
    </>
  );
};

export default React.memo(Radio);
