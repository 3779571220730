import React from 'react';
import {DefaultTheme, withTheme} from 'styled-components';
// Components
import {
  Role,
  LayoutTypes,
  RoomState,
  ParticipantProps,
  SessionStageMissingHostView,
  SessionStageParticipantView,
} from 'bright-livekit';
// Styles
import S from '../style';

const GeneralLayoutComponent = ({
  roomState,
  participantRenderer,
  stageSize,
}: {
  roomState: RoomState;
  participantRenderer?: (props: ParticipantProps) => React.ReactElement | null;
  stageSize: {
    height: number;
    width: number;
  };
  theme: DefaultTheme;
}) => {
  const ParticipantRenderer =
    participantRenderer ?? SessionStageParticipantView;
  const sessionID = roomState.session.id;

  const creatorParticipant = roomState.participantsOnStage.find(
    p => p.role === Role.Creator
  );
  const backup =
    creatorParticipant ?? roomState.participantsOnStage.length > 0
      ? roomState.participantsOnStage[0]
      : undefined;

  const speakerParticipant = roomState.mainSpeaker ?? backup;

  let i = 0;
  let length = roomState.participantsOnStage.length;

  let singleParticipant: string | undefined;

  let visibleParticipants = roomState.participantsOnStage.map(p => p.id);

  if (
    [LayoutTypes.Speaker, LayoutTypes.CreatorOnly].includes(roomState.layout)
  ) {
    singleParticipant =
      roomState.layout === LayoutTypes.Speaker
        ? speakerParticipant?.id
        : backup?.id;
    if (singleParticipant) {
      visibleParticipants = [singleParticipant];
    }
  }

  return (
    <S.GeneralStageLayout $brandColor={roomState.brandColor}>
      {roomState.participantsOnStage[0].role !== Role.Creator && (
        <SessionStageMissingHostView
          layout={roomState.layout}
          stageSize={stageSize}
          stagePosition={0}
          totalStageParticipants={length + 1}
          brandColor={roomState.brandColor}
        />
      )}
      {roomState.participantsOnStage?.map(participant => {
        // If creator is not present skip their slot
        if (i === 0 && participant.role !== Role.Creator) {
          i++;
          length++;
        }

        const float =
          i > 0 && roomState.layout === LayoutTypes.PictureInPicture;
        const shareHeight = stageSize.height / 6;
        const shareWidth = (shareHeight * 16) / 9;
        return (
          <ParticipantRenderer
            roomState={roomState}
            modControls={roomState.modControls}
            onStage={roomState.onStage}
            key={participant.id}
            participant={participant}
            sessionID={sessionID}
            stageSize={stageSize}
            stagePosition={i++}
            totalStageParticipants={length}
            shareHeight={`${shareHeight}px`}
            shareWidth={`${shareWidth}px`}
            orientation="landscape"
            mode={float ? 'share' : 'desktop'}
            visible={visibleParticipants.includes(participant.id)}
          />
        );
      })}
    </S.GeneralStageLayout>
  );
};

export const GeneralLayout = withTheme(GeneralLayoutComponent);
