import styled from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';

const S = {
  ColorPicker: styled.div`
    position: relative;
    display: inline-block;
    z-index: 3;
  `,
  Selected: styled.div`
    padding: ${props => props.theme.spacing.MD};
    background-color: ${props => props.theme.backgroundColor.primary};
    border-radius: ${props => props.theme.borderRadius.MD};
    border: ${props =>
      `${props.theme.borderWidth['1']} solid ${props.theme.borderColor.default}`};
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  `,
  HexPreview: styled.div`
    height: 24px;
    width: 24px;
    border: ${props =>
      `${props.theme.borderWidth['1']} solid ${props.theme.borderColor.subdued}}`};
    margin-right: ${props => props.theme.spacing.MD};
  `,

  HexCode: styled(Ag.ParagraphMD)``,
  PickerWrapper: styled.div`
    position: absolute;
    bottom: -${props => props.theme.spacing['2XS']};
    transform: translateY(100%);
    left: 0;
  `,
};

export default S;
