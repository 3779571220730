import styled from 'styled-components';
import {media} from '@brightlive/shared/styles/breakpoints-v3';

const S: Record<string, React.ElementType> = {};

S.DeviceSettings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${props => props.theme.spacing.MD};
  ${media.MD} {
    flex-direction: row;
    gap: ${props => props.theme.spacing.LG};
  }
`;

S.DeviceSettingsVideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position relative;
  height: 192px;
  ${media.MD} {
    height: 240px;
  }
`;

S.DeviceSettingsAvatarContainer = styled.div`
  background-color: ${props => props.theme.backgroundColor.disabled};
  border-radius: ${props => props.theme.borderRadius['2XL']};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 343px;
  height: 192px;

  ${media.MD} {
    width: 429px;
    height: 240px;
  }
`;

S.DeviceSettingsStats = styled.div`
  position: absolute;
  bottom: ${props => props.theme.spacing.SM};
  left: ${props => props.theme.spacing.MD};
  gap: ${props => props.theme.spacing.XS};
  display: flex;
`;

S.DeviceSettingsDeviceToggle = styled.div`
  position: absolute;
  bottom: ${props => props.theme.spacing.MD};
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  ${media.MD} {
    display: none;
  }
`;
S.DeviceSettingsAudioButton = styled.div`
  margin-right: ${props => props.theme.spacing.MD};
`;
S.DeviceSettingsVideoButton = styled.div``;

S.DeviceSettingsControls = styled.div`
  margin-top: ${props => props.theme.spacing.LG};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.LG};
  width: 100%;
  ${media.MD} {
    margin-top: 0;
    flex: 1;
  }
`;

export default S;
