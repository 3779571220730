import React, {CSSProperties} from 'react';
import {Property} from 'csstype';
import {withTheme, DefaultTheme} from 'styled-components';
// Components
import {LayoutTypes, StatusMessage} from 'bright-livekit';
// Types
import {Hex} from '@brightlive/shared/helpers/interfaces';
// Styles
import {
  getHeight,
  getHeightMobile,
  getMargin,
  getWidth,
  getWidthMobile,
} from 'bright-livekit/styles/layout';

interface SessionStageMissingHostViewProps {
  stageSize: {width: number; height: number} | undefined;
  totalStageParticipants: number;
  stagePosition: number;
  mode?: 'desktop' | 'mobile' | 'share';
  layout: LayoutTypes;
  brandColor: Hex;
  theme: DefaultTheme;
}

const SessionStageMissingHostViewComponent = ({
  stageSize,
  totalStageParticipants,
  stagePosition,
  mode = 'desktop',
  layout,
  brandColor,
  theme,
}: SessionStageMissingHostViewProps) => {
  let x = 0;
  let y = 0;
  let width: Property.Width = 0;
  let height: Property.Width = 0;

  let containerStyles: CSSProperties = {
    left: 0,
    top: 0,
    height: '100px',
    width: '100px',
    position: 'relative',
    backgroundColor: theme.backgroundColor.secondary,
  };

  if (mode === 'mobile' && stageSize && totalStageParticipants) {
    containerStyles = {
      left: 0,
      top: 0,
      height:
        getHeightMobile(
          stageSize,
          totalStageParticipants - 1,
          stagePosition || 0
        ) + 'px',
      width:
        getWidthMobile(
          stageSize,
          totalStageParticipants - 1,
          stagePosition || 0
        ) + 'px',
      position: 'relative',

      backgroundColor: theme.backgroundColor.secondary,
    };
  } else if (mode === 'desktop') {
    if (
      stageSize &&
      typeof stagePosition !== 'undefined' &&
      stagePosition >= 0 &&
      totalStageParticipants
    ) {
      width =
        getWidth(layout, stageSize, totalStageParticipants - 1, stagePosition) +
        'px';
      height =
        getHeight(
          layout,
          stageSize,
          totalStageParticipants - 1,
          stagePosition
        ) + 'px';
      const margin = getMargin(
        layout,
        stageSize,
        totalStageParticipants - 1,
        stagePosition
      );
      x = margin.Left;
      y = margin.Top;
    }

    containerStyles = {
      width: width,
      height: height,
      left: x,
      top: y,
      position: 'absolute',
      backgroundColor: theme.backgroundColor.secondary,
    };
  }

  return (
    <div style={containerStyles}>
      <StatusMessage
        fullPage={false}
        brandColor={brandColor}
        text="Your host will be here shortly"
      />
    </div>
  );
};

export const SessionStageMissingHostView = withTheme(
  SessionStageMissingHostViewComponent
);
