import React, {useEffect, useRef, useState} from 'react';
import {useAnimation} from 'framer-motion';
import {withTheme, DefaultTheme} from 'styled-components';
// Components
import Tooltip from '@brightlive/shared/components/Tooltip';
// Style
import S from './style';

export interface ToastProps {
  text: string;
  name: string;
  hideCallout: Function;
  theme: DefaultTheme;
}

const UnreadCallout = ({text, name, hideCallout, theme}: ToastProps) => {
  const calloutRef = useRef<HTMLDivElement>(null);
  const [startTimer, setStartTimer] = useState<ReturnType<typeof setTimeout>>();
  const [closeTimer, setCloseTimer] = useState<number>();

  const controls = useAnimation();

  const MARGIN_RIGHT = parseInt(theme.spacing['2XS'], 10);

  const handleHideCallout = async () => {
    await clearTimeout(closeTimer);
    await setCloseTimer(setTimeout(hideCallout, 1500));
    const parentWidth = calloutRef?.current?.parentElement?.offsetWidth || 56;
    const startRight = parentWidth + MARGIN_RIGHT;
    // must use top property instead of bottom since toast can change height
    controls.start({
      x: startRight,
      transition: {duration: 0.15, ease: 'easeInOut'},
      transitionEnd: {visibility: 'hidden'},
    });
    closeTimer;
  };

  useEffect(() => {
    // Start timer from start when updates come in
    clearTimeout(startTimer);
    setStartTimer(setTimeout(handleHideCallout, 3500));
    if (calloutRef?.current) {
      const parentWidth = calloutRef?.current?.parentElement?.offsetWidth || 56;
      const startRight = parentWidth + MARGIN_RIGHT;
      const endRight = '-100%';
      controls.set({x: startRight, y: '-50%', visibility: 'visible'});
      controls.start({
        x: endRight,
        y: '-50%',
        transition: {duration: 0.15, ease: 'easeInOut'},
      });
      startTimer;
    }
  }, [text]);

  return (
    <S.CalloutWrapper
      animate={controls}
      ref={calloutRef}
      initial={{left: -MARGIN_RIGHT}}
    >
      <Tooltip
        text={
          <S.TextWrapper>
            <S.Name>{name}:</S.Name>
            <S.Text>{text}</S.Text>
          </S.TextWrapper>
        }
        position="left"
        width="280px"
        size="large"
        inverse={false}
      />
    </S.CalloutWrapper>
  );
};

export default React.memo(withTheme(UnreadCallout));
