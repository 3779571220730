import {UserInitState} from 'redux/auth/reducer';

export interface TrackingEventParams {
  event: string;
  currentUser?: UserInitState;
  currentUserId?: string;
  roles?: string[];
  data?: Record<string, string | number | Date | null>;
  uniqueIdentifier?: string;
  dependsOnEvent?: string;
}

/**
 * Tracking Service
 */
export class TrackingService {
  /**
   * Fire a tracking event
   *
   * @param {TrackingEventParams}     params                  Event fire parameters
   * @param {string}                  params.event            Event name
   * @param {UserInitState}           params.currentUser      Current user object
   * @param {string}                  params.currentUserId    User uid
   * @param {string[]}                params.roles            User roles
   * @param {Record<string, string>}  params.data             Event data parameters
   * @param {string}                  params.uniqueIdentifier Unique identifier for this event, if passed we will check to make sure this event has not been fired before to prevent duplicates
   * @param {string}                  params.dependsOnEvent   Dependent event, if passed make sure the dependent event was already fired during this session
   *
   * @return  {void}
   */
  public static fire({
    event,
    currentUser,
    currentUserId,
    roles,
    data = {},
    uniqueIdentifier = '',
    dependsOnEvent = '',
  }: TrackingEventParams) {
    // If this event depends on another event ensure that event was fired previously
    if (
      dependsOnEvent &&
      window?.sessionStorage?.getItem(`eventFired-${dependsOnEvent}`) !== 'true'
    ) {
      return;
    }

    if (uniqueIdentifier) {
      if (
        window?.localStorage?.getItem(`${event}-${uniqueIdentifier}`) === 'true'
      ) {
        return;
      }
      window?.localStorage?.setItem(`${event}-${uniqueIdentifier}`, 'true');
    }

    if (currentUser) {
      data.uid = currentUser.id;
      data.roles = currentUser.roles.join(',');
    } else {
      if (currentUserId) {
        data.uid = currentUserId;
      }
      if (roles) {
        data.roles = roles.join(',');
      }
    }
    window?.dataLayer?.push({
      event,
      ...data,
    });
    // If this event depended on another and has been fired, clear the depended on event to reset to the original state
    if (dependsOnEvent) {
      window?.sessionStorage?.setItem(`eventFired-${dependsOnEvent}`, 'false');
    }
    window?.sessionStorage?.setItem(`eventFired-${event}`, 'true');
  }
}
