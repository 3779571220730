import React, {useState, useEffect, useRef} from 'react';
import {uniqueId} from 'lodash';
import icons, {IconNames} from '../../icons';
// Components
import ContextualAlert from '@brightlive/shared/components/ContextualAlert';
// Icons
import Calendar from '../../icons/Calendar';
import VisibilityOff from '../../icons/VisibilityOff';
import VisibilityOn from '../../icons/VisibilityOn';
// Helpers
import {IconProps} from '../../helpers/interfaces';
// Style
import S from './style';

/* eslint-disable  @typescript-eslint/no-explicit-any */

export interface TextInputProps {
  onFocus?: () => void;
  onBlur?: () => void;
  value?: string;
  label?: string;
  placeholder?: string;
  keyPressHandler?: () => void;
  error?: string;
  prefix?: string;
  instructions?: string | JSX.Element;
  type?: string;
  disabled?: boolean;
  maxLength?: number;
  calendarOpen?: boolean;
  icon?: IconNames;
  autoFocus?: boolean;
  [x: string]: any;
}

const TextInput = ({
  onFocus,
  onBlur,
  value = '',
  label,
  placeholder,
  keyPressHandler,
  error,
  instructions,
  prefix,
  type = 'text',
  disabled = false,
  maxLength,
  calendarOpen,
  icon,
  autoFocus = false,
  ...props
}: TextInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const inputId = 'input' + uniqueId();
  const [isFocused, setIsFocused] = useState(false);
  const [inputType, setInputType] = useState(type);

  // manually control calendar input unfocus
  useEffect(() => {
    if (!calendarOpen) {
      setIsFocused(false);
    }
  }, [calendarOpen]);

  const onInputFocus = () => {
    setIsFocused(true);
    onFocus && onFocus();
  };

  const onInputBlur = () => {
    // if this input is being used as a date picker - dont unfocus until calendar is closed
    if (type === 'date' && calendarOpen) return;
    // everything else function normally
    setIsFocused(false);
    onBlur && onBlur();
  };

  const handleKeyPress = e => {
    if (e.which === 13 && keyPressHandler) {
      e.target.blur();
      keyPressHandler();
    }
  };

  const handleInputClick = () => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  };

  const handlePasswordToggle = e => {
    e.stopPropagation();
    if (inputType === 'text') {
      setInputType('password');
    } else {
      setInputType('text');
    }
  };

  const getIcon = () => {
    if (icon) {
      const Icon: React.FC<IconProps> = icons[icon];
      return (
        <S.Icon>
          <Icon size={24} contentColor="default" />
        </S.Icon>
      );
    }
    if (type === 'date') {
      return (
        <S.Icon>
          <Calendar size={24} contentColor="default" />
        </S.Icon>
      );
    }
    if (type === 'password') {
      const Icon = inputType === 'password' ? VisibilityOn : VisibilityOff;
      return (
        <S.Icon onClick={handlePasswordToggle}>
          <Icon size={24} contentColor="default" />
        </S.Icon>
      );
    }
    return null;
  };

  return (
    <S.WrapperOuter>
      <S.Wrapper
        $isFocused={isFocused}
        $hasValue={value || type === 'date'}
        $hasLabel={!!label}
        disabled={disabled}
        $error={!!error}
        onClick={handleInputClick}
      >
        <S.Border $isFocused={isFocused} disabled={disabled} $error={!!error} />
        <S.Content>
          {label && (
            <S.Label
              id={inputId}
              $hasValue={isFocused || value || type === 'date'}
              disabled={disabled}
            >
              {label}
            </S.Label>
          )}
          <S.WrapperInner>
            {prefix && (
              <S.Prefix
                $isFocused={isFocused}
                $hasValue={value}
                disabled={disabled}
              >
                {prefix}
              </S.Prefix>
            )}
            <S.TextInput
              as="input"
              ref={inputRef}
              {...props}
              value={value}
              placeholder={isFocused ? placeholder : label}
              aria-placeholder={placeholder}
              aria-labelledby={inputId}
              aria-disabled={disabled}
              aria-errormessage={error}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
              onKeyPress={handleKeyPress}
              type={inputType}
              disabled={disabled}
              required={type === 'date'}
              aria-required={type === 'date'}
              maxLength={maxLength}
              $isDate={type === 'date'}
              autoFocus={autoFocus}
            />
          </S.WrapperInner>
        </S.Content>
        {getIcon()}
        {!!maxLength && (
          <S.CharCount disabled={disabled}>
            {`${value?.length}/${maxLength}`}
          </S.CharCount>
        )}
      </S.Wrapper>
      {instructions && !error && <S.HelperText>{instructions}</S.HelperText>}
      {!!error && <ContextualAlert text={error} type="error" />}
    </S.WrapperOuter>
  );
};

export default TextInput;
