import {createLocalTracks} from 'twilio-video';
import {VideoUtils} from './VideoUtils';

export class TwilioVideoUtils extends VideoUtils {
  async createLocalTracks(opts: {
    audio: boolean;
    video: boolean;
  }): Promise<unknown> {
    createLocalTracks(opts);
    return;
  }
}
