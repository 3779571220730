import React, {useEffect, useState} from 'react';
import {useAnimation} from 'framer-motion';
//Components
// Style
import S from './style';

export const Countdown = ({onComplete}: {onComplete: Function}) => {
  const [count, setCount] = useState(3);

  const numberControls = useAnimation();

  useEffect(() => {
    runCountdown();
  }, []);

  const runCountdown = async () => {
    await numberControls.start({
      opacity: 0,
      scale: 2,
      transition: {duration: 1, ease: 'easeOut'},
    });
    setCount(2);
    await numberControls.set({scale: 1});
    await numberControls.start({
      opacity: 1,
      transition: {duration: 0.2, ease: 'easeOut'},
    });
    await numberControls.start({
      opacity: 0,
      scale: 2,
      transition: {duration: 1, ease: 'easeOut'},
    });
    setCount(1);
    await numberControls.set({scale: 1});
    await numberControls.start({
      opacity: 1,
      transition: {duration: 0.2, ease: 'easeOut'},
    });
    await numberControls.start({
      opacity: 0,
      scale: 2,
      transition: {duration: 1, ease: 'easeOut'},
    });
    onComplete();
  };

  return (
    <S.Countdown>
      <S.Modal>
        <S.Title>Lights, camera, action in...</S.Title>
        <S.NumberWrapper>
          <S.Number animate={numberControls}>{count}</S.Number>
        </S.NumberWrapper>
      </S.Modal>
    </S.Countdown>
  );
};
