import styled from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';

const S = {
  Content: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 416px;
  `,

  Title: styled(Ag.HeadingMD)`
    margin-bottom: ${props => props.theme.spacing.XL};
    text-align: center;
  `,

  Body: styled(Ag.ParagraphMD)``,

  Link: styled(Ag.Link)``,

  CancelButton: styled.div`
    margin-right: ${props => props.theme.spacing.MD};
  `,

  Buttons: styled.div`
    display: flex;
    justify-content: center;
  `,

  AlertWrapper: styled.div`
    margin-bottom: 16px;
  `,
};

export default S;
