import React, {useEffect, useRef, RefObject} from 'react';
import Link from 'next/link';
import {useSwipeable} from 'react-swipeable';
import {useAnimation} from 'framer-motion';
import {map} from 'lodash';
// Components
import Button from '../Button';
// Icons
import ArrowDownward from '../../icons/ArrowDownward';
import ArrowUpward from '../../icons/ArrowUpward';
import Check from '../../icons/Check';
import icons from '../../icons';
// Helpers
import {MenuItem, IconProps} from '../../helpers/interfaces';
// Style
import S from './style';

export type MenuTypes = 'default' | 'kebab' | 'select' | 'dropdown button';

export interface MenuProps {
  menuItems?: MenuItem[];
  customTopContent?: React.ReactNode;
  onSwipeUp?: Function;
  value?: string | number;
  desktopWidth?: string;
  menuDirection?: 'left' | 'right';
  maxHeightDesktop?: string;
  maxHeightMobile?: string;
  isMobile: boolean;
  type: MenuTypes;
  title?: string;
  disableClose?: boolean;
  onClose: Function;
  hasMobileOverlay?: boolean;
  hasClearOverlay?: boolean;
  onMount?: Function;
  onUnmount?: Function;
  hideCheck?: boolean;
  triggerRef?: RefObject<HTMLDivElement>; // element that triggers menu opening
  menuPosition?: {top: number; left: number; right: number};
}

interface ItemProps {
  menuItem: MenuItem;
  value?: string | number;
  handleClick: Function;
  hideCheck?: boolean;
}

const Item = ({menuItem, value, handleClick, hideCheck}: ItemProps) => {
  const getIcon = menuItem => {
    if (!menuItem.icon) return null;
    const Icon: React.FC<IconProps> = icons[menuItem.icon];
    return (
      <S.LeadingIcon>
        <Icon
          size={24}
          contentColor={menuItem.negative ? 'negative' : 'default'}
        />
      </S.LeadingIcon>
    );
  };

  const getRightContent = menuItem => {
    let content: React.ReactNode | null = null;
    if (menuItem.arrow) {
      content =
        menuItem.arrow === 'asc' ? (
          <ArrowUpward contentColor="default" size={24} />
        ) : (
          <ArrowDownward contentColor="default" size={24} />
        );
    } else if (value && value === menuItem.value && !hideCheck) {
      content = <Check contentColor="positive" size={24} />;
    } else if (menuItem.button) {
      content = (
        <Button
          type={menuItem.button.type}
          size="small"
          text={menuItem.button.text}
          onClick={menuItem.button.onClick}
        />
      );
    }

    if (!content) return null;
    return <S.RightWrapper>{content}</S.RightWrapper>;
  };

  const getContent = () => {
    return (
      <S.MenuItem
        onClick={() => {
          if (menuItem.disabled || !menuItem.onClick) return;
          handleClick(menuItem.onClick);
        }}
        selected={value && value === menuItem.value}
        aria-selected={value && value === menuItem.value}
        role="listitem"
        as={menuItem.href && !menuItem.disabled ? 'a' : 'div'}
        title={menuItem.title}
      >
        <S.LeftWrapper>
          {getIcon(menuItem)}
          <S.TextWrapper>
            <S.Label
              $selected={value && value === menuItem.value}
              $negative={menuItem.negative}
              disabled={menuItem.disabled}
              aria-selected={value && value === menuItem.value}
            >
              {menuItem.label}
            </S.Label>
            {menuItem.description && (
              <S.Description>{menuItem.description}</S.Description>
            )}
          </S.TextWrapper>
        </S.LeftWrapper>
        {getRightContent(menuItem)}
      </S.MenuItem>
    );
  };
  if (menuItem.isLine) {
    return <S.Line />;
  }
  if (menuItem.href && !menuItem.disabled) {
    return (
      <Link href={menuItem.href} passHref>
        {getContent()}
      </Link>
    );
  }
  return getContent();
};

const Menu = ({
  menuItems,
  customTopContent,
  onSwipeUp,
  value,
  desktopWidth,
  menuDirection = 'left',
  isMobile,
  type,
  title,
  onClose,
  disableClose = false,
  maxHeightDesktop,
  maxHeightMobile,
  hasMobileOverlay = true,
  hasClearOverlay = false,
  onMount,
  onUnmount,
  hideCheck,
  triggerRef,
  menuPosition,
}: MenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const controls = useAnimation();

  const duration = 0.5;

  useEffect(() => {
    if (onMount && menuRef.current) {
      onMount(menuRef.current.clientHeight);
    }

    return () => {
      if (onUnmount) {
        onUnmount();
      }
    };
  }, []);

  useEffect(() => {
    // on mobile - animate in
    if (isMobile && menuRef.current) {
      // set first so it animates exactly 0.3 sec from starting pos
      controls.set({y: menuRef.current.clientHeight});
      controls.start({
        y: 0,
        transition: {duration, ease: 'easeIn'},
      });
      // otherwise on desktop add outside click handler
    } else {
      document.addEventListener('mousedown', handleClickOutside);
    }
    const body = document.querySelector('body') as HTMLBodyElement;
    if (body && isMobile) {
      body.style.overflow = 'hidden';
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (body) {
        body.style.overflow = 'visible';
      }
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (isMobile) return;
    const target = event.target as Element;
    if (
      menuRef.current &&
      !menuRef.current.contains(target) &&
      (!triggerRef?.current || !triggerRef.current.contains(target))
    ) {
      onClose();
    }
  };

  const handleClick = onItemClick => {
    onItemClick();
    if (isMobile) {
      return;
    } else {
      onClose();
    }
  };

  const handleSwipeDown = async () => {
    if (disableClose || !isMobile || !menuRef.current) return; // swipe is only for mobile menu
    await controls.start({
      y: menuRef.current.clientHeight,
      transition: {duration, ease: 'easeOut'},
    });
    onClose();
  };

  const handleSwipeUp = () => {
    if (onSwipeUp) onSwipeUp();
  };

  const handlers = useSwipeable({
    onSwipedDown: handleSwipeDown,
    onSwipedUp: handleSwipeUp,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <>
      {hasMobileOverlay && (
        <S.MenuBackground
          onClick={handleSwipeDown}
          $noBackground={hasClearOverlay}
        />
      )}
      <S.Menu
        animate={controls}
        initial={{y: isMobile ? '100vh' : 0}}
        $desktopWidth={desktopWidth}
        $menuDirection={menuDirection}
        {...handlers}
        ref={menuRef}
        $type={type}
        role="list"
        $menuPosition={menuPosition}
      >
        <S.MenuHandleWrapper {...handlers}>
          <S.MenuHandle />
        </S.MenuHandleWrapper>
        {title && (
          <S.Header>
            <S.Title>{title}</S.Title>
          </S.Header>
        )}
        <S.Content>
          {customTopContent && (
            <S.CustomTopContent>{customTopContent}</S.CustomTopContent>
          )}
          {menuItems && (
            <S.MenuItemsWrapper
              $maxHeightDesktop={maxHeightDesktop}
              $maxHeightMobile={maxHeightMobile}
              $hasCustomTopContent={!!customTopContent}
            >
              {map(menuItems, (menuItem, i) => {
                return (
                  <Item
                    menuItem={menuItem}
                    value={value}
                    handleClick={handleClick}
                    key={`menuItem-${i}-${menuItem.label}`}
                    hideCheck={hideCheck}
                  />
                );
              })}
            </S.MenuItemsWrapper>
          )}
        </S.Content>
      </S.Menu>
    </>
  );
};

export default React.memo(Menu);
