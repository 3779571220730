import React, {useEffect, useState} from 'react';
import {useInterval} from '@brightlive/shared/hooks/useInterval';
import {TrackingService} from 'bright-livekit/services/TrackingService';
// Components
import {Role, RoomState} from 'bright-livekit';
// Icons
import Chat from '@brightlive/shared/icons/Chat';
// Style
import S from './style';

export const ModChatBanner = ({roomState}: {roomState: RoomState}) => {
  const [bannerTime, setBannerTime] = useState(0);
  const [bannerText, setBannerText] = useState('');
  const [bannerDisplayName, setBannerDisplayName] = useState('');

  useInterval(() => {
    const bannerTimeout = (bannerTime + 10000 - Date.now()) / 1000;
    if (bannerTimeout <= 0) {
      setBannerText('');
      setBannerDisplayName('');
    }
  }, 1000);

  const showBanner = (text: string, name: string) => {
    setBannerTime(Date.now());
    setBannerText(text);
    setBannerDisplayName(name);
  };

  useEffect(() => {
    // Only show the message to creators / mods
    if (
      roomState.selfParticipant?.role === Role.Creator ||
      roomState.selfParticipant?.isShowRunner
    ) {
      // Skip if no mod chats are present
      if (!roomState.modChat || roomState.modChat.size === 0) {
        return;
      }
      // Iterate over all mod chats
      for (const chat of roomState.modChat.docs) {
        // If chat has already been viewed, skip it
        if (window.sessionStorage.getItem(chat.id)) {
          continue;
        }
        // Set the chat to session storage so that the user doesn't see it twice
        window.sessionStorage.setItem(chat.id, 'true');
        const chatData = chat.data();
        TrackingService.fire({
          event: 'modchat',
          currentUser: roomState.currentUser,
          data: {
            session_id: roomState.session.id,
            time: new Date(new Date().getTime()),
          },
        });
        // Do not show if message was from the current user
        if (roomState.currentUser.id !== chatData.participantID) {
          showBanner(chatData.messageText, chatData.displayName);
          return;
        }
      }
    }
  }, [roomState.modChat]);
  return (
    <>
      {bannerDisplayName && bannerText && (
        <S.ModChatBanner>
          <Chat size={24} contentColor="black" />
          <S.ModChatText>
            <S.ModChatTextBold>{bannerDisplayName}: </S.ModChatTextBold>
            {bannerText}
          </S.ModChatText>
        </S.ModChatBanner>
      )}
    </>
  );
};
