import React from 'react';
// Components
import Imgix from 'react-imgix';
// Types
import {BannerLayoutTypes} from 'bright-livekit';
import {Hex} from '@brightlive/shared/helpers/interfaces';
// Styles
import S from './style';

export const Banner = ({
  image,
  position,
  brandColor,
}: {
  image: string;
  position: BannerLayoutTypes;
  brandColor: Hex;
}) => {
  return (
    <S.Banner $position={position} $brandColor={brandColor}>
      <Imgix
        src={`${process.env.NEXT_PUBLIC_IMAGE_PREFIX}${image}`}
        sizes="100%"
        imgixParams={{ar: '12:1', fit: 'crop'}}
      />
    </S.Banner>
  );
};
