import React, {useEffect, useState, useRef} from 'react';
import {SketchPicker} from 'react-color';
import {withTheme, DefaultTheme} from 'styled-components';
import {uniq} from 'lodash';
// Helpers
import {Hex} from '@brightlive/shared/helpers/interfaces';
import {useLocalStorage} from '@brightlive/shared/hooks/useLocalStorage';
// Style
import S from './style';

export interface ColorPickerProps {
  value: Hex;
  setColor: (color: Hex) => void;
  theme: DefaultTheme;
}

const MAX_COLORS = 16;

const ColorPicker = ({value, setColor, theme}: ColorPickerProps) => {
  const rootEl = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [presetColors, setPresetColors] = useState<Hex[]>([]);
  const [selectedColors, setSelectedColors] = useLocalStorage(
    'selected-brand-colors',
    []
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!open && value) {
      addUserSelectedColor();
    }
  }, [open]);

  useEffect(() => {
    const defaultColors = [
      theme.color.black,
      theme.color.grey['50'],
      theme.color.blue['300'],
      theme.color.pink['200'],
      theme.color.orange['400'],
      theme.color.orange['500'],
    ];
    let allColors = [...selectedColors, ...defaultColors];
    allColors = allColors.map((color: Hex) => {
      return color.toLowerCase();
    });
    const uniqueColors = uniq(allColors);
    const visibleColors = uniqueColors.slice(0, MAX_COLORS);
    setPresetColors(visibleColors);
  }, [selectedColors]);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Element;
    if (rootEl.current && !rootEl.current.contains(target)) {
      setOpen(false);
    }
  };

  const addUserSelectedColor = () => {
    let userSelectedColors = [...[value], ...selectedColors];
    userSelectedColors = userSelectedColors.map((color: Hex) => {
      return color.toLowerCase();
    });
    const uniqueColors = uniq(userSelectedColors);
    const newSelectedColors = uniqueColors.slice(0, MAX_COLORS);
    setSelectedColors(newSelectedColors);
  };

  const handleChangeComplete = (color: {hex: Hex}) => {
    setColor(color.hex);
  };

  return (
    <S.ColorPicker ref={rootEl}>
      <S.Selected onClick={() => setOpen(!open)}>
        <S.HexPreview style={{backgroundColor: value}} />
        <S.HexCode>{value}</S.HexCode>
      </S.Selected>
      {open && (
        <S.PickerWrapper>
          <SketchPicker
            color={value}
            disableAlpha={true}
            presetColors={presetColors}
            onChangeComplete={handleChangeComplete}
          />
        </S.PickerWrapper>
      )}
    </S.ColorPicker>
  );
};

export default React.memo(withTheme(ColorPicker));
