import React, {useMemo, useState} from 'react';
import {uniqueId} from 'lodash';
import {Timestamp} from 'firebase/firestore';
import {useDispatch} from 'react-redux';
import moment from 'moment';
// Components
import Spinner from '@brightlive/shared/components/Spinner';
import TooltipWrapper from '@brightlive/shared/components/TooltipWrapper';
import IconButton from '@brightlive/shared/components/IconButton';
import Menu from '@brightlive/shared/components/Menu';
import TextInput from '@brightlive/shared/components/TextInput';
import Button from '@brightlive/shared/components/Button';
import DropdownButton from '@brightlive/shared/components/DropdownButton';
import Modal from '@brightlive/shared/components/Modal';
import VideoPlayer from '@brightlive/shared/components/VideoPlayer';
import DeleteRecordingModal from 'components/recordings/DeleteRecordingModal';
import Tag from '@brightlive/shared/components/Tag';
// Actions
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
import {deleteRecording, updateRecording} from 'redux/recording/actions';
// Style
import S from './style';
interface Asset {
  duration: number;
  id: string;
  playbackID: string;
  staticAssetsReady: boolean;
  status: string;
  type: string;
}

export enum RecordingItemModes {
  Card,
  List,
}

export interface RecordingItemProps {
  assets: {audio?: Asset; stage?: Asset; edit?: Asset};
  createdAt: Timestamp;
  title: string;
  timestamp: number;
  sessionID: string;
  recordingID: string;
  mode?: RecordingItemModes;
  isStudio?: boolean;
}

const RecordingItem = ({
  assets,
  createdAt,
  title,
  sessionID: session,
  recordingID: recording,
  mode = RecordingItemModes.List,
  isStudio = false,
}: RecordingItemProps) => {
  const dispatch = useDispatch();
  const audioAsset = assets['audio'];
  const mainAsset = assets['stage'] || assets['edit'];
  const audioReady = audioAsset?.staticAssetsReady;
  const mainAssetReady = mainAsset?.staticAssetsReady;
  const ready = audioReady && mainAssetReady;
  const duration = mainAsset?.duration || 0;
  const [fileName, setFileName] = useState(title);
  const [showEdit, setShowEdit] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const {isMobile} = useWindowSize();
  const handleDownloadClick = (url: string) => {
    window.open(`${url}?download=${title}`, '_blank');
    return;
  };
  const formattedDuration = useMemo(() => {
    if (!duration) return;
    const durationSeconds = moment.duration(duration, 'seconds');
    if (duration < 60) {
      // if less than 1 min
      return `${durationSeconds.seconds()}s`;
    } else if (duration >= 3600) {
      // if greater than 1 hr
      const minutes = durationSeconds.minutes()
        ? ` ${durationSeconds.minutes()}m`
        : '';
      return `${durationSeconds.hours()}hr ${minutes} ${durationSeconds.seconds()}s`;
    } else {
      return `${durationSeconds.minutes()}m ${durationSeconds.seconds()}s`;
    }
  }, [duration]);
  const formattedCreationDate = useMemo(() => {
    return `${moment(createdAt).format('MMM D, YYYY')} at ${moment(
      createdAt
    ).format('h:mmA')}`; // 'Mar 9, 2002 at 4:30PM';
  }, [createdAt]);

  const videoJsOptions = {
    fill: true,
    fluid: false,
    sources: [
      {
        src: `https://stream.mux.com/${mainAsset?.playbackID}/high.mp4`,
        type: 'video/mp4',
      },
    ],
    muted: false,
    controls: true,
    preload: 'auto',
  };

  const dropdownBtnOptions = [
    {
      label: 'All assets',
      onClick: () => {
        handleDownloadClick(
          `https://stream.mux.com/${mainAsset?.playbackID}/high.mp4`
        );
        handleDownloadClick(
          `https://stream.mux.com/${mainAsset?.playbackID}/audio.m4a`
        );
      },
    },
    {
      label: 'Video only',
      onClick: () =>
        handleDownloadClick(
          `https://stream.mux.com/${mainAsset?.playbackID}/high.mp4`
        ),
    },
    {
      label: 'Audio only',
      onClick: () =>
        handleDownloadClick(
          `https://stream.mux.com/${mainAsset?.playbackID}/audio.m4a`
        ),
    },
  ];

  const getImage = useMemo(() => {
    if (mode === RecordingItemModes.Card) {
      return (
        <S.Thumb>
          <VideoPlayer options={videoJsOptions} videoKey={uniqueId()} />
          <S.DurationTag>
            <Tag color="black" type="basic" text={formattedDuration} />
          </S.DurationTag>
        </S.Thumb>
      );
    } else {
      return (
        <S.ImageContainer>
          <img
            width="85px"
            height="48px"
            src={`https://image.mux.com/${mainAsset?.playbackID}/thumbnail.jpg`}
          />
        </S.ImageContainer>
      );
    }
  }, [mainAsset, audioAsset, mode]);

  return (
    <S.RecordingCard
      $mode={mode}
      onMouseLeave={() => {
        setShowOptions(false);
      }}
    >
      {showEdit && !isStudio && !isMobile ? (
        <S.EditContainer>
          <TextInput
            value={fileName}
            label="Recording name"
            autoFocus
            onChange={e => {
              e.preventDefault();
              setFileName(e.target.value);
            }}
            keyPressHandler={async () => {
              await dispatch(updateRecording(session, recording, fileName));
              setShowOptions(false);
              setShowEdit(false);
            }}
          />
          <S.EditButtons>
            <Button
              text="Cancel"
              size="small"
              type="tertiary"
              onClick={() => {
                setShowEdit(false);
              }}
            />
            <Button
              text="Save"
              size="small"
              type="primary"
              onClick={async () => {
                await dispatch(updateRecording(session, recording, fileName));
                setShowEdit(false);
                setShowOptions(false);
              }}
            />
          </S.EditButtons>
        </S.EditContainer>
      ) : (
        <S.Content $mode={mode} $preview={false}>
          {ready ? (
            getImage
          ) : (
            <S.SpinnerContainer $mode={mode}>
              <Spinner size="small" />
            </S.SpinnerContainer>
          )}
          <S.RecordingCardTitle $mode={mode}>
            <S.Title $mode={mode}>{title}</S.Title>
            <S.DescriptionWrapper>
              <S.Description $mode={mode}>
                {!ready && 'processing'}
                {ready && mode === RecordingItemModes.List
                  ? formattedDuration
                  : null}
                {ready && mode === RecordingItemModes.Card
                  ? formattedCreationDate
                  : null}
              </S.Description>
            </S.DescriptionWrapper>
          </S.RecordingCardTitle>
          <S.Buttons $mode={mode} $isMobile={isMobile}>
            {mode === RecordingItemModes.List && (isStudio || !isMobile) && (
              <>
                <S.Button>
                  <TooltipWrapper
                    tooltipContent="Download video"
                    referenceContent={
                      <IconButton
                        disabled={!mainAssetReady}
                        icon="Movie"
                        size="medium"
                        type="tertiary"
                        onClick={() =>
                          handleDownloadClick(
                            `https://stream.mux.com/${mainAsset?.playbackID}/high.mp4`
                          )
                        }
                      />
                    }
                  />
                </S.Button>
                <S.Button>
                  <TooltipWrapper
                    tooltipContent="Download audio only"
                    referenceContent={
                      <IconButton
                        disabled={!audioReady}
                        icon="MusicNote"
                        size="medium"
                        type="tertiary"
                        onClick={() =>
                          handleDownloadClick(
                            `https://stream.mux.com/${audioAsset?.playbackID}/audio.m4a`
                          )
                        }
                      />
                    }
                  />
                </S.Button>
              </>
            )}
            {mode === RecordingItemModes.Card && (
              <S.Button>
                <DropdownButton
                  text="Download"
                  type="secondary"
                  options={dropdownBtnOptions}
                  isMobile={isMobile}
                  width="144px"
                />
              </S.Button>
            )}
            <S.Button>
              <TooltipWrapper
                tooltipContent="More actions"
                referenceContent={
                  <IconButton
                    disabled={!audioReady}
                    icon="AndroidMore"
                    size="medium"
                    type="tertiary"
                    onClick={() => {
                      setShowOptions(!showOptions);
                    }}
                  />
                }
              />
              {showOptions && (
                <S.RecordingMenuContainer>
                  <Menu
                    isMobile={isMobile}
                    type="default"
                    onClose={() => {
                      setShowOptions(false);
                    }}
                    menuItems={[
                      {
                        label: 'Edit name',
                        onClick: () => {
                          setShowEdit(true);
                        },
                      },
                      {
                        label: 'Delete recording',
                        onClick: async () => {
                          setConfirmOpen(true);
                        },
                      },
                    ]}
                  />
                </S.RecordingMenuContainer>
              )}
            </S.Button>
          </S.Buttons>
        </S.Content>
      )}
      {showEdit && (isMobile || isStudio) ? (
        <Modal
          closeModal={async () => {
            setShowEdit(false);
            setShowOptions(false);
          }}
          desktopWidth="500px"
          buttons={{
            button1Text: 'Cancel',
            button2Text: 'Save',
            button2OnClick: async () => {
              await dispatch(updateRecording(session, recording, fileName));
              setShowEdit(false);
              setShowOptions(false);
            },
          }}
        >
          <S.ModalContent>
            <S.ModalTitle>Edit recording name</S.ModalTitle>
            <TextInput
              value={fileName}
              label="Recording name"
              autoFocus
              onChange={e => {
                e.preventDefault();
                setFileName(e.target.value);
              }}
              keyPressHandler={async () => {
                await dispatch(updateRecording(session, recording, fileName));
                setShowOptions(false);
                setShowEdit(false);
              }}
            />
          </S.ModalContent>
        </Modal>
      ) : null}

      {confirmOpen && (
        <DeleteRecordingModal
          title={title || 'This Recording'}
          closeModal={() => setConfirmOpen(false)}
          handleDeleteRequest={async () => {
            await dispatch(deleteRecording(session, recording));
            setConfirmOpen(false);
            setShowOptions(false);
          }}
        />
      )}
    </S.RecordingCard>
  );
};

export default RecordingItem;
