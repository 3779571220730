import styled, {DefaultTheme} from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import {Hex} from '@brightlive/shared/helpers/interfaces';
import {contrastIsLight} from '@brightlive/shared/helpers/colorFormatter';

const S = {
  LivekitStatusMessage: styled.div(
    (props: {$brandColor: Hex; $fullPage: boolean; theme: DefaultTheme}) => `
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props.$fullPage ? '100vh' : '100%'};
    flex-direction: column;
    background-color: ${props.$brandColor};
    gap: ${props.theme.spacing.MD};
    padding: ${props.theme.spacing.MD};
    box-sizing: border-box;

    ${media.MD} {
      height: 100%;
    }
  `
  ),
  LivekitStatusHeader: styled(Ag.HeadingLG)(
    (props: {$brandColor: Hex; theme: DefaultTheme}) => `
    text-align: center;
    color: ${
      contrastIsLight({
        baseColor: props.$brandColor,
        whiteColor: props.theme.color.white as Hex,
      })
        ? props.theme.color.white
        : props.theme.color.black
    };
  `
  ),
};

export default S;
