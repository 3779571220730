import styled, {DefaultTheme} from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';

const S = {
  Accordion: styled.div`
    width: 100%;
  `,

  AccordionHeader: styled.div(
    (props: {$smPadding: boolean; theme: DefaultTheme}) => `
      width: 100%;
      box-sizing: border-box;
      padding: ${
        props.$smPadding
          ? `0 ${props.theme.spacing.MD}`
          : props.theme.spacing.MD
      };
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: pointer;

      &:hover,
      &:active {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: ${props.theme.actionColor.hover};
        }
      }
    `
  ),

  AccordionHeaderText: styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: calc(100% - ${props => props.theme.spacing.XL});
  `,

  Icon: styled.div(
    (props: {$open: boolean; theme: DefaultTheme}) => `
    transform: ${props.$open ? 'scaleY(-1)' : 'none'};
    margin-left: ${props.theme.spacing.XS};
  `
  ),

  TextMedium: styled(Ag.HeadingMD)``,

  TextSmall: styled(Ag.HeadingSM)``,

  TextXS: styled(Ag.Heading2XS)``,

  TextDescription: styled(Ag.ParagraphSM)`
    color: ${props => props.theme.contentColor.moreSubdued};
  `,

  Content: styled.div`
    padding: ${props =>
      `${props.theme.spacing.MD} ${props.theme.spacing.MD} ${props.theme.spacing['2XL']}`};
  `,
};

export default S;
