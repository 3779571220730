// there are some annoying issues with tsc and some components in livekit-react
import React, {useEffect, useState} from 'react';
import {useAnimation} from 'framer-motion';
import {useInterval} from '@brightlive/shared/hooks/useInterval';
import moment from 'moment';
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';

// Styles
import S from './style';

interface LiveSessionIndicatorProps {
  startedAt: Date | null | undefined;
}

export const LiveSessionIndicator = ({
  startedAt,
}: LiveSessionIndicatorProps) => {
  const [runTime, setRunTime] = useState('');

  const liveTextControls = useAnimation();
  const liveWrapperControls = useAnimation();
  const liveIconControls = useAnimation();

  const {isMobile} = useWindowSize();

  // Interval for the runtime of the session
  useInterval(() => {
    if (startedAt) {
      // Figure out the difference in time
      const diff = moment().diff(moment(startedAt));
      setRunTime(moment.utc(diff).format('HH:mm:ss'));
    }
  }, 500);

  useEffect(() => {
    runLiveAnimation();
  }, []);

  const runLiveAnimation = async () => {
    await liveWrapperControls.start({
      opacity: 1,
      transition: {duration: 0.25, ease: 'easeIn', delay: 0.5},
    });
    runLiveIconAnimation();
  };

  const runLiveIconAnimation = () => {
    liveIconControls.start({
      scale: [null, 1.75, 1],
      transition: {duration: 1.5, repeat: Infinity},
    });
  };

  return (
    <S.LivekitLiveIndicator animate={liveWrapperControls}>
      <S.LivekitLiveIcon>
        <S.LivekitLiveIconOuter animate={liveIconControls} />
        <S.LivekitLiveIconInner />
      </S.LivekitLiveIcon>
      <S.LivekitLiveText animate={liveTextControls}>
        {isMobile ? 'RECORDING' : `RECORDING - ${runTime}`}
      </S.LivekitLiveText>
    </S.LivekitLiveIndicator>
  );
};
