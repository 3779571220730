import Ag from '@brightlive/shared/styles/typography-v3';
import styled from 'styled-components';

const S = {
  MobileControlsContainer: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing.MD};
    padding: 0 ${props => props.theme.spacing.LG};
  `,
  Button: styled.div`
    margin: ${props => props.theme.spacing.XS};
  `,
  Back: styled.div`
    margin-right: ${props => props.theme.spacing.MD};
  `,
  ButtonWrapper: styled.div`
    position: relative;
  `,
  TooltipWrapperCenter: styled.div`
    position: absolute;
    left: 50%;
    top: -${props => props.theme.spacing['2XS']};
    transform: translate(-50%, -100%);
  `,
  TooltipWrapperRight: styled.div`
    position: absolute;
    right: 50%;
    top: -${props => props.theme.spacing['2XS']};
    transform: translate(0, -100%);
  `,
  TooltipWrapper: styled.div``,
  UnreadFlag: styled(Ag.ParagraphXS)`
    min-width: ${props => props.theme.spacing.SM};
    padding: 2px ${props => props.theme.spacing['2XS']};
    position: absolute;
    top: -${props => props.theme.spacing.XS};
    right: -${props => props.theme.spacing.XS};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: ${props => props.theme.backgroundColor.primary};
    background-color: ${props => props.theme.actionColor.brand};
    border: ${props => props.theme.borderWidth[4]} solid
      ${props => props.theme.backgroundColor.body};
    border-radius: ${props => props.theme.borderRadius.XL};
  `,
};
export default S;
