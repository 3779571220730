import Ag, {
  getHeadingXS,
  getParagraphMD,
  getBold,
} from '@brightlive/shared/styles/typography-v3';
import styled, {DefaultTheme} from 'styled-components';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import {toRgbaString} from '@brightlive/shared/helpers/colorFormatter';

const S = {
  ChatMessage: styled.div`
    display: flex;
    background-color: ${props => props.theme.backgroundColor.primary};
    margin-bottom: 1px;
    min-height: 96px;
    padding: 0 ${props => props.theme.margin.mobile.page};

    ${media.MD} {
      padding: 0;
    }
  `,

  Video: styled.div(
    (props: {$isSelf: boolean; theme: DefaultTheme}) => `
  width: 96px;
  min-height: 96px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  order: ${props.$isSelf ? '2' : '1'};
  ${media.MD} {
    width: 128px;
    min-height: 128px;
  }
`
  ),

  AttendeeAvatarContainer: styled.div`
    position: relative;
    padding-top: ${props => props.theme.spacing.SM};
    ${media.MD} {
      padding-top: ${props => props.theme.spacing.MD};
    }
  `,

  CamOffIcon: styled.div`
    position: absolute;
    bottom: -${props => props.theme.borderWidth[4]};
    right: -${props => props.theme.borderWidth[4]};
  `,

  ChatParticipantHoverContainer: styled.div`
    background: ${props => toRgbaString(props.theme.actionColor.hover, 0.2)};
    position: absolute;
    padding: 0 ${props => props.theme.spacing.MD};
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
  `,

  ActionBar: styled.div`
    padding-top: ${props => props.theme.spacing['2XS']};
    display: flex;
    position: relative;
    gap: ${props => props.theme.spacing['2XS']};
  `,

  MoreOptions: styled.div`
    position: fixed;
    z-index: 3000;
    width: 200px;
  `,

  ChatParticipantControlRight: styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
  `,

  ChatParticipantControlCenter: styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    gap: ${props => props.theme.spacing.MD};
    align-items: center;
  `,

  TextContainer: styled.div(
    (props: {$isSelf: boolean; theme: DefaultTheme}) => `
  padding: ${props.theme.spacing.MD} ${props.theme.spacing.SM};
  flex: 1;
  max-width: calc(100% - 120px);
  order: ${props.$isSelf ? '1' : '2'};
  position: relative;
`
  ),

  NameWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,

  Name: styled(Ag.ParagraphSM)`
    ${getBold()}
    ${media.MD} {
      ${props => getHeadingXS(props.theme)}
    }
  `,

  Timestamp: styled(Ag.ParagraphXS)`
    color: ${props => props.theme.contentColor.subdued};
  `,

  Text: styled(Ag.ParagraphSM)`
    margin-top: ${props => props.theme.spacing['2XS']};
    overflow-wrap: break-word;

    a {
      color: ${props => props.theme.contentColor.white};
      text-decoration: underline;
    }
    ${media.MD} {
      ${props => getParagraphMD(props.theme)}
    }
  `,
};
export default S;
