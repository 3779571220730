export class EventRegister {
  private static events: WeakMap<Function, (...params) => void> = new WeakMap();

  public static wrap(
    converter: (...params) => (...params) => void,
    handler: (...params) => void
  ): (...params) => void {
    const existing = EventRegister.events.get(handler);
    if (existing) {
      return existing;
    }
    const wrapper = converter(handler);
    EventRegister.events.set(handler, wrapper);
    return wrapper;
  }
}
