import {
  BrightParticipantEvent,
  BrightRoomEvent,
  TrackSubscriptionMode,
} from 'bright-livekit';
import {EventRegister} from 'bright-livekit/services/EventRegister';
import {
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalParticipant,
  RemoteAudioTrackPublication,
  RemoteParticipant,
  RemoteVideoTrackPublication,
  TrackPublication,
} from 'twilio-video';

import {TrackType} from '../BrightParticipant';
import {ITrackPublication} from '../track/ITrackPublication';
import {TwilioTrackPublication} from '../track/TwilioTrackPublication';
import {IParticipant} from './IParticipant';

export class TwilioParticipant implements IParticipant {
  identity: string;
  participant: RemoteParticipant | LocalParticipant;
  private trackPublications: WeakMap<TrackPublication, TwilioTrackPublication>;
  private currentMode: TrackSubscriptionMode;
  private mode: TrackSubscriptionMode;
  private modeDebounceTimeout: NodeJS.Timeout | null;
  private facingMode: 'user' | 'environment';
  private cameraDeviceId: string | null = null;
  private micDeviceId: string | null = null;
  constructor(participant: RemoteParticipant | LocalParticipant) {
    this.facingMode = 'environment';
    this.identity = participant.identity;
    this.participant = participant;
    this.trackPublications = new WeakMap();
    this.currentMode = TrackSubscriptionMode.None;
    this.modeDebounceTimeout = null;
    this.mode = TrackSubscriptionMode.None;
  }

  public get isCameraEnabled(): boolean {
    for (const track of this.participant.videoTracks.values()) {
      if (track.isEnabled) {
        return true;
      }
    }
    return false;
  }
  public get isMicrophoneEnabled(): boolean {
    for (const track of this.participant.audioTracks.values()) {
      if (track.isEnabled) {
        return true;
      }
    }
    return false;
  }
  public get isSpeaking(): boolean {
    return false;
  }
  public get connectionQuality(): string {
    return this.participant.networkQualityLevel?.toString() || '';
  }
  public get isLocal(): boolean {
    return !!(this.participant as LocalParticipant).publishTrack;
  }
  public get isPresent(): boolean {
    return !!this.participant.identity;
  }
  public get tracks(): ITrackPublication[] {
    return this.getTracks();
  }
  public get audioTracks(): ITrackPublication[] {
    return this.getTracks().filter(t => t.kind === TrackType.Audio);
  }
  public get videoTracks(): ITrackPublication[] {
    return this.getTracks().filter(t => t.kind === TrackType.Video);
  }

  getTrackPublication(publication: TrackPublication) {
    let trackPublication = this.trackPublications.get(publication);
    if (!trackPublication) {
      trackPublication = new TwilioTrackPublication(publication);
      this.trackPublications.set(publication, trackPublication);
    }
    return trackPublication;
  }
  private _restartVideoTrack() {
    const localParticipant = this.participant as LocalParticipant;
    createLocalVideoTrack({deviceId: this.cameraDeviceId || undefined}).then(
      localVideoTrack => {
        const tracks = Array.from(localParticipant.videoTracks.values()).map(
          p => p.track
        );
        localParticipant.unpublishTracks(tracks);
        tracks.forEach(t => t.detach());

        localParticipant.publishTrack(localVideoTrack);
      }
    );
  }
  private _restartAudioTrack() {
    const localParticipant = this.participant as LocalParticipant;
    createLocalAudioTrack({deviceId: this.micDeviceId || undefined}).then(
      localAudioTrack => {
        const tracks = Array.from(localParticipant.audioTracks.values()).map(
          p => p.track
        );
        localParticipant.unpublishTracks(tracks);
        tracks.forEach(t => t.detach());

        localParticipant.publishTrack(localAudioTrack);
      }
    );
  }
  switchActiveDevice(
    device: 'audioinput' | 'videoinput' | 'audiooutput',
    deviceId: string
  ) {
    if (!this.isLocal) {
      throw new Error('Not the local participant');
    }
    switch (device) {
      case 'audioinput':
        this.micDeviceId = deviceId;
        if (this.audioTracks.length > 0) {
          this._restartAudioTrack();
        }
        break;
      case 'videoinput':
        this.cameraDeviceId = deviceId;
        if (this.videoTracks.length > 0) {
          this._restartVideoTrack();
        }
        break;
      default:
        break;
    }
  }
  async setMicrophoneEnabled(enabled: boolean) {
    if (this.isLocal) {
      const localParticipant = this.participant as LocalParticipant;
      if (enabled && localParticipant.audioTracks.size === 0) {
        this._restartAudioTrack();
      } else {
        localParticipant.audioTracks.forEach(publication => {
          if (enabled) {
            publication.track.enable();
          } else {
            publication.track.disable();
          }
        });
      }
    }
  }
  async setCameraEnabled(enabled: boolean) {
    if (this.isLocal) {
      const localParticipant = this.participant as LocalParticipant;
      if (enabled && localParticipant.videoTracks.size === 0) {
        this._restartVideoTrack();
      } else {
        localParticipant.videoTracks.forEach(publication => {
          if (enabled) {
            publication.track.enable();
          } else {
            publication.track.disable();
          }
        });
      }
    }
  }
  flipCamera() {
    if (this.participant instanceof LocalParticipant) {
      this.facingMode = this.facingMode === 'user' ? 'environment' : 'user';
      this.participant.videoTracks.forEach(publication => {
        publication.track.restart({
          facingMode: this.facingMode,
        });
      });
    }
  }

  private _trackPublicationConverter = (
    handler: (publication: TwilioTrackPublication) => void
  ) => {
    return (publication: TrackPublication) => {
      handler(this.getTrackPublication(publication));
    };
  };

  on(
    event: BrightRoomEvent | BrightParticipantEvent,
    handler: (...params) => void
  ) {
    const e = this.getEvent(event);
    if (e && this.participant) {
      switch (event) {
        case BrightParticipantEvent.TrackMuted:
          this.participant.on(
            e,
            EventRegister.wrap(this._trackPublicationConverter, handler)
          );
          break;
        // Special case for unmuted, when first published a remote user's track doesn't also get an enabled event so we have to listen to a trackPublished as if it's an unmute event
        case BrightParticipantEvent.TrackUnmuted:
          this.participant.on(
            e,
            EventRegister.wrap(this._trackPublicationConverter, handler)
          );
          this.participant.on(
            'trackPublished',
            EventRegister.wrap(this._trackPublicationConverter, handler)
          );
          break;
        default:
          this.participant.on(e, handler);
          break;
      }
    }
  }
  off(event: BrightRoomEvent | BrightParticipantEvent, handler: () => void) {
    const e = this.getEvent(event);
    if (e && this.participant) {
      switch (event) {
        case BrightParticipantEvent.TrackMuted:
          this.participant.off(
            e,
            EventRegister.wrap(this._trackPublicationConverter, handler)
          );
          break;
        case BrightParticipantEvent.TrackUnmuted:
          this.participant.off(
            e,
            EventRegister.wrap(this._trackPublicationConverter, handler)
          );
          this.participant.off(
            'trackPublished',
            EventRegister.wrap(this._trackPublicationConverter, handler)
          );
          break;
        default:
          this.participant.off(e, handler);
          break;
      }
    }
  }

  private getEvent(event: BrightRoomEvent | BrightParticipantEvent) {
    switch (event) {
      case BrightRoomEvent.LocalTrackPublished:
      case BrightParticipantEvent.TrackPublished:
      case BrightParticipantEvent.LocalTrackPublished:
        return 'trackPublished';
      case BrightParticipantEvent.TrackMuted:
        return 'trackDisabled';
      case BrightParticipantEvent.TrackUnmuted:
        return 'trackEnabled';
      // case BrightParticipantEvent.IsSpeakingChanged:
      //   return ''
      case BrightRoomEvent.LocalTrackUnpublished:
      case BrightParticipantEvent.LocalTrackUnpublished:
      case BrightParticipantEvent.TrackUnpublished:
        return 'trackUnpublished';
      case BrightParticipantEvent.TrackSubscribed:
        return 'trackSubscribed';
      case BrightParticipantEvent.TrackUnsubscribed:
        return 'trackUnsubscribed';
      default:
        return null;
    }
  }
  /**
   * Actually set the track subscription on an interval to debounce constant calls on scroll or render events
   *
   * @param   {TrackSubscriptionMode}  mode  Mode to set
   *
   */
  private _setTrackSubscriptionsDebounced(mode: TrackSubscriptionMode) {
    if (this.mode === mode || this.isLocal) {
      return;
    }
    this.mode = mode;
    this.ensureSubscription();
  }
  /**
   * Set the current track subscription mode
   *
   * @param   {TrackSubscriptionMode}  mode  Mode to set
   *
   */
  setTrackSubscriptions(mode: TrackSubscriptionMode) {
    this.currentMode = mode;
    // If the user scrolls very fast or an element is temporarily invisible due to a re-render of it's container we don't want to unsubscribe it immediately. Wait 1 second and make sure is still hidden
    if (mode === TrackSubscriptionMode.None) {
      this.modeDebounceTimeout = setTimeout(() => {
        if (this.currentMode === TrackSubscriptionMode.None) {
          this._setTrackSubscriptionsDebounced(mode);
        }
      }, 1000);
    } else {
      // The user's tracks have been re-subscribed to before the mode debounce timer. Cancel that timer
      if (this.modeDebounceTimeout) {
        clearTimeout(this.modeDebounceTimeout);
      }
      this._setTrackSubscriptionsDebounced(mode);
    }
  }
  /**
   * Ensure the correct tracks are subscribed to based on the subscription mode.
   * Call when a new track is published for this user
   *
   */
  ensureSubscription() {
    this.participant?.videoTracks.forEach(t => {
      if (t.kind === 'video') {
        const pub = t as RemoteVideoTrackPublication;
        const enable = [
          TrackSubscriptionMode.VideoOnly,
          TrackSubscriptionMode.Both,
        ].includes(this.mode);
        if (enable) {
          pub.track?.switchOn();
        } else {
          pub.track?.switchOff();
        }
      }
    });
    this.participant?.audioTracks.forEach(t => {
      if (t.kind === 'audio') {
        const pub = t as RemoteAudioTrackPublication;
        const enable = [
          TrackSubscriptionMode.AudioOnly,
          TrackSubscriptionMode.Both,
        ].includes(this.mode);
        if (enable) {
          pub.track?.attach();
        } else {
          pub.track?.detach();
        }
      }
    });
  }
  getTracks(): ITrackPublication[] {
    const tracks: TwilioTrackPublication[] = [];
    for (const track of this.participant.tracks.values()) {
      tracks.push(this.getTrackPublication(track));
    }
    return tracks;
  }
  getTrack(type: TrackType): ITrackPublication | undefined {
    switch (type) {
      case TrackType.Audio:
        for (const track of this.participant.audioTracks.values()) {
          return this.getTrackPublication(track);
        }
        break;
      case TrackType.ScreenShare:
        for (const track of this.participant.videoTracks.values()) {
          if (track.trackName === 'screenshare') {
            return this.getTrackPublication(track);
          }
        }
        break;
      case TrackType.ScreenShareAudio:
        // TODO
        break;
      case TrackType.Video:
        for (const track of this.participant.videoTracks.values()) {
          return this.getTrackPublication(track);
        }
        break;
    }
    return;
  }
}
