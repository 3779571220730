import React from 'react';
import {DefaultTheme, withTheme} from 'styled-components';
// Components
import {
  Role,
  SessionListParticipantView,
  BrightScreenShareView,
  RoomState,
  ParticipantProps,
  ScreenShareLayoutTypes,
  StatusMessage,
} from 'bright-livekit';
// Styles
import S from '../style';

const ScreenshareLayoutComponent = ({
  roomState,
  participantRenderer,
  stageSize,
}: {
  roomState: RoomState;
  participantRenderer?: (props: ParticipantProps) => React.ReactElement | null;
  stageSize: {
    height: number;
    width: number;
  };
  theme: DefaultTheme;
}) => {
  const ParticipantRenderer = participantRenderer ?? SessionListParticipantView;
  const sessionID = roomState.session.id;
  const gridParticipantSize = stageSize.height / 7;

  const creatorParticipant = roomState.participantsOnStage.find(
    p => p.role === Role.Creator
  );

  const backup =
    creatorParticipant ?? roomState.participantsOnStage.length > 0
      ? roomState.participantsOnStage[0]
      : undefined;

  const speakerParticipant = roomState.mainSpeaker ?? backup;

  if (!roomState.screenShareTrack) {
    return (
      <StatusMessage
        text="Loading"
        loading={true}
        brandColor={roomState.brandColor}
      />
    );
  }

  const participantsVisible =
    ScreenShareLayoutTypes.Grid === roomState.screenShareLayout;

  const speakerVisible = [
    ScreenShareLayoutTypes.ScreenShareMain,
    ScreenShareLayoutTypes.SpeakerMain,
  ].includes(roomState.screenShareLayout);

  const speakerMain =
    ScreenShareLayoutTypes.SpeakerMain === roomState.screenShareLayout;
  const shairMain =
    ScreenShareLayoutTypes.ScreenShareMain === roomState.screenShareLayout;

  let i = 0;
  const screenShareParticipants = (
    <S.ScreenShareOnStageParticipants>
      {roomState.participantsOnStage?.map(participant => {
        return (
          <ParticipantRenderer
            roomState={roomState}
            modControls={roomState.modControls}
            onStage={roomState.onStage}
            key={participant.id}
            participant={participant}
            sessionID={sessionID}
            stageSize={stageSize}
            stagePosition={i++}
            totalStageParticipants={length}
            shareHeight={`${gridParticipantSize}px`}
            shareWidth={`${gridParticipantSize}px`}
            orientation="landscape"
            mode="share"
            visible={participantsVisible}
          />
        );
      })}
    </S.ScreenShareOnStageParticipants>
  );

  return (
    <>
      <S.ScreenShareGridContainer
        $pip={speakerMain}
        $brandColor={roomState.brandColor}
      >
        <S.ScreenShareGrid
          $full={!participantsVisible}
          $pip={speakerMain}
          $gridParticipantSize={gridParticipantSize}
        >
          <BrightScreenShareView
            trackPublication={roomState.screenShareTrack}
          ></BrightScreenShareView>
        </S.ScreenShareGrid>
      </S.ScreenShareGridContainer>
      {speakerParticipant && (
        <S.ScreenShareSpeakerContainer $pip={shairMain}>
          <ParticipantRenderer
            roomState={roomState}
            modControls={roomState.modControls}
            onStage={roomState.onStage}
            key={speakerParticipant.id}
            participant={speakerParticipant}
            sessionID={sessionID}
            stageSize={stageSize}
            stagePosition={1}
            totalStageParticipants={length}
            shareHeight="100%"
            shareWidth="100%"
            orientation="landscape"
            mode="share"
            visible={speakerVisible}
          />
        </S.ScreenShareSpeakerContainer>
      )}
      {screenShareParticipants}
    </>
  );
};

export const ScreenshareLayout = withTheme(ScreenshareLayoutComponent);
