import styled, {DefaultTheme} from 'styled-components';
import {Hex} from '@brightlive/shared/helpers/interfaces';

const S = {
  StageRefWrapper: styled.div`
    flex: 1;
    position: relative;
  `,
  StageRefInnerWrapper: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
  `,
  StageRef: styled.div`
    order: 1;
    position: relative;
  `,
  GeneralStageLayout: styled.div(
    (props: {$brandColor: Hex; theme: DefaultTheme}) => `
    display: flex;
    justify-content: end;
    align-items: flex-end;
    flex-direction: column;
    height: 100%;
    position: relative;
    background-color: ${props.$brandColor};
  `
  ),
  ScreenShareOnStageParticipants: styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    z-index: 2;
  `,

  ScreenShareSpeakerContainer: styled.div(
    (props: {$pip: boolean; theme: DefaultTheme}) => `
      height: ${props.$pip ? '25%' : '100%'};
      width: ${props.$pip ? '25%' : '100%'};
      position: absolute;
      bottom: 0px;
      right: 0;
    `
  ),
  ScreenShareCreatorContainer: styled.div`
    width: 150px;
    height: 150px;
    position: absolute;
    bottom: 0px;
    right: 0;
  `,
  ScreenShareGridContainer: styled.div(
    (props: {$pip: boolean; $brandColor: Hex; theme: DefaultTheme}) => `
      display: flex;
      position: absolute;
      align-items: ${props.$pip ? 'flex-end' : 'center'};
      justify-content: ${props.$pip ? 'flex-end' : 'flex-start'};
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: ${props.$brandColor};
    `
  ),
  ScreenShareGrid: styled.div(
    (props: {
      $full: boolean;
      $pip: boolean;
      $gridParticipantSize: number;
      theme: DefaultTheme;
    }) => `
      width: ${
        props.$pip
          ? '45%'
          : props.$full
          ? '100%'
          : `calc(100% - ${props.$gridParticipantSize}px)`
      };
      max-height: 100%;
      z-index: ${props.$pip ? '2' : '0'};
    `
  ),
};

export default S;
