export class CaptionEntry {
  private _transcript: string;
  private _lastUpdateTime: number;
  userID: string;
  name: string;
  readonly EXPIRATION_TIME = 15000;
  constructor(userID: string, name: string) {
    this.name = name;
    this.userID = userID;
    this._lastUpdateTime = 0;
    this._transcript = '';
  }
  public get lastUpdateTime() {
    return this._lastUpdateTime;
  }
  public get isExpired() {
    const now = new Date().getTime();
    // Captions expire after a given length of time
    return this._lastUpdateTime < now - this.EXPIRATION_TIME;
  }
  public set transcript(text: string) {
    if (this._transcript === text) {
      return;
    }
    this._lastUpdateTime = new Date().getTime();
    this._transcript = text;
  }
  public get transcript() {
    return this._transcript;
  }
}
