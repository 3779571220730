import React from 'react';
//Components
import Modal from '@brightlive/shared/components/Modal';
// Styles
import S from './style';

export const EndSessionModal = ({
  buttonLoading,
  onClose,
  endSession,
}: {
  buttonLoading: boolean;
  onClose: () => void;
  endSession: () => void;
}) => {
  return (
    <Modal
      closeModal={() => {
        onClose();
      }}
      buttons={{
        button1Text: 'Cancel',
        button2Text: 'End session',
        button2Loading: buttonLoading,
        button1OnClick: () => {
          onClose();
        },
        button2OnClick: endSession,
      }}
    >
      <S.ModalHeader>End session for all?</S.ModalHeader>
      <S.ModalContent>
        This action will abruptly end the session for everyone. Ending the
        session before the alloted time will result in refunds to all
        participants. You will not be able to start this session again. Are you
        sure you want to end the session?
      </S.ModalContent>
    </Modal>
  );
};
