import React from 'react';
// Images
import VideocamOff from '@brightlive/shared/icons/VideocamOff';
import Flag from '@brightlive/shared/icons/Flag';
import ScreenShare from '@brightlive/shared/icons/ScreenShare';
// Helpers
import {getSrc, getAvatarColors} from '../../helpers/image';
// Style
import S from './style';

export type Sizes =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | number;

export const sizes = {
  small: 32,
  medium: 72,
  large: 96,
  xlarge: 120,
  xxlarge: 296,
};

export interface AvatarProps {
  avatar?: string;
  displayName?: string;
  videoMuted?: boolean;
  screenshare?: boolean;
  userFlagged?: boolean;
  size: Sizes;
}

const Avatar = ({
  avatar,
  displayName,
  videoMuted,
  screenshare,
  userFlagged,
  size,
}: AvatarProps) => {
  const StatusIcons = () => {
    return (
      <S.StatusIconsWrapper>
        {userFlagged && (
          <S.FlaggedBackground $videoMuted={videoMuted}>
            <Flag size={16} />
          </S.FlaggedBackground>
        )}
        {screenshare && (
          <S.ScreenShareBackground $videoMuted={videoMuted}>
            <ScreenShare contentColor="subdued" size={16} />
          </S.ScreenShareBackground>
        )}
        {videoMuted && (
          <S.VideoMutedBackground $shared={userFlagged}>
            <VideocamOff contentColor="subdued" size={16} />
          </S.VideoMutedBackground>
        )}
      </S.StatusIconsWrapper>
    );
  };
  const calculatedSize = typeof size === 'number' ? size : sizes[size];
  if (avatar) {
    return (
      <S.RelativeContainer $size={calculatedSize}>
        <S.Avatar
          $size={calculatedSize}
          src={getSrc(avatar)}
          height={calculatedSize}
          width={calculatedSize}
          imgixParams={{fit: 'crop'}}
          alt={displayName}
        />
        {StatusIcons()}
      </S.RelativeContainer>
    );
  }

  const firstInitial = `${displayName?.charAt(0).toUpperCase()}`;
  const avatarColors = getAvatarColors(firstInitial);

  return (
    <S.AvatarCircle
      as="div"
      $size={calculatedSize}
      $color={avatarColors.background}
    >
      <S.Initials $size={size} $color={avatarColors.foreground}>
        {firstInitial}
      </S.Initials>
      {StatusIcons()}
    </S.AvatarCircle>
  );
};

export default React.memo(Avatar);
