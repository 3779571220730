import styled, {DefaultTheme} from 'styled-components';
import Ag, {
  getBold,
  getParagraphMD,
} from '@brightlive/shared/styles/typography-v3';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import {focus, hover, active} from '@brightlive/shared/helpers/style';
import {Hex} from '@brightlive/shared/helpers/interfaces';

const S = {
  LayoutView: styled.div`
    background-color: ${props => props.theme.backgroundColor.primary};
    height: 100%;
    overflow-y: auto;
    padding: ${props => props.theme.spacing.MD}
      ${props => props.theme.spacing.MD} ${props => props.theme.spacing.XL};
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Section: styled.div`
    flex: 1;
  `,
  SectionContent: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
    padding: ${props => props.theme.spacing.XS};
    margin-bottom: ${props => props.theme.spacing.LG};
  `,
  Subtitle: styled(Ag.ParagraphMD)``,
  BrandingSection: styled.div`
    padding: ${props => props.theme.spacing.LG} 0;
  `,
  BrandingContent: styled.div`
    padding: ${props => props.theme.spacing.XS}
      ${props => props.theme.spacing.MD} 0;
  `,
  BrandingHeader: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,

  BrandingHeaderText: styled.div`
    flex: 1;
  `,
  BrandingTitle: styled(Ag.HeadingXS)`
    margin-bottom: ${props => props.theme.spacing['2XS']};
  `,
  BrandingSubtitle: styled(Ag.ParagraphSM)``,
  BrandingInnerContent: styled.div`
    margin-top: ${props => props.theme.spacing.LG};
  `,
  Switch: styled.div`
    margin-left: ${props => props.theme.spacing.MD};
  `,
  NameSection: styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 0;
  `,
  BannerPreview: styled.div(
    (props: {$brandColor: Hex; theme: DefaultTheme}) => `
    img {
      width: 100%;
      background-color: ${props.$brandColor};
    }
  `
  ),
  BannerPreviewIcons: styled.div`
    display: flex;
    gap: ${props => props.theme.spacing.XS};
    margin-top: ${props => props.theme.spacing.LG};
  `,
  HiddenFileInput: styled.input`
    display: none;
  `,
  BannerLayout: styled.div`
    margin-top: ${props => props.theme.spacing['2XL']};
  `,
  BannerLayoutTitle: styled(Ag.Heading2XS)`
    margin-bottom: ${props => props.theme.spacing['2XS']};
  `,
  BannerLayoutSubtitle: styled(Ag.ParagraphSM)`
    margin-bottom: ${props => props.theme.spacing.XS};
  `,
  BannerItems: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
  `,
  LayoutItem: styled.div(
    (props: {$selected: boolean; theme: DefaultTheme}) => `
    padding: ${props.theme.spacing.XS};
    background: ${
      props.$selected ? props.theme.actionColor.selected : 'transparent'
    };
    border-radius: ${props.theme.borderRadius.SM};
    margin-bottom: ${props.theme.spacing.XS};
    position: relative;
    cursor: pointer;
    box-sizing: border-box;

    ${focus({
      theme: props.theme,
      disabled: false,
      borderRadius: props.theme.borderRadius.SM,
    })}

    ${hover({
      backgroundColor: props.theme.actionColor.hover,
      disabled: props.$selected,
      borderRadius: props.theme.borderRadius.SM,
    })}

    ${active({
      backgroundColor: props.theme.actionColor.pressed,
      disabled: props.$selected,
      borderRadius: props.theme.borderRadius.SM,
    })}
  `
  ),
  Label: styled.div`
    padding-top: ${props => props.theme.spacing.XS};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
  `,
  LabelText: styled(Ag.ParagraphSM)(
    (props: {$selected: boolean; theme: DefaultTheme}) => `
    ${props.$selected ? getBold() : ''}
    margin-right: ${props.theme.spacing.XS};

    ${media.LG} {
      ${getParagraphMD(props.theme)}
      ${props.$selected ? getBold() : ''}
    }
  `
  ),
  Image: styled.img`
    width: 100%;
    position: relative;
    z-index: 2;
  `,
};
export default S;
