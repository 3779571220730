// eslint-disable-next-line node/no-extraneous-import
import {Property} from 'csstype';
import {TrackSubscriptionMode} from 'bright-livekit/types/BrightParticipant';
import React, {ReactElement, useEffect, useState, useRef} from 'react';
import moment from 'moment';
import {useDispatch} from 'react-redux';
import useOnScreen from 'bright-livekit/hooks/useOnScreen';
// Components
import {
  ParticipantProps,
  useParticipant,
  VideoRenderer,
  ViewMode,
} from 'bright-livekit';
import Linkify from 'linkify-react';
import TooltipWrapper from '@brightlive/shared/components/TooltipWrapper';
import Avatar from '@brightlive/shared/components/Avatar';
import Menu from '@brightlive/shared/components/Menu';
import IconButton from '@brightlive/shared/components/IconButton';
// Redux
import {
  toggleDeleteCommentModal,
  toggleFlagUserModal,
} from 'redux/livekit/actions';
// Helpers
import {MenuItem} from '@brightlive/shared/helpers/interfaces';
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
// Style
import S from './style';
import {Role} from 'bright-livekit/types/Enums';

export const SessionChatParticipantView = ({
  participant,
  text,
  timestamp,
  displayName,
  modControls,
  sessionID,
  roomState,
  replaceQuestion,
}: ParticipantProps) => {
  moment.updateLocale('en', {
    relativeTime: {
      s: 'just now',
      ss: 'just now',
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
    },
  });

  const dispatch = useDispatch();
  const {cameraPublication, isLocal} = useParticipant(
    participant?.videoParticipant
  );
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [moreOptionsPosition, setMoreOptionsPosition] = useState({
    top: 0,
    left: 0,
  });
  const [buttonLoading, setButtonLoading] = useState('');
  const [awaitingInvite, setAwaitingInvite] = useState('');
  const ref = useRef<HTMLDivElement>(null);
  const textContainerRef = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);

  const {isMobile, width, height} = useWindowSize();
  let hoverControls;

  const reclacHoverPositions = () => {
    if (textContainerRef?.current) {
      const rect = textContainerRef.current?.getBoundingClientRect();
      setMoreOptionsPosition({top: rect.y + 45, left: rect.x});
    }
  };

  // Recalc the hover container positions when deletions are made
  useEffect(() => reclacHoverPositions(), [roomState]);

  useEffect(() => {
    reclacHoverPositions();
  }, [textContainerRef, width, height]);

  useEffect(() => {
    if (!buttonLoading || !awaitingInvite) {
      return;
    }
    const inviteResponse = roomState.notifications?.docs.some(
      r => r.data().notificationReference === awaitingInvite
    );
    if (inviteResponse) {
      setButtonLoading('');
      setAwaitingInvite('');
    }
  }, [roomState.notifications, awaitingInvite]);

  useEffect(() => {
    // On stage users can add comments as well, don't change user's subscription if they are not green room only
    if (participant?.role !== Role.GreenRoom) {
      return;
    }
    if (isVisible) {
      participant?.setTrackSubscriptions(TrackSubscriptionMode.VideoOnly);
    } else {
      participant?.setTrackSubscriptions(TrackSubscriptionMode.None);
    }
  }, [isVisible]);

  if (!participant || !participant.videoParticipant) {
    return <></>;
  }

  const addVideoToStage = () => {
    if (!roomState.stageFull) {
      if (participant.id === roomState.currentUser.id) {
        roomState.videoUtils?.moveOnStage(
          sessionID,
          roomState.currentUser.id,
          ViewMode.Video
        );
      } else {
        roomState.videoUtils?.inviteOnStage(
          sessionID,
          roomState.currentUser.id,
          participant.id,
          ViewMode.Video
        );
      }
    } else {
      roomState.videoUtils?.removeFromStage(
        sessionID,
        roomState.currentUser.id,
        participant.id,
        !!roomState.modControls
      );
    }
    setShowMoreOptions(false);
  };

  const objectFit: Property.ObjectFit = 'cover';

  const onRemoveQuestionClick = () => {
    dispatch(
      toggleDeleteCommentModal({toggled: true, participant: participant})
    );
  };

  const onBanUserClick = () => {
    dispatch(
      toggleFlagUserModal({
        toggled: true,
        participant: participant,
      })
    );
  };

  const moreMenuItems = (): MenuItem[] => {
    const items: MenuItem[] = [];
    if (
      participant.id === roomState.selfParticipant?.id ||
      (participant.id !== roomState.selfParticipant?.id &&
        participant.role !== Role.Creator)
    ) {
      items.push({
        label: 'Delete question',
        negative: true,
        onClick: () => {
          if (participant.id === roomState.selfParticipant?.id) {
            roomState.videoUtils?.removeOwnQuestion(
              sessionID,
              roomState.currentUser.id
            );
          } else {
            onRemoveQuestionClick();
          }
          setShowMoreOptions(false);
        },
      });
    }
    if (modControls) {
      // Is the user we are acting on not on stage (Full moderator controls)
      if (participant.role !== Role.Creator) {
        if (isMobile) {
          let label =
            participant.role === Role.GreenRoom
              ? 'Bring to stage'
              : 'Remove from stage';
          if (participant.id === roomState.selfParticipant?.id) {
            label =
              participant.role === Role.GreenRoom
                ? 'Join stage'
                : 'Leave stage';
          }
          items.unshift({
            label: label,
            disabled: roomState.stageFull,
            onClick: addVideoToStage,
          });
        }
        if (participant.id !== roomState.selfParticipant?.id) {
          // Add ability to remove user from session
          items.push({
            label: 'Remove from session',
            negative: true,
            onClick: () => {
              onBanUserClick();
              setShowMoreOptions(false);
            },
          });
        }
      }
    }
    return items;
  };

  // Self hover controls (remove replace own question)
  if (participant.id === roomState.selfParticipant?.id) {
    hoverControls = (
      <>
        {participant.role === Role.GreenRoom && modControls && (
          <TooltipWrapper
            tooltipContent="Join stage"
            referenceContent={
              <IconButton
                type="tertiary"
                size="small"
                icon="PersonAdd"
                disabled={roomState.stageFull}
                onClick={addVideoToStage}
              />
            }
          />
        )}
        <TooltipWrapper
          tooltipContent="Replace comment"
          referenceContent={
            <IconButton
              type="tertiary"
              size="small"
              icon="Edit"
              onClick={replaceQuestion}
            />
          }
        />
        <TooltipWrapper
          tooltipContent="Delete"
          referenceContent={
            <IconButton
              type="tertiary"
              size="small"
              icon="Delete"
              onClick={onRemoveQuestionClick}
            />
          }
        />
      </>
    );
    // Current user is a moderator
  } else if (modControls) {
    // Is the user we are acting on not on stage (Full moderator controls)
    if (participant.role === Role.GreenRoom) {
      hoverControls = (
        <>
          <TooltipWrapper
            tooltipContent="Bring to stage"
            referenceContent={
              <IconButton
                type="tertiary"
                size="small"
                icon="PersonAdd"
                disabled={roomState.stageFull}
                onClick={addVideoToStage}
              />
            }
          />
          <div ref={textContainerRef}>
            <TooltipWrapper
              tooltipContent="More actions"
              referenceContent={
                <IconButton
                  type="tertiary"
                  size="small"
                  icon="AndroidMore"
                  onClick={() => setShowMoreOptions(true)}
                />
              }
            />
          </div>
        </>
      );
    } else if (participant.role !== Role.Creator) {
      // Is the user we are acting on currently on stage (Only delete comment action)
      hoverControls = (
        <>
          <TooltipWrapper
            tooltipContent="Delete"
            referenceContent={
              <IconButton
                type="tertiary"
                size="small"
                icon="Delete"
                onClick={onRemoveQuestionClick}
              />
            }
          />
          <div ref={textContainerRef}>
            <TooltipWrapper
              tooltipContent="More actions"
              referenceContent={
                <IconButton
                  type="tertiary"
                  size="small"
                  icon="AndroidMore"
                  onClick={() => setShowMoreOptions(true)}
                />
              }
            />
          </div>
        </>
      );
    }
  }

  let mainElement: ReactElement;

  if (
    participant.role !== Role.OnStage &&
    participant.role !== Role.Creator &&
    cameraPublication?.isSubscribed &&
    cameraPublication?.track &&
    !cameraPublication?.isMuted
  ) {
    mainElement = (
      <VideoRenderer
        track={cameraPublication.track}
        isLocal={isLocal}
        objectFit={objectFit}
        name={participant.id}
        width={isMobile ? '96px' : '128px'}
        height="100%"
      />
    );
  } else {
    mainElement = (
      <S.AttendeeAvatarContainer>
        <Avatar
          size={isMobile ? 'medium' : 'large'}
          avatar={participant.avatar}
          displayName={participant.displayName}
          screenshare={roomState.screenShareUser === participant.id}
          userFlagged={participant.role === Role.Banned}
          videoMuted={
            !cameraPublication?.isSubscribed ||
            !cameraPublication?.track ||
            cameraPublication?.isMuted
          }
        />
      </S.AttendeeAvatarContainer>
    );
  }

  const enableMobileClick =
    isMobile &&
    (participant.id === roomState.selfParticipant?.id || modControls);

  return (
    <S.ChatMessage
      ref={ref}
      onMouseLeave={() => {
        setShowMoreOptions(false);
      }}
      onClick={enableMobileClick ? () => setShowMoreOptions(true) : undefined}
    >
      <S.Video $isSelf={false}>{mainElement}</S.Video>
      <S.TextContainer $isSelf={false}>
        <S.NameWrapper>
          <S.Name>{displayName}</S.Name>
          <S.Timestamp>{moment(timestamp).fromNow(true)}</S.Timestamp>
        </S.NameWrapper>
        <S.Text>
          <Linkify options={{target: '_blank', rel: 'noopener'}}>
            {text}
          </Linkify>
        </S.Text>
        <S.ActionBar>
          {hoverControls}
          {showMoreOptions && (
            <S.MoreOptions
              style={{
                top: moreOptionsPosition.top,
                left: moreOptionsPosition.left,
              }}
            >
              <Menu
                isMobile={isMobile}
                type="default"
                onClose={() => setShowMoreOptions(false)}
                menuItems={moreMenuItems()}
              />
            </S.MoreOptions>
          )}
        </S.ActionBar>
      </S.TextContainer>
    </S.ChatMessage>
  );
};
