import React from 'react';
//Components
import {BrightParticipant, RoomState} from 'bright-livekit';
import Modal from '@brightlive/shared/components/Modal';
import Button from '@brightlive/shared/components/Button';
// Styles
import S from './style';

interface DeleteCommentModalProps {
  onClose: Function;
  participant: BrightParticipant;
  sessionID: string;
  roomState: RoomState;
}

export const DeleteCommentModal = ({
  onClose,
  participant,
  sessionID,
  roomState,
}: DeleteCommentModalProps) => {
  const handleDelete = () => {
    if (roomState.modControls) {
      roomState.videoUtils?.removeQuestion(sessionID, participant.id);
    } else {
      roomState.videoUtils?.removeOwnQuestion(sessionID, participant.id);
    }
    onClose();
  };

  return (
    <Modal closeModal={onClose}>
      <S.ModalContent>
        <S.ModalTitle>Delete comment?</S.ModalTitle>
        <S.ModalBody>
          {participant.displayName}'s comment will be deleted. This action
          cannot be undone. Are you sure you want to delete the comment?
        </S.ModalBody>
        <S.Buttons>
          <Button
            type="secondary"
            size="medium"
            text="Cancel"
            onClick={onClose}
          />
          <Button
            type="negative"
            size="medium"
            text="Delete"
            onClick={handleDelete}
          />
        </S.Buttons>
      </S.ModalContent>
    </Modal>
  );
};
