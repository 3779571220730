import React from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
import {IconProps} from '@brightlive/shared/helpers/interfaces';

const RaiseHandFilled = ({
  size = 24,
  contentColor = 'default',
  theme,
  ...props
}: IconProps & {
  theme: DefaultTheme;
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M12.775 24c-1.217 0-2.329-.23-3.337-.688a8.461 8.461 0 0 1-2.613-1.824 8.23 8.23 0 0 1-1.712-2.638 8.12 8.12 0 0 1-.613-3.1v-10c0-.35.121-.646.363-.888.241-.241.537-.362.887-.362s.646.12.888.362c.241.242.362.538.362.888v5.75c0 .133.05.25.15.35a.48.48 0 0 0 .7 0c.1-.1.15-.217.15-.35V2.75c0-.35.121-.646.363-.888.241-.241.537-.362.887-.362s.646.12.887.362c.242.242.363.538.363.888v7.75c0 .133.05.25.15.35a.48.48 0 0 0 .7 0c.1-.1.15-.217.15-.35V1.25c0-.35.121-.646.363-.888C12.104.121 12.4 0 12.75 0s.646.12.887.362c.242.242.363.538.363.888v9.25c0 .133.05.25.15.35a.48.48 0 0 0 .7 0c.1-.1.15-.217.15-.35V3.25c0-.35.121-.646.363-.888.241-.241.537-.362.887-.362s.646.12.888.362c.241.242.362.538.362.888v10.775c-.933.133-1.708.513-2.325 1.138a3.985 3.985 0 0 0-1.125 2.262.423.423 0 0 0 .113.4.537.537 0 0 0 .412.175.432.432 0 0 0 .3-.113.483.483 0 0 0 .15-.312c.1-.733.433-1.346 1-1.837A2.92 2.92 0 0 1 18 15a.48.48 0 0 0 .35-.15c.1-.1.15-.217.15-.35V9.25c0-.35.121-.646.363-.887.241-.242.537-.363.887-.363s.646.121.888.363c.241.241.362.537.362.887v6.5c0 1.067-.2 2.096-.6 3.087a8.342 8.342 0 0 1-1.688 2.638 8.2 8.2 0 0 1-2.599 1.837c-1.009.459-2.121.688-3.338.688Z"
      fill={
        contentColor.indexOf('#') > -1
          ? contentColor
          : theme.contentColor[contentColor]
      }
    />
  </svg>
);

export default withTheme(RaiseHandFilled);
