import {LayoutTypes} from 'bright-livekit';

const BORDER = 0;

function getTotalWidth(
  layout: LayoutTypes,
  stageSize: {width: number; height: number},
  total: number,
  num: number
) {
  if (total < num) {
    return 0;
  }
  return (
    stageSize.width -
    (getLayout(layout, total, num).neighbors.horiz + 2) * BORDER
  );
}
function getTotalHeight(
  layout: LayoutTypes,
  stageSize: {width: number; height: number},
  total: number,
  num: number
) {
  if (total < num) {
    return 0;
  }
  return (
    stageSize.height -
    (getLayout(layout, total, num).neighbors.vert + 2) * BORDER
  );
}

export function getHeight(
  layout: LayoutTypes,
  stageSize: {width: number; height: number},
  total: number,
  num: number
) {
  return Math.floor(
    getTotalHeight(layout, stageSize, total, num) *
      getLayout(layout, total, num).height
  );
}
export function getWidth(
  layout: LayoutTypes,
  stageSize: {width: number; height: number},
  total: number,
  num: number
) {
  return Math.floor(
    getTotalWidth(layout, stageSize, total, num) *
      getLayout(layout, total, num).width
  );
}

export function getHeightMobile(
  stageSize: {width: number; height: number},
  total: number,
  num: number
) {
  if (stageSize.width < stageSize.height) {
    return layoutMobileVertical[total][num].height * stageSize.height;
  } else {
    return layoutMobileHorizontal[total][num].height * stageSize.height;
  }
}
export function getWidthMobile(
  stageSize: {width: number; height: number},
  total: number,
  num: number
) {
  if (stageSize.width < stageSize.height) {
    return layoutMobileVertical[total][num].width * stageSize.width;
  } else {
    return layoutMobileHorizontal[total][num].width * stageSize.width;
  }
}

export function getMargin(
  layout: LayoutTypes,
  stageSize: {width: number; height: number},
  total: number,
  num: number
) {
  return {
    Left:
      Math.floor(
        getTotalWidth(layout, stageSize, total, num) *
          getLayout(layout, total, num).margin.left
      ) +
      BORDER * (getLayout(layout, total, num).neighbors.left + 1),
    Top:
      Math.floor(
        getTotalHeight(layout, stageSize, total, num) *
          getLayout(layout, total, num).margin.top
      ) +
      BORDER * (getLayout(layout, total, num).neighbors.top + 1),
    Right: BORDER,
    Bottom: BORDER,
  };
}

// layout for the various stage setups having a creator and some or none stage guests
export const layouts = {
  single: [
    [
      {
        height: 1,
        width: 1,
        neighbors: {
          left: 0,
          top: 0,
          vert: 0,
          horiz: 0,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
    ],
  ],
  pip: [
    [
      {
        height: 1,
        width: 1,
        neighbors: {
          left: 0,
          top: 0,
          vert: 0,
          horiz: 0,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.5,
        neighbors: {
          left: 0,
          top: 0,
          vert: 0,
          horiz: 0,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
    ],
  ],
  'split-right': [
    [
      {
        height: 1,
        width: 1,
        neighbors: {
          left: 0,
          top: 0,
          vert: 0,
          horiz: 0,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
    ],
    [
      {
        height: 1,
        width: 0.5,
        neighbors: {
          left: 1,
          top: 0,
          vert: 0,
          horiz: 0,
        },
        margin: {
          left: 0.5,
          top: 0,
        },
      },
      {
        height: 1,
        width: 0.5,
        neighbors: {
          left: 0,
          top: 0,
          vert: 0,
          horiz: 0,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
    ],
    [
      {
        height: 1,
        width: 0.5,
        neighbors: {
          left: 1,
          top: 0,
          vert: 0,
          horiz: 0,
        },
        margin: {
          left: 0.5,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.5,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 0,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.5,
        neighbors: {
          left: 0,
          top: 1,
          vert: 1,
          horiz: 0,
        },
        margin: {
          left: 0,
          top: 0.5,
        },
      },
    ],
    [
      {
        height: 1,
        width: 0.5,
        neighbors: {
          left: 1,
          top: 0,
          vert: 0,
          horiz: 2,
        },
        margin: {
          left: 0.5,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.5,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 0,
          top: 1,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 1,
          top: 1,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.25,
          top: 0.5,
        },
      },
    ],
    [
      {
        height: 1,
        width: 0.5,
        neighbors: {
          left: 2,
          top: 0,
          vert: 0,
          horiz: 2,
        },
        margin: {
          left: 0.5,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 1,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.25,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 0,
          top: 1,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 1,
          top: 1,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.25,
          top: 0.5,
        },
      },
    ],
    [
      {
        height: 1,
        width: 0.5,
        neighbors: {
          left: 3,
          top: 0,
          vert: 0,
          horiz: 3,
        },
        margin: {
          left: 0.5,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.251,
        neighbors: {
          left: 1,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.25,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.166,
        neighbors: {
          left: 0,
          top: 1,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.166,
        neighbors: {
          left: 1,
          top: 1,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.166,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.168,
        neighbors: {
          left: 2,
          top: 1,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.332,
          top: 0.5,
        },
      },
    ],
    [
      {
        height: 1,
        width: 0.5,
        neighbors: {
          left: 3,
          top: 0,
          vert: 0,
          horiz: 3,
        },
        margin: {
          left: 0.5,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.166,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.166,
        neighbors: {
          left: 1,
          top: 0,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.166,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.166,
        neighbors: {
          left: 2,
          top: 0,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.332,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.166,
        neighbors: {
          left: 0,
          top: 1,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.166,
        neighbors: {
          left: 1,
          top: 1,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.166,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.166,
        neighbors: {
          left: 2,
          top: 1,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.332,
          top: 0.5,
        },
      },
    ],
  ],
  'split-left': [
    [
      {
        height: 1,
        width: 1,
        neighbors: {
          left: 0,
          top: 0,
          vert: 0,
          horiz: 0,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
    ],
    [
      {
        height: 1,
        width: 0.5,
        neighbors: {
          left: 0,
          top: 0,
          vert: 0,
          horiz: 0,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
      {
        height: 1,
        width: 0.5,
        neighbors: {
          left: 1,
          top: 0,
          vert: 0,
          horiz: 0,
        },
        margin: {
          left: 0.5,
          top: 0,
        },
      },
    ],
    [
      {
        height: 1,
        width: 0.5,
        neighbors: {
          left: 0,
          top: 0,
          vert: 0,
          horiz: 0,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.5,
        neighbors: {
          left: 1,
          top: 0,
          vert: 1,
          horiz: 0,
        },
        margin: {
          left: 0.5,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.5,
        neighbors: {
          left: 1,
          top: 1,
          vert: 1,
          horiz: 0,
        },
        margin: {
          left: 0.5,
          top: 0.5,
        },
      },
    ],
    [
      {
        height: 1,
        width: 0.5,
        neighbors: {
          left: 0,
          top: 0,
          vert: 0,
          horiz: 2,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.5,
        neighbors: {
          left: 1,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.5,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 1,
          top: 1,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.5,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 2,
          top: 1,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.75,
          top: 0.5,
        },
      },
    ],
    [
      {
        height: 1,
        width: 0.5,
        neighbors: {
          left: 0,
          top: 0,
          vert: 0,
          horiz: 2,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 1,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.5,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 2,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.75,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 1,
          top: 1,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.5,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 2,
          top: 1,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.75,
          top: 0.5,
        },
      },
    ],
    [
      {
        height: 1,
        width: 0.5,
        neighbors: {
          left: 0,
          top: 0,
          vert: 0,
          horiz: 3,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 1,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.5,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 2,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.75,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.166,
        neighbors: {
          left: 1,
          top: 1,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.5,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.166,
        neighbors: {
          left: 2,
          top: 1,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.666,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.166,
        neighbors: {
          left: 3,
          top: 1,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.832,
          top: 0.5,
        },
      },
    ],
    [
      {
        height: 1,
        width: 0.5,
        neighbors: {
          left: 0,
          top: 0,
          vert: 0,
          horiz: 3,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.166,
        neighbors: {
          left: 1,
          top: 0,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.5,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.166,
        neighbors: {
          left: 2,
          top: 0,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.666,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.166,
        neighbors: {
          left: 3,
          top: 0,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.832,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.166,
        neighbors: {
          left: 1,
          top: 1,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.5,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.166,
        neighbors: {
          left: 2,
          top: 1,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.666,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.166,
        neighbors: {
          left: 3,
          top: 1,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.832,
          top: 0.5,
        },
      },
    ],
  ],
  grid: [
    [
      {
        height: 1,
        width: 1,
        neighbors: {
          left: 0,
          top: 0,
          vert: 0,
          horiz: 0,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
    ],
    [
      {
        height: 1,
        width: 0.5,
        neighbors: {
          left: 0,
          top: 0,
          vert: 0,
          horiz: 0,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
      {
        height: 1,
        width: 0.5,
        neighbors: {
          left: 1,
          top: 0,
          vert: 0,
          horiz: 0,
        },
        margin: {
          left: 0.5,
          top: 0,
        },
      },
    ],
    [
      {
        height: 1,
        width: 0.333,
        neighbors: {
          left: 0,
          top: 0,
          vert: 0,
          horiz: 2,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
      {
        height: 1,
        width: 0.333,
        neighbors: {
          left: 1,
          top: 0,
          vert: 0,
          horiz: 2,
        },
        margin: {
          left: 0.333,
          top: 0,
        },
      },
      {
        height: 1,
        width: 0.33,
        neighbors: {
          left: 2,
          top: 0,
          vert: 0,
          horiz: 2,
        },
        margin: {
          left: 0.666,
          top: 0,
        },
      },
    ],
    [
      {
        height: 0.5,
        width: 0.5,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 1,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.5,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 1,
        },
        margin: {
          left: 0.5,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.5,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 1,
        },
        margin: {
          left: 0,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.5,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 1,
        },
        margin: {
          left: 0.5,
          top: 0.5,
        },
      },
    ],
    [
      {
        height: 0.5,
        width: 0.333,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 1,
        },
        margin: {
          left: 0.1666,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.333,
        neighbors: {
          left: 1,
          top: 0,
          vert: 1,
          horiz: 1,
        },
        margin: {
          left: 0.5,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.333,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.333,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.333,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.333,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.666,
          top: 0.5,
        },
      },
    ],
    [
      {
        height: 0.5,
        width: 0.333,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.333,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.333,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.333,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.666,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.333,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.333,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.333,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.333,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.666,
          top: 0.5,
        },
      },
    ],
    [
      {
        height: 0.5,
        width: 0.333,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.333,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.333,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.333,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 2,
        },
        margin: {
          left: 0.666,
          top: 0,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.25,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.5,
          top: 0.5,
        },
      },
      {
        height: 0.5,
        width: 0.25,
        neighbors: {
          left: 0,
          top: 0,
          vert: 1,
          horiz: 3,
        },
        margin: {
          left: 0.75,
          top: 0.5,
        },
      },
    ],
  ],
};
const getLayout = (layoutType: LayoutTypes, total: number, num: number) => {
  if ([LayoutTypes.CreatorOnly, LayoutTypes.Speaker].includes(layoutType)) {
    return layouts['single'][0][0];
  }
  if (LayoutTypes.PictureInPicture === layoutType) {
    return layouts['pip'][0][num === 0 ? 0 : 1];
  }
  const res =
    typeof layouts[layoutType] === 'undefined'
      ? layouts[LayoutTypes.SplitLeft]
      : layouts[layoutType];
  return res[total][num];
};

const layoutMobileVertical = [
  [
    {
      height: 1,
      width: 1,
    },
  ],
  [
    {
      height: 0.5,
      width: 1,
    },
    {
      height: 0.5,
      width: 1,
    },
  ],
  [
    {
      height: 0.5,
      width: 1,
    },
    {
      height: 0.5,
      width: 0.5,
    },
    {
      height: 0.5,
      width: 0.5,
    },
  ],
  [
    {
      height: 0.5,
      width: 1,
    },
    {
      height: 0.25,
      width: 1,
    },
    {
      height: 0.25,
      width: 0.5,
    },
    {
      height: 0.25,
      width: 0.5,
    },
  ],
  [
    {
      height: 0.5,
      width: 1,
    },
    {
      height: 0.25,
      width: 0.5,
    },
    {
      height: 0.25,
      width: 0.5,
    },
    {
      height: 0.25,
      width: 0.5,
    },
    {
      height: 0.25,
      width: 0.5,
    },
  ],
  [
    {
      height: 0.5,
      width: 1,
    },
    {
      height: 0.25,
      width: 0.5,
    },
    {
      height: 0.25,
      width: 0.5,
    },
    {
      height: 0.25,
      width: 0.333,
    },
    {
      height: 0.25,
      width: 0.333,
    },
    {
      height: 0.25,
      width: 0.333,
    },
  ],
  [
    {
      height: 0.5,
      width: 1,
    },
    {
      height: 0.167,
      width: 0.5,
    },
    {
      height: 0.167,
      width: 0.5,
    },
    {
      height: 0.167,
      width: 0.5,
    },
    {
      height: 0.167,
      width: 0.5,
    },
    {
      height: 0.167,
      width: 0.5,
    },
    {
      height: 0.167,
      width: 0.5,
    },
  ],
];

const layoutMobileHorizontal = [
  [
    {
      height: 1,
      width: 1,
    },
  ],
  [
    {
      height: 1,
      width: 0.5,
    },
    {
      height: 1,
      width: 0.5,
    },
  ],
  [
    {
      height: 1,
      width: 0.5,
    },
    {
      height: 0.5,
      width: 0.5,
    },
    {
      height: 0.5,
      width: 0.5,
    },
  ],
  [
    {
      height: 1,
      width: 0.5,
    },
    {
      height: 0.5,
      width: 0.5,
    },
    {
      height: 0.5,
      width: 0.25,
    },
    {
      height: 0.5,
      width: 0.25,
    },
  ],
  [
    {
      height: 1,
      width: 0.5,
    },
    {
      height: 0.5,
      width: 0.25,
    },
    {
      height: 0.5,
      width: 0.25,
    },
    {
      height: 0.5,
      width: 0.25,
    },
    {
      height: 0.5,
      width: 0.25,
    },
  ],
  [
    {
      height: 1,
      width: 0.5,
    },
    {
      height: 0.5,
      width: 0.25,
    },
    {
      height: 0.5,
      width: 0.25,
    },
    {
      height: 0.5,
      width: 0.166,
    },
    {
      height: 0.5,
      width: 0.166,
    },
    {
      height: 0.5,
      width: 0.166,
    },
  ],
  [
    {
      height: 1,
      width: 0.5,
    },
    {
      height: 0.5,
      width: 0.166,
    },
    {
      height: 0.5,
      width: 0.166,
    },
    {
      height: 0.5,
      width: 0.166,
    },
    {
      height: 0.5,
      width: 0.166,
    },
    {
      height: 0.5,
      width: 0.166,
    },
    {
      height: 0.5,
      width: 0.166,
    },
  ],
];
