import {useSelector} from 'react-redux';
import {UIReducer} from 'redux/ui/reducer';
import React, {useEffect, useMemo, useState} from 'react';
// Components
import {
  Role,
  RoomState,
  SessionListParticipantView,
  BrightParticipant,
} from 'bright-livekit';
// Styles
import S from './style';
import Alert from '@brightlive/shared/components/Alert';

export const AttendeesView = ({
  roomState,
  replaceQuestion,
}: {
  roomState: RoomState;
  replaceQuestion: Function;
}) => {
  const alertBanner = useSelector((state: UIReducer) => state.ui.alertBanner);
  const [onStageParticipants, setOnStageParticipants] = useState<
    BrightParticipant[]
  >([]);
  const [offStageParticipants, setOffStageParticipants] = useState<
    BrightParticipant[]
  >([]);

  useEffect(() => {
    if (roomState.participantsPresent.length > 0) {
      const onStage = roomState.participantsPresent
        .filter(part => [Role.OnStage, Role.Creator].includes(part.role))
        .sort(sortParticipants);
      setOnStageParticipants(onStage);
      const offStage = roomState.participantsPresent
        .filter(part => part.role === Role.GreenRoom)
        .sort(sortParticipants);
      setOffStageParticipants(offStage);
    }
  }, [roomState.participantsPresent]);
  const sortParticipants = (a, b) => {
    if (a.displayName.toLocaleLowerCase() < b.displayName.toLocaleLowerCase()) {
      return -1;
    }
    if (a.displayName.toLocaleLowerCase() > b.displayName.toLocaleLowerCase()) {
      return 1;
    }
    return 0;
  };
  const OnStageParticipants = useMemo(() => {
    if (onStageParticipants.length > 0) {
      return onStageParticipants.map(participant => {
        return (
          <SessionListParticipantView
            roomState={roomState}
            key={participant.id}
            participant={participant}
            sessionID={roomState.session.id}
            replaceQuestion={replaceQuestion}
          />
        );
      });
    } else {
      return (
        <S.NoAttendeesWrapper>
          <S.NoAttendeesIcon>👻</S.NoAttendeesIcon>
          <S.NoAttendeesText>No one is on stage</S.NoAttendeesText>
        </S.NoAttendeesWrapper>
      );
    }
  }, [onStageParticipants]);
  const OffStageParticipants = useMemo(() => {
    if (offStageParticipants.length > 0) {
      return offStageParticipants.map(participant => {
        return (
          <SessionListParticipantView
            roomState={roomState}
            key={participant.id}
            participant={participant}
            sessionID={roomState.session.id}
            replaceQuestion={replaceQuestion}
          />
        );
      });
    }
    return null;
  }, [offStageParticipants]);
  return (
    <S.AttendeesListContainer>
      {alertBanner.alertType === 'alert' && (
        <Alert
          type="critical"
          text={
            alertBanner.text.includes('issue recording')
              ? 'There is an issue recording the video and audio of the attendees on stage'
              : alertBanner.text
          }
        ></Alert>
      )}
      <S.AttendeesListHeader>On stage</S.AttendeesListHeader>
      <S.OnStageAttendeesContainer>
        {OnStageParticipants}
      </S.OnStageAttendeesContainer>
      {OffStageParticipants && OffStageParticipants?.length > 0 && (
        <S.AttendeesListHeader>Off stage</S.AttendeesListHeader>
      )}
      <S.OffStageAttendeesContainer>
        {OffStageParticipants}
      </S.OffStageAttendeesContainer>
    </S.AttendeesListContainer>
  );
};
