import React from 'react';
// Style
import S from './style';

interface EmojiProps {
  label?: string;
  symbol: string;
  size?: number;
}

const Emoji = ({label, symbol, size}: EmojiProps) => {
  return (
    <S.Emoji
      role="img"
      aria-label={label ? label : ''}
      aria-hidden={label ? 'false' : 'true'}
      $size={size}
    >
      {symbol}
    </S.Emoji>
  );
};

export default React.memo(Emoji);
