import 'lazysizes';

export const lazyProps = {
  attributeConfig: {
    src: 'data-src',
    srcSet: 'data-srcset',
    sizes: 'data-sizes',
  },
  className: 'lazyload',
};

const DEFAULT_IMAGE = 'images/bright.png';

/**
 * Generate image url given path to image
 *
 * @param   {string}  path  path to image
 *
 * @return  {string}        full image url
 */
export const getSrc = (path: string): string => {
  return `${process.env.NEXT_PUBLIC_IMAGE_PREFIX}${
    path ? path : DEFAULT_IMAGE //Using a standard ternary here because ?? is true for empty strings
  }`;
};

const USER_COLORS = [
  {
    background: {color: 'pink', value: '100'},
    foreground: {color: 'pink', value: '500'},
  },
  {
    background: {color: 'pink', value: '500'},
    foreground: {color: 'pink', value: '100'},
  },
  {
    background: {color: 'scarlet', value: '100'},
    foreground: {color: 'scarlet', value: '500'},
  },
  {
    background: {color: 'orange', value: '500'},
    foreground: {color: 'orange', value: '100'},
  },
  {
    background: {color: 'cactus', value: '100'},
    foreground: {color: 'cactus', value: '500'},
  },
  {
    background: {color: 'cactus', value: '500'},
    foreground: {color: 'cactus', value: '100'},
  },
  {
    background: {color: 'green', value: '100'},
    foreground: {color: 'green', value: '500'},
  },
  {
    background: {color: 'indigo', value: '100'},
    foreground: {color: 'indigo', value: '500'},
  },
  {
    background: {color: 'indigo', value: '500'},
    foreground: {color: 'indigo', value: '100'},
  },
];

interface UserColors {
  background: {color: string; value: string};
  foreground: {color: string; value: string};
}

export const getAvatarColors = (input?: string): UserColors => {
  if (!input) return USER_COLORS[0];
  // Simple hash code function
  let hash = 0,
    i: number,
    chr: number;
  for (i = 0; i < input.length; i++) {
    chr = input.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return USER_COLORS[Math.abs(hash) % USER_COLORS.length];
};
