import styled, {DefaultTheme} from 'styled-components';
import {camelCase} from 'lodash';
import {
  getEyebrowMD,
  getParagraphSM,
} from '@brightlive/shared/styles/typography-v3';
import {BasicColors} from './index';
import {hover, active} from '../../helpers/style';

const S: Record<string, React.ElementType> = {};

type TagTypes = 'basic' | 'removable' | 'special new' | 'special live';

const TAG_HEIGHT = 24;

const getColor = (
  theme: DefaultTheme,
  tagType: TagTypes,
  colorType: 'backgroundColor' | 'color',
  basicColor?: BasicColors
) => {
  if (tagType === 'special live') {
    return theme.tag.specialLive[colorType];
  } else if (tagType === 'special new') {
    return theme.tag.specialNew[colorType];
  } else if (tagType === 'removable') {
    return theme.tag.removable[colorType];
  } else {
    return theme.tag[camelCase(basicColor)][colorType];
  }
};

S.Tag = styled.div(
  (props: {$type: TagTypes; $color?: BasicColors; theme: DefaultTheme}) => `
  height: ${TAG_HEIGHT}px;
  border-radius: ${props.theme.borderRadius.SM};
  padding-left: ${props.theme.spacing['2XS']};
  padding-right: ${
    props.$type === 'removable' ? 0 : props.theme.spacing['2XS']
  };
  background-color: ${getColor(
    props.theme,
    props.$type,
    'backgroundColor',
    props.$color
  )};
  display: inline-flex;
  align-items: center;
`
);

S.TagText = styled.p(
  (props: {$type: TagTypes; $color?: BasicColors; theme: DefaultTheme}) => `
  ${
    props.$type === 'special live' || props.$type === 'special new'
      ? getEyebrowMD(props.theme)
      : getParagraphSM(props.theme)
  }
    color: ${getColor(props.theme, props.$type, 'color', props.$color)};
    white-space: nowrap;


  }
`
);

S.Icon = styled.div`
  margin-right: ${props => props.theme.spacing['2XS']};
  display: flex;
  align-items: center;
`;

S.XIconCircle = styled.div(
  (props: {theme: DefaultTheme}) => `
  width: ${TAG_HEIGHT}px;
  height: ${TAG_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: ${props.theme.borderRadius.SM};
  margin-left: ${props.theme.spacing['2XS']};
  position: relative;
  overflow: hidden;

  ${hover({
    backgroundColor: props.theme.actionColor.hover,
    borderRadius: props.theme.borderRadius.SM,
  })}
  ${active({
    backgroundColor: props.theme.actionColor.pressed,
    borderRadius: props.theme.borderRadius.SM,
  })}
`
);

S.XIcon = styled.img`
  width: 16px;
`;

export default S;
