import styled, {DefaultTheme} from 'styled-components';
import {motion} from 'framer-motion';
import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag, {getParagraphMD} from '@brightlive/shared/styles/typography-v3';
import {getTheme} from '@brightlive/shared/helpers/getTheme';

const S = {
  NavigationBar: styled(motion.div)`
    height: ${props => props.theme.sizing.mobile.navHeight};
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    ${media.MD} {
      height: ${props => props.theme.sizing.desktop.navHeight};
    }
  `,

  Background: styled.div(
    (props: {
      $isSolid: boolean;
      $hamburgerOpen: boolean;
      theme: DefaultTheme;
    }) => `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: opacity 0.5s ease-in-out;
    background:  ${props.theme.backgroundColor.body};
    height: ${props.theme.sizing.mobile.navHeight};
    opacity: ${props.$isSolid ? '0.8' : '0'};
    transition: opacity 0.5s ease-in-out;
    border-bottom: ${
      getTheme(props.theme) === 'light' && !props.$hamburgerOpen
        ? `${props.theme.borderWidth[1]} solid ${props.theme.borderColor.subdued}`
        : 'none'
    };
    backdrop-filter: blur(24px);

    ${media.MD} {
      height: ${props.theme.sizing.desktop.navHeight};
    }
  `
  ),

  Content: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 ${props => props.theme.margin.mobile.page};
    flex: 1;
    height: 100%;

    ${media.MD} {
      padding: 0 ${props => props.theme.margin.desktop.page};
    }
  `,

  CloseIcon: styled.div(
    (props: {$searchOpen: boolean; theme: DefaultTheme}) => `
      display: none;

      ${media.MD} {
        display: ${props.$searchOpen ? 'flex' : 'none'};
        cursor: pointer;
        height: 48px;
        width: 48px;
        border-radius: 100px;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: ${props.theme.actionColor.hover};
        }

        &:pressed {
          background-color: ${props.theme.actionColor.pressed};
        }
      }
    `
  ),

  Back: styled.div`
    cursor: pointer;
    margin-left: -${props => props.theme.spacing.XS};

    ${media.MD} {
      display: none;
    }
  `,

  SearchInput: styled.div(
    (props: {
      $searchOpen: boolean;
      $hamburgerOpen: boolean;
      $isFocused: boolean;
      $backgroundColor: string;
      theme: DefaultTheme;
    }) => `
      background: ${
        props.$searchOpen
          ? props.theme.backgroundColor.secondary
          : 'transparent'
      };
      box-sizing: border-box;
      border-radius: 100px;
      display: ${props.$hamburgerOpen ? 'none' : 'flex'};
      align-items: center;
      position: relative;
      cursor: pointer;
      transition: width 0.5s ease-out, background 0.5s ease-out;
      width: ${props.$searchOpen ? '100%' : '48px'};

      ${media.MD} {
        margin-right: ${props.theme.spacing.MD};
        width: ${props.$searchOpen ? '360px' : '48px'};
      }
    `
  ),

  SearchIcon: styled.div(
    (props: {$searchOpen: boolean; theme: DefaultTheme}) => `
    cursor: pointer;
    height: 48px;
    width: 48px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${props.theme.actionColor.hover};
    }

    &:pressed {
      background-color: ${props.theme.actionColor.pressed};
    }
  `
  ),

  Input: styled.input`
    ${props => getParagraphMD(props.theme)}
    flex: 1;
    border: none;
    background-color: transparent;
    color: ${props => props.theme.contentColor.default};

    &::placeholder {
      color: ${props => props.theme.contentColor.subdued};
      opacity: 1; /* for Firefox */
    }
  `,

  LeftContent: styled.div(
    (props: {$searchOpen: boolean}) => `
      display: ${props.$searchOpen ? 'none' : 'flex'};
      align-items: center;
      flex: 1;

      ${media.MD} {
        display: flex;
        height: 100%;
      }
    `
  ),

  DesktopNavItems: styled.div`
    display: none;

    ${media.MD} {
      display: flex;
      align-items: center;
      flex: 1;
      height: 100%;
    }
  `,

  LivekitDesktopMenuItem: styled.div`
    margin-right: ${props => props.theme.spacing.LG};
    display: flex;
    position: relative;
    align-items: center;
    height: 100%;
  `,

  TabText: styled(Ag.ParagraphMD)(
    (props: {$selected: boolean; theme: DefaultTheme}) => `
    color: ${
      props.$selected
        ? props.theme.contentColor.default
        : props.theme.contentColor.inverseSubdued
    };
    `
  ),

  LivekitDesktopMenu: styled.div`
    display: none;

    ${media.MD} {
      display: flex;
      margin-right: ${props => props.theme.spacing.LG};
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }
  `,

  LivekitLiveIndicator: styled(motion.div)`
    display: flex;
    align-items: center;
    opacity: 0;
    margin-left: ${props => props.theme.spacing.LG};

    ${media.MD} {
      margin-left: -${props => props.theme.spacing.LG};
    }
  `,

  LivekitLiveIcon: styled.div`
    height: 14px;
    width: 14px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${props => props.theme.spacing.XS};
  `,

  LivekitLiveIconOuter: styled(motion.div)`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${props => props.theme.contentColor.brand};
    opacity: 0.5;
  `,

  LivekitLiveIconInner: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${props => props.theme.contentColor.brand};
  `,

  LivekitLiveText: styled(motion.p)`
    ${props => getParagraphMD(props.theme)}
    color: ${props => props.theme.contentColor.brand};
  `,

  NavItem: styled.a(
    (props: {$isActive: boolean; theme: DefaultTheme}) => `
    ${getParagraphMD(props.theme)}
    color: ${props.theme.contentColor.default};
    height: 100%;
    padding: 0 ${props.theme.spacing.MD};
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    font-weight: ${
      props.$isActive
        ? props.theme.fontWeight.bold
        : props.theme.fontWeight.regular
    };

    &:focus:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: ${props.theme.borderWidth[4]} solid ${
      props.theme.focusColor.default
    };
    }

    &:hover {
      background-color: ${props.theme.actionColor.hover};
    }

    &:pressed {
      background-color: ${props.theme.actionColor.pressed};
    }
  `
  ),

  RightContent: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    ${media.MD} {
      height: 100%;
      width: auto;
      justify-content: flex-start;
    }
  `,

  LoginButton: styled.div`
    margin-right: ${props => props.theme.spacing.MD};
    height: 100%;
  `,

  LogoWrapper: styled.a`
    display: block;

    ${media.MD} {
      margin-right: ${props => props.theme.spacing['2XL']};
    }
  `,

  Logo: styled.img`
    height: 48px;
  `,

  User: styled.div(
    (props: {$dropdownOpen: boolean; theme: DefaultTheme}) => `
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: ${props.theme.spacing.XS};

    ${media.MD} {
      padding: ${props.theme.spacing.XS};
      border-radius: 100px;
      position: relative;
      background: ${
        props.$dropdownOpen ? props.theme.actionColor.pressed : 'transparent'
      };

      ${
        !props.$dropdownOpen
          ? `&:hover {
        background: ${props.theme.actionColor.hover};
      }`
          : ''
      }

      &:active {
        background: ${props.theme.actionColor.pressed};
      }
    }
  `
  ),

  DisplayName: styled(Ag.ParagraphMD)`
    display: none;

    ${media.MD} {
      display: block;
      position: relative;
      z-index: 1;
    }
  `,

  Arrow: styled.div(
    (props: {$dropdownOpen: boolean; theme: DefaultTheme}) => `

    display: none;

    ${media.MD} {
      margin-left: ${props.theme.spacing['2XS']};
      transform: ${props.$dropdownOpen ? 'scaleY(-1)' : 'none'};
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
    }
    `
  ),

  UserAvatar: styled.div`
    ${media.MD} {
      margin-right: ${props => props.theme.spacing.XS};
      position: relative;
      z-index: 1;
    }
  `,

  Menu: styled.div`
    display: none;

    ${media.MD} {
      display: block;
      width: 200px;
      position: absolute;
      bottom: -${props => props.theme.spacing.SM};
      right: 0;
    }
  `,

  HamburgerIcon: styled.div`
    height: 48px;
    width: 48px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: ${props => props.theme.actionColor.hover};
    }

    &:pressed {
      background-color: ${props => props.theme.actionColor.pressed};
    }

    ${media.MD} {
      display: none;
    }
  `,

  AnimationWrapper: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2000;
    position: absolute;
    left: 90px;
    top: 2px;
    transform: rotate(10.98deg);

    ${media.MD} {
      right: 17px;
      left: unset;
      top: -17px;
    }
  `,
};

export default S;
