import styled, {DefaultTheme} from 'styled-components';

const S = {
  StatsContainer: styled.div`
    color: ${props => props.theme.contentColor.default};
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    overflow-y: auto;
  `,
  StatsTable: styled.table`
    margin: ${props => props.theme.spacing.MD};
  `,
  StatsRow: styled.tr(
    (props: {odd?: boolean; theme: DefaultTheme}) => `
  background-color: ${
    props.odd
      ? props.theme.backgroundColor.secondary
      : props.theme.backgroundColor.primary
  };
`
  ),
  StatsHead: styled.th`
    text-align: left;
    padding: ${props => props.theme.spacing['2XS']};
    font-weight: ${props => props.theme.fontWeight.bold};
  `,
  StatsCell: styled.td`
    padding: ${props => props.theme.spacing['2XS']};
    max-width: 100px;
  `,
};
export default S;
