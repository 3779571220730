/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BrightParticipantEvent,
  BrightRoomEvent,
  TrackSubscriptionMode,
} from 'bright-livekit';
import {EventRegister} from 'bright-livekit/services/EventRegister';
import {
  ConnectionQuality,
  LocalParticipant,
  LocalTrack,
  Participant,
  ParticipantEvent,
  RemoteTrackPublication,
  RoomEvent,
  Track,
  TrackPublication,
} from 'livekit-client';
import {TrackType} from '../BrightParticipant';
import {ITrackPublication} from '../track/ITrackPublication';
import {LivekitTrackPublication} from '../track/LivekitTrackPublication';
import {IParticipant} from './IParticipant';

export class LivekitParticipant implements IParticipant {
  private events: WeakMap<Function, (...params) => void>;
  private trackPublications: WeakMap<TrackPublication, LivekitTrackPublication>;
  facingMode: 'user' | 'environment';
  identity: string;
  participant: Participant;
  currentMode: TrackSubscriptionMode;
  mode: TrackSubscriptionMode;
  modeDebounceTimeout: NodeJS.Timeout | null;
  constructor(participant: Participant) {
    this.facingMode = 'environment';
    this.participant = participant;
    this.identity = participant.identity;
    this.currentMode = TrackSubscriptionMode.None;
    this.modeDebounceTimeout = null;
    this.mode = TrackSubscriptionMode.None;
    this.events = new WeakMap();
    this.trackPublications = new WeakMap();
  }

  public get isCameraEnabled(): boolean {
    return this.participant.isCameraEnabled;
  }
  public get isMicrophoneEnabled(): boolean {
    return this.participant.isMicrophoneEnabled;
  }
  public get isSpeaking(): boolean {
    return this.participant.isSpeaking;
  }
  public get connectionQuality(): string {
    return this.participant.connectionQuality.toString();
  }
  public get isLocal(): boolean {
    return this.participant instanceof LocalParticipant;
  }
  public get isPresent(): boolean {
    return (
      !!this.participant.identity &&
      this.participant.connectionQuality !== ConnectionQuality.Unknown
    );
  }
  public get tracks(): ITrackPublication[] {
    return this.getTracks();
  }
  public get audioTracks(): ITrackPublication[] {
    return this.getTracks().filter(t => t.kind === TrackType.Audio);
  }
  public get videoTracks(): ITrackPublication[] {
    return this.getTracks().filter(t => t.kind === TrackType.Video);
  }
  setMicrophoneEnabled(enabled: boolean) {
    if (this.participant instanceof LocalParticipant) {
      this.participant.setMicrophoneEnabled(enabled);
    }
  }
  async setCameraEnabled(enabled: boolean) {
    if (this.participant instanceof LocalParticipant) {
      await this.participant.setCameraEnabled(enabled);
    }
  }
  flipCamera() {
    if (this.participant instanceof LocalParticipant) {
      const videoPub = this.participant.getTrack(LocalTrack.Source.Camera);
      if (!videoPub) {
        // Check if video exists before restarting track
        return;
      }
      this.facingMode = this.facingMode === 'user' ? 'environment' : 'user';
      videoPub.videoTrack?.restartTrack({facingMode: this.facingMode});
    }
  }

  private _trackPublicationConverter = (
    handler: (publication: LivekitTrackPublication) => void
  ) => {
    return (publication: TrackPublication) => {
      handler(this.getTrackPublication(publication));
    };
  };

  on(
    event: BrightRoomEvent | BrightParticipantEvent,
    handler: (...params) => void
  ) {
    const e = this.getEvent(event);
    if (e && this.participant) {
      switch (event) {
        case BrightParticipantEvent.TrackMuted:
        case BrightParticipantEvent.TrackUnmuted:
          this.participant.on(
            e,
            EventRegister.wrap(this._trackPublicationConverter, handler)
          );
          break;
        default:
          this.participant.on(e, handler);
          break;
      }
    }
  }
  off(event: BrightRoomEvent | BrightParticipantEvent, handler: () => void) {
    const e = this.getEvent(event);
    if (e && this.participant) {
      switch (event) {
        case BrightParticipantEvent.TrackMuted:
        case BrightParticipantEvent.TrackUnmuted:
          this.participant.off(
            e,
            EventRegister.wrap(this._trackPublicationConverter, handler)
          );
          break;
        default:
          this.participant.off(e, handler);
          break;
      }
    }
  }
  private getEvent(event: BrightRoomEvent | BrightParticipantEvent) {
    switch (event) {
      case BrightRoomEvent.LocalTrackUnpublished:
        return RoomEvent.LocalTrackUnpublished;
      case BrightRoomEvent.LocalTrackPublished:
        return RoomEvent.LocalTrackPublished;
      case BrightParticipantEvent.TrackPublished:
        return ParticipantEvent.TrackPublished;
      case BrightParticipantEvent.TrackMuted:
        return ParticipantEvent.TrackMuted;
      case BrightParticipantEvent.TrackUnmuted:
        return ParticipantEvent.TrackUnmuted;
      case BrightParticipantEvent.IsSpeakingChanged:
        return ParticipantEvent.IsSpeakingChanged;
      case BrightParticipantEvent.TrackUnpublished:
        return ParticipantEvent.TrackUnpublished;
      case BrightParticipantEvent.TrackSubscribed:
        return ParticipantEvent.TrackSubscribed;
      case BrightParticipantEvent.TrackUnsubscribed:
        return ParticipantEvent.TrackUnsubscribed;
      case BrightParticipantEvent.LocalTrackPublished:
        return ParticipantEvent.LocalTrackPublished;
      case BrightParticipantEvent.LocalTrackUnpublished:
        return ParticipantEvent.LocalTrackUnpublished;
      default:
        return null;
    }
  }
  /**
   * Actually set the track subscription on an interval to debounce constant calls on scroll or render events
   *
   * @param   {TrackSubscriptionMode}  mode  Mode to set
   *
   */
  private _setTrackSubscriptionsDebounced(mode: TrackSubscriptionMode) {
    if (this.mode === mode) {
      return;
    }
    console.log('Setting subscription: ' + this.identity + ': ' + mode);
    this.mode = mode;
    this.ensureSubscription();
  }
  /**
   * Set the current track subscription mode
   *
   * @param   {TrackSubscriptionMode}  mode  Mode to set
   *
   */
  setTrackSubscriptions(mode: TrackSubscriptionMode) {
    this.currentMode = mode;
    // If the user scrolls very fast or an element is temporarily invisible due to a re-render of it's container we don't want to unsubscribe it immediately. Wait 1 second and make sure is still hidden
    if (mode === TrackSubscriptionMode.None) {
      this.modeDebounceTimeout = setTimeout(() => {
        if (this.currentMode === TrackSubscriptionMode.None) {
          this._setTrackSubscriptionsDebounced(mode);
        }
      }, 1000);
    } else {
      // The user's tracks have been re-subscribed to before the mode debounce timer. Cancel that timer
      if (this.modeDebounceTimeout) {
        clearTimeout(this.modeDebounceTimeout);
      }
      this._setTrackSubscriptionsDebounced(mode);
    }
  }
  /**
   * Ensure the correct tracks are subscribed to based on the subscription mode.
   * Call when a new track is published for this user
   *
   */
  ensureSubscription() {
    this.participant?.videoTracks.forEach(t => {
      if (t instanceof RemoteTrackPublication) {
        const enable = [
          TrackSubscriptionMode.VideoOnly,
          TrackSubscriptionMode.Both,
        ].includes(this.mode);
        t.setSubscribed(enable);
        if (enable && t.track?.start) {
          t.track?.start();
        }
      }
    });
    this.participant?.audioTracks.forEach(t => {
      if (t instanceof RemoteTrackPublication) {
        const enable = [
          TrackSubscriptionMode.AudioOnly,
          TrackSubscriptionMode.Both,
        ].includes(this.mode);
        t.setSubscribed(enable);
        if (enable && t.track?.start) {
          t.track?.start();
        }
      }
    });
  }
  getTrackPublication(publication: TrackPublication) {
    let trackPublication = this.trackPublications.get(publication);
    if (!trackPublication) {
      trackPublication = new LivekitTrackPublication(publication);
      this.trackPublications.set(publication, trackPublication);
    }
    return trackPublication;
  }
  getTracks(): ITrackPublication[] {
    return this.participant.getTracks().map(t => this.getTrackPublication(t));
  }
  getTrack(type: TrackType): ITrackPublication | undefined {
    let livekitType = Track.Source.Unknown;
    switch (type) {
      case TrackType.Audio:
        livekitType = Track.Source.Microphone;
        break;
      case TrackType.ScreenShare:
        livekitType = Track.Source.ScreenShare;
        break;
      case TrackType.ScreenShareAudio:
        livekitType = Track.Source.ScreenShareAudio;
        break;
      case TrackType.Video:
        livekitType = Track.Source.Camera;
        break;
      default:
        break;
    }
    const livekitTrackPub = this.participant.getTrack(livekitType);
    if (livekitTrackPub) {
      return this.getTrackPublication(livekitTrackPub);
    }
    return;
  }
}
