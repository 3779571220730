import React from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
// Types
import {contrastIsLight} from '@brightlive/shared/helpers/colorFormatter';
import {Hex} from '@brightlive/shared/helpers/interfaces';

const Block = ({brandColor, theme}: {brandColor: Hex; theme: DefaultTheme}) => (
  <svg viewBox="0 0 112 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="112" height="72" fill={theme.color.wgrey['100']} />
    <rect
      width="77"
      height="40"
      transform="matrix(1 0 0 -1 17.5 56)"
      fill={brandColor}
    />
    <path
      d="M34.636 41H36.668V37L36.588 32.872L39.116 37L41.644 41H44.028V29.8H41.996L42.012 33.816L42.092 37.944L39.548 33.816L37.004 29.8H34.636V41ZM48.3403 41.144C49.4603 41.144 50.4043 40.696 50.9803 39.832V41H53.1883V35.432C53.1883 33.64 51.9723 32.616 49.7803 32.6C47.7323 32.6 46.2603 33.64 45.9243 35.448H47.9723C48.1643 34.504 48.7723 34.024 49.6683 34.024C50.5483 34.024 50.9963 34.488 50.9963 34.984C50.9963 35.752 50.0203 36.024 48.9323 36.328C47.4443 36.744 45.7323 37.208 45.7323 38.984C45.7323 40.28 46.7563 41.144 48.3403 41.144ZM47.8923 38.792C47.8923 37.864 48.8203 37.608 49.7163 37.32C50.1803 37.176 50.6443 37.016 50.9803 36.744V38.904C50.5643 39.448 49.9083 39.752 49.1723 39.752C48.4363 39.752 47.8923 39.448 47.8923 38.792ZM55.2435 41H57.4675V35.032C57.7875 34.52 58.3315 34.168 58.9715 34.168C59.8195 34.168 60.3635 34.664 60.3635 35.608V41H62.5875V35.048C62.8915 34.536 63.4515 34.168 64.0915 34.168C64.9395 34.168 65.4675 34.664 65.4675 35.608V41H67.6915V35.272C67.6915 33.72 66.6675 32.616 65.0355 32.616C63.8515 32.616 62.9075 33.224 62.3795 34.152C61.9955 33.224 61.1315 32.616 59.9315 32.616C58.8595 32.616 57.9955 33.144 57.4675 33.992V32.776H55.2435V41ZM73.3435 41.176C75.3755 41.176 76.8315 40.04 77.2155 38.104H75.1355C74.9115 39.192 74.2715 39.736 73.3755 39.736C72.2555 39.736 71.5195 38.84 71.4555 37.096H77.3275V36.776C77.3275 34.088 75.5995 32.584 73.3755 32.584C70.9755 32.584 69.2955 34.168 69.2955 36.872C69.2955 39.56 70.9595 41.176 73.3435 41.176ZM73.3595 34.024C74.3515 34.024 75.0235 34.68 75.2155 36.008H71.5195C71.7115 34.696 72.3835 34.024 73.3595 34.024Z"
      fill={
        contrastIsLight({
          baseColor: brandColor,
          whiteColor: theme.color.white as Hex,
        })
          ? theme.color.white
          : theme.color.black
      }
    />
  </svg>
);

export default withTheme(Block);
