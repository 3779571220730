import React from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
// Components
import Spinner from '@brightlive/shared/components/Spinner';
// Helpers
import {contrastIsLight} from '@brightlive/shared/helpers/colorFormatter';
import {Hex} from '@brightlive/shared/helpers/interfaces';
// Styles
import S from './style';

const StatusMessageComponent = ({
  text,
  brandColor,
  loading,
  fullPage = true,
  theme,
}: {
  text: string;
  brandColor: Hex;
  loading?: boolean;
  fullPage?: boolean;
  theme: DefaultTheme;
}) => {
  const spinnerType = contrastIsLight({
    baseColor: brandColor,
    whiteColor: theme.backgroundColor.white as Hex,
  })
    ? 'inverse'
    : 'default';
  return (
    <S.LivekitStatusMessage $brandColor={brandColor} $fullPage={fullPage}>
      <S.LivekitStatusHeader $brandColor={brandColor}>
        {text}
      </S.LivekitStatusHeader>
      {loading && <Spinner size="large" type={spinnerType} />}
    </S.LivekitStatusMessage>
  );
};

export const StatusMessage = withTheme(StatusMessageComponent);
