import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useInterval} from '@brightlive/shared/hooks/useInterval';
// Components
import {Role, RoomState, NotificationType} from 'bright-livekit';
import Modal from '@brightlive/shared/components/Modal';
import Button from '@brightlive/shared/components/Button';
// Icons
import Warning from '@brightlive/shared/icons/Warning';
// Redux
import {toggleToast} from 'redux/ui/actions';
// Style
import S from './style';

enum Banners {
  clear = '',
  joinedStage = 'Welcome to the stage!',
  unstableInternet = 'Your internet connection is unstable',
  AskToLeaveStage = 'Ready to leave the stage?',
}
enum Toasts {
  joinedStage = ' joined the stage',
  leftStage = ' left the stage',
  declinedInvite = ' wants to remain off stage',
  stageIsFull = ' cannot join the stage, the stage is full',
}

export const NotificationsView = ({
  roomState,
  sessionID,
  navExpanded = false,
}: {
  sessionID: string;
  roomState: RoomState;
  navExpanded?: boolean;
}) => {
  const dispatch = useDispatch();
  const getUser = (id: string) => {
    return roomState.participants.find(p => p.id === id);
  };
  const [muteModalVisibilty, setMuteModalVisibility] = useState(false);
  const [toggleCameraModalVisibilty, setToggleCameraModalVisibilty] =
    useState(false);
  const [stageInviteVisibility, setStageInviteVisibility] = useState(false);
  const [stageInviteTime, setStageInviteTime] = useState(0);
  const [stageCountdown, setStageCountdown] = useState(0);
  const [stageInviteNotificationID, setStageInviteNotificationID] =
    useState('');
  const [notificationSenderName, setNotificationSenderName] = useState('');
  const [bannerTime, setBannerTime] = useState(0);
  const [bannerText, setBannerText] = useState(Banners.clear);
  const [stageInviteLoading, setStageInviteLoading] = useState(false);

  const cameraEnabled =
    !!roomState.selfParticipant?.videoParticipant?.isCameraEnabled;
  const micEnabled =
    !!roomState.selfParticipant?.videoParticipant?.isMicrophoneEnabled;

  useInterval(() => {
    const countdown = Math.floor((stageInviteTime + 30000 - Date.now()) / 1000);
    setStageCountdown(countdown > 0 ? countdown : 0);

    const bannerTimeout = (bannerTime + 7000 - Date.now()) / 1000;
    if (bannerTimeout <= 0) {
      setBannerText(Banners.clear);
    }
  }, 1000);

  const showBanner = (text: Banners) => {
    setBannerTime(Date.now());
    setBannerText(text);
  };

  const showToast = (text: string) => {
    dispatch(toggleToast(true, text, 'default', undefined, 'top'));
  };

  useEffect(() => {
    if (
      stageInviteLoading &&
      roomState.selfParticipant?.role === Role.OnStage
    ) {
      setStageInviteLoading(false);
      setStageInviteVisibility(false);
    }
  }, [roomState.selfParticipant?.role, stageInviteLoading]);

  useEffect(() => {
    if (!roomState.notifications || roomState.notifications.size === 0) {
      return;
    }
    for (const notification of roomState.notifications.docs) {
      if (window.sessionStorage.getItem(notification.id)) {
        continue;
      }
      window.sessionStorage.setItem(notification.id, 'true');
      if (roomState.currentUser.id === notification.data().recipient) {
        const user = getUser(notification.data().sender);
        if (
          user?.displayName &&
          [
            NotificationType.AskToUnmute,
            NotificationType.AskToTurnOnCamera,
            NotificationType.InviteToStage,
          ].includes(notification.data().type)
        ) {
          setNotificationSenderName(user?.displayName);
        }
        if (notification.data().type === NotificationType.InviteToStage) {
          setStageCountdown(30);
          setStageInviteNotificationID(notification.id);
          setStageInviteVisibility(true);
          setStageInviteTime(Date.now());
        } else if (notification.data().type === NotificationType.AskToUnmute) {
          setMuteModalVisibility(true);
        } else if (
          notification.data().type === NotificationType.AskToTurnOnCamera
        ) {
          setToggleCameraModalVisibilty(true);
        } else if (notification.data().type === NotificationType.JoinedStage) {
          showBanner(Banners.joinedStage);
        } else if (
          notification.data().type === NotificationType.AskToLeaveStage
        ) {
          showBanner(Banners.AskToLeaveStage);
        } else if (
          notification.data().type === NotificationType.UnstableInternet
        ) {
          showBanner(Banners.unstableInternet);
        } else if (notification.data().type === NotificationType.StageIsFull) {
          showToast('You' + Toasts.stageIsFull);
          setStageInviteLoading(false);
          setStageInviteVisibility(false);
        }
      }
      if (
        roomState.selfParticipant?.role === Role.Creator ||
        roomState.selfParticipant?.isShowRunner
      ) {
        const recipientUser = getUser(notification.data().recipient);
        if (notification.data().type === NotificationType.DeclinedStage) {
          showToast(
            (recipientUser?.displayName || 'User') + Toasts.declinedInvite
          );
        } else if (
          notification.data().type === NotificationType.JoinedStage &&
          roomState.currentUser.id !== notification.data().recipient
        ) {
          showToast(
            (recipientUser?.displayName || 'User') + Toasts.joinedStage
          );
        } else if (notification.data().type === NotificationType.LeaveStage) {
          showToast((recipientUser?.displayName || 'User') + Toasts.leftStage);
        } else if (notification.data().type === NotificationType.StageIsFull) {
          showToast(
            (recipientUser?.displayName || 'User') + Toasts.stageIsFull
          );
        }
      }
      // Display user has joined
      if (
        roomState.currentUser.id !== notification.data().sender &&
        notification.data().type === NotificationType.JoinedSession
      ) {
        const senderUser = getUser(notification.data().sender);
        showToast(`${senderUser?.displayName ?? 'User'} joined`);
      }
    }
  }, [roomState.notifications]);

  const ActionButtons = useMemo(() => {
    if (bannerText === Banners.joinedStage) {
      if (cameraEnabled && micEnabled) return;
      let text;
      if (!cameraEnabled && !micEnabled) text = 'Turn on mic and camera';
      if (!cameraEnabled) text = 'Turn on camera';
      if (!micEnabled) text = 'Turn on mic';
      return (
        <Button
          type="primary inverse"
          size="small"
          text={text}
          width="auto"
          onClick={() => {
            if (!cameraEnabled)
              roomState?.videoService?.localParticipant?.setCameraEnabled(true);
            if (!micEnabled)
              roomState?.videoService?.localParticipant?.setMicrophoneEnabled(
                true
              );
            showBanner(Banners.clear);
          }}
        />
      );
    }
    return null;
  }, [roomState, bannerText]);

  return (
    <>
      {bannerText !== Banners.clear && (
        <S.NotificationBanner
          $navExpanded={navExpanded}
          $warning={bannerText === Banners.unstableInternet}
        >
          <S.NotificationBannerContent
            $centered={
              !!(
                cameraEnabled &&
                micEnabled &&
                bannerText !== Banners.AskToLeaveStage
              )
            }
            $warning={bannerText === Banners.unstableInternet}
          >
            {bannerText}
            {ActionButtons}
            {bannerText === Banners.AskToLeaveStage && (
              <Button
                type="primary inverse"
                size="small"
                text="Leave"
                width="auto"
                onClick={() => {
                  if (
                    roomState.currentUser.id &&
                    roomState?.selfParticipant?.id
                  ) {
                    roomState.videoUtils?.removeFromStage(
                      sessionID,
                      roomState.currentUser.id,
                      roomState.selfParticipant.id,
                      !!roomState.modControls
                    );
                    showBanner(Banners.clear);
                  }
                }}
              />
            )}
          </S.NotificationBannerContent>
        </S.NotificationBanner>
      )}
      {muteModalVisibilty && (
        <Modal
          closeModal={() => {
            setMuteModalVisibility(false);
          }}
          buttons={{
            button1Text: 'Stay muted',
            button2Text: 'Unmute mic',
            button2OnClick: () => {
              roomState?.videoService?.localParticipant?.setMicrophoneEnabled(
                true
              );
              setMuteModalVisibility(false);
            },
          }}
        >
          <S.ModalContent>
            <S.ModalTitle>
              {notificationSenderName} would like you to unmute
            </S.ModalTitle>
          </S.ModalContent>
        </Modal>
      )}
      {toggleCameraModalVisibilty && (
        <Modal
          closeModal={() => {
            setToggleCameraModalVisibilty(false);
          }}
          buttons={{
            button1Text: 'Keep camera off',
            button2Text: 'Turn camera on',
            button2OnClick: () => {
              roomState?.videoService?.localParticipant?.setCameraEnabled(true);
              setToggleCameraModalVisibilty(false);
            },
          }}
        >
          <S.ModalContent>
            <S.ModalTitle>
              {notificationSenderName} would like you to turn on your camera
            </S.ModalTitle>
          </S.ModalContent>
        </Modal>
      )}
      {stageInviteVisibility && (
        <Modal
          closeModal={() => {
            setStageInviteVisibility(false);
          }}
          buttons={{
            button2Loading: stageInviteLoading,
            button1Text: stageCountdown > 0 ? 'Not now' : 'Change question',
            button2Text:
              stageCountdown > 0 ? 'Join and unmute' : 'Resubmit question',
            button1OnClick: () => {
              if (stageCountdown > 0) {
                roomState.videoUtils?.declineStageInvite(
                  sessionID,
                  roomState.currentUser.id,
                  stageInviteNotificationID
                );
                setStageInviteVisibility(false);
              } else if (roomState.selfParticipant) {
                roomState.videoUtils?.removeOwnQuestion(
                  sessionID,
                  roomState.currentUser.id
                );
              }
              setStageInviteVisibility(false);
            },
            button2OnClick: () => {
              if (stageCountdown > 0) {
                setStageInviteLoading(true);
                roomState.videoUtils?.acceptStageInvite(
                  sessionID,
                  roomState.currentUser.id,
                  stageInviteNotificationID
                );
              } else if (roomState.selfParticipant) {
                if (roomState.selfParticipant.question) {
                  roomState.videoUtils?.sendQuestion(
                    sessionID,
                    roomState.selfParticipant.id,
                    roomState.selfParticipant.question || ''
                  );
                }
                setStageInviteVisibility(false);
              }
            },
          }}
        >
          {stageCountdown > 0 ? (
            <S.ModalContent>
              <S.ModalTitle>You're invited to join the stage!</S.ModalTitle>
              <S.ModalBody as="div">
                <p>
                  {notificationSenderName} would like you to invite you to the
                  stage. Ready to join with your mic unmuted?
                </p>
                <br />
                <S.ModalCountdown>
                  <S.Icon>
                    <Warning contentColor="warning" size={24} />
                  </S.Icon>
                  This invitation will expire in {stageCountdown}s
                </S.ModalCountdown>
              </S.ModalBody>
            </S.ModalContent>
          ) : (
            <S.ModalContent>
              <S.ModalTitle>
                Sorry, your invitation to join the stage expired
              </S.ModalTitle>
              <S.ModalBody>
                Resubmit your question to let {notificationSenderName} know
                you're back, or change your question.
              </S.ModalBody>
            </S.ModalContent>
          )}
        </Modal>
      )}
    </>
  );
};
