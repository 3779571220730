import React, {useEffect, useRef, useState} from 'react';
// Components
import {ChatMessage, RoomState} from 'bright-livekit';
// Styles
import S from './style';

export const ChatView = ({roomState}: {roomState: RoomState}) => {
  const listContainer = useRef<HTMLDivElement>(null);
  const [numMessages, setNumMessages] = useState(0);

  // Is the user scrolled to the bottom with 5 pixels of buffer
  const isAtBottom =
    listContainer.current &&
    listContainer.current.scrollTop + 5 >
      listContainer.current.scrollHeight - listContainer.current.offsetHeight;

  useEffect(() => {
    if (!roomState.chat || !listContainer.current) {
      return;
    }

    // Save the last message seen while this component is being viewed
    const latestChatMessage = roomState.chat.docs
      .at(roomState.chat.docs.length - 1)
      ?.data();
    if (latestChatMessage && latestChatMessage.timestamp) {
      // Set to session storage in the event of refresh or close tab
      roomState.setLastSeenChatId(
        roomState.chat.docs[roomState.chat.docs.length - 1].id
      );
    }

    // Is the user scrolled to the bottom
    // or is the new message coming from the current user
    // or is this the first time rendering messages
    // -> if so scroll to the bottom
    if (
      isAtBottom ||
      roomState.chat.docs.at(roomState.chat.docs.length - 1)?.data()
        .participantID === roomState.selfParticipant?.id ||
      (numMessages === 0 && roomState.chat.size > 0)
    ) {
      listContainer.current?.scrollBy({
        top: listContainer.current.scrollHeight,
      });
    }
    setNumMessages(roomState.chat.size);
  }, [roomState.chat]);

  return (
    <>
      {roomState.chat?.docs?.length ? (
        <S.ChatContainer ref={listContainer}>
          {roomState.chat?.docs.map(chatMessage => {
            const chatMessageData = chatMessage.data();
            const participant = roomState.participants.find(
              p => p.id === chatMessageData.participantID
            );
            return (
              <ChatMessage
                key={chatMessage.id}
                avatar={participant?.avatar || ''}
                isMe={
                  chatMessageData.participantID ===
                  roomState.selfParticipant?.id
                }
                text={chatMessageData.messageText}
                displayName={chatMessageData.displayName}
                timestamp={chatMessageData.timestamp?.toDate()}
              />
            );
          })}
        </S.ChatContainer>
      ) : (
        <S.NoChat>
          <S.NoChatIcon>💬</S.NoChatIcon>
          <S.NoChatText>No messages yet</S.NoChatText>
        </S.NoChat>
      )}
    </>
  );
};
