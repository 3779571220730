import React from 'react';
// Components
import {StageProps, StageLayout, StatusMessage} from 'bright-livekit';
// Styles
import S from 'bright-livekit/styles/style';

export const RecordingStage = ({
  roomState,
  participantRenderer,
  sessionID,
  recordingMode,
}: StageProps) => {
  const {isConnecting, error} = roomState;
  if (error) {
    console.error(error);
    return (
      <StatusMessage
        text="Error: Please Refresh"
        brandColor={roomState.brandColor}
      />
    );
  }

  if (roomState.sessionState === 'loading' || isConnecting) {
    return (
      <StatusMessage
        text="Loading"
        brandColor={roomState.brandColor}
        loading={true}
      />
    );
  }

  return (
    // global container
    <S.LivekitWrapper>
      <S.LivekitTopRecording>
        <StageLayout
          roomState={roomState}
          participantRenderer={participantRenderer}
          sessionID={sessionID}
          recordingMode={recordingMode}
        ></StageLayout>
      </S.LivekitTopRecording>
    </S.LivekitWrapper>
  );
};
