import React, {Dispatch, useState} from 'react';
// Components
import {IVideoService} from 'bright-livekit';
import Select from '@brightlive/shared/components/Select';
// Hooks
import {useInterval} from '@brightlive/shared/hooks/useInterval';

export interface DeviceSelectProps {
  videoService: IVideoService;
}
export const DeviceSelect = ({videoService}: DeviceSelectProps) => {
  const [availAudioDevices, setAvailAudioDevices] = useState<MediaDeviceInfo[]>(
    []
  );
  const [availSpeakerDevices, setAvailSpeakerDevices] = useState<
    MediaDeviceInfo[]
  >([]);
  const [availVideoDevices, setAvailVideoDevices] = useState<MediaDeviceInfo[]>(
    []
  );
  const [activeMicrophone, setActiveMicrophone] = useState(
    window?.localStorage.getItem('livekit.audioInput')
  );
  const [activeCamera, setActiveCamera] = useState(
    window?.localStorage.getItem('livekit.videoInput')
  );
  const [activeSpeaker, setActiveSpeaker] = useState(
    window?.localStorage.getItem('livekit.audioOutput')
  );

  useInterval(() => {
    getMediaDevices();
  }, 1000);

  // Find a default or saved value for each device
  function findDevice(
    kind: string,
    savedDevice: string | null,
    set: Dispatch<React.SetStateAction<string | null>>,
    devices: MediaDeviceInfo[]
  ) {
    if (savedDevice) {
      const activeDevice = devices.find(
        device => device.deviceId === savedDevice && device.kind === kind
      );
      set(activeDevice?.deviceId ?? null);
    } else {
      const defaultDevice = devices.find(
        device => device.deviceId === 'default' && device.kind === kind
      );
      set(defaultDevice?.deviceId ?? null);
    }
  }

  const getMediaDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    if (devices?.length) {
      // Save device options to state
      const audioDevices = devices.filter(
        device => device.kind === 'audioinput'
      );
      setAvailAudioDevices(audioDevices);
      const speakerDevices = devices.filter(
        device => device.kind === 'audiooutput'
      );
      setAvailSpeakerDevices(speakerDevices);
      const videoDevices = devices.filter(
        device => device.kind === 'videoinput'
      );
      setAvailVideoDevices(videoDevices);
      // Check for defaults options
      const savedSpeakerDevice = window?.localStorage.getItem(
        'livekit.audioOutput'
      );
      const savedVideoDevice =
        window?.localStorage.getItem('livekit.videoInput');
      const savedAudioDevice =
        window?.localStorage.getItem('livekit.audioInput');

      findDevice('videoinput', savedVideoDevice, setActiveCamera, devices);
      findDevice('audiooutput', savedSpeakerDevice, setActiveSpeaker, devices);
      findDevice('audioinput', savedAudioDevice, setActiveMicrophone, devices);
    }
    return;
  };

  const storageType = {
    audio: 'livekit.audioInput',
    speaker: 'livekit.audioOutput',
    video: 'livekit.videoInput',
  };
  const switchDeviceType = {
    audio: 'audioinput',
    speaker: 'audiooutput',
    video: 'videoinput',
  };
  const stateTypes = {
    audio: availAudioDevices,
    speaker: availSpeakerDevices,
    video: availVideoDevices,
  };
  const setStateTypes = {
    audio: setActiveMicrophone,
    speaker: setActiveSpeaker,
    video: setActiveCamera,
  };
  // Switch to selected device
  const handleDeviceSelect = (deviceId, type) => {
    window?.localStorage?.setItem(storageType[type], deviceId);
    videoService.switchActiveDevice(switchDeviceType[type], deviceId);
    setStateTypes[type](window?.localStorage.getItem(storageType[type]));
  };

  return (
    <>
      <Select
        label="Microphone"
        desktopWidth="400px"
        menuDirection="right"
        hasDesktopMenuStyling={true}
        hasMobileMenuStyling={true}
        placeholder="Select..."
        value={activeMicrophone ?? ''}
        onChange={(id: string) => handleDeviceSelect(id, 'audio')}
        options={stateTypes['audio']?.map(device => ({
          value: device.deviceId,
          label: device.label,
        }))}
      />
      <Select
        label="Camera"
        desktopWidth="400px"
        menuDirection="right"
        hasDesktopMenuStyling={true}
        hasMobileMenuStyling={true}
        placeholder="Select..."
        value={activeCamera ?? ''}
        onChange={(id: string) => handleDeviceSelect(id, 'video')}
        options={stateTypes['video']?.map(device => ({
          value: device.deviceId,
          label: device.label,
        }))}
      />
      {stateTypes['speaker']?.length ? (
        <Select
          label="Speaker"
          desktopWidth="400px"
          menuDirection="left"
          hasDesktopMenuStyling={true}
          hasMobileMenuStyling={true}
          placeholder="Select..."
          value={activeSpeaker ?? ''}
          onChange={(id: string) => handleDeviceSelect(id, 'speaker')}
          options={stateTypes['speaker']?.map(device => ({
            value: device.deviceId,
            label: device.label,
          }))}
        />
      ) : null}
    </>
  );
};
